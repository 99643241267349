<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4" id="menu_root">
    <!-- Brand Logo -->
    <a href="/area-usuario" class="brand-link dnc-brand">
      <!-- <img
        src="../assets/img/Admin.png"
        alt="Administração DNC"
        class="brand-image img-rounded p-1 elevation-3 bg-white"
        style="opacity: 0.8"
      /> -->
      <img
        src="../assets/img/logo_adm.png"
        alt="Administração DNC"
        class="brand-bitmap"
        width="150"
      />
      <!-- <span class="brand-text font-weight-light">DNC Admin</span> -->

    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-0 d-flex">
          <div class="image">
            <b-avatar variant="primary" :src="profissional_url_foto" v-if="(profissional_url_foto!='')"></b-avatar>
            <b-avatar variant="primary" v-else></b-avatar>
          </div>
          <div class="info">
            <a href="#" class="d-block">{{ usuario_nome }}</a>
          </div>
      </div>
      <div class="btn-group info d-flex justify-content-center p-1" >
        <a href="/editar-perfil" class="btn btn-sm bg-gradient-danger text-white"><i class="fas fa-edit"></i> &nbsp;Editar Perfil</a>
        <!-- <a href="#" class="btn btn-sm bg-gradient-primary text-white" @click="confirma_logout()">Sair&nbsp; <i class="fas fa-sign-out-alt"></i></a> -->
      </div>
      <!-- <div class="info d-flex justify-content-center p-1" style="border-bottom:1px solid #c0c0c0">
              <small class="direct-chat-timestamp ">
                <i><a href="" class="nav-link">Editar Perfil</a></i> /
                <i><a href="" class="nav-link">Sair&nbsp; <i class="fas fa-sign-out-alt"></i></a></i>
              </small>
      </div>     -->





      <!-- SidebarSearch Form -->
      <!--
            <div class="form-inline">
                <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-sidebar">
                    <i class="fas fa-search fa-fw"></i>
                    </button>
                </div>
                </div>
            </div> -->

      <!-- Sidebar Menu -->
      <nav class="mt-2" :key="keyMenu">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="true"
        >
          <!-- Add icons to the links using the .nav-icon class
                with font-awesome or any other icon font library -->

          <li
            class="nav-item"
            v-show="menu_operacoes"
            @click="menuAtivo('operacoes')"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ active: menu_ativo.operacoes }"
            >
              <i class="fas fa-hard-hat nav-icon"></i>
              <p>
                Operações
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <!-- Agenda -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-address-book nav-icon"></i>
                  <p>
                    Agenda
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/cupom" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Cupons</p>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/agenda" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Gerenciamento</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Alunos -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-child nav-icon"></i>
                  <p>
                    Alunos
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/menu-alunos" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Gerenciamento</p>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/reset-credencial" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-key nav-icon"></i>
                      <p>Reset Senha</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Carrinho -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-shopping-cart nav-icon"></i>
                  <p>
                    Carrinho
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/compras" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-shopping-basket nav-icon"></i>
                      <p>Compras antigo</p>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/Vendas" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-shopping-basket nav-icon"></i>
                      <p>Compras novo</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Execuções -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-cogs nav-icon"></i>
                  <p>
                    Execuções
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/controle-geral" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Controle Geral</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Facilitadores -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-user-tie nav-icon"></i>
                  <p>
                    Facilitadores
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/menu-alocarfacilitadores" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Alocar Facilitadores</p>
                    </a>
                  </li>
                </ul>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/menu-criarfacilitadores" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Criar Facilitadores</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Salas -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-house-user nav-icon"></i>
                  <p>
                    Salas
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/menu-zoomsalas" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Salas Zoom</p>
                    </a>
                  </li>
                </ul>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/zoom-videos" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Vídeos Zoom</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Treinamentos -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-clipboard-list nav-icon"></i>
                  <p>
                    Treinamentos
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/menu-treinamentos" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Gerenciamento</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Turmas -->
              <li class="nav-item">
                <!-- <a href="#" class="nav-link">
                  <i class="fas fa-chalkboard-teacher nav-icon"></i>
                  <p>
                    Turmas / Grupos
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a> -->
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/menu-turmas" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Gerenciamento</p>
                    </a>
                  </li>
                  <!--
                  <li class="nav-item">
                    <a href="/controle-tarefa" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Controle Tarefas</p>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/grupos" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Grupos</p>
                    </a>
                  </li>
                  -->
                  <li class="nav-item">
                    <a href="/grupos-softcheck" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-users nav-icon"></i>
                      <p>Grupos Softcheck</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- Class -->
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-file-signature nav-icon"></i>
                  <p>
                    DNC.Class
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/gestao-class" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Gerenciamento</p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- Conteudo - LMS -->

          <li class="nav-item" v-show="menu_lms" @click="menuAtivo('lms')">
            <a href="#" class="nav-link" :class="{ active: menu_ativo.lms }">
              <i class="nav-icon fas fa-graduation-cap"></i>
              <p>
                Plataforma LMS
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
                <li class="nav-item">
                    <a href="/regras-lms" class="nav-link">
                        <i class="fas fa-dice-d20 nav-icon"></i>
                        <p>Configuração de regras</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/gestao-de-acessos" class="nav-link">
                      <i class="fa fa-door-open nav-icon"></i>
                      <p>Gestão de Acessos</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/gestao-de-cursos" class="nav-link">
                      <i class="fa fa-cogs nav-icon"></i>
                      <p>Gestão de Cursos</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/gestao-notificacoes" class="nav-link">
                        <i class="far fa-bell nav-icon"></i>
                        <p>Gestão de Notificações</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/gestao-de-versionamento" class="nav-link">
                        <i class="far fa-window-restore nav-icon"></i>
                        <p>Gestão de Versionamento</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/liberacao-slot" class="nav-link">
                        <i class="fas fa-stop-circle nav-icon"></i>
                        <p>Liberação de Slot</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/plano-estudos" class="nav-link">
                        <i class="fas fa-stopwatch nav-icon"></i>
                        <p>Plano de estudos</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/comunicados" class="nav-link">
                        <i class="nav-icon fas fa-bullhorn"></i>
                        <p>Comunicados</p>
                    </a>
                </li>
            </ul>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-cloud-upload-alt nav-icon"></i>
                  <p>
                    Uploads em massa
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/bulk-acessos-cursos" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-clipboard-check nav-icon"></i>
                      <p>Acesso aos cursos</p>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/bulk-arquivos" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-file nav-icon"></i>
                      <p>Bulk - arquivos</p>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/bulk-conteudo" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-cubes nav-icon"></i>
                      <p>Bulk - conteúdo</p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            class="nav-item"
            v-show="menu_financeiro"
            @click="menuAtivo('financeiro')"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ active: menu_ativo.financeiro }"
            >
              <i class="nav-icon fas fa-dollar-sign"></i>
              <p>
                Financeiro
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
                <li class="nav-item">
                  <a href="/cupom" class="nav-link">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-ticket-alt nav-icon"></i>
                    <p>Cupons</p>
                  </a>
                </li>
                <li class="nav-item">
                    <a href="/compras" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-shopping-basket nav-icon"></i>
                      <p>Compras</p>
                    </a>
                </li>
              </ul>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <span class="material-icons nav-icon"> receipt </span>
                  <p>
                    Notas Fiscais
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/emissao-nota-fiscal" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="material-icons nav-icon">
                        receipt_long
                      </span>
                      <p>Emissão em massa</p>
                    </a>
                  </li>
                </ul>
              </li>
              <!-- <li class="nav-item">
                <a href="#" class="nav-link">
                  <span class="material-icons nav-icon">
                    production_quantity_limits
                  </span>
                  <p>
                    Estorno
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="gerenciar_estorno.php" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="material-icons nav-icon">
                        point_of_sale
                      </span>
                      <p>Gerenciar estorno</p>
                    </a>
                  </li>
                </ul>
              </li> -->
              <!-- <li class="nav-item">
                <a href="#" class="nav-link">
                  <span class="material-icons nav-icon">
                    insert_chart_outlined
                  </span>
                  <p>
                    Dashboard
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="fin_chart_carrinho.php" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="material-icons nav-icon">
                        shopping_cart
                      </span>
                      <p>Carrinhos</p>
                    </a>
                  </li>
                </ul>
              </li> -->
            </ul>
          </li>

          <li
            class="nav-item"
            v-show="menu_produto"
            @click="menuAtivo('produto')"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ active: menu_ativo.produto }"
            >
              <i class="nav-icon fas fa-gem"></i>
              <p>
                Produto
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="/controle-tarefa" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-clipboard-check nav-icon"></i>
                  <p>Controle de Assignments</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/provas" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-file-signature nav-icon"></i>
                  <p>Provas</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/regras-lms" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-dice-d20 nav-icon"></i>
                  <p>Regras</p>
                </a>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
            v-show="menu_recursos_humanos"
            @click="menuAtivo('recursos_humanos')"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ active: menu_ativo.recursos_humanos }"
            >
              <i class="nav-icon fas fa-bullhorn"></i>
              <p>
                Recursos Humanos
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
          </li>

          <li
            class="nav-item"
            v-show="menu_marketing"
            @click="menuAtivo('marketing')"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ active: menu_ativo.marketing }"
            >
              <i class="nav-icon fas fa-chalkboard-teacher"></i>
              <p>
                Marketing
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <!-- <li class="nav-item">
                <a href="/carrinho-temporario" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-shopping-cart nav-icon"></i>
                  <p>Criar carrinho</p>
                </a>
              </li> -->
            </ul>
          </li>
          <li class="nav-item" v-show="menu_cxcs" @click="menuAtivo('cxcs')">
            <a href="#" class="nav-link" :class="{ active: menu_ativo.cxcs }">
              <i class="nav-icon fas fa-paint-brush"></i>
              <p>
                Cx/Cs
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="/mentoria" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-check nav-icon"></i>
                  <p>Mentorias</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/abono" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-check nav-icon"></i>
                  <p>Abono de falta</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/assignment" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-tasks nav-icon"></i>
                  <p>Assignments</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/boletim-alunos" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-chalkboard-teacher nav-icon"></i>
                  <p>Boletim dos Alunos</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/boletim-turmas" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-school nav-icon"></i>
                  <p>Boletim das Turmas</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/controle-geral" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-play nav-icon"></i>
                  <p>Execuções de Scripts</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/reset-credencial" class="nav-link">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fas fa-key nav-icon"></i>
                  <p>Reset Senha</p>
                </a>
              </li>
            </ul>
          </li>
          <li
            class="nav-item"
            v-show="menu_vendas"
            @click="menuAtivo('vendas')"
          >
            <a href="#" class="nav-link" :class="{ active: menu_ativo.vendas }">
              <i class="nav-icon fas fa-cash-register"></i>
              <p>
                Vendas
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                    <a href="/compras-alunos" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-child nav-icon"></i>
                      <p>Compras por alunos</p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/compras" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-shopping-basket nav-icon"></i>
                      <p>Compras</p>
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a href="/Vendas" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-shopping-basket nav-icon"></i>
                      <p>Vendas por vendedor</p>
                    </a>
                </li> -->
                <!-- <li class="nav-item">
                  <a href="/cupom-novo" class="nav-link">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-ticket-alt nav-icon"></i>
                    <p>Cupons <i>New</i></p>
                  </a>
                </li> -->
                <!-- <li class="nav-item">
                  <a href="/carrinho-temporario" class="nav-link">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-shopping-cart nav-icon"></i>
                    <p>Criar carrinho</p>
                  </a>
                </li> -->
                <li class="nav-item" v-show="false">
                  <a href="/link-vendas" class="nav-link">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-link nav-icon"></i>
                    <p>Link de Vendas</p>
                  </a>
                </li>
              </ul>

          </li>

          <li
            class="nav-item"
            v-show="menu_tech"
            @click="menuAtivo('tecnologia')"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ active: menu_ativo.tecnologia }"
            >
              <i class="nav-icon fab fa-linux"></i>
              <p>
                Tecnologia
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="fas fa-child nav-icon"></i>
                  <p>
                    Usuários
                    <i class="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="/menu-usuarios" class="nav-link">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="fas fa-list nav-icon"></i>
                      <p>Gerenciamento</p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li
            class="nav-item"
          >
            <a class="nav-link" href="/help">
              <i class="nav-icon fas fa-question"></i>
              <p>
                Vídeos de ajuda
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
          </li>

          <!-- <li class="nav-item ">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Dashboard
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="./index.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Dashboard v1</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="./index2.html" class="nav-link active">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Dashboard v2</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="./index3.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Dashboard v3</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="pages/widgets.html" class="nav-link">
                            <i class="nav-icon fas fa-th"></i>
                            <p>
                                Widgets
                                <span class="right badge badge-danger">New</span>
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-copy"></i>
                            <p>
                                Layout Options
                                <i class="fas fa-angle-left right"></i>
                                <span class="badge badge-info right">6</span>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/layout/top-nav.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Top Navigation</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/layout/top-nav-sidebar.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Top Navigation + Sidebar</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/layout/boxed.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Boxed</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/layout/fixed-sidebar.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Fixed Sidebar</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/layout/fixed-sidebar-custom.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Fixed Sidebar <small>+ Custom Area</small></p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/layout/fixed-topnav.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Fixed Navbar</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/layout/fixed-footer.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Fixed Footer</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/layout/collapsed-sidebar.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Collapsed Sidebar</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-chart-pie"></i>
                            <p>
                                Charts
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/charts/chartjs.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>ChartJS</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/charts/flot.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Flot</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/charts/inline.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Inline</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/charts/uplot.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>uPlot</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tree"></i>
                            <p>
                                UI Elements
                                <i class="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/UI/general.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>General</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/UI/icons.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Icons</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/UI/buttons.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Buttons</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/UI/sliders.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Sliders</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/UI/modals.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Modals & Alerts</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/UI/navbar.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Navbar & Tabs</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/UI/timeline.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Timeline</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/UI/ribbons.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Ribbons</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-edit"></i>
                            <p>
                                Forms
                                <i class="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/forms/general.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>General Elements</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/forms/advanced.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Advanced Elements</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/forms/editors.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Editors</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/forms/validation.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Validation</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-table"></i>
                            <p>
                                Tables
                                <i class="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/tables/simple.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Simple Tables</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/tables/data.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>DataTables</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/tables/jsgrid.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>jsGrid</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-header">EXAMPLES</li>
                    <li class="nav-item">
                        <a href="pages/calendar.html" class="nav-link">
                            <i class="nav-icon fas fa-calendar-alt"></i>
                            <p>
                                Calendar
                                <span class="badge badge-info right">2</span>
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="pages/gallery.html" class="nav-link">
                            <i class="nav-icon far fa-image"></i>
                            <p>
                                Gallery
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="pages/kanban.html" class="nav-link">
                            <i class="nav-icon fas fa-columns"></i>
                            <p>
                                Kanban Board
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon far fa-envelope"></i>
                            <p>
                                Mailbox
                                <i class="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/mailbox/mailbox.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Inbox</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/mailbox/compose.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Compose</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/mailbox/read-mail.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Read</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-book"></i>
                            <p>
                                Pages
                                <i class="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/examples/invoice.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Invoice</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/profile.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Profile</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/e-commerce.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>E-commerce</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/projects.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Projects</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/project-add.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Project Add</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/project-edit.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Project Edit</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/project-detail.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Project Detail</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/contacts.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Contacts</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/faq.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>FAQ</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/contact-us.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Contact us</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon far fa-plus-square"></i>
                            <p>
                                Extras
                                <i class="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>
                                        Login & Register v1
                                        <i class="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview">
                                    <li class="nav-item">
                                        <a href="pages/examples/login.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Login v1</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="pages/examples/register.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Register v1</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="pages/examples/forgot-password.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Forgot Password v1</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="pages/examples/recover-password.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Recover Password v1</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>
                                        Login & Register v2
                                        <i class="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview">
                                    <li class="nav-item">
                                        <a href="pages/examples/login-v2.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Login v2</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="pages/examples/register-v2.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Register v2</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="pages/examples/forgot-password-v2.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Forgot Password v2</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="pages/examples/recover-password-v2.html" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Recover Password v2</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/lockscreen.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Lockscreen</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/legacy-user-menu.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Legacy User Menu</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/language-menu.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Language Menu</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/404.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Error 404</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/500.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Error 500</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/pace.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Pace</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/examples/blank.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Blank Page</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="starter.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Starter Page</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-search"></i>
                            <p>
                                Search
                                <i class="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="pages/search/simple.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Simple Search</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="pages/search/enhanced.html" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Enhanced</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-header">MISCELLANEOUS</li>
                    <li class="nav-item">
                        <a href="iframe.html" class="nav-link">
                            <i class="nav-icon fas fa-ellipsis-h"></i>
                            <p>Tabbed IFrame Plugin</p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="https://adminlte.io/docs/3.1/" class="nav-link">
                            <i class="nav-icon fas fa-file"></i>
                            <p>Documentation</p>
                        </a>
                    </li>
                    <li class="nav-header">MULTI LEVEL EXAMPLE</li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="fas fa-circle nav-icon"></i>
                            <p>Level 1</p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-circle"></i>
                            <p>
                                Level 1
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Level 2</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>
                                        Level 2
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview">
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            <i class="far fa-dot-circle nav-icon"></i>
                                            <p>Level 3</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            <i class="far fa-dot-circle nav-icon"></i>
                                            <p>Level 3</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            <i class="far fa-dot-circle nav-icon"></i>
                                            <p>Level 3</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Level 2</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="fas fa-circle nav-icon"></i>
                            <p>Level 1</p>
                        </a>
                    </li>
                    <li class="nav-header">LABELS</li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon far fa-circle text-danger"></i>
                            <p class="text">Important</p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon far fa-circle text-warning"></i>
                            <p>Warning</p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link">
                            <i class="nav-icon far fa-circle text-info"></i>
                            <p>Informational</p>
                        </a>
                    </li> -->
        </ul>
        <div class="btn-group info d-flex justify-content-center p-1" >
           <a href="#" class="btn btn-sm bg-gradient-primary text-white" @click="confirma_logout()">Sair&nbsp; <i class="fas fa-sign-out-alt"></i></a>
        </div>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Login from '@/components/Login.vue'
// import store from '@/store'

export default {
  name: "Menu",
  components: {
    // HelloWorld,
    // Login
  },
  data() {
    return {
      user: {},
      profissional_url_foto:"",
      permissoes: {
        operacoes: "",
        financeiro: "",
        produto: "",
        recursos_humanos: "",
        marketing: "",
        vendas: "",
        tecnologia: "",
        lms: "",
      },

      menu_ativo: {
        operacoes: false,
        financeiro: false,
        produto: false,
        recursos_humanos: false,
        marketing: false,
        vendas: false,
        tecnologia: false,
        lms: false,
      },
      usuario_nome: "",
      menu_operacoes: false,
      menu_financeiro: false,
      menu_produto: false,
      menu_recursos_humanos: false,
      menu_marketing: false,
      menu_vendas: false,
      menu_tech: false,
      menu_lms: false,
      menu_cxcs: false,
      keyMenu: 0,
    };
  },

  methods: {
    menuAtivo(menu_ativado) {
      Object.keys(this.menu_ativo).forEach((menu) => {
        if (menu_ativado == menu) {
          this.menu_ativo[menu] = true;
        } else {
          this.menu_ativo[menu] = false;
        }
      });
    },
    async valida_acesso() {
      var pagina_atual = window.location.href;
      let url = pagina_atual.split("/");
      this.page = url[3];
      if (
        sessionStorage.acesso_auth == "Negado" ||
        sessionStorage.acesso_auth == "Permitido" ||
        sessionStorage.acesso_auth == ""
      ) {
        let endpoint = "validar/acesso";
        var formData = new FormData();
        formData.append("id", this.dados_user.profissional_id);
        formData.append("nome", this.dados_user.profissional_nome);
        formData.append("page", this.page);
        formData.append("acao", "validacao_acesso");
        const response = await this.$http.post(endpoint, formData);
        if (response.data == 0) {
          sessionStorage.acesso_auth = "Negado";
          window.location.href = "/";
        } else {
          sessionStorage.acesso_auth = "Permitido";
        }
      } else {
        sessionStorage.acesso_auth = "Negado";
        window.location.href = "/";
      }
    },

    confirma_logout(){
          this.$bvModal
          .msgBoxConfirm(
            "Você deseja realmente fazer logout?",
            {
              title: "Logout",
              okVariant: "primary",
              okTitle: "Sim",
              cancelTitle: "Não",
              cancelVariant: "light",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            }
          )
          .then((value) => {
            if (value == true) {
              this.logout();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      logout(){
        sessionStorage.dados_user = undefined;
        sessionStorage.permissoes = undefined;
        sessionStorage.acesso_auth = undefined;
        sessionStorage.carrega_menu = undefined;
        window.location.href = "/";
      },

    habilita_menu() {
      this.user.dados = JSON.parse(sessionStorage.dados_user);
      this.user.permissoes = JSON.parse(sessionStorage.permissoes);
      this.usuario_nome = this.user.dados.profissional_nome.split(" ")[0];
      if(this.user.dados.profissional_url_foto!=""){
        this.profissional_url_foto = this.user.dados.profissional_url_foto;
      }

      let admin = false;
      this.user.permissoes.forEach((permissao) => {
        this.permissoes[permissao["privilegio_tipo_menu"]] =
          permissao["privilegio_status"];
        if (
          permissao["privilegio_tipo_menu"] == "admin" &&
          permissao["privilegio_status"] == "A"
        ) {
          admin = true;
        }
      });

      admin || (this.permissoes.operacoes && this.permissoes.operacoes != "N")
        ? (this.menu_operacoes = true)
        : (this.menu_operacoes = false);
      admin || (this.permissoes.financeiro && this.permissoes.financeiro != "N")
        ? (this.menu_financeiro = true)
        : (this.menu_financeiro = false);
      admin || (this.permissoes.produto && this.permissoes.produto != "N")
        ? (this.menu_produto = true)
        : (this.menu_produto = false);
      admin ||
      (this.permissoes.recursos_humanos &&
        this.permissoes.recursos_humanos != "N")
        ? (this.menu_recursos_humanos = true)
        : (this.menu_recursos_humanos = false);
      admin || (this.permissoes.marketing && this.permissoes.marketing != "N")
        ? (this.menu_marketing = true)
        : (this.menu_marketing = false);
      admin || (this.permissoes.vendas && this.permissoes.vendas != "N")
        ? (this.menu_vendas = true)
        : (this.menu_vendas = false);
      admin || (this.permissoes.lms && this.permissoes.lms != "N")
        ? (this.menu_lms = true)
        : (this.menu_lms = false);
      admin || (this.permissoes.tecnologia && this.permissoes.tecnologia != "N")
        ? (this.menu_tech = true)
        : (this.menu_tech = false);
      admin || (this.permissoes.cxcs && this.permissoes.cxcs != "N")
        ? (this.menu_cxcs = true)
        : (this.menu_cxcs = false);

      this.keyMenu++;
    },
  },
  mounted() {
    if (sessionStorage.acesso_auth == "Negado") {
      this.alert_login = true;
      this.mensagem_login = "Acesso Negado! Efetue o Login";
      sessionStorage.acesso_auth = "";
    } else if (sessionStorage.carrega_menu) {
      sessionStorage.carrega_menu = false;
      this.habilita_menu();
    }
  },
};
</script>
