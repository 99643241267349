<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gestão de Canais da Circle</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Gestão de Space Ids</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <!-- selects head -->
      <div class="selects-head">
        <div class="col-md-12 row">
            <!-- curso -->
            <div class="col-md-4 card select-card">
              <label for="input-with-list">Digite e selecione o curso:</label>
              <b-form-input list="input-list" id="input-with-list" v-model="curso_id_selecionado"></b-form-input>
                <b-form-datalist id="input-list">
                  <option v-for="(curso, index) in cursos" :key="index" :value="curso.curso_id">{{curso.curso_titulo}}</option>
                </b-form-datalist>
            </div>
            <!-- turma -->
            <div class="col-md-4 card select-card" v-if="turmas && curso_categoria == 1">
              <label for="input-with-list">Digite e selecione a turma:</label>
              <b-form-input list="data-list-turmas" id="ipt-list-turmas" v-model="turma_codigo"></b-form-input>
              <b-form-datalist id="data-list-turmas">
                <option v-for="(turma, index) in turmas" :key="index" :value="turma.softcheck_turma_codigo">{{turma.softcheck_turma_codigo}}</option>
              </b-form-datalist>
            </div>
            <!-- opts -->
            <div class="flex-sup col-md-2 select-card opt-card" v-if="save_disabled == false">
                <label for="input-with-list">Opções:</label>
                <b-button class="bs-btn bs-btn-primary" variant="primary" @click.prevent="insere_space_id()">Salvar</b-button>
            </div>
        </div>

      </div>

    <!-- features body -->
    <div class="flex-sup channels-manegement">

        <h5> Defina os canais para a turma selecionada: </h5>

        <div class="flex-sup lista-de-canais"> 

          <!-- > channel card -->
          <div class="flex-sup canal-card" v-for="space_id_curso in space_ids_curso" :key="space_id_curso.space_id">
            <div class="col-md-8 flex-sup channel-col">
              <small> Acesso ao canal: </small>
              <b-form-select v-model="space_id_curso.space_id">
                <b-form-select-option v-for="space_id in space_ids" :key="space_id.id" :value="space_id.id">
                  {{space_id.name}} - {{space_id.space_group_name}}
                </b-form-select-option>
              </b-form-select>
              <!-- <b-form-input :list="space_id_curso.lms_integracao_circle_id.toString()" :id="space_id_curso.space_id.toString()" v-model="space_id_curso.space_id"></b-form-input>
              <b-form-datalist :id="space_id_curso.lms_integracao_circle_id.toString()">
                <option v-for="space_id in space_ids" :key="space_id.id" :value="space_id.id">{{space_id.name}} - {{space_id.space_group_name}}</option>
              </b-form-datalist> -->
            </div>
            <div class="col-md-4 flex-sup channel-col channel-edit">
              <b-button pill variant="outline-danger" @click.prevent="remove_space_id(space_id_curso.space_id)"> <i class="fas fa-times"></i> Retirar </b-button>
            </div>
          </div>
        </div>

        <div class="flex-sup row bottom-add" v-if="curso_id_selecionado && save_disabled == false">
          <div class="card col-md-3 add-card">
            <b-button pill variant="outline-primary" @click.prevent="adiciona_space_id()"> <i class="fas fa-plus"></i> Adicionar </b-button>
          </div>
        </div>

    </div>

    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>

/* > head */

    .flex-sup {
        display: -webkit-box;    
        display: -moz-box;       
        display: -ms-flexbox;     
        display: -webkit-flex;   
        display: flex;
    }

    .selects-head {
        width: 100%; padding: 0 13px; align-content: center; border-bottom: 2px solid var(--wht-2);
    }

    .select-card {padding: 13px; margin-left: 13px;}
        .select-card:first-child {margin-left: 0;}

        .opt-card {flex-direction: column;}

/* manegement */

    .channels-manegement {padding: 13px; flex-direction: column;}

        .channel-acess-cards, .channel-acess-card {padding: 13px;}
            .channel-acess-card .col-md-10 {padding: 0; align-content: center;}

            .channel-acess-card {
                display: -webkit-box;    
                display: -moz-box;       
                display: -ms-flexbox;     
                display: -webkit-flex;   
                display: flex; 
                align-items: center;
            } 

    /* lista de canais */
    
    .lista-de-canais {flex-direction: column;}

        .canal-card {
            width: 100%; background: #fff; border-radius: 5px; padding: 13px; border: 1px solid var(--wht-2);
            justify-content: space-between; margin-top: 13px;
        }

            .canal-card:first-child {margin-top: 0;}

            .channel-edit button {margin-right: 13px;}

    /* + */

    .bottom-add {
        width: 100%; justify-content: flex-end; margin-top: 13px; 
        padding-top: 13px; border-top: 2px solid var(--wht-2);
    }
        .add-card {padding: 13px;}

    @media screen and (max-width: 537px) {

        .select-card {margin-left: 0;}

            .canal-card {flex-direction: column;}
                .channel-edit {margin-top: 13px; justify-content: flex-start; flex-direction: row-reverse;}
                    .channel-edit button {margin-right: 0; margin-left: 13px;}
    }

</style>


<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default{
  components: {
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      curso_id_selecionado: null,
      cursos: [],
      turma_codigo: null,
      turmas: null,
      space_ids: [],
      space_ids_curso: []
    };
  },
  created() {
    this.lista_cursos()
    this.lista_space_ids()
  },
  watch: {
    curso_id_selecionado() {
      if(this.cursos.filter((element) => element.curso_id == this.curso_id_selecionado).map((element) => element.curso_categoria)[0] === 1) {
        this.lista_turma_codigos()
        if(this.turma_codigo ) {
          this.lista_space_ids_curso()
        }
      } else {
        this.lista_space_ids_curso()
      }
    },
    curso_categoria() {
      if(this.curso_categoria == 2) {
        this.turma_codigo = null
      }
    },
    turma_codigo() {
      if(this.turma_codigo) {
        this.lista_space_ids_curso()
      }
    }
  },
  computed: {
    curso_categoria() {
      return this.cursos.filter((element) => element.curso_id == this.curso_id_selecionado).map((element) => element.curso_categoria)[0]
    },
    save_disabled() {
      if(this.cursos.filter((element) => element.curso_id == this.curso_id_selecionado).map((element) => element.curso_categoria)[0] === 1) {
        if(this.turma_codigo) {
          return false
        }
      } else if(this.cursos.filter((element) => element.curso_id == this.curso_id_selecionado).map((element) => element.curso_categoria)[0] === 2) {
        return false
      }

      return true
    },
    space_ids_alocados() {
      return this.space_ids_curso.map((element) => element.space_id).join(", ")
    }
  },
  methods: {
    async lista_cursos() {
      await this.$http.get('curso/listar')
        .then((response) => {
          this.cursos = response.data.cursos
        })
    },

    async lista_turma_codigos() {
      await this.$http.get(`turma/codigo/listar/${this.curso_id_selecionado}`)
        .then((response) => {
          this.turmas = response.data.turmas
          this.turma_codigo = null
        })
    },

    async lista_space_ids_curso() {
      await this.$http.get(`space/ids/${this.curso_id_selecionado}/${this.turma_codigo}`)
        .then((response) => {
          this.space_ids_curso = response.data.response
        })
    },

    async lista_space_ids() {
      await this.$http.get('space/ids')
        .then((response) => {
          this.space_ids = response.data.response
        })
    },
    
    remove_space_id(space_id_curso) {
      const index = this.space_ids_curso.findIndex(element => element.space_id == space_id_curso)

      this.space_ids_curso.splice(index, 1)
    },

    adiciona_space_id() {
      this.space_ids_curso.push({ space_id: 0, lms_integracao_circle_id: Math.random() * 100, curso_id: this.curso_id_selecionado, turma_codigo: this.turma_codigo })
    },

    async insere_space_id() {
      await this.$http.post('adiciona/space/ids', {
        turma_codigo: this.turma_codigo,
        curso_id: this.curso_id_selecionado,
        space_ids: this.space_ids_alocados
      })
        .then((response) => {
          if(response.data.status == true) {
            alert('Adicionado com sucesso')
            this.lista_space_ids_curso()
          } else {
            alert(response.data.message)
            this.lista_space_ids_curso()
          }
        })
    }
  },
};
</script>


