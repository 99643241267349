<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Plano de estudos</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active"> Plano de estudos </li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="container-fluid">
        <div class="card ">
          <div class="row">
            <!-- upload box -->
            <div class="card-body col-sm-6">
              <div class="form-group">
                <div class="row">
                  <!-- curso -->
                  <div class="col-4">
                    <label > Selecione o curso: </label>
                    <div>
                      <b-form-select label="Selecione o curso:" @change="lista_turma_codigos()" v-model="curso_id_selecionado">
                        <b-form-select-option v-for="(curso, index) in cursos" :key="index" :value="curso.curso_id">
                          {{curso.curso_titulo}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                  <!-- turma -->
                  <div class="col-4">
                    <label > Selecione a turma: </label>
                    <div>
                      <b-form-select label="Selecione o curso:" @change="lista_slots()" v-model="turma_codigo_selecionado">
                        <b-form-select-option v-for="(turma, index) in turmas" :key="index" :value="turma.turma_codigo">
                          {{turma.turma_codigo}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                </div>
                <div class="spinner-border text-primary mt-1 mb-1"
                    role="status"
                    v-show="loading"
                  ></div>
              </div>
            </div>
          </div>

          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Slot numero</th>
                  <th>Tempo semanal mínimo em minutos</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(slot, index) in slots" :key="index">
                  <td>{{slot.slot_numero}}</td>
                  <td>
                    <b-form-input type="number" v-model="slot.tempo_estudo"></b-form-input>
                    <!-- <b-form-spinbutton id="demo-sb" :value="slot.tempo_estudo_minimo_semanal" v-model="value" min="1" max="672"></b-form-spinbutton> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
          <div class="row d-flex justify-content-end">
            <div class="col-4 d-flex justify-content-end m-3">
              <b-button class="bs-btn" :disabled="loading" v-b-modal.submitStatus variant="success m-1" @click.prevent="salvar_plano_estudo()">Salvar</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      loading: false,
      cursos: [],
      turma_codigo_selecionado: 0,
      curso_id_selecionado: 0,
      slots: [],
      turma_codigo: null,
      turmas: null
    };
  },
  created() {
    this.lista_cursos()
  },
  methods: {
    salvar_plano_estudo(){
      this.loading = true
      let erro = false;

      if(!this.slots.length){
        
        erro = true
      }
      this.slots.forEach((slot) => {
        if(!slot.tempo_estudo){
          erro = true
        }
      })
      if(erro){
        this.loading = false
        this.$bvModal.msgBoxOk('Preencha todos os slots!',{title:'Aviso!',okVariant:'primary',headerBgVariant:'danger',bodyBgVariant:'danger',footerBgVariant:'danger'})
        return false
      }

      this.$http.post('plano-estudos/turma', {
        turma_codigo: this.turma_codigo_selecionado,
        slots: this.slots
      })
        .then((response) => {
          this.$bvModal.msgBoxOk(response.data.mensagem, {
              title: "Sucesso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "success",
              bodyBgVariant: "success",
              footerBgVariant: "success",
            });
          this.loading = false
        })
        .catch((exception) => {
          this.$bvModal.msgBoxOk(exception.response.data.mensagem, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          })
          this.loading = false
        })

    },
    lista_cursos() {
      this.$http.get('cursos')
        .then((response) => {
          this.cursos = response.data
        })
    },

    lista_slots() {

      this.$http.get('cursos/'+this.curso_id_selecionado+'/turmas/'+this.turma_codigo_selecionado+'/slots')
        .then((response) => {
          this.slots = response.data
        }).catch((exception) => {
          console.log(exception)
          alert("Ocorreu um erro ao buscar os slots!")
        })
    },

    lista_turma_codigos() {
      this.$http.get('cursos/'+this.curso_id_selecionado+'/turmas')
        .then((response) => {
            this.turmas = response.data
        })
        .catch(exception => {
          this.turmas = []
          this.$bvModal.msgBoxOk(exception.response.data.mensagem, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          })
        })
    }
  },
}
</script>