<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Salas de conferências</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">SalasConferência</li>
              </ol>
            </div>

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="card">
        <div class="row">
          <div class="card-body col-sm-3">
            <button
              class="btn btn-lg btn-success"
              v-show="btn_executar"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              :variant="varianteSubmit"
              @click="cadastraLobby()"
            >
              <span>Cadastrar novo lobby <i class="fas fa-play"></i></span>
            </button>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body">
          <table id="example1" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Ações</th>
                <th>RID lobby</th>
                <th>Nome do lobby</th>
                <th>Link do lobby</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal size="lg" id="modal_sala" hide-footer>
      <template #modal-title> {{ form_titulo }} </template>

      <b-form>
        <div v-show="(exibir_lobby && !exibir_sala) || exibir_cadastro">
          <div class="row">
            <div class="col-sm-4">
              <b-form-group id="input-1" label="Nome do lobby">
                <b-form-input
                  id="lobby_nome"
                  trim
                  v-model="formLobby.lobby_nome"
                ></b-form-input>
                <label class="text-danger small">{{
                  errosFormLobby.lobby_nome
                }}</label>
              </b-form-group>
            </div>

            <div class="col-sm-4">
              <b-form-group id="input-2" label="Link do lobby">
                <b-form-input
                  id="lobby_link"
                  trim
                  v-model="formLobby.lobby_link"
                ></b-form-input>
                <label class="text-danger small">{{
                  errosFormLobby.lobby_link
                }}</label>
              </b-form-group>
            </div>

            <div class="col-sm-4">
              <b-form-group id="input-3" label="Status">
                <b-form-select
                  id="lobby_status"
                  trim
                  v-model="formLobby.lobby_status"
                  :options="lobby_status"
                ></b-form-select>
                <!-- <label class="text-danger small">{{
                errosFormSala.sala_url
              }}</label> -->
              </b-form-group>
            </div>
          </div>
        </div>
        <hr />
        <div v-show="(!exibir_lobby && exibir_sala) || exibir_cadastro">
          <div
            class="row"
            v-for="(sala, index) in formLobby.salas"
            :key="index"
          >
            <div class="col-sm-4">
              <b-form-group id="input-2" label="Nome sala">
                <b-form-input
                  id="sala_nome"
                  trim
                  v-model="formSala.sala_nome"
                ></b-form-input>
                <!-- <label class="text-danger small">{{
                errosFormSala.sala_url
              }}</label> -->
              </b-form-group>
            </div>

            <div class="col-sm-4">
              <b-form-group id="input-2" label="Status sala">
                <b-form-input
                  id="sala_status"
                  trim
                  v-model="formSala.sala_status"
                ></b-form-input>
                <!-- <label class="text-danger small">{{
                errosFormSala.sala_url
              }}</label> -->
              </b-form-group>
            </div>
          </div>
        </div>

        <hr />
        <div class="input-group justify-content-md-end">
          <b-button
            @click="limparFormLobby()"
            id="btn-limpa-form"
            variant="danger"
            >Limpar formulário</b-button
          >
          <b-button
            class="mr-1"
            @click="enviarFormSala()"
            id="btn-cadastro"
            :variant="varianteSubmit"
            >{{ botaoSubmitLobby }}</b-button
          >


          <b-button
            class="mr-1"
            @click="adicionarLinhaSala()"
            id="btn-adicionar"
            variant="warning"
            >{{ botaoInserirSala }}</b-button
          >

          <b-button
            class="mr-1"
            @click="removerLinhaSala()"
            id="btn-remover"
            variant="danger"
            >{{ botaoRemoverSala }}</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

function abreModalEdicao(lobby_id, exibir_sala, exibir_lobby) {
  window.elemento_vue.editaLobby(lobby_id, exibir_sala, exibir_lobby);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Zomsalas",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user: {},
      preloader_status: true,
      loading: false,
      page: "SalasConferencias",
      form_titulo: "",
      botaoInserirSala: "Adicionar linha",
      botaoRemoverSala: "Remover linha",
      botaoSubmitLobby: "",
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      btn_verSala: null,
      varianteSubmit: "",
      exibir_sala: false,
      exibir_lobby: false,
      exibir_cadastro: false,
      lobby_status: [
        { text: "Ativo", value: "1" },
        { text: "Inativo", value: "2" },
      ],
      formSala: {
        sala_id: "",
        lobby_id: "",
        sala_nome: "",
        sala_status: "",
      },
      sala: {
        sala_nome: "",
        sala_status: "",
      },
      formLobby: {
        lobby_id: "",
        lobby_nome: "",
        lobby_link: "",
        lobby_status: "",
        salas: [],
      },
      errosFormLobby: {
        lobby_nome: "",
        lobby_link: "",
      },
    };
  },
  created() {
    this.preloader_status = false;
    this.adicionarLinhaSala();
  },

  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
    isDisabledVerSala: function () {
      return !this.btn_verSala;
    },
  },
  methods: {
    adicionarLinhaSala() {
      this.formLobby.salas.push({
        sala_nome: "",
        sala_status: "",
      });
    },
    removerLinhaSala() {
      this.formLobby.salas.splice(1, 1);
    },

    async cadastraLobby() {
      this.limparFormLobby();
      this.exibir_cadastro= true;
      this.form_titulo = "Cadastra novo lobby";
      this.botaoSubmitLobby = "Cadastrar lobby";
      this.varianteSubmit = "primary";
      this.acao = "novo lobby";
      this.$bvModal.show("modal_sala");
      this.errosFormLobby = {
        lobby_nome: "",
        lobby_link: "",
      };
    },


    async editaLobby(lobby_id, exibir_sala, exibir_lobby) {
      this.limparFormLobby();
      this.exibir_sala = exibir_sala;
      this.exibir_lobby = exibir_lobby;
      this.exibir_cadastro= false;
      this.form_titulo = "Editar lobby";
      this.botaoSubmitLobby = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";
      this.modal_sala = true;

      let url = "lista/lobby/" + lobby_id;

      try {
        const response = await this.$http.get(url);
        let dados_lobby = response.data;
        (this.formLobby.lobby_id = dados_lobby.lobby_id),
          (this.formLobby.lobby_nome = dados_lobby.lobby_nome),
          (this.formLobby.lobby_link = dados_lobby.lobby_link),
          (this.formLobby.lobby_status = dados_lobby.lobby_status);
        
        this.$bvModal.show("modal_sala");
      } catch (err) {
        let msg = "Erro ao buscar dados do lobby!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
    },

    


    async lista_lobbys() {
      let url = "lista/lobbyconferencias";
      const response = await this.$http.get(url);

      this.salas = response.data;
      let data_table = new Array();
      let status_cor = "text-secundary";

      this.salas.forEach((salas) => {
        let botao_editar_sala =
          "<button class='btn btn-warning btn-sm mr-1 open-lobby' id = " +
          salas.lobby_id +
          " type='button'><i id = " +
          salas.lobby_id +
          " class = 'fas fa-edit'></i>Editar</button>";

        let botao_ver_sala =
          "<button class='btn btn-warning btn-sm mr-1 open-sala' id = " +
          salas.lobby_id +
          " type='button'><i id = " +
          salas.lobby_id +
          " class = 'fas fa-edit'></i>Ver salas</button>";

        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_sala : "") +
            (this.btn_editar ? botao_ver_sala : "") +
            "</span>",

          "<span class=" + status_cor + ">" + salas.lobby_id + "</span>",

          "<span class=" + status_cor + ">" + salas.lobby_nome + "</span>",

          "<span class=" + status_cor + ">" + salas.lobby_link + "</span>",

          "<span class=" + status_cor + ">" + salas.lobby_status + "</span>",
        ]);
      });

      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
          drawCallback: function () {
            $(".open-sala").on("click", function (event) {
              abreModalEdicao(event.target.id, true, false);
            });
            $(".open-lobby").on("click", function (event) {
              abreModalEdicao(event.target.id, false, true);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
    },

    habilita_botoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "operacoes") {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }

        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },
    async enviarFormSala() {
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let botaoLimpaForm = document.querySelector("#btn-limpa-form");
      let erros = false;
      botaoCadastro.disabled = true;
      botaoLimpaForm.disabled = true;

      if (!this.formLobby.lobby_nome) {
        this.errosFormLobby.lobby_nome = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormLobby.lobby_nome = "";
      }
      if (!this.formLobby.lobby_link) {
        this.errosFormLobby.lobby_link = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormLobby.lobby_link = "";
      }

      if (!erros) {
        let retorno;
        if (this.acao == "novo") {
          let url = "cadastra/lobby";
          const response = await this.$http.post(url, {
            lobby: {
              lobby_id: this.formLobby.lobby_id,
              lobby_nome: this.formLobby.lobby_nome,
              lobby_link: this.formLobby.lobby_link,
              lobby_status: this.formLobby.lobby_status,
              sala_nome: this.formSala.sala_nome,
              sala_id: this.formSala.sala_id,
              sala_status: this.formSala.sala_status,
            },
            page: this.page,
            profissional_id: this.user.dados.profissional_id,
            acao: "cadastrar lobby",
          });
          retorno = response.data;

        } 
        else if (this.acao == "editar") {
          let url = "atualiza/lobby";
          const response = await this.$http.patch(url, {
            lobby: {
              lobby_id: this.formLobby.lobby_id,
              lobby_nome: this.formLobby.lobby_nome,
              lobby_link: this.formLobby.lobby_link,
              lobby_status: this.formLobby.lobby_status,
              sala_nome: this.formSala.sala_nome,
              sala_id: this.formSala.sala_id,
              sala_status: this.formSala.sala_status,

            },
            page: this.page,
            profissional_id: this.user.dados.profissional_id,
            acao: "editar lobby",
          });
          retorno = response.data;
          console.log(retorno);
        }

        if (retorno.erro) {
          this.$bvModal.msgBoxOk(retorno.erro, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        } else {
          this.$bvModal.msgBoxOk(retorno.mensagem, {
            title: "Sucesso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          });
          this.$bvModal.hide("modal_sala");
          this.lista_lobbys();
        }
      }
      botaoCadastro.disabled = false;
      botaoLimpaForm.disabled = false;
    },

    limparFormLobby() {
      this.formLobby.lobby_id = "";
      (this.formLobby.lobby_nome = ""),
        (this.formLobby.lobby_link = ""),
        (this.formLobby.lobby_status = "");

          

    },
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    //quando liga a tela
    window.elemento_vue = this;
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    this.habilita_botoes();
    this.lista_lobbys();
  },
};
</script>


