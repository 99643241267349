<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />

    

    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Vídeos de ajuda</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-12">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Vídeos</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <div class="card">
        <div class="row">
          <!-- upload box -->
          <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <div>
                    <b-form-group label="Squad" label-for="squad">
                    <b-form-select 
                        id="tipo"
                        :options="squads"
                        v-model="squad"
                        @change="lista_videos_squad()" 
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-4">
                  <b-form-group id="input-1" label="Nome" label-for="name">
                      <b-form-input
                        id="name"
                        v-model="nomeVideo"
                        placeholder="palavras chave.."
                        trim
                      ></b-form-input>
                    </b-form-group>
                </div>
            </div>
              <Video v-for = "(video, index) in listaFiltrada" :key="index" :video="video" :mostrar="false"/>
          </div>
        </div>
      </div>

    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<script>
import Video from "@/components/Video.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";


export default {
  components: {
    Footer,
    Navbar,
    Menu,
    Video
  },
  data() {
    return {
      videos: [],
      lista: [],
      squads: [{
        text: 'todos',
        value: 0
        }
      ],
      nomeVideo: "",
      squad: ""
    };
  },
  computed: {
      listaFiltrada(){
        return this.videos.filter(video => {
        return video.titulo.toLowerCase().includes(this.nomeVideo.toLowerCase())
      })
    }
  },
  created() {
    this.lista_videos()
    this.lista_squads()
  },

  methods: {
    async lista_videos() {
      let url = "lista/videos";
      const response = await this.$http.get(url);
      this.videos = response.data
      this.lista = this.videos
    },

    async lista_squads(){
      let url = "lista/squads";
      const response = await this.$http.get(url);
      let squads = response.data
      squads.forEach(squad => {
        this.squads.push({
          text: squad.squad_nome,
          value: squad.squad_id
        })
      })
    },

    async lista_videos_squad(){
      console.log(this.squad)
      if(this.squad){
        this.nomeVideo = ""
        let url = "lista/videos/" + this.squad;
        const response = await this.$http.get(url);
        this.videos = response.data
        this.lista = this.videos
      }else{
        this.lista_videos()
      }
    }
  },
}
</script>