<template>
    <div class="login-box" id="root">        
        <!-- /.login-logo -->        

        <div class="card card-primary" style="border-radius: 21px; box-shadow: 0;">
            <div class="card-header-login text-center">
                <img src="../assets/img/logo_dnc_adm_preto.png" alt="AdminLTE Logo" style="width: 19rem">
            </div>
            <div class="card-body">
                <p class="login-box-msg">Faça o login para iniciar a sessão:</p>

                <form>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" required="required" 
                            v-model="usuario"
                            placeholder="Usuário"
                            style="border-top-left-radius: 8px; 
                            border-bottom-left-radius: 8px;"                            
                        >
                        <div class="input-group-append">
                            <div class="input-group-text-login">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input @keyup.enter="onEnter()" type="password"
                            class="form-control" 
                            required="required" 
                            v-model="senha"
                            style="border-top-left-radius: 8px; 
                            border-bottom-left-radius: 8px;"
                            placeholder="Senha"
                        >
                        <div class="input-group-append" >
                            <div class="input-group-text-login">
                                <span class="fas fa-lock" ></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-12">
                            <a @click="verifica_login();" class="btn btn-primary btn-block" 
                                style="background-color: #0c70f2;
                                margin-bottom:12px; 
                                border-radius: 8px;">
                                Entrar
                            </a>
                        </div>
                        <!-- /.col -->
                    </div>
                    <div class="row justify-content-center">
                <div
                  class="spinner-border text-primary mt-1 mb-1"
                  role="status"
                  v-show="loading"
                ></div>
                </div>
                </form>

                <!-- <div class="social-auth-links text-center mt-2 mb-3">
        <a href="#" class="btn btn-block btn-primary">
          <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
        </a>
        <a href="#" class="btn btn-block btn-danger">
          <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
        </a>
      </div> -->
                <!-- /.social-auth-links -->

                <p class="mb-1">
                    <!-- <a href="forgot-password.html"><b style="font-size:14px">Esqueci minha senha.</b></a> -->
                    <button data-toggle="modal" data-target=".bd-example-modal-lg">Esqueci minha senha</button>
                </p>
                <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            
                            <!-- <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div> -->
                            <div class="modal-body" id="modalBody">
                                <div class="row">
                                    <div class="col-md-5 img_esqueci_senha">
                                        <img src="../assets/img/img_esqueci_senha.png" alt="" class="esqueci_senha_img" data-testid="esqueciSenhaImg">
                                    </div>
                                    <div class="col-md-6 esqueci_senha_itens">
                                        <span class="esqueci_senha_close" data-dismiss="modal">X</span>
                                        <h4 class="esqueci_senha_itens_h4" data-testid="esqueciSenhaRecuperaConta">Recuperação de conta</h4>
                                        <p class="esqueci_senha_itens_p">Informe seu e-mail ou login e enviaremos  instruções para você criar sua senha</p>
                                            <!-- <input type="text" v-model="formUsuario" class="form-control esqueci_senha_input erroFormUsuario" placeholder="E-mail / Senha" aria-label="large" aria-describedby="inputGroup-sizing-sm">
                                            <label for="" v-show="erroFormUsuario" class="erroFormUsuario">E-mail incorreto ou inexistente</label>
                                            <button type="button" class="esqueci_senha_btn"  @click="enviaFormUsuario()">Enviar</button> -->
                                        <form action="" class="formSubmit">
                                            <input type="text" v-model="formUsuario" class="form-control esqueci_senha_input" placeholder="escola@dnc.com.br" id="validationDefault01" value="" required>
                                            <label for="" v-show="erroFormUsuario" class="erroFormUsuario">E-mail incorreto ou inexistente</label>
                                            <button data-testid="esqueciBtnEnviaForm" @click="enviaFormUsuario()" class="btn btn-primary esqueci_senha_btn" type="submit" id="btn-cadastro">Enviar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
    <!-- /.login-box -->
</template>

<style scoped>
    .modal-content{
        top: 50%;
        transform: translateY(40%) !important;
    }
    .modal-body{
        padding: 0;
        background-color: white;
        border-radius: 5px;
    }

    .formSubmit{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 40%;
        gap: 10px;
    }

    .erroFormUsuario{
        text-align: end;
        width: 100%;
        font-weight: 200;
        color: red;
    }

    .erroFormUsuarioInput{
        border: 1px solid red;
        background-color: #0C70F2;
    }
    .esqueci_senha_img{
        border-radius: 5px 0px 0px 5px;
    }

    .esqueci_senha_close{
        text-align: end;
        width: 100%;
        font-weight: 800;
        font-size: 18px;
        color: #170F49;
        cursor: pointer;
    }

    .esqueci_senha_itens_p{
        font-size: 18px;
        color:#6F6C90;
    }
    .esqueci_senha_itens_h4{
        font-weight: 600;
        color: #170F49;
        font-size: 24px;
    }
    .esqueci_senha_btn{
        background-color: #0C70F2;
        width: 100%;
        height: 50%;
        border-radius: 10px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 18px;
    }

    .esqueci_senha_input::placeholder{
        color: black;
        font-weight: 600;
    }

    .esqueci_senha_input{
        border: 1px solid #cbcccd;
        color: black;
        font-size: 18px;
        height: 50%;
        font-weight: 600;
    }
    .esqueci_senha_itens{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-direction: column;
    }
</style>

<script>

export default {
  name: 'Login',
  props: {
      
  },
    components: {
  
  },
  data: function(){
      return{
        usuario:"",
        senha:"",
        page:"index",        
        status:false,
        loading: false,
        valida_token: false,
        formUsuario:null,
        erroFormUsuario:false
      }
  },

  mounted () {

    if (this.$cookies.get('token_adm')) {
      this.$http.get(`/fetch/dados/profissional/${this.$cookies.get('token_adm')}`)
        .then((response) => {
            if(response.data.profissional.profissional_user) {
                 this.usuario = response.data.profissional.profissional_user
                this.senha = 'senha'
                this.valida_token = true
                this.verifica_login()
            }
          })
        }
    },

  methods:{
      /***************************** Funcoes de ACESSO ***********************************/
      async enviaFormUsuario() {
        let modalBody = document.querySelector("#modalBody");
        if(this.formUsuario == 1){
            this.erroFormUsuario = true
            this.formUsuario = ''
            this.formUsuario.required = false
            modalBody.disabled = true;
        }else{
            this.erroFormUsuario = ''
        }

        // eslint-disable-next-line no-empty
        if (this.formUsuario) {
        const emailValidado = String(this.formUsuario).toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        if (emailValidado) {
          // To dentro
          this.$axios.post('funcionario/senha/reset', { funcionario_email: this.formUsuario })
            .then(() => {
              alert('Sua solicitação foi enviada em seu email!')
              this.$router.replace({ name: 'index' })
            }).catch(() => {
              alert('Erro encontrado!')
            })
        } else {
          alert('Digite um email valido')
        }
      } else {
        alert('Preencha os campos corretamente')
      }
      },

        async verifica_login() {

            this.loading = true
            let url = "verifica/login";
            if (this.usuario && this.usuario != "" && this.senha && this.senha != "") {
                var formData = new FormData();
                formData.append('usuario', this.usuario)
                formData.append('senha', this.senha)
                formData.append('valida_token', this.valida_token)
                formData.append('page', this.page)
                formData.append('acao', 'login')
                await this.$http.post(url, formData).then((response) => {
                if (response.data.login === 0) {
                    this.$bvModal.msgBoxOk(response.data.retorno,{title:'Falha no Login',okVariant:'primary',headerBgVariant:'danger',bodyBgVariant:'danger',footerBgVariant:'danger'})
                    this.loading = false
                } else {
                    if (typeof (Storage) !== "undefined") {
                            sessionStorage.dados_user = JSON.stringify(response.data.retorno.dados);
                            sessionStorage.permissoes = JSON.stringify(response.data.retorno.permissoes);
                            sessionStorage.acesso_auth = "Permitido";
                            sessionStorage.carrega_menu = true
                            
                    } else {
                        this.$bvModal.msgBoxOk('Seu navegador não suporta web storage!',{title:'Erro',okVariant:'primary',headerBgVariant:'danger',bodyBgVariant:'danger',footerBgVariant:'danger'})
                        this.loading = false
                    }
                    window.location.href = "/area-usuario";
                }
                }).catch((error) => {
                    console.log(error);
                })
                

            } else {
                this.$bvModal.msgBoxOk("Preencha todos os dados",{title:'Atenção',okVariant:'primary',headerBgVariant:'warning',bodyBgVariant:'warning',footerBgVariant:'warning'})
                this.loading = false
            }
        },
        onEnter() {
            this.verifica_login()
        }
  },
}
</script>