<template>
    <div id="rootEmissao">
      <div class="content-wrapper"  v-show="hash" >
  
        <!-- familiar nav -->
        <div class="flex-sup familiar-nav">
  
          <h3 class="flex-sup"> <small> Operações/Alunos </small> <i class="fas fa-angle-down"></i> </h3>
  
          <ul class="flex-sup fami-nav-list">
  
            <!-- variants: isAbaLoading, isActualPlace -->
  
            <!-- > gerenciamento -->
            <li class="flex-sup"> 
              <a class="flex-sup" href="/menu-alunos"> <i class="far fa-clipboard"></i> <small> Gerenciar alunos </small> <img src="../../assets/img/mini-load.png" alt="um momento"> </a>  
            </li>
  
            <!-- > compras -->
            <li class="flex-sup"> 
              <a class="flex-sup" href="/compras"> <i class="fas fa-shopping-cart"></i> <small> Gerenciar compras </small> <img src="../../assets/img/mini-load.png" alt="um momento"> </a>  
            </li>
            
            <!-- > turmas -->
            <li class="flex-sup isActualPlace"> 
              <a class="flex-sup" href="/menu-turmas"> <i class="fas fa-users"></i> <small> Gerenciar turmas </small> <img src="../../assets/img/mini-load.png" alt="um momento"> </a>  
            </li>
  
            <!-- > grupos -->
            <li class="flex-sup"> 
              <a class="flex-sup" href="/aleatoriza-grupos"> <i class="fas fa-random"></i> <small> Aleatorizar grupos </small> <img src="../../assets/img/mini-load.png" alt="um momento"> </a>  
            </li>
  
            <!-- > treinamentos -->
            <li class="flex-sup"> 
              <a class="flex-sup" href="/menu-treinamentos"> <i class="fas fa-graduation-cap"></i> <small> Gerenciar treinamentos </small> <img src="../../assets/img/mini-load.png" alt="um momento"> </a>  
            </li>
  
          </ul>
  
        </div>
  
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Gerenciamento de facilitadores</h1>
              </div>
            </div>
            <!-- /.row -->
          </div>
          <!-- /.container-fluid -->
        </div>
  
        <div class="card">
          <div class="row">
            <div class="card-body col-sm-3">
              <button
                class="btn btn-lg bs-btn btn-success"
                v-show="btn_executar"
                data-toggle="modal"
                data-target="#modal-success"
                type="button"
                :variant="varianteSubmit"
                @click="cadastraFacilitador()"
              >
                <span>Cadastrar novo facilitador <i class="fas fa-play"></i></span>
              </button>
            </div>
            <div class="card-body col-sm-3">
              <button
                class="btn btn-lg bs-btn btn-success"
                v-show="btn_executar"
                data-toggle="modal"
                data-target="#modal-success"
                type="button"
                :variant="varianteSubmit"
                @click="cadastraFuncao()"
              >
                <span>Cadastrar nova função <i class="fas fa-play"></i></span>
              </button>
            </div>
          </div>
          
          <!-- <div class="row">
            <div class="card-body col-sm-3">
              <button
                class="btn btn-lg bs-btn btn-success"
                v-show="btn_executar"
                data-toggle="modal"
                data-target="#modal-success"
                type="button"
                :variant="varianteSubmit"
                @click="alocaFacilitador()"
              >
                <span>Alocar facilitador <i class="fas fa-play"></i></span>
              </button>
            </div>
          </div> -->
  
          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Ações </th>
                  <th>RID facilitador</th>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>Cargo</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
  
      <b-modal size="lg" id="modal_facilitador" hide-footer>
        <template #modal-title> {{ form_titulo }} </template>
  
        <b-form>
          <div class="row">
  
            <div class="col-sm-12">
              <b-form-group id="input-1" label="Nome Completo">
                <b-form-input
                  id="nome"
                  trim
                  v-model="formFacilitador.nome"
                ></b-form-input>
              <label class="text-danger small">{{
                  errosFormFacilitador.nome
                }}</label>
              </b-form-group>
            </div>
           
          </div>
  
          <div class="row">
            <div class="col-sm-12">
              <b-form-group id="input-1" label="CPF">
                <b-form-input
                  id="cpf"
                  v-model="formFacilitador.cpf"
                  trim
                  v-mask="'###.###.###-##'"
                  placeholder="000.000.000-00"
                ></b-form-input>
               <label class="text-danger small">{{
                  errosFormFacilitador.cpf
                }}</label>
              </b-form-group>
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-12">
              <b-form-group id="input-" label="Função">
                <b-form-select
                  id="funcao"
                  trim
                  placeholder="https://"
                  v-model="formFacilitador.funcao"
                  :options="funcoes"
                ></b-form-select>
                <label class="text-danger small">{{
                  errosFormFacilitador.funcao
                }}</label>
              </b-form-group>
            </div>
          </div>
          <hr>
          <div class="input-group justify-content-md-end">
            <b-button
              @click="limparFormFacilitador()"
              id="btn-limpa-form"
              variant="danger"
              >Limpar formulário</b-button
            >
            <b-button
              class="mr-1"
              @click="enviarFormFacilitador()"
              id="btn-cadastro"
              :variant="varianteSubmit"
              >{{ botaoSubmitFacilitador }}</b-button
            >
  
          </div>
        </b-form>
      </b-modal>
  
  
      
      <!-- MODAL DE FUNÇÕES  -->
      <b-modal size="lg" id="modal_funcoes" hide-footer>
        <template #modal-title> {{ form_titulo }} </template>
  
        <b-form>
          <div class="row">
  
            <div class="col-sm-12">
              <b-form-group id="input-1" label="Nome do função">
                <b-form-input
                  id="funcao_nome"
                  trim
                  placeholder="EX: Facilitador"
                  v-model="formFuncao.funcao_nome"
                ></b-form-input>
              <!-- <label class="text-danger small">{{
                  errosFormFacilitador.nome
                }}</label> -->
              </b-form-group>
            </div>
          </div>
            
          <hr>
          <div class="input-group justify-content-md-end">
            <b-button
              @click="limparFormFacilitador()"
              id="btn-limpa-form"
              variant="danger"
              >Limpar formulário</b-button
            >
            <b-button
              class="mr-1"
              @click="enviarFormFuncao()"
              id="btn-cadastroFuncao"
              :variant="varianteSubmit"
              >{{ botaoSubmitFuncao }}</b-button
            >
  
          </div>
        </b-form>
      </b-modal>
  
    </div>
    <!-- /.content-wrapper -->
  </template>
  
  <style scoped>
  
    .bs-btn {align-items: center;}
    .bs-btn i {margin-left: 8px;}
  
  </style>
  
  
  <script>
  var dados_checks = new Array();
  window.dados_checks = dados_checks;
  
  function abreModalEdicao(facilitador) {
    window.elemento_vue.editaFacilitador(facilitador);
  }
  
  import jQuery from "jquery";
  const $ = jQuery;
  window.$ = $;
  
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  // import store from '@/store'
  
  export default {
    name: "Facilitadores",
    components: {
    },
    data() {
      return {
        hash:'',
        user:'',
        preloader_status: true,
        loading: false,
        page: "Facilitadores",
        form_titulo: "",
        botaoSubmitFacilitador: "",
        botaoSubmitFuncao: "",
        btn_executar: null,
        btn_excluir: null,
        btn_editar: null,
        funcoes: [],
        alocacao: [],
        varianteSubmit:"",
        formFacilitador:{
            softcheck_profissional_id:"",
            nome: "",
            cpf: "",
            funcao: "",
        },
        formFuncao: {
          funcao_nome: "",
          id_funcao: ""
        },
        // formAlocacao:{
        //     profissional_id:"",
        //     nome: "",
        //     funcao: "",
        //     turma: "",
        //     dia: "",
        //     grupo:"",
        // },
        errosFormFacilitador:{
            nome: "",
            cpf: "",
            funcao: "",
        },
        errosFormFuncao:{
            funcao_nome: "",
        },
      };
    },
    created() {
      this.preloader_status = false;
      this.fetchDadosEmbed();
    },
    computed: {
      isDisabledExecutar: function () {
        return !this.btn_executar;
      },
      isDisabledExcluir: function () {
        return !this.btn_excluir;
      },
      isDisabledEditar: function () {
        return !this.btn_editar;
      },
    },
    methods: {
        async fetchDadosEmbed() {
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.hash = urlParams.get('habilitado')
            this.user = urlParams.get('id')
        },
  
      async listafuncoes(){
         let url = "lista/funcoes";
        const response = await this.$http.get(url);
        let funcoes = response.data;
        funcoes.forEach((funcao) => {
          this.funcoes.push({
            text: funcao.funcao_id + " " + funcao.funcao_nome,
            value: funcao.funcao_id,
          });
        });
      },
  
      // async listaAlocacao() {
      //   let url = "lista/alocacao";
      //   const response = await this.$http.get(url);
      //   let alocaoes = response.data;
      //   alocaoes.forEach((alocacao) => {
      //     this.alocacao.push({
      //       text: alocacao.turma,
      //       value: alocacao.turma,
      //     });
      //   });
      // },
  
      async cadastraFacilitador() {
        this.limparFormFacilitador();
        this.form_titulo = "Cadastra novo facilitador";
        this.botaoSubmitFacilitador = "Cadastrar facilitador";
        this.varianteSubmit = "primary";
        this.acao = "novo";
        this.errosFormFacilitador = {
          nome: "",
            cpf: "",
            funcao: "",
        }
        this.$bvModal.show("modal_facilitador");    
      },
      async cadastraFuncao() {
        // this.limparFormFacilitador();
        this.form_titulo = "Cadastra nova função";
        this.botaoSubmitFuncao = "Cadastrar função";
        this.varianteSubmit = "primary";
        this.acao = "novaFuncao";
        // this.errosFormFacilitador = {
        //   nome: "",
        //     cpf: "",
        //     funcao: "",
        // }
        this.$bvModal.show("modal_funcoes");    
      },
  
      // async alocaFacilitador() {
      //   this.limparFormFacilitador();
      //   this.form_titulo = "Alocação de facilitador";
      //   this.botaoSubmitFacilitador = "Alocar facilitador";
      //   this.varianteSubmit = "primary";
      //   this.acao = "novo";
      //   // this.errosFormFacilitador = {
      //   //   nome: "",
      //   //     cpf: "",
      //   //     funcao: "",
      //   // }
      //   this.$bvModal.show("modal_aloca_facilitador");    
      // },
  
      async editaFacilitador(softcheck_profissional_id){
        this.form_titulo="Editar facilitador";
        this.botaoSubmitFacilitador = "Salvar";
        this.varianteSubmit = "warning";
        this.acao = "editar";
        this.modalTamanho = "lg";
        this.modal_facilitador = true;
  
        try {
          this.facilitadores.forEach((facilitador) => {
            if (facilitador.softcheck_profissional_id == softcheck_profissional_id) {
              this.formFacilitador.softcheck_profissional_id = facilitador.softcheck_profissional_id,
              this.formFacilitador.nome = facilitador.softcheck_profissional_nome;
              this.formFacilitador.cpf = facilitador.softcheck_profissional_cpf;
              this.formFacilitador.funcao = facilitador.softcheck_profissional_tipo;
            }
          });
          this.$bvModal.show("modal_facilitador");
        }catch(err){ 
          let msg = "Erro ao buscar dados do facilitador!";
          this.$bvModal.msgBoxOk(msg, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        }
      },
  
      async lista_facilitadores() {
        let url = "lista/criacao/facilitadores";
        const response = await this.$http.get(url);
  
        this.facilitadores = response.data;
        let data_table = new Array();
        let status_cor = "text-secundary";
  
        this.facilitadores.forEach((facilitador) => {
          let botao_editar_facilitador=
          "<button class='btn btn-warning btn-sm mr-1 open-facilitador' type='button' id='"+facilitador.softcheck_profissional_id+"'><i id = " +
            facilitador.softcheck_profissional_id +
            " class = 'fas fa-edit'></i>Editar</button>";
          data_table.push([
  
             "<span class=" +
              status_cor +
              ">" +
              (this.btn_editar ? botao_editar_facilitador : "" )+
              "</span>",
  
            "<span class=" +
              status_cor +
              ">" +
              facilitador.softcheck_profissional_id +
              "</span>",
            "<span class=" +
              status_cor +
              ">" +
              facilitador.softcheck_profissional_nome +
              "</span>",
            "<span class=" +
              status_cor +
              ">" +
              facilitador.softcheck_profissional_cpf +
              "</span>",
            "<span class=" +
              status_cor +
              ">" +
              facilitador.softcheck_profissional_tipo +
              "</span>",
           
          ]);
        });
  
        $("#example1")
          .DataTable({
            data: data_table,
            destroy: true,
            responsive: true,
            lengthChange: false,
            autoWidth: false,
            buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
             drawCallback: function() {
              $(".open-facilitador").on("click", function(event) {
                abreModalEdicao(event.target.id);
              });
            },
          })
          .buttons()
          .container()
          .appendTo("#example1_wrapper .col-md-6:eq(0)");
      },
  
      habilita_botoes() {
         let privilegio
        let admin = true
        
        if(admin){
          privilegio = 'A'
        }
        if (privilegio == "A") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = true;
        } else {
          if (privilegio == "LE") {
            this.btn_executar = true;
            this.btn_editar = true;
            this.btn_excluir = false;
          } else {
            if (privilegio == "LX") {
              this.btn_executar = false;
              this.btn_editar = false;
              this.btn_excluir = true;
            } else {
              this.btn_executar = false;
              this.btn_editar = false;
              this.btn_excluir = false;
            }
          }
        }
      },
      async enviarFormFacilitador(){
        let botaoCadastro = document.querySelector("#btn-cadastro");
        let botaoLimpaForm = document.querySelector("#btn-limpa-form");
        let erros = false;
        botaoCadastro.disabled = true;
        botaoLimpaForm.disabled = true;
        
  
        if (!this.formFacilitador.nome) {
          this.errosFormFacilitador.nome = "O campo não foi preenchido!";
          erros = true;
        } else {
          this.errosFormFacilitador.nome = "";
        }
        if (!this.formFacilitador.cpf) {
          this.errosFormFacilitador.cpf = "O campo não foi preenchido!";
          erros = true;
        }
        else if (this.formFacilitador.cpf && !this.$cpf(this.formFacilitador.cpf)) {
          this.errosFormFacilitador.cpf = "O CPF é inválido!";
          erros = true;
        } else {
          this.errosFormFacilitador.cpf = "";
        }
        if (!this.formFacilitador.funcao) {
          this.errosFormFacilitador.funcao = "O campo não foi preenchido!";
          erros = true;
        } else {
          this.errosFormFacilitador.funcao = "";
        }
       
       
        if (!erros) {
          let retorno
           if (this.acao == "novo") {
              let url = "cadastra/facilitador";
              const response = await this.$http.post(url, {
                
                facilitador_nome: this.formFacilitador.nome,
                facilitador_cpf: this.formFacilitador.cpf,
                facilitador_tipo: this.formFacilitador.funcao,   
  
                page: this.page,
                profissional_id: this.user,
                acao: "cadastrar facilitador"
              });
              retorno = response.data;
            }
  
            else if (this.acao == "editar") {
              let url = "atualiza/facilitador";
              const response = await this.$http.patch(url, {
                dados :{
                softcheck_profissional_id: this.formFacilitador.softcheck_profissional_id,
                facilitador_nome: this.formFacilitador.nome,
                facilitador_cpf: this.formFacilitador.cpf,
                facilitador_tipo: this.formFacilitador.funcao,       
               
                page: this.page,
                profissional_id: this.user,
                acao: "editar facilitador"
                }
              });
              retorno = response.data;
            }
  
             if (retorno.erro) {
              this.$bvModal.msgBoxOk(retorno.erro, {
                title: "Aviso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "danger",
                bodyBgVariant: "danger",
                footerBgVariant: "danger",
              });
            } else {
              this.$bvModal.msgBoxOk(retorno.mensagem, {
                title: "Sucesso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "success",
                bodyBgVariant: "success",
                footerBgVariant: "success",
              });
              this.$bvModal.hide("modal_facilitador");
              this.lista_facilitadores();
            }
  
          }
          botaoCadastro.disabled = false;
        botaoLimpaForm.disabled = false;
  
        }, 
        
        
      async enviarFormFuncao(){
        let botaoCadastro = document.querySelector("#btn-cadastroFuncao");
        let botaoLimpaForm = document.querySelector("#btn-limpa-form");
        let erros = false;
        botaoCadastro.disabled = true;
        botaoLimpaForm.disabled = true;
        
  
        if (!this.formFuncao.funcao_nome) {
          this.errosFormFuncao.funcao_nome = "O campo não foi preenchido!";
          erros = true;
        } else {
          this.errosFormFuncao.funcao_nome = "";
        }
       
        if (!erros) {
          let retorno
           if (this.acao == "novaFuncao") {
              let url = "cadastra/funcao";
              const response = await this.$http.post(url, {
                
                funcao_nome: this.formFuncao.funcao_nome,
                
                // page: this.page,
                // profissional_id: this.user.dados.profissional_id,
                // acao: "cadastrar função"
              });
              retorno = response.data;
                
                 if (retorno.erro) {
              this.$bvModal.msgBoxOk(retorno.erro, {
                title: "Aviso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "danger",
                bodyBgVariant: "danger",
                footerBgVariant: "danger",
              });
            } else {
              this.$bvModal.msgBoxOk(retorno.mensagem, {
                title: "Sucesso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "success",
                bodyBgVariant: "success",
                footerBgVariant: "success",
              });
              this.$bvModal.hide("modal_funcao");
              this.lista_funcao();
            }
  
            } 
  
          }
          botaoCadastro.disabled = false;
        botaoLimpaForm.disabled = false;
  
        },  
  
      limparFormFacilitador(){
        Object.keys(this.errosFormFacilitador).forEach((item) => {
          this.errosFormFacilitador[item] = "";
        });
  
      }
    
    },
    filters: {
      dinheiro: function (value) {
        value = parseFloat(value);
        var dinheiro = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          useGrouping: true,
        });
        return dinheiro;
      },
    },
    mounted() {
      //quando liga a tela
      window.elemento_vue = this;
      Date.prototype.addDias = function (dias) {
        this.setDate(this.getDate() + dias);
      };
  
      let hoje = new Date();
      let amanha = new Date();
      amanha.addDias(1);
      this.date_start = hoje;
      this.date_end = amanha;
  
      this.habilita_botoes();
      this.lista_facilitadores();
      // this.listaAlocacao();
      this.listafuncoes();
    },
  };
  </script>
  <style scoped>
  .content-wrapper{
      margin-left: 0 !important;
  }
  </style>
  
  
  