/* eslint-disable vue/script-setup-uses-vars */
<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Controle de Tarefas</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">ControleTarefas</li>
              </ol>
            </div>

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="container-fluid">
        <div class="card">
          <div class="row">
            <div class="card-body col-sm-3">
              <button
                class="btn btn-lg btn-success"
                v-show="btn_executar"
                data-toggle="modal"
                data-target="#modal-success"
                type="button"
                :variant="varianteSubmit"
                @click="cadastraTarefa()"
              >
                <span>Cadastrar nova tarefa<i class="fas fa-play"></i></span>
              </button>
            </div>
          </div>

          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>RID tarefa</th>
                  <th>Tarefa numero</th>
                  <th>Turma codigo</th>
                  <th>Tarefa bloqueada</th>
                  <th>Tarefa gabarito</th>
                  <th>Data modificação</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>

    <b-modal size="lg" id="modal_sala" hide-footer>
      <template #modal-title> {{ form_titulo }} </template>

      <b-form>
        <div class="row">
          <div class="col-sm-4">
            <b-form-group id="input-1" label="Turma codigo">
              <select class="form-control" v-model="formTarefa.turma_codigo">
                <option
                  v-for="turma, index in turmas"
                  :value="turma.softcheck_turma_codigo"
                  :key="index"
                >
                  {{ turma.softcheck_turma_codigo }}
                </option>
              </select>
              <label class="text-danger small">{{
                errosFormTarefa.turma_codigo
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group id="input-1" label="Tarefa numero">
              <b-form-input
                id="tarefa_numero"
                trim
                v-model="formTarefa.tarefa_numero"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group id="input-1" label="Tarefa bloqueada">
              <b-form-select
                id="tarefa_bloqueada"
                trim
                v-model="formTarefa.tarefa_bloqueada"
                :options="status_tarefa"
              ></b-form-select>
              <label class="text-danger small">{{
                errosFormTarefa.tarefa_bloqueada
              }}</label>
            </b-form-group>
          </div>
         
          <div class="col-sm-12">            
            <b-form-group id="input-1" label="Gabarito do Assignment">
              <div class="flex-sup file-upload-box">
                <b-form-file
                    multiple
                    placeholder="Arraste o Gabarito para cá"
                    drop-placeholder="Jogue aqui!"
                    type="file"
                    v-model="arquivo"
                ></b-form-file>
                <div class="flex-sup upload-indiq">
                    <strong> Solte os arquivos em cima  </strong>
                    <i class="fas fa-arrow-up"></i>
                </div>
              </div>
              <label class="text-danger small">{{
                errosFormTarefa.tarefa_gabarito_url
              }}</label>
            </b-form-group>
          </div>
        </div>

        <hr />
        <div class="input-group justify-content-md-end">
          <b-button
            @click="limparFormTarefa()"
            id="btn-limpa-form"
            variant="danger"
            >Limpar formulário</b-button
          >
          <b-button
            class="mr-1"
            @click="enviarFormTarefa()"
            id="btn-cadastro"
            :variant="varianteSubmit"
            >{{ botaoSubmitTarefa }}</b-button
          >

        </div>
      </b-form>
    </b-modal>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

function abreModalEdicao(tarefa_id) {
  window.elemento_vue.editaTarefa(tarefa_id);
}
function alteraStatus(tarefa_id, value) {
  window.elemento_vue.statusTarefa(tarefa_id, value);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "ControleTarefas",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user: {},
      preloader_status: true,
      loading: false,
      page: "ControleTarefa",
      form_titulo: "",
      botaoSubmitTarefa: "",
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      varianteSubmit:"",
      arquivo:null,
      status_tarefa:[
        { text: "Entrega permitida", value: "0" },
        { text: "Entrega bloqueada", value: "1" },
       ],

      turmas: [],
      //variaveis para v-model
      formTarefa: {
        tarefa_id: "",
        turma_codigo: null,
        tarefa_numero: "",
        tarefa_bloqueada: null,
        tarefa_gabarito_url: "",
        data_modificacao: "",
      },
      // status : "teste",
      //variaveis para validação de cadastro
      errosFormTarefa: {
        turma_codigo: "",
        tarefa_bloqueada: "",
        tarefa_gabarito_url: "",
      },
    };
  },
  created() {
    this.preloader_status = false;
    this.listaTurmas();
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {
    async statusTarefa(tarefa_id, value) {
      let status;
      if (value == true) {
        status = 1;
      } else if (value == false) {
        status = 0;
      }

      let url = "atualiza/status";
      const response = await this.$http.patch(url, {
        tarefa_id,
        status,

        page: this.page,
        profissional_id: this.user.dados.profissional_id,
        acao: "editar status",
      });
      let retorno = response.data;
      console.log(retorno)
      this.lista_tarefas()
    },

    async listaTurmas() {
      let url = "lista/turmas";
      const response = await this.$http.get(url);
      this.turmas = response.data;
    },

    async cadastraTarefa() {
      this.limparFormTarefa();
      this.form_titulo = "Cadastra nova tarefa";
      this.botaoSubmitTarefa = "Cadastrar tarefa";
      this.varianteSubmit = "primary";
      this.acao = "novo";
      this.$bvModal.show("modal_sala");
      this.errosFormTarefa = {
        turma_codigo: "",
        tarefa_bloqueada: "",
        tarefa_gabarito_url: "",
      };
    },

    async editaTarefa(tarefa_id) {
      this.limparFormTarefa();
      this.form_titulo = "Editar tarefa";
      this.botaoSubmitTarefa = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";
      this.modal_sala = true;

      let url = "lista/tarefa/" + tarefa_id;

      try {
        const response = await this.$http.get(url);
        let dados_tarefa = response.data;
        (this.formTarefa.tarefa_id = dados_tarefa.tarefa_id),
          (this.formTarefa.tarefa_numero = dados_tarefa.tarefa_numero),
          (this.formTarefa.tarefa_bloqueada = dados_tarefa.tarefa_bloqueada),
          (this.formTarefa.turma_codigo = dados_tarefa.turma_codigo),
          (this.formTarefa.tarefa_gabarito_url =
            dados_tarefa.tarefa_gabarito_url),
          (this.formTarefa.data_modificacao = dados_tarefa.data_modificacao),
          this.$bvModal.show("modal_sala");
      } catch (err) {
        let msg = "Erro ao buscar dados da tarefa!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
    },

    async lista_tarefas() {
      let url = "lista/tarefas";
      const response = await this.$http.get(url);

      this.tarefas = response.data;
      let data_table = new Array();
      let status_cor = "text-secundary";

      this.tarefas.forEach((tarefas) => {
        let botao_editar_sala =
          "<button class='btn btn-warning btn-sm mr-1 open-sala' id = " +
          tarefas.tarefa_id +
          " type='button'><i id = " +
          tarefas.tarefa_id +
          " class = 'fas fa-edit'></i>Editar</button>";
          if(tarefas.tarefa_bloqueada == 0){
              tarefas.tarefa_bloqueada=''
          }else{
            tarefas.tarefa_bloqueada = 'checked'
          }
        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_sala : "") +
            "</span>",

          "<span class=" + status_cor + ">" + tarefas.tarefa_id + "</span>",

          "<span class=" + status_cor + ">" + tarefas.tarefa_numero + "</span>",

          "<span class=" + status_cor + ">" + tarefas.turma_codigo + "</span>",

          "<span class=" +
            status_cor +
            "> </span><label class='switch btn'><input type='checkbox' id = '" +
            tarefas.tarefa_id +
            "' class='botao_status' "+ tarefas.tarefa_bloqueada +" ><span class='slider round'></span><label>",

          "<span class=" +
            status_cor +
            ">" +
            tarefas.tarefa_gabarito_url +
            "</span>",

          "<span class=" +
            status_cor +
            ">" +
            tarefas.data_modificacao +
            "</span>",
        ]);
      });

      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
          drawCallback: function () {
            $(".open-sala").on("click", function (event) {
              abreModalEdicao(event.target.id);
            });
            $(".botao_status").on("click", function (event) {
              alteraStatus(event.target.id, event.target.checked);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
    },

    habilita_botoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "operacoes") {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }

        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },
    async enviarFormTarefa() {
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let botaoLimpaForm = document.querySelector("#btn-limpa-form");
      let erros = false;
      botaoCadastro.disabled = true;
      botaoLimpaForm.disabled = true;

      if (!this.formTarefa.turma_codigo) {
        this.errosFormTarefa.turma_codigo = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTarefa.turma_codigo = "";
      }
      if (!this.formTarefa.tarefa_bloqueada) {
        this.errosFormTarefa.tarefa_bloqueada = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTarefa.tarefa_bloqueada = "";
      }
       if (!this.formTarefa.tarefa_gabarito_url) {
        this.formTarefa.tarefa_gabarito_url = null;
      } 

      if (!erros) {
        let retorno
          if (this.acao == "novo") {
            let url = "cadastra/tarefa";
            let dados = { tarefa:{
                tarefa_id: this.formTarefa.tarefa_id,
                tarefa_numero: this.formTarefa.tarefa_numero,
                tarefa_bloqueada: this.formTarefa.tarefa_bloqueada,
                tarefa_gabarito_url: this.formTarefa.tarefa_gabarito_url,
                turma_codigo: this.formTarefa.turma_codigo,
              },
              page: this.page,
              profissional_id: this.user.dados.profissional_id,
              acao: "cadastrar tarefa"};
            let formData = new FormData();
            if(this.arquivo != null){
              formData.append("arquivo", this.arquivo[0]);
            }else{
              formData.append("arquivo", null);
            }
            formData.append("dado", JSON.stringify(dados));
            const response = await this.$http.post(url,formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
            });
            retorno = response.data;
          }

           else if (this.acao == "editar") {
              let url = "atualiza/tarefa";
              let dados = { tarefa:{
                  tarefa_id: this.formTarefa.tarefa_id,
                  tarefa_numero: this.formTarefa.tarefa_numero,
                  tarefa_bloqueada: this.formTarefa.tarefa_bloqueada,
                  tarefa_gabarito_url: this.formTarefa.tarefa_gabarito_url,
                  turma_codigo: this.formTarefa.turma_codigo,
                },
                page: this.page,
                profissional_id: this.user.dados.profissional_id,
                acao: "editar tarefa"};
              let formData2 = new FormData();
              if(this.arquivo != null){
                formData2.append("arquivo", this.arquivo[0]);
              }else{
                formData2.append("arquivo", null);
              }
              formData2.append("dado", JSON.stringify(dados));
              const response = await this.$http.post(url,formData2,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
              });
              retorno = response.data;
           }

            if (retorno.erro) {
             this.$bvModal.msgBoxOk(retorno.erro, {
               title: "Aviso!",
               okVariant: "light",
               okTitle: "ok",
               headerBgVariant: "danger",
               bodyBgVariant: "danger",
               footerBgVariant: "danger",
             });
           } else {
             this.$bvModal.msgBoxOk(retorno.mensagem, {
               title: "Sucesso!",
               okVariant: "light",
               okTitle: "ok",
               headerBgVariant: "success",
               bodyBgVariant: "success",
               footerBgVariant: "success",
             });
             this.$bvModal.hide("modal_sala");
             this.lista_tarefas();
         }

         }
         botaoCadastro.disabled = false;
       botaoLimpaForm.disabled = false;

      },  

    limparFormTarefa(){
      this.tarefa_id= "",
      this.turma_codigo= "",
      this.tarefa_numero= "",
      this.tarefa_bloqueada="",
      this.tarefa_gabarito_url="",
      this.data_modificacao=""
    },
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    //quando liga a tela
    window.elemento_vue = this;
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    this.habilita_botoes();
    this.lista_tarefas();
  },
};
</script>


<style>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>