<template>
  <div class="card">
    <div class="card-header">
      {{ video.titulo }}
      <button class="btn btn-primary btn-sm float-right" @click="showVideo()">
        {{ texto }}
        <i
          :class="{
            'fas fa-chevron-down': !mostrar,
            'fas fa-chevron-up': mostrar,
          }"
        ></i>
      </button>
    </div>
    <div class="card-body" v-show="mostrar">
      <iframe
        class="w-100"
        height="630" 
        :src="video.url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texto: "Abrir",
    };
  },
  props: {
    video: Object,
    mostrar: Boolean,
  },
  methods: {
    showVideo() {
      this.mostrar = !this.mostrar;
      if (this.mostrar) {
        this.texto = "Fechar";
      } else {
        this.texto = "Abrir";
      }
    },
  },
};
</script>
