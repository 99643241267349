<template>

    <div id="rootEmissao">
        <Menu />
        <Navbar />
        <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Comunicados</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active"> Comunicados </li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="container-fluid">
        <div class="card ">
          <div class="row">
            <!-- upload box -->
            <div class="card-body col-sm-6">
              <div class="form-group">
                <div class="row">
                  <!-- curso -->
                  <div class="col-6">
                    <label > Selecione o curso: </label>
                    <div>
                      <b-form-select label="Selecione o curso:" @change="valida_curso()" v-model="curso_selecionado">
                        <b-form-select-option v-for="(curso, index) in cursos" :key="index" :value="curso">
                          {{curso.curso_titulo}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                  <!-- turma -->
                  <div v-show="turma_visivel" class="col-5">
                    <label > Selecione a turma: </label>
                    <div>
                      <b-form-select label="Selecione a turma:" v-model="turma_selecionado">
                        <b-form-select-option v-for="(turma, index) in turmas" :key="index" :value="turma">
                          {{turma.softcheck_turma_codigo}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                </div>
                <div class="spinner-border text-primary mt-1 mb-1"
                    role="status"
                    v-show="loading"
                  ></div>
              </div>
            </div>
          </div>
          <div>
              <quill-editor class="Editor"
              @change="limitEditor()"
              :options="quillOptions"
              v-model="texto_comunicado">

              </quill-editor>
              <div class="send">
                <b-button class="bs-btn" :disabled="limit" variant="success m-1" @click.prevent="salvar_comunicado()">Enviar</b-button>
                <strong v-show="limit">Limite de caracteres atingido</strong>
                <b-button class="bs-btn" variant="success m-1" @click.prevent="ver_comunicado()">Ver Comunicados</b-button>
              </div>

          </div>
          <div class="table">
            <b-overlay :show="showOverlay" rounded="sm">
              <table v-show="view_table" class="table table-bordered table-hover">
                  <thead>
                    <tr class="head">
                      <th>Id</th>
                      <th>Curso</th>
                      <th>Turma</th>
                      <th>Comunicado</th>
                      <th>Editar</th>
                      <th>Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(comunicado) in comunicados" :key="comunicado.comunicado_id">
                      <td>{{comunicado.comunicado_id}}</td>
                      <td>{{ comunicado.comunicado_curso }}</td>
                      <td>{{ comunicado.comunicado_turma }}</td>
                      <td ><p v-html="comunicado.comunicado" style="padding-left: 10px;"></p></td>
                      <td>
                        <b-button class="bs-btn" v-b-modal.modal-1 variant="success m-1" @click.prevent="render_comunicado(comunicado.comunicado, comunicado.comunicado_id)">Editar</b-button>
                      </td>
                      <td>
                        <b-button class="bs-btn" variant="danger m-1" @click.prevent="excluir(comunicado.comunicado_id)">Excluir</b-button>
                      </td>
                    </tr>
                  </tbody>
              </table>
            
            </b-overlay>
            <div v-show="sem_comunicados" style="text-align: center;">
              <hr>
              <h2>Sem Comunicados</h2>
            </div>
          </div>

          <div class="editor_modal">
            <b-modal
            v-show="Modal"
            id="modal-1"
            hide-footer title="Editando comunicado..."
            ref="modal-editor"
            >
              <div>
                <quill-editor class="Editor"
                @change="limitEditor()"
                :options="quillOptions"
                v-model="comunicado_editado"
                :content="texto">

              </quill-editor>
              </div>
              <hr>
              <div class="modal-editor-button">
                <b-button class="modal-button-footer" variant="danger" @click.prevent="hide_modal()"> Cancelar</b-button>
                <b-button class="modal-button-footer" variant="primary" @click.prevent="atualizar_comunicado()"> Salvar</b-button>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </div>
</template>
<style>
 .Editor {
  background-color: rgba(218, 218, 215, 0.712);
  height: 100%;
 }
 .send{
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
 }
 .modal-editor-button{
  display: flex;
  justify-content: space-between;
}
.modal-button-footer{
  height: 40px;
}
</style>
<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
export default {
  name:'Comunicados',
  components: {
      Navbar,
      Menu,
      Footer
  },
  data() {
      return{
        sem_comunicados:false,
        turmaCodigo:'',
        id_update:0,
        comunicado_editado:'',
        texto:'',
        comunicados:[],
        view_table:false,
        Modal:false,
        limit:false,
          loading: false,
          cursos: [],
          turma_selecionado: 0,
          curso_selecionado: 0,
          showOverlay: false,
          turma_codigo: null,
          turmas: [],
          turma_visivel :false,
          texto_comunicado:'',
          quillOptions:{
            placeholder:'Digite o comunicado',
            modules:{
              toolbar:this.toolbarOptions
            }
          },
          toolbarOptions :[
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
          ]

      }
  },
  mounted(){
      this.lista_cursos()
  },
  methods: {
    hide_modal(){
      this.$refs['modal-editor'].hide()
    },
    excluir(id){
      this.$http.delete(`communication/delete/${id}`)
      .then(() => {
        this.ver_comunicado()
      })
    },
    ver_comunicado(){
      this.showOverlay = true
      this.turmaCodigo = this.turma_selecionado.softcheck_turma_codigo === undefined? 0 : this.turma_selecionado.softcheck_turma_codigo
      this.$http.get(`communication/list/${this.turmaCodigo}/${this.curso_selecionado.curso_id}`)
      .then((response) => {
        this.comunicados = response.data
        if (!this.comunicados.length) {
          this.view_table = false
          this.sem_comunicados = true
        }else{
          this.sem_comunicados = false
          this.view_table = true
          this.turmaCodigo = 0
        }
        this.showOverlay = false
      })
      
    },
      limitEditor(){
        let limitCaracteres = 400
        if (this.texto_comunicado.length > limitCaracteres) {
          this.limit = true;
          
        }else{
          this.limit = false;
        }
      },
      lista_cursos() {
          this.$http.get('course/list')
          .then((response) => {
          this.cursos = response.data
          })
      },

      valida_curso(){
          if([1,3].includes(this.curso_selecionado.curso_categoria)){
              this.lista_turma_codigos();
              this.turma_visivel = true;

          }else{
              this.turmaCodigo = 0
              this.turma_visivel = false;
          }
      },
      lista_turma_codigos() {
          this.$http.get(`class/list/${this.curso_selecionado.curso_id}`)
              .then((response) => {
              this.turmas = response.data
              this.turma_codigo = null
              })
      },
      salvar_comunicado(){
        this.showOverlay = true
        this.turmaCodigo = this.turma_selecionado.softcheck_turma_id === undefined? 0 : this.turma_selecionado.softcheck_turma_id
        this.$http.post('/communication/create/',{
          communication:this.texto_comunicado,
          classId: this.turmaCodigo,
          classCode: this.turma_selecionado.softcheck_turma_codigo === undefined ? "Sem turma" : this.turma_selecionado.softcheck_turma_codigo,
          courseTitle: this.curso_selecionado.curso_titulo,
          courseId:this.curso_selecionado.curso_id
        }).then(() => {
          this.ver_comunicado()
          this.texto_comunicado = ''
          this.showOverlay = false
        })
      },
      render_comunicado(comunicado,id){
        this.id_update = id
        this.texto = comunicado
        this.comunicado_editado = comunicado
      },

      atualizar_comunicado(){
        this.showOverlay = true
        this.hide_modal();
        this.$http.put(`communication/edit/${this.id_update}`,{
          communication:this.comunicado_editado
        }).then(() => {
          this.ver_comunicado();
          this.showOverlay = false
        })
      }
  }
}

</script>