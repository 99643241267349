<template>
    <div>
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Vídeos Zoom</h1>
              </div>
            </div>
          </div>
        </div>
  
        <div class="card">
          <div class="row" v-show="loading">
              <div class="mx-auto text-center">
                  <div class="spinner-border text-primary"></div>
              </div>
          </div>
          <div class="card-body">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <td v-if="!pasta_selecionada">
                    Pasta
                  </td>
                  <td v-if="pasta_selecionada">
                    Vídeos
                    <button @click.prevent="pasta_selecionada = null">Voltar</button>    
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-show="!pasta_selecionada" v-for="pasta in pastas_zoom" :key="pasta.id"> <!-- v-for -->
                  <td>
                    <a href="" @click.prevent="pasta_selecionada = pasta">{{pasta.topic}}</a>
                  </td>
                </tr>
                <tr v-show="arquivos" v-for="arquivo in arquivos" :key="arquivo.id">
                  <td>
                    <a v-if="arquivo.file_extension == 'MP4'" :href="arquivo.url_s3" target="_blank">
                      {{pasta_selecionada.topic}} - .{{arquivo.file_extension}}
                    </a>
  
                    <a v-else :href="arquivo.download_url" target="_blank">
                      {{pasta_selecionada.topic}} - .{{arquivo.file_extension}}
                    </a>
                    <button v-if="arquivo.file_extension == 'MP4'" @click.prevent="upload_video(arquivo.download_url + '?access_token=eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6IjJ6UnB1WnBKU1FXSzVNakVzbjlORmciLCJleHAiOjE3NjE1MzQwMDAsImlhdCI6MTYzNTM1NDY3OX0.GwRLDpn21TSNAuJ4LyiZB-jMFtuLQa2266_agaJoqko', pasta_selecionada.topic, arquivo.id)">Upload</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import AWS from "aws-sdk";
  // import axios from "axios";
  
  export default {
    data() {
      return {
        pastas_zoom: [],
        pasta_selecionada: null,
        loading: false,
        arquivos_upload: []
      }
    },
    created() {
      this.fetch_videos_zoom()
    },
    computed: {
      arquivos() {
        if(this.pasta_selecionada) {
          return this.pasta_selecionada.recording_files
        }
  
        return null;
      }
    },
    methods: {
      async fetch_videos_zoom() {
        this.loading = true
        await this.$http.get('lista/recordings')
          .then((response) => {
            this.pastas_zoom = response.data
            this.loading = false
          })
      },
  
      async upload_video(downloadUrl, topic, id) {
        if(this.loading) {
          alert('Aguarde o processamento!')
        } else {
          this.loading = true;
          this.$http.post(`upload/s3`, {
            downloadUrl: downloadUrl,
            topic: topic,
            id: id
          })
            .then(() => {
              this.loading = false
              alert('Upload concluído, recarregue a página')
              this.fetch_videos_zoom()
            })
            .catch(() => {
              this.loading = false
              alert('Recarregue a página')
            })
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .content-wrapper{
      margin-left: 0 !important;
  }
  </style>