<template>
    <div id="rootGrupos">
        <Menu />
        <Navbar />
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Configuração de Regras</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Configuração de Regras</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card">
                    <div class="row">
                        <!-- upload box -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-3">
                                    <div>
                                        <b-form-group label="Turmas" label-for="turma">
                                            <b-form-select 
                                                id="turma"
                                                :options="turmas"
                                                @change="lista_regras()"
                                                v-model="turma">
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div><br></div>
                                    <button v-if="turma" style="margin-left:8px;" class="btn btn-lg btn-success float-right" type="button" @click="copiarRegra()">
                                        <span>Copiar Regra <i class="fas fa-plus"></i></span>
                                    </button>
                                    <button class="btn btn-lg btn-success float-right" type="button" @click="adicionarRegra()">
                                        <span>Adicionar Regra <i class="fas fa-plus"></i></span>
                                    </button>
                                </div>
                            </div>
                            <!-- Exibição das provas -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="mx-auto" v-show="loading">
                                    <div class="mx-auto text-center">
                                        <div class="spinner-border text-primary"></div>
                                    </div>
                                    </div> 
                                    <table id="regras_turma" class="table table-bordered table-hover" v-show="true">
                                    <thead>
                                    <tr>
                                        <th><i class="fas fa-plus"></i></th>
                                        <th>#</th>
                                        <th>Categoria</th>
                                        <th>Nota</th>
                                        <th>Quantidade</th>
                                        <th>Ranking</th>
                                        <th>Curso</th>
                                        <th>Turma</th>
                                    </tr>
                                    </thead>
                                    <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal size="lg" id="modal_copiar" hide-footer>
            <template #modal-title> {{ form_titulo }} </template>
            <b-form>
                <div class="row">
                    <div class="col-3">
                        <b-form-group label="Turma a ser copiada">
                            <b-form-select
                                v-model="turma_codigo_copiar"
                                :options="todas_turmas"
                                @change="lista_regras_copiar()"
                            ></b-form-select>
                            <label class="text-danger small">{{form_erros.turma}}</label>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                      <p>Turma que irá receber a cópia: <b>{{this.turma}}</b></p>
                    </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="mx-auto" v-show="loading">
                    <div class="mx-auto text-center">
                        <div class="spinner-border text-primary"></div>
                    </div>
                    </div> 
                    <table id="regras_turma_copiar" class="table table-bordered table-hover" v-show="true">
                    <thead>
                    <tr>
                        <th><i class="fas fa-plus"></i></th>
                        <th>#</th>
                        <th>Categoria</th>
                        <th>Nota</th>
                        <th>Quantidade</th>
                        <th>Ranking</th>
                        <th>Curso</th>
                        <th>Turma</th>
                    </tr>
                    </thead>
                    <tbody></tbody>
                    </table>
                </div>
                </div>
                <hr>
                <div class="input-group justify-content-md-end">
                    <div class="mx-auto" v-show="loading_modal">
                        <div class="mx-auto text-center">
                            <div class="spinner-border text-primary"></div>
                        </div>
                    </div> 
                    <b-button
                        class="mr-1 btn btn-danger"
                        id="btn-cadastro"
                        :disabled = loading_modal
                        @click="enviar_formulario_copia()"
                    >Copiar</b-button>
                    
                </div>
                
            </b-form>
            
        </b-modal>

        <b-modal size="lg" id="modal_regra" hide-footer>
            <template #modal-title> {{ form_titulo }} </template>
            <b-form>
                <div class="row">
                    <div class="col-3">
                        <b-form-group label="Curso">
                            <b-form-select
                                v-model="curso"
                                :options="cursos"
                            ></b-form-select>
                            <label class="text-danger small">{{form_erros.curso}}</label>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Turma">
                            <b-form-select
                                v-model="turma_codigo"
                                :options="todas_turmas"
                            ></b-form-select>
                            <label class="text-danger small">{{form_erros.turma}}</label>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Categoria">
                            <b-form-select
                                v-model="categoria"
                                :options="categorias"
                            ></b-form-select>
                            <label class="text-danger small">{{form_erros.categoria}}</label>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-card>
                            <b-form-group
                            label="Regras"
                            label-align="left"
                            > 
                                <b-form-row>
                                    <b-col>
                                        <b-form-group 
                                        id="input-4" 
                                        description="Nota"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="regra_nota"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                        <label class="text-danger small">{{form_erros.nota}}</label>
                                    </b-col>
                                    
                                    <b-col>
                                        <b-form-group 
                                        id="input-30"
                                        description="Quantidade"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="regra_quantidade"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                        <label class="text-danger small">{{form_erros.quantidade}}</label>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-26"
                                        description="Pontos no Ranking"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="regra_nota_ranking"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                        <label class="text-danger small">{{form_erros.pontuacao_ranking}}</label>
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <hr>
                <div class="input-group justify-content-md-end">
                    <div class="mx-auto" v-show="loading_modal">
                        <div class="mx-auto text-center">
                            <div class="spinner-border text-primary"></div>
                        </div>
                    </div> 
                    <b-button
                        class="mr-1 btn btn-danger"
                        id="btn-cadastro"
                        :disabled = loading_modal
                        @click="enviar_formulario()"
                    >Salvar</b-button>
                    
                </div>
                
            </b-form>
            
        </b-modal>
        <Footer />
    </div>
</template>

<script>
function abreModalEdicao(prova_id) {
    window.elemento_vue.editaRegra(prova_id);
}
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {
            acao: "",
            categorias: [],
            categoria: "",
            cursos: [],
            curso: "",
            user: {},
            page: "Configuração de Regras",
            loading: false,
            loading_modal: false,
            regras: [],
            turma_codigo_copiar: '',
            turmas: [{
                text: "Todas",
                value: ""
            }],
            op_binarias: [{
                    text: "Sim",
                    value: 1
                },{
                    text: "Não",
                    value: 0
                }
            ],
            todas_turmas: [{
                text: "Não tem",
                value: null
            }],
            form_erros: {
                curso:'',
                turma: '',
                categoria:'',
                nota: '',
                quantidade: '',
                pontos_ranking: ''
            },
            turma: '',
            regra_id: '',
            form_titulo: '',
            turma_codigo: '',
            regra_nota: '',
            regra_quantidade: '',
            regra_nota_ranking: ''
        };
    },
    created() {
        this.busca_turmas()
        this.lista_categorias()
        this.lista_cursos()
        this.lista_regras()
    },
    methods: {
        // Carregamento do select das turmas
        limpa_erros(){
            this.form_erros = {
                curso:'',
                turma: '',
                categoria:'',
                nota: '',
                quantidade: '',
                pontos_ranking: ''
            }
        },
        limpa_formulario(){
                this.limpa_erros()
                this.curso = ""
                this.regra_nota_ranking = ""
                this.regra_quantidade = ""
                this.regra_nota = ""
                this.categoria = ""
                this.turma_codigo = null
        },
        async busca_turmas(){
            this.loading = true;
            let url = "turmas";
            const response = await this.$http.get(url);

            response.data.forEach(turma => {
                this.turmas.push({
                    text: turma.turma_codigo,
                    value: turma.turma_codigo
                });
                this.todas_turmas.push({
                    text: turma.turma_codigo,
                    value: turma.turma_codigo
                });
            });
            this.loading = false;
        },

        // Carregamento das informações da tabela
        async lista_categorias(){
            let url = "categorias"
            const response = await this.$http.get(url);

            response.data.forEach(categoria => {
                this.categorias.push({
                    text: categoria.regra_categoria_titulo,
                    value: categoria.regra_categoria_id
                })
            });
        },

        async lista_cursos(){
            let url = "cursos"
            const response = await this.$http.get(url);

            response.data.forEach(curso => {
                this.cursos.push({
                    text: curso.curso_titulo,
                    value: curso.curso_id
                })
            });
        },
        async lista_regras(){
            this.loading = true;
            let url
            if(!this.turma){
                url = "regras/turmas";
            }else{
                url = "regras/turmas/"+this.turma;
            }
            
            const response = await this.$http.get(url);
            let data_table = new Array();
            response.data.forEach(regra => {
                // Botão para editar a regra
                let botao_editar=
                    "<button class='btn btn-warning btn-sm mr-1 open-edicao' id = " +
                    regra.regra_id +
                    " type='button'><i id = " +
                    regra.regra_id +
                    " class = 'fas fa-edit'></i>Editar</button>";

                data_table.push([
                    "<span>" + botao_editar + "</span>",
                    "<span>" + regra.regra_id + "</span>",
                    "<span>" + regra.regra_categoria_titulo + "</span>",
                    "<span>" + regra.regra_nota + "</span>",
                    "<span>" + regra.regra_quantidade+ "</span>",
                    "<span>" + regra.regra_nota_ranking+ "</span>",
                    "<span>" + regra.curso_titulo+ "</span>",
                    "<span>" + regra.turma_codigo + "</span>"
                ]);

                this.regras.push(regra);
            });

            $("#regras_turma").DataTable({
                data: data_table,
                destroy: true,
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                language: {
                    sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                    sInfoEmpty: " ",
                    sLengthMenu: "_MENU_ resultados por página",
                    sSearch: "",
                    sEmptyTable: "Nenhum registro encontrado",
                    sInfoFiltered: "Filtrados de _MAX_ registros",
                    sLoadingRecords: "Carregando...",
                    sProcessing: "Processando...",
                    sInfoPostFix: "",
                    sInfoThousands: ".",
                    sSearchPlaceholder: "Pesquisar",
                    sZeroRecords:
                    "Nenhum registro encontrado <i class='far fa-frown'></i>",
                    paginate: {
                        sNext: "Próximo",
                        sPrevious: "Anterior",
                    },
                },
                drawCallback: function() {
                    $(".open-edicao").on("click", function(event) {
                        abreModalEdicao(event.target.id);
                    });
                }
            });

            this.loading = false;
        },
        async lista_regras_copiar(){
            this.loading = true;
            let url
            if(this.turma_codigo_copiar){
              url = "regras/turmas/"+this.turma_codigo_copiar;
            } else {
              return null
            }
            
            const response = await this.$http.get(url);
            let data_table = new Array();
            response.data.forEach(regra => {
                // Botão para editar a regra
                let botao_editar=
                    "<button class='btn btn-warning btn-sm mr-1 open-edicao' id = " +
                    regra.regra_id +
                    " type='button'><i id = " +
                    regra.regra_id +
                    " class = 'fas fa-edit'></i>Editar</button>";

                data_table.push([
                    "<span>" + botao_editar + "</span>",
                    "<span>" + regra.regra_id + "</span>",
                    "<span>" + regra.regra_categoria_titulo + "</span>",
                    "<span>" + regra.regra_nota + "</span>",
                    "<span>" + regra.regra_quantidade+ "</span>",
                    "<span>" + regra.regra_nota_ranking+ "</span>",
                    "<span>" + regra.curso_titulo+ "</span>",
                    "<span>" + regra.turma_codigo + "</span>"
                ]);

                this.regras.push(regra);
            });

            $("#regras_turma_copiar").DataTable({
                data: data_table,
                destroy: true,
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                language: {
                    sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                    sInfoEmpty: " ",
                    sLengthMenu: "_MENU_ resultados por página",
                    sSearch: "",
                    sEmptyTable: "Nenhum registro encontrado",
                    sInfoFiltered: "Filtrados de _MAX_ registros",
                    sLoadingRecords: "Carregando...",
                    sProcessing: "Processando...",
                    sInfoPostFix: "",
                    sInfoThousands: ".",
                    sSearchPlaceholder: "Pesquisar",
                    sZeroRecords:
                    "Nenhum registro encontrado <i class='far fa-frown'></i>",
                    paginate: {
                        sNext: "Próximo",
                        sPrevious: "Anterior",
                    },
                },
                drawCallback: function() {
                    $(".open-edicao").on("click", function(event) {
                        abreModalEdicao(event.target.id);
                    });
                }
            });

            this.loading = false;
        },
        // Modal de edição de regra
        async editaRegra(regra_id){
            this.limpa_formulario()
            this.form_titulo = "Editar Regra";
            this.acao = "editar"
            let url = 'regras/'+regra_id
            console.log(url)
            await this.$http.get(url).then((response) => {
                    this.regra_id = regra_id
                    this.regra_nota_ranking = response.data.regra_nota_ranking
                    this.regra_quantidade = response.data.regra_quantidade
                    this.regra_nota = response.data.regra_nota
                    this.categoria = response.data.regra_categoria_id
                    this.turma_codigo = response.data.turma_codigo
                    this.curso = response.data.curso_id
                    this.$bvModal.show("modal_regra");
                    
                }).catch(() => {
                    this.$bvModal.msgBoxOk('ocorreu um erro ao pesquisar pela regra!', {
                        title: "Aviso!",
                        okVariant: "light",
                        okTitle: "ok",
                        headerBgVariant: "danger",
                        bodyBgVariant: "danger",
                        footerBgVariant: "danger",
                    });
                });
            
        },
        // Modal de inserção de regra
        adicionarRegra(){
            this.limpa_formulario()
            this.form_titulo ="Adicionar Regra";
            this.acao = "salvar"
            this.$bvModal.show("modal_regra");
        },
        copiarRegra(){
            this.limpa_formulario()
            this.form_titulo ="Copiar Regra";
            this.acao = "copiar"
            this.$bvModal.show("modal_copiar");
        },
        async enviar_formulario_copia() {
          await this.$http.post('regras/copiar', {
            turma_codigo: this.turma,
            turma_codigo_copiar: this.turma_codigo_copiar
          })
            .then(() => {
              this.$bvModal.msgBoxOk('Regras de turma copiados com sucesso!', {
                  title: "Sucesso!",
                  okVariant: "light",
                  okTitle: "ok",
                  headerBgVariant: "success",
                  bodyBgVariant: "success",
                  footerBgVariant: "success",
              });
              this.lista_regras()
              this.$bvModal.hide("modal_regra");
            })
        },
        
        // Garante que o usuário colocará somente números na campo
        isNumber: function(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        
        
        async enviar_formulario(){
            this.loading_modal = true
            let erro = false
            this.limpa_erros()
            if(!this.curso){
                this.form_erros.curso = "Selecione o curso!"
                erro = true
            }
            if(!this.categoria){
                this.form_erros.categoria = "Selecione a categoria!"
                erro = true
            }
            if(!this.regra_nota){
                this.form_erros.nota = "Informe a nota!"
                erro = true
            }
            if(!this.regra_quantidade){
                this.form_erros.quantidade = "Informe a quantidade!"
                erro = true
            }
            if(!this.regra_nota_ranking){
                this.form_erros.pontuacao_ranking = "Informe a pontuação do ranking!"
                erro = true
            }

            if(erro){
                this.loading_modal = false
                return false
            }
            let url = 'regras'

            if(this.acao == 'salvar'){    
                await this.$http.post(url, {
                    categoria_id: this.categoria,
                    regra_nota: this.regra_nota,
                    regra_quantidade: this.regra_quantidade,
                    regra_nota_ranking: this.regra_nota_ranking,
                    curso_id: this.curso,
                    turma_codigo: this.turma_codigo
                }).then((response) => {
                    this.$bvModal.msgBoxOk(response.data.mensagem, {
                        title: "Sucesso!",
                        okVariant: "light",
                        okTitle: "ok",
                        headerBgVariant: "success",
                        bodyBgVariant: "success",
                        footerBgVariant: "success",
                    });
                    this.lista_regras()
                    this.$bvModal.hide("modal_regra");
                }).catch((exception) => {
                    this.$bvModal.msgBoxOk(exception.response.data.mensagem, {
                        title: "Aviso!",
                        okVariant: "light",
                        okTitle: "ok",
                        headerBgVariant: "danger",
                        bodyBgVariant: "danger",
                        footerBgVariant: "danger",
                    });
                });

            }else{
                await this.$http.patch(url, {
                    regra_id: this.regra_id,
                    categoria_id: this.categoria,
                    regra_nota: this.regra_nota,
                    regra_quantidade: this.regra_quantidade,
                    regra_nota_ranking: this.regra_nota_ranking,
                    curso_id: this.curso,
                    turma_codigo: this.turma_codigo
                }).then((response) => {
                    this.$bvModal.msgBoxOk(response.data.mensagem, {
                        title: "Sucesso!",
                        okVariant: "light",
                        okTitle: "ok",
                        headerBgVariant: "success",
                        bodyBgVariant: "success",
                        footerBgVariant: "success",
                    });
                    this.lista_regras()
                    this.$bvModal.hide("modal_regra");
                }).catch((exception) => {
                    this.$bvModal.msgBoxOk(exception.response.data.mensagem, {
                        title: "Aviso!",
                        okVariant: "light",
                        okTitle: "ok",
                        headerBgVariant: "danger",
                        bodyBgVariant: "danger",
                        footerBgVariant: "danger",
                    });
                });
            }
            this.loading_modal = false
        }
    },
    mounted() {
        window.elemento_vue = this;
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    }
}
</script>