<template>
  <div class="teste">
    <h1>This is an about page</h1>
    <download-excel
      class="btn btn-default"
      :data="json_dados"
      :fields="json_campos"
      worksheet="Planilha de Trabalho"
      name="meu_arquivo.xls"
    >
      Download Excel (you can customize this with html code!)
    </download-excel>
  </div>
</template>

<script>
export default {
  name: "Teste",
  components: {},
  data() {
    return {
      json_campos: {
        "Nome Completo": "nome",
        Cidade: "cidade",
        Nascimento: "dt_nasc",
        Telefone: "tel.tel1",
        "Telefone 2": {
          field: "tel.tel2",
          callback: (value) => {
            return `Texto - ${value}`;
          },
        },
      },
      json_dados: [
        {
          nome: "Tony Peña",
          cidade: "New York",
          country: "United States",
          dt_nasc: "1978-03-15",
          tel: {
            tel1: "1-541-754-3010",
            tel2: "(541) 754-3010",
          },
        },
        {
          nome: "Tony Peña",
          cidade: "New York",
          country: "United States",
          dt_nasc: "1978-03-15",
          tel: {
            tel1: "1-541-754-3010",
            tel2: "(541) 754-3010",
          },
        },
        {
          nome: "Tony Peña",
          cidade: "New York",
          country: "United States",
          dt_nasc: "1978-03-15",
          tel: {
            tel1: "1-541-754-3010",
            tel2: "(541) 754-3010",
          },
        },
        {
          nome: "Tony Peña",
          cidade: "New York",
          country: "United States",
          dt_nasc: "1978-03-15",
          tel: {
            tel1: "1-541-754-3010",
            tel2: "(541) 754-3010",
          },
        },                
       ],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
};
</script>

