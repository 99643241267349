import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    all_videos_done: false,
  },
  mutations: {
    CHANGE_LOADING(state) {
      state.loading = !state.loading
    },
    CHANGE_STATUS_VIDEO(state) {
      state.all_videos_done = true
    }
  },
  modules: {},
});
