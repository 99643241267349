<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />

    <div class="content-wrapper">
      <!-- custom page body -->
      <div class="content-header">
        <div class="flex-sup customPageBody">
          <div class="flex-sup adm-card head-adm-card">
            <h1>Gerenciamento de grupos para o Softcheck</h1>

            <!-- right: nav/save -->
            <div class="flex-sup right-save subhead-right-btn">
              <ol class="breadcrumb float-sm-right mr-2">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Grupos Softcheck</li>
              </ol>

              <button class="btn btn-lg bs-btn btn-success mr-2" type="button" @click="adicionar_aluno()">
                <span>Editar aluno e grupos <i class="fas fa-chalkboard-teacher"></i></span>
              </button>

              <button class="btn btn-lg bs-btn btn-success" type="button" @click="upload_arquivo()">
                <span>Upload Grupos de Projeto <i class="fas fa-file-upload"></i></span>
              </button>
            </div>
          </div>

          <!-- > Notificações Personalizadas -->
          <div class="flex-sup adm-card">
            <h4>Selecione a turma:</h4>
            <!-- filter/head line -->
            <div class="flex-sup adm-card-headline">
              <!-- left:filter cards -->
              <div class="flex-sup filter-left">
                <div class="flex-sup head-line-card">
                  <h5>Turmas:</h5>
                  <b-form-select 
                    placeholder="Selecione a turma"
                    @click.prevent
                    :options="turmas"
                    @change="turma_codigo_aluno = null; lista_days()"
                    v-model="turma_codigo">
                  </b-form-select>
                </div>
                &nbsp;&nbsp;
                <div class="flex-sup arrow-between" v-show="mostrar_day">
                  <img src="../assets/img/headline_arrow.png" />
                </div>
                &nbsp;&nbsp;
                <div class="flex-sup head-line-card" v-show="mostrar_day">
                  <h5>Day:</h5>
                  <b-form-select 
                      @click.prevent
                      :options="day_turma"
                      @change="controle_opcao()"
                      v-model="day">
                  </b-form-select>
                </div>
                &nbsp;&nbsp;
                <div class="flex-sup arrow-between" v-show="mostrar_opcao">
                  <img src="../assets/img/headline_arrow.png" />
                </div>
                &nbsp;&nbsp;
                <div class="flex-sup head-line-card" v-show="mostrar_opcao">
                  <h5>Opção:</h5>
                  <b-form-select 
                      @click.prevent
                      :options="opcoes"
                      @change="controle_opcao2();listar_ultimo_grupo()"
                      v-model="opcao">
                  </b-form-select>
                </div>
                <!-- GRUPOS ALEATÓRIOS -->
                <div v-show="painel_aleatorio">&nbsp;&nbsp;</div>
                <div class="flex-sup arrow-between" v-show="painel_aleatorio">
                  <img src="../assets/img/headline_arrow.png" />
                </div>
                <div v-show="painel_aleatorio">&nbsp;&nbsp;</div>
                <div class="flex-sup head-line-card" v-show="painel_aleatorio">
                  <h5>Quantidade de Grupos:</h5>
                  <b-form-input
                    id="qtd_grupo"
                    @keypress="isNumber($event)"
                    v-model="qtd_grupos"
                  ></b-form-input>
                </div>
                <div v-show="painel_aleatorio">&nbsp;&nbsp;</div>
                <div class="flex-sup arrow-between" v-show="painel_aleatorio">
                  <img src="../assets/img/headline_arrow.png" />
                </div>
                <div v-show="painel_aleatorio">&nbsp;&nbsp;</div>
                <div class="flex-sup head-line-card" v-show="painel_aleatorio">
                  <a
                    href="#"
                    @click="aleatoriza_turma()"
                    class="flex-sup add-button"
                  >
                    <i class="fas fa-random"></i>
                    <small> Aleatorizar grupos </small>
                  </a>
                </div>
                <!-- FIM GRUPOS ALEATÓRIOS -->
                <div v-show="painel_projeto">&nbsp;&nbsp;</div>
                <div class="flex-sup arrow-between" v-show="painel_projeto">
                  <img src="../assets/img/headline_arrow.png" />
                </div>
                <div v-show="painel_projeto">&nbsp;&nbsp;</div>
                <div class="flex-sup head-line-card" v-show="painel_projeto">
                  <a href="#" @click="salva_turma_projeto()" class="flex-sup save-button">
                    <small> Salvar </small>
                    <i class="fas fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- Loading Gif -->
            <div class="row" v-show="loading">
              <div class="mx-auto text-center">
                <img :src="require('../assets/loading.gif')" fluid width="20%">
              </div>
            </div> 

            <!-- Painel de Exportação -->
            <div class="row justify-content-md-center" v-show="!loading && painel_exportacao">
              <div class="col-2">
                <a @click.prevent="gerar_copiar_link()" class="flex-sup bglessBtn" target="_blank" rel="noopener noreferrer" ref="mylink">
                  <i class="fas fa-copy"></i> <small> Copiar link </small>
                </a>
              </div>
              <div class="col-2"> 
                <a class="flex-sup bglessBtn" @click="gera_impressao()">
                  <i class="far fa-file-pdf"></i> <small> Gerar PDF </small>
                </a>
              </div>
            </div> 

            <!-- the group list -->
            <ul class="flex-sup group-list" >
              <!-- card example: -->
              <li class="flex-sup group-card" v-show="!loading && painel_aleatorio" v-for="grupo in grupos" :key="grupo.grupo_id">
                <div class="flex-sup grp-card-head">
                  <small>{{ grupo.grupo_codigo }}</small>
                </div>
                <ul class="flex-sup group-names" v-for="aluno in grupo.alunos" :key="aluno.aluno_id">
                  <li><strong> {{aluno.aluno_id}} </strong> - {{aluno.aluno_nome.toLowerCase()}}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal_upload" hide-footer>
      <template #modal-title>Upload de Grupos</template>
      <div class="overflow-auto">
        <div class="text-center"> 
          <p class="text-danger"><strong>Atenção! Este processo irá sobrescrever dados no banco.</strong></p>
          <a :href="caminho" download>Arquivo exemplo para upload</a>
          <hr>
        </div>
        <div class="flex-sup file-upload-box">
          <b-form-file
            multiple
            placeholder="Arraste a planilha para cá"
            drop-placeholder="Jogue aqui!"
            type="file"
            @change="envia_arquivo($event)"
          ></b-form-file>
          <div class="flex-sup upload-indiq">
            <strong> Solte os arquivos em cima  </strong>
            <i class="fas fa-arrow-up"></i>
          </div>
        </div>
        <hr>
        <div class="input-group justify-content-md-end">
          <div class="mx-auto" v-show="loading_modal">
            <div class="mx-auto text-center">
              <div class="spinner-border text-primary"></div>
            </div>
          </div> 
          <b-button
            class="mr-1 btn btn-danger"
            type="submit"
          >Ok</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal_response">
      <div class="overflow-auto text-center">
        {{mensagem_response}}
        
        <div v-show="copiar_link" class="d-flex justify-content-center">
          <a v-show="copiar_link" @click.prevent="gerar_copiar_link()" class="flex-sup bglessBtn" target="_blank" rel="noopener noreferrer" ref="mylink">
            <i class="fas fa-copy"></i> <small> Copiar link </small>
          </a>
        </div>
      </div>
    </b-modal>

    <b-modal size="xl" id="modal-aluno" hide-footer>
      <template #modal-title> Edição de aluno em grupo softcheck </template>
            <div>
              <b-form>
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-group label="Turmas" label-for="Turmas">
                      <b-form-select
                        @change="turma_codigo = null; lista_days(); lista_grupos()"
                        :options="turmas"
                        v-model="turma_codigo_aluno">
                      </b-form-select>
                      <label v-show="erro_turma" class="text-danger small">Selecione a turma!</label>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                  <b-form-group label="Day" label-for="Day">
                      <b-form-select 
                      @click.prevent
                      @change="lista_grupos()"
                      :options="day_turma"
                      v-model="day_aluno">
                      </b-form-select>
                      <label v-show="erro_day" class="text-danger small">Selecione o day!</label>
                    </b-form-group>
                </div>
                </div>

                <div v-show="inserir_informacoes_aluno">
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <b-form-group label="Grupo" label-for="grupo">
                      <b-form-select 
                      @click.prevent
                      :options="grupos_insercao"
                      v-model="grupo_aluno">
                      </b-form-select>
                      <label v-show="erro_grupo" class="text-danger small">Selecione o grupo!</label>
                    </b-form-group>
                    </div>
                  <div class="col-sm-3">
                    <b-form-group id="input-1" label="RID" label-for="rid">
                      <b-form-input
                        id="rid"
                        @focusout="busca_aluno()"
                        placeholder="RID"
                        v-model="aluno_rid"
                        trim
                      ></b-form-input>
                      <label v-show="erro_aluno" class="text-danger small">aluno inválido!</label>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6">
                    <b-form-group
                      id="input-3"
                      label="CPF"
                      label-for="cpf"
                    >
                      <b-form-input
                        trim
                        v-model="aluno_cpf"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b-form-group
                      label="Nome"
                      label-for="nome"
                    >
                      <b-form-input
                        id="name"
                        v-model="aluno_nome"
                        trim
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="input-group justify-content-md-end">
                  <b-button
                    id="btn-limpa-form"
                    variant="danger"
                    @click="limpa_formulario_insercao_aluno()"
                    >Limpar formulário</b-button
                  >
                  <b-button
                    class="mr-1"
                    id="btn-cadastro"
                    @click="insere_aluno_grupo()"
                    variant="primary"
                    >Inserir</b-button
                  >
                </div>
                <hr>
                <p><strong>Atenção!</strong> Ao clicar no <i class="fas fa-times-circle"></i> o aluno será removido do grupo. Caso queira movê-lo de grupo, será necessário excluí-lo e inserir num novo grupo.</p>
                
                <!-- Loading Gif -->
                <div class="row" v-show="loading_grupos">
                  <div class="mx-auto text-center">
                    <img :src="require('../assets/loading.gif')" fluid width="20%">
                  </div>
                </div> 

                <div v-show="!loading_grupos">
                  <ul class="flex-sup group-list" >
                    <li class="flex-sup group-card m-1" v-for="grupo in grupos_alunos" :key="grupo.grupo_id">
                      <div class="flex-sup grp-card-head">
                        <small>{{ grupo.grupo_codigo }}</small>
                      </div>
                      <ul class="flex-sup group-names" v-for="aluno in grupo.alunos" :key="aluno.aluno_id">
                        <li><i class="fas fa-times-circle" @click="removerAlunoGrupoSoftcheck(aluno, grupo.grupo_id)"></i> &nbsp;&nbsp;{{aluno}} </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                </div>
                

                <div class="row justify-content-center">
                  <div
                    class="spinner-border text-primary"
                    role="status"
                    v-show="false"
                  ></div>
                </div>
              </b-form>
            </div>
    </b-modal>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>
.content-wrapper {
  position: relative;
  
}

.head-adm-card{
  background-color:white;
}

.adm-card{
  background-color:white;
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      // Informações da página
      user: {},
      erro_grupo: false,
      erro_aluno: false,
      erro_turma: false,
      erro_day: false,
      aluno_valido: false,
      inserir_informacoes_aluno: false,
      page: "Aleatoriza Grupos",
      habilitar_pagina: false,
      // Seleção de turma e day dinâmico
      turmas: [{
        text: "selecione",
        value: null
      }],
      turma_id: null,
      turma_codigo: null,
      turma_codigo_aluno: null,
      days: [],
      day_turma: [{
        text: "selecione",
        value: null
      }],
      day: null,
      day_aluno: null,
      day_id: '',
      grupo_aluno: null,
      // Opção de projeto ou aleatorio
      opcao: null,
      opcoes: [{
        text: "selecione",
        value: null
      },{
        text: "Grupos de Projeto",
        value: 1
      },{
        text: "Grupos Aleatórios",
        value: 2
      }],
      // Opções para aleatorização de grupo
      prefixo_grupo: "",
      qtd_grupos: "",
      // Array com os grupos
      grupos: [],
      grupos_alunos: [],
      grupos_insercao: [],
      // Arquivo para upload de grupos de projeto
      file: [],
      caminho: "/files/grupos_projeto_exemplo.xlsx",
      // Variável do link gerado
      link_gerado: '',
      // Tratativa de UX      
      loading_modal: false,
      loading: false,
      loading_grupos: false,
      mensagem_response: "",
      mostrar_day: false,
      mostrar_opcao: false,
      mostrar_salvar: false,
      painel_aleatorio: false,
      painel_projeto: false,
      copiar_link: false,
      painel_exportacao: false,
      aluno_rid: null,
      aluno_cpf: null,
      aluno_nome: null
    };
  },
  created() {
    this.carrega_turmas_e_days();
  },
  methods: {
    // Select de turmas e seus days
    busca_aluno(){
      if(this.aluno_rid.length == 0){
        this.aluno_valido = false
        return false
      }
      const url = "lista/aluno"
      this.$http.get(url+"/"+this.aluno_rid)
      .then((response) => {
        if(!response.data.aluno_cpf){
          alert("Aluno não encontrado, verifique o RID informado!")
        }else{
          console.log(response.data)
          this.aluno_cpf = response.data.aluno_cpf
          this.aluno_nome = response.data.aluno_nome
          this.aluno_valido = true
        }
      }).catch((exception) => {
        alert("Ocorreu um problema ao buscar o aluno!")
        this.aluno_valido = false
        console.log(exception)
      })
    },

    async carrega_turmas_e_days(){
      this.loading = true;
      let url = "itens/por/turma";
      const response = await this.$http.get(url);
      let dados_days = new Array;
      response.data.forEach(turma => {
        // Info dos itens
        let aux = [];
        let value = turma.titulo_day.split(",");
        value.forEach(element => {
          let item = element.split("-");
          aux.push({
            text: item[1],
            value: item[0]
          });
        });
        dados_days[turma.turma_codigo] = aux;

        // Montando as turmas
        this.turmas.push({
          text: turma.turma_codigo,
          value: turma.turma_codigo,
          turma_id: turma.turma_id
        });
      });
      this.days = dados_days;
      this.loading = false;
    },

    limpa_formulario_insercao_aluno(){
      this.turma_codigo_aluno = null
      this.day_aluno = null
      this.aluno_nome = null
      this.aluno_cpf = null
      this.aluno_rid = null
      this.inserir_informacoes_aluno = false
      this.aluno_valido = false
      this.grupo_aluno = []
      this.grupos_alunos = []
      this.grupos_insercao = []
    },

    async insere_aluno_grupo(){
      let erro = false
      let turma_atual
      let grupo_atual
      let day_atual

      if(!this.grupo_aluno){
        erro = true
        this.erro_grupo = true
      }else{
        this.erro_grupo = false
      }

      if(!this.aluno_valido){
        erro = true
        this.erro_aluno = true
      }else{
        this.erro_aluno = false
      }

      if(!this.turma_codigo_aluno){
        erro = true
        this.erro_turma = true
      }else{
        this.erro_turma = false
      }

      if(!this.day_aluno){
        erro = true
        this.erro_day = true
      }else{
        this.erro_day = false
      }
      
      if(!erro){
        this.loading_grupos = true;
        
        this.turmas.forEach(turma => {
          if(turma.value == this.turma_codigo_aluno){
            turma_atual = turma.text
          }
        })

      this.grupos_insercao.forEach(grupo => {
          if(grupo.value == this.grupo_aluno){
            grupo_atual = grupo.text
          }
        })

      this.day_turma.forEach(day => {
        if(day.value == this.day_aluno){
            day_atual = day.text
          }
      })
        
      this.$bvModal
          .msgBoxConfirm(
      "Você deseja inserir o aluno "+this.aluno_nome+" no grupo "+ grupo_atual +" no "+day_atual+" da turma "+turma_atual+"?",
            {
              title: "Aviso!",
              okVariant: "primary",
              okTitle: "Sim",
              cancelTitle: "Não",
              cancelVariant: "light",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            }
          )
          .then((value) => {
            if (value == true) {
              const url = 'grupo/aluno';

              this.$http.post(url, {
                grupo_id: this.grupo_aluno,
                turma_codigo: this.turma_codigo_aluno,
                aluno_id: this.aluno_rid,
                day: this.day_aluno
              })
              .then((response) => {
                this.$bvModal.msgBoxOk(response.data.mensagem,{title:'Sucesso!',okVariant:'success',headerBgVariant:'success',bodyBgVariant:'success',footerBgVariant:'success'})
                this.lista_grupos();
                this.loading_grupos = false;
              })
              .catch((exception) => {
                this.$bvModal.msgBoxOk(exception.response.data.mensagem,{title:'Erro!',okVariant:'danger',headerBgVariant:'danger',bodyBgVariant:'danger',footerBgVariant:'danger'})
              })
            }
          })
          .catch((err) => {
            console.log(err);
          });
        }
    },

    lista_grupos(){
      if(this.turma_codigo_aluno && this.day_aluno){
        this.grupos_alunos = []
        this.grupos_insercao = []
        let url = "listar/aluno/grupo/"+this.turma_codigo_aluno+"/"+this.day_aluno+"/0";
        this.$http.get(url)
        .then((response) => {
          let grupos = response.data
          grupos.forEach(grupo => {
            let grupo_atual = {
              alunos: [],
              grupo_id: null,
              grupo_codigo: null
            }

            let alunos = grupo.alunos.split(',')
            grupo_atual.grupo_id = grupo.grupo_id
            grupo_atual.alunos = alunos
            grupo_atual.grupo_codigo = grupo.grupo_codigo          

            this.grupos_alunos.push(grupo_atual)
            this.grupos_insercao.push({
              value: grupo_atual.grupo_id,
              text: grupo_atual.grupo_codigo
            })
          })
          this.inserir_informacoes_aluno = true
        }).catch((exception) => {
          alert(exception.response.data.mensagem)
          this.grupos_alunos = []
          this.grupos_insercao = []
        });

        this.$http.get('cursos')
        .then((response) => {
          this.cursos = response.data
        })
      }
    },

    adicionar_aluno(){
      this.limpa_formulario_insercao_aluno()
      this.$bvModal.show("modal-aluno");
    },
    // Select dinâmico para mostrar somente os days da turma
    lista_days(){
      if (this.turma_codigo != null || this.turma_codigo_aluno != null){
        let turma_codigo = (this.turma_codigo) ? this.turma_codigo : this.turma_codigo_aluno
        this.mostrar_day = true;
        this.day_turma = this.days[turma_codigo];
        let turma_selecionada = this.turmas.find(el => el.value == this.turma_codigo);
        this.turma_id = turma_selecionada.turma_id;
      }
      else{
        // Limpa variáveis
        this.day_turma = [];
        this.day_turma.push({
          text: "selecione",
          value: null
        });
        this.day = null;
        this.opcao = null;
        this.mostrar_day = false;
        this.mostrar_opcao = false,
        this.mostrar_salvar = false;
        this.painel_projeto = false;
        this.painel_aleatorio = false;
      }
      this.grupos = [];
      this.qtd_grupos = '';
      this.painel_exportacao = false;
    },
    // Controle de mostrar opções do painel
    controle_opcao(){
      if (this.day != null && this.day != ''){
        this.mostrar_opcao = true;
      }
      else{
        this.opcao = null;
        this.mostrar_opcao = false,
        this.mostrar_salvar = false;
        this.painel_projeto = false;
        this.painel_aleatorio = false;
      }
      this.grupos = [];
      this.qtd_grupos = '';
      this.painel_exportacao = false;
    },
    // Controle de mostrar opções do painel
    controle_opcao2(){
      if (this.opcao != null){
        this.mostrar_salvar = true;
      }
      else{
        this.mostrar_salvar = false;
        this.painel_projeto = false;
        this.painel_aleatorio = false;
        this.painel_exportacao = false;
      }
      this.grupos = [];
      this.qtd_grupos = '';
      this.painel_exportacao = false;

      // Tratativa dos paineis
      if (this.opcao == 1){
        this.painel_projeto = true;
        this.painel_aleatorio = false;
      }
      else if (this.opcao == 2){
        this.painel_projeto = false;
        this.painel_aleatorio = true;
      }
    },
    // Chamada da API para aleatorização das turmas
    async aleatoriza_turma() {
      if (
        this.turma_codigo != null &&
        this.day != null &&
        (this.qtd_grupos != "" || this.qtd_grupos > 0)
      ) {
        this.loading = true;
        let url = "aleatorizacao/grupos";
        const response = await this.$http.post(url, {
          page: this.page,
          profissional_id: this.user.dados.profissional_id,
          acao: "Aleatorizacao dos grupos de day da turma " + this.turma_codigo,
          turma_codigo: this.turma_codigo,
          qtd_grupos: this.qtd_grupos,
          day: this.day
        });
        this.grupos = [];
        response.data.forEach((grupo) => {
          this.grupos.push({
            grupo_codigo: grupo.grupo_codigo,
            grupo_id: grupo.grupo_id,
            alunos: grupo.alunos
          });
        });
        this.loading = false;
        // Link gerado
        this.link_gerado = "https://softcheck.app.dnc.group/"+this.day+"/"+this.turma_codigo;
        // Mostrar modal com link
        this.copiar_link = true;
        this.mensagem_response = "Grupos aleatórios gerados com sucesso!";
        this.$bvModal.show("modal_response");
        // Mostrar painel com botões
        this.painel_exportacao = true;
      } else {
        this.copiar_link = false;
        this.mensagem_response = "Preencha todos os campos!";
        this.$bvModal.show("modal_response");
      }
    },
    // Lista ultimo grupo gerado
    async listar_ultimo_grupo(){
        let url = "listar/aluno/grupo/"+this.turma_codigo+"/"+this.day+"/0";
        this.$http.get(url)
        .then((response) => {
          let grupos = response.data          
          grupos.forEach(grupo => {

            let alunos = grupo.alunos.split(',');
            let grupo_alunos = [];
            alunos.forEach(aluno => {
              grupo_alunos.push({aluno_id:aluno.split('-')[0],aluno_nome:aluno.split('-')[1]});
            });
            
            this.grupos.push({
                grupo_codigo: grupo.grupo_codigo,
                grupo_id: grupo.grupo_id,
                alunos: grupo_alunos
              });                          
            
          })         
          this.link_gerado = "https://softcheck.app.dnc.group/"+this.day+"/"+this.turma_codigo;
          if(this.opcao!=1){
            this.painel_projeto = true;
            this.painel_exportacao = true;
          }          
        }).catch((exception) => {
          console.log(exception.response.data.mensagem);
        });

    },

    gerar_copiar_link() {
      navigator.clipboard.writeText(this.link_gerado);

      alert('Link copiado: ' + this.link_gerado)
    },

    // Chamada da API para definição do grupo de projeto
    async salva_turma_projeto(){
      this.loading = true;
      let url = "exclusao/grupos/aleatorios";
      await this.$http.post(url, {
        page: this.page,
        profissional_id: this.user.dados.profissional_id,
        acao: "Remoção dos grupos aleatórios da turma " + this.turma_codigo,
        turma_id: this.turma_id,
        day: this.day
      });
      this.loading = false;
      // Link gerado
      this.link_gerado = "https://softcheck.app.dnc.group/"+this.day+"/"+this.turma_codigo;
      // Mostrar modal com link
      this.copiar_link = true;
      this.mensagem_response = "Grupos salvos com sucesso!";
      this.$bvModal.show("modal_response");
      // Mostrar painel com botões
      this.painel_exportacao = true;
    },
    // Garante que o usuário colocará somente números na campo
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // Abre uma nova aba com a tabela
    gera_impressao() {
      window.open("/gera-grupos?turma=" + this.turma_codigo + "&day=" + this.day);
    },
    // Aciona modal para upload
    upload_arquivo() {
      // Aciona modal
      this.$bvModal.show("modal_upload");
    },
    // Envia o arquivo para back atualizar o BD
    async envia_arquivo(event) {
      this.loading_modal = true;

      let formData = new FormData();
      formData.append(
        "file",
        event.target.files ? event.target.files[0] : event.dataTransfer.files[0]
      );

      await this.$http
        .post("importar/arquivo/grupo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.mensagem_response = "Arquivo salvo com sucesso!";
            //console.log(response.data);
          } else {
            this.mensagem_response = "Ocorreu um erro. Entre em contato com tech.";
          }
        });

      this.loading_modal = false;
      this.copiar_link = false;
      this.$bvModal.hide("modal_upload");
      this.$bvModal.show("modal_response");
    },
    // Função para copiar link
    copyURL() {
      var Url = this.$refs.mylink;
      Url.innerHTML = window.location.href;
      console.log(Url.innerHTML)
      Url.select();
      document.execCommand("copy");
    },
    // Função para remover um aluno do grupo Softcheck
    async removerAlunoGrupoSoftcheck(aluno, grupo_id){
      this.loading_grupos = true;
      // Tratativa para pegar o aluno_id
      let aluno_info = aluno.split(" - ");

      // Chamada da API para remover aluno
      let url = "exclusao/aluno/softcheck";
      await this.$http.post(url, {
        page: this.page,
        profissional_id: this.user.dados.profissional_id,
        acao: "Remoção do aluno " + aluno + " do grupo " + grupo_id,
        turma_codigo: this.turma_codigo_aluno,
        day: this.day_aluno,
        grupo_id: grupo_id,
        aluno_id: aluno_info[0]
      });

      // Atualiza o array de grupos
      this.lista_grupos();

      this.loading_grupos = false;
    }
  },
  mounted() {
    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);

    // Somente operações conseguem ver esta página
    this.user.permissoes.forEach(permissao => {
      if (permissao.privilegio_tipo_id == 2 && (permissao.privilegio_status == "LE" || permissao.privilegio_status == "A")){
        this.habilitar_pagina = true;
      }
    });
  }
};
</script>