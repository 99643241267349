<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />
    <div class="content-wrapper">

      <!-- -------------------- modal start -->
      <div class="flex-sup custom-modal-wrapper" v-if="isAddCustomNotify">

        <div class="flex-sup custom-adm-modal" id="add_notify_popup">
          
            <!-- head -->
            <div class="flex-sup modal-head">
              <h2> Adicionar/Editar Notificações condicionais </h2>
              <a class="flex-sup modal-close" @click="add_new_custom_notify()">
                <span class="material-icons"> close </span>
              </a>
            </div>

            <!-- modal body -->
            <div class="flex-sup modal-body">

              <!-- side: meta dados -->
              <aside class="flex-sup modal-metas styled-card blank-scrollbar">

                <h3> Meta dados </h3>

                <div class="flex-sup side-ipt-card">
                  <strong> Identificação da notificação: </strong>
                  <small> (use isto para pesquisar pela notificação) </small>
                  <b-form-input v-model ="notificacao_indentificacao" type="text" placeholder="ex.: notifi de assignment"></b-form-input>
                </div>
                <label class="text-danger small">{{erro_notificacao_identificacao}}</label>

                <div class="flex-sup side-ipt-card">
                  <strong> Título de exibição: </strong>
                  <small> (isso será a primeira coisa que o aluno vai ler) </small>
                  <b-form-input v-model="notificacao_exibicao" type="text" placeholder="ex.: Alerta de assignment"></b-form-input>
                </div>
                <label class="text-danger small">{{erro_notificacao_exibicao}}</label>

                <div class="flex-sup side-ipt-card">
                  <strong> Descrição: </strong>
                  <small> (use isto para pesquisar pela notificação) </small>
                  <b-form-textarea
                    v-model="notificacao_descricao"
                    id="custom_notify_title"
                    placeholder="título da notificação"
                    rows="2"
                    max-rows="5"
                  ></b-form-textarea> 
                </div>
                <label class="text-danger small">{{erro_notificacao_descricao}}</label>

                <div class="flex-sup side-ipt-card tag-sideIptCard">
                  <strong> tag tipo: </strong>

                  <div class="flex-sup tag-wrapper">
                    <b-form-select v-model="notificacao_tipo" :options="notificacao_tipos"></b-form-select>

                    <div class="flex-sup tag-line">
                      <small> pré-vizualização: </small>
                      <span class="flex-sup tag-badge tag_type-mudanca"> MUDANÇA </span>
                    </div>
                  </div>

                </div>
                <label class="text-danger small">{{erro_notificacao_tipo}}</label>

                <div class="flex-sup side-ipt-card data-sideIptCard">
                  <strong> Data de liberação: </strong>

                  <b-form-datepicker v-model="notificacao_data" id="example-datepicker" class="mb-2"></b-form-datepicker>

                  <b-form-checkbox
                    @change="notificacao_data_atual()"
                    v-model="data_checked"
                    id="checkbox-1"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >
                    Imediatamente
                  </b-form-checkbox>
                  
                </div>
                <label class="text-danger small">{{erro_notificacao_data_liberacao}}</label>

                <div class="flex-sup side-ipt-card">
                  <strong> Link do botão: </strong>
                  <b-form-input v-model="notificacao_link" type="text" placeholder="https://"></b-form-input>
                </div>
                <label class="text-danger small">{{erro_notificacao_link}}</label>

                <b-form-checkbox
                    v-show="!notificacao_cron"
                    v-model="notificacao_condicional"
                    @change="(notificacao_condicional ? adicionar_condicao() : limpa_notificacoes_condicionais())"
                  >
                    Notificação condicionais
                  </b-form-checkbox>
                <b-form-checkbox
                    v-show="!notificacao_condicional"
                    id="checkbox-2"
                    name="checkbox-2"
                    v-model="notificacao_cron"
                    @change="notificacao_frequencia = null; limpa_frequencias(); limpa_notificacoes_condicionais();"
                  >
                    Notificação repetitiva
                  </b-form-checkbox>
                <div v-if="notificacao_cron">
                  <div class="flex-sup side-ipt-card tag-sideIptCard">
                    <strong> Frequência: </strong>

                    <div class="flex-sup tag-wrapper">
                      <b-form-select v-model="notificacao_frequencia" @change="limpa_frequencias()" :options="frequencias"></b-form-select>
                    </div>

                  </div>

                  <div v-if="notificacao_frequencia == 'semanalmente'" class="flex-sup side-ipt-card tag-sideIptCard">
                    <strong> Dia da semana: </strong>

                    <div class="flex-sup tag-wrapper">
                      <b-form-select v-model="notificacao_frequencia_dia" :options="dias"></b-form-select>
                    </div>
                    <label class="text-danger small">{{erro_notificacao_frequencia_dia}}</label>

                  </div>

                  <div v-if="notificacao_frequencia == 'mensalmente'" class="flex-sup side-ipt-card tag-sideIptCard">
                    <strong> Dia do mês: </strong>

                    <div class="flex-sup tag-wrapper">
                      <b-form-input v-model ="notificacao_frequencia_mes" type="number" placeholder="ex.: 2, 28, 31"></b-form-input>
                    </div>
                    <label class="text-danger small">{{erro_notificacao_frequencia_mes}}</label>

                  </div>
                </div>

              </aside>

              <!-- main: regras de exibição -->

              <div class="flex-sup main-showRules">

                <h3> Regras de exibição </h3>

                  <h4> Exibir essa notificação para: </h4>

                  <!-- filter:cursos -->
                  <div class="flex-sup receiver-filter">

                    <div class="flex-sup receiver-toggle-wrapp col-2">
                      <b-form-checkbox v-model="todos_cursos" @change="preenche_todos_cursos()" name="check-button" class="receiver-toggle" switch>
                      </b-form-checkbox>
                      <small> Todos os cursos </small>
                    </div>

                    <div v-show="!todos_cursos" class="flex-sup receiver-toggle-wrapp col-2">
                      <b-form-checkbox v-model="selecionar_turmas" @change="limpa_destinatarios(); preenche_todas_turmas()" name="check-button" class="receiver-toggle" switch>
                      </b-form-checkbox>
                      <small> Selecionar turmas </small>
                    </div>

                      <b-form-select v-show="!todos_cursos"  id="cursos" v-model="curso_id_selecionado" @change="lista_curso_turmas_codigo()" :options="cursos" class="notify-range-select"></b-form-select>                        
                      <a v-show ="!todos_cursos && !selecionar_turmas" class="flex-sup rule-add-btn"> <span :disabled="bloquear_botao_adicionar" @click="adiciona_notificacao(1)" class="material-icons"> add </span> </a>                        

                  </div>

                  <!-- filter:turmas -->
                  <div v-show="!todos_cursos && selecionar_turmas" class="flex-sup receiver-filter">

                    <div class="flex-sup receiver-toggle-wrapp col-2">
                      <b-form-checkbox v-model="todas_turmas" @change="preenche_todas_turmas()" name="check-button" class="receiver-toggle" switch>
                      </b-form-checkbox>
                      <small> Todas as turmas deste curso </small>
                    </div>

                    <div v-show="!todas_turmas" class="flex-sup receiver-toggle-wrapp col-2">
                      <b-form-checkbox v-model="selecionar_alunos" @change="preenche_todos_alunos()" name="check-button" class="receiver-toggle" switch>
                      </b-form-checkbox>
                      <small> Selecionar alunos </small>
                    </div>

                      <b-form-select v-show = !todas_turmas id="cursos" v-model="turma_codigo_selecionado" @change="preenche_todos_alunos()" :options="turmas" class="notify-range-select"></b-form-select>                        
                      <a v-show ="!todas_turmas && !selecionar_alunos" class="flex-sup rule-add-btn"> <span :disabled="bloquear_botao_adicionar" @click="adiciona_notificacao(2)" class="material-icons"> add </span> </a>                        

                  </div>

                  <!-- filter:alunos -->
                  <div v-show ="!todas_turmas && selecionar_turmas && selecionar_alunos" class="flex-sup receiver-filter">

                    <div class="flex-sup receiver-toggle-wrapp col-4">
                      <b-form-checkbox v-model="todos_alunos" @change="preenche_todos_alunos()" name="check-button" class="receiver-toggle" switch>
                      </b-form-checkbox>
                      <small> Todos os alunos desta turma </small>
                    </div>

                      <b-form-select v-show="!todos_alunos" id="cursos" v-model="aluno_id_selecionado" :options="alunos" class="notify-range-select"></b-form-select>
                      <a v-show="!todos_alunos" class="flex-sup rule-add-btn"> <span :disabled="bloquear_botao_adicionar" @click="adiciona_notificacao(3)" class="material-icons"> add </span> </a>

                  </div>

                <h6> Destinatários: </h6>

                <ul class="flex-sup tags-field">
                    <li v-for="destinatario in destinatarios" :key="destinatario.valor_destinatario" class="flex-sup"> 
                      <small> {{destinatario.nome_destinatario}} </small> 
                      <a class="flex-sup tag-remove"> 
                        <span class="material-icons" @click="remove_destinatario(destinatario)"> close </span> 
                      </a> 
                    </li>
                      <!-- <li class="flex-sup"> <small> Imersão de projetos </small> <a class="flex-sup tag-remove"> <span class="material-icons"> close </span> </a> </li>
                      <li class="flex-sup"> <small> Sales and mkt. specialist </small> <a class="flex-sup tag-remove"> <span class="material-icons"> close </span> </a> </li>
                      <li class="flex-sup"> <small> Data Expert </small> <a class="flex-sup tag-remove"> <span class="material-icons"> close </span> </a> </li> -->
                </ul>
                <label class="text-danger small">{{erro_notificacao_destinatarios}}</label>
                <div class="row">
                  <button class="ft-save-btn text-white" @click="limpa_destinatarios()"> <small> Remover todos os destinatários </small></button>
                </div>
                

                <!-- >>> Regras de exibição: -->
              <div v-show="notificacao_condicional">
                <h4> Exibir essa notificação quando: </h4>
                <label class="text-danger small">{{erro_notificacao_condicional}}</label>

                <ul class="flex-sup rule-lines">
                  <li v-for="(notificacao_configurada, index) in regras_notificacao_configurada" :key="index" class="flex-sup">
                    <div class="flex-sup left-rules-selects">
                      <b-form-select v-model="notificacao_configurada.notificacao_regra" :options="NSR_principle"></b-form-select>
                      <b-form-select v-model="notificacao_configurada.notificacao_condicao" :options="NSR_expression"></b-form-select>
                      <b-form-input  v-model="notificacao_configurada.notificacao_condicao_valor" type="number" max="100000000000" placeholder="digite um valor.."></b-form-input>
                      <small class="number-grandeza"> valor </small>
                    </div>
                    <a class="flex-sup del-rule"> <span @click="remover_condicao(index)" class="material-icons"> close </span> </a>
                  </li>

                  <a class="flex-sup add-new-rule">
                    <small @click="adicionar_condicao()"> Adicionar condicionais </small>
                    <span class="material-icons"> add </span>
                  </a>

                </ul>
              </div>

              </div>

            </div>

            <!-- footer popup options -->
            <div class="flex-sup pop-footer">

              <a class="flex-sup pop-ft-btn" @click="add_new_custom_notify()"> <small> Cancelar </small> <span class="material-icons"> close </span> </a>
              <a class="flex-sup pop-ft-btn ft-save-btn" @click="salvar_notificacao()"> <small> Salvar todas alterações </small> <span class="material-icons"> save </span> </a>

            </div>

        </div>

      </div>
      <!-- -------------------- modal end -->

      <!-- custom page body -->
      <div class="flex-sup customPageBody">

        <div class="flex-sup adm-card head-adm-card">

          <h1> Gestão de notificações </h1>

          <!-- right: nav/save -->
          <div class="flex-sup right-save">

              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active"> Plano de estudos </li>
              </ol>

          </div>

        </div>
        
        <!-- > Notificações Personalizadas -->
        <div class="flex-sup adm-card">

          <h3> Notificações condicionais </h3>
          <h4> <i class="fas fa-filter"></i> Filtrar notificações para: </h4>

          <!-- filter/head line -->
          <div class="flex-sup adm-card-headline">

            <!-- left:filter cards -->

            <div class="flex-sup filter-left">

              <div class="flex-sup head-line-card">
                <h5> Curso: </h5>
                  <b-form-input list="my-list-id" placeholder="Todos os cursos"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

              <div class="flex-sup arrow-between">
                <img src="../assets/img/headline_arrow.png">
              </div>

              <div class="flex-sup head-line-card">
                <h5> Turmas deste curso: </h5>
                  <b-form-input list="my-list-id" placeholder="Todas as turmas"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

              <div class="flex-sup arrow-between">
                <img src="../assets/img/headline_arrow.png">
              </div>

              <div class="flex-sup head-line-card">
                <h5> Alunos desta turma: </h5>
                  <b-form-input list="my-list-id" placeholder="Todos os alunos"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

            </div>

            <!-- right: select/add -->
            <div class="flex-sup right-addSearch">

              <div class="flex-sup head-line-card search-card">
                <i class="fas fa-search"></i>
                <b-form-input list="my-list-id" placeholder="Pesquisar notif_id"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

              <div class="flex-sup head-line-card">
                <a href="#" class="flex-sup add-button" @click="add_new_custom_notify()">
                  <i class="fas fa-plus"></i>
                  <small> Adicionar notificação </small>
                </a>
              </div>

            </div>

          </div>

          <!-- Tabela: notificações personalizadas -->

          <div class="table-wrapper">

           
            <table class="table table-bordered notify-table table-responsive">
              <thead>
                <tr>
                  <th class="td_notifi">notifi_id</th>
                  <th class="td_titulo">Título de exibição</th>
                  <th class="td_descricao">Descrição</th>
                  <th class="td_tag">Tipo (badge)</th>
                  <th class="td_data">Data de liberação</th>
                  <th class="td_data">Turma Código</th>
                  <th class="td_data">Link</th>
                  <th class="td_data">Tipo Destinatário</th>
                  <th class="td_data">Quantidade destinatário</th>
                  <th class="td_edit">Opções</th>
                </tr>
              </thead>
              <tbody>

                <tr v-for="(notificacao, index) in notificacoes" :key="index">
                  <td class="td_notifi"> 
                    <p class="table-output"> {{notificacao.notificacao_id}} </p>
                  </td>
                  <td class="td_titulo"> 
                    <p class="table-output"> {{notificacao.notificacao_exibicao}}  </p>
                  </td>

                  <!-- Descrição -->
                  <td class="td_descricao"> 
                    <div id="tooltip"><v-icon>info</v-icon>                      
                      <span id="tooltiptext">{{notificacao.notificacao_descricao}}</span>
                    </div>
                    <!-- <p class="table-output">
                      {{notificacao.notificacao_descricao}}
                    </p> -->
                    
                  </td>

                  <!-- Tipo (badge) -->
                  <td class="td_tag">
                    <div class="flex-sup tag-line">
                      <span class="flex-sup tag-badge tag_type-mudanca"> {{notificacao.notificacao_tipo}} </span>
                    </div>

                  </td>

                  <!-- Data de liberação -->
                  <td class="td_data">
                    <p class="table-output"> {{notificacao.notificacao_data_liberacao}} </p>
                  </td>

                  <td class="td_data">
                    <p class="table-output"> {{ notificacao.notificacao_turma }} </p>
                  </td>

                  <td class="td_data">                  
                    <div id="tooltip"><v-icon>info</v-icon>                      
                      <span id="tooltiptext">{{notificacao.notificacao_link}}</span>
                    </div>
                  </td>

                  <td class="td_data">
                    <p class="table-output"> {{notificacao.notificacao_tipo_destinatario}} </p>
                  </td>

                  <td class="td_data">
                    <p class="table-output"> {{ notificacao.notificacao_destinatario}} </p>
                  </td>

                  <!-- Opções -->
                  <td class="td_edit">
                    <a href="#" class="flex-sup edit-button"> <i class="fas fa-pen-square"></i> <small> Editar </small> </a>
                  </td>

                </tr>

              </tbody>
            </table>
          
          </div>

        </div>

        <!-- > Notificações Automáticas -->
        <div class="flex-sup adm-card seconds-adm-card" v-show="false">

          <h3> Notificações automáticas </h3>
          <h4> <i class="fas fa-filter"></i> Filtrar notificações para: </h4>

          <!-- filter/head line -->
          <div class="flex-sup adm-card-headline">

            <!-- left:filter cards -->

            <div class="flex-sup filter-left">

              <div class="flex-sup head-line-card">
                <h5> Curso: </h5>
                  <b-form-input list="my-list-id" placeholder="Todos os cursos"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

              <div class="flex-sup arrow-between">
                <img src="../assets/img/headline_arrow.png">
              </div>

              <div class="flex-sup head-line-card">
                <h5> Turmas deste curso: </h5>
                  <b-form-input list="my-list-id" placeholder="Todas as turmas"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

              <div class="flex-sup arrow-between">
                <img src="../assets/img/headline_arrow.png">
              </div>

              <div class="flex-sup head-line-card">
                <h5> Alunos desta turma: </h5>
                  <b-form-input list="my-list-id" placeholder="Todos os alunos"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

            </div>

            <!-- right: select/add -->
            <div class="flex-sup right-addSearch">

              <div class="flex-sup head-line-card search-card">
                <i class="fas fa-search"></i>
                <b-form-input list="my-list-id" placeholder="Pesquisar notif_id"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>
              </div>

            </div>

          </div>

          <!-- Tabela: notificações personalizadas -->

          <div class="table-wrapper">

          <table class="table table-bordered notify-table table-notify-auto">
            <thead>
              <tr>
                <th class="td_notifi">notifi_id</th>
                <th class="td_titulo">Título de exibição</th>
                <th class="td_descricao">Descrição</th>
                <th class="td_tag">Tipo (badge)</th>
                <th class="td_edit">Opções</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- notifi_id -->
                <td class="td_notifi"> 
                  <small> use para pesquisar </small>
                  <b-form-input id="custom_notify_id" v-model="text" placeholder="Identificação"></b-form-input> 
                </td>

                <!-- Título de exibição -->
                <td class="td_titulo"> 
                  <b-form-textarea
                    id="custom_notify_title"
                    v-model="text"
                    placeholder="título da notificação"
                    rows="2"
                    max-rows="13"
                  ></b-form-textarea> 
                </td>

                <!-- Descrição -->
                <td class="td_descricao"> 
                  <b-form-textarea
                    id="custom_notify_desc"
                    v-model="text"
                    placeholder="Descrição da notificação"
                    rows="2"
                    max-rows="13"
                  ></b-form-textarea>
                </td>

                <!-- Tipo (badge) -->
                <td class="td_tag">
                  
                  <b-form-input list="my-list-id" placeholder="Pesquisar notif_id"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>

                  <div class="flex-sup tag-line">
                    <small> pré-vizualização: </small>
                    <span class="flex-sup tag-badge tag_type-mudanca"> MUDANÇA </span>
                  </div>

                </td>

                <!-- Opções -->
                <td class="td_edit">

                  <b-form-checkbox v-model="checked" name="check-button" switch>
                    Ligado
                  </b-form-checkbox>

                </td>

              </tr>

              <!-- ---------------- Linha: teste de repetição -->

              <tr>
                <!-- notifi_id -->
                <td class="td_notifi"> 
                  <small> use para pesquisar </small>
                  <b-form-input id="custom_notify_id" v-model="text" placeholder="Identificação"></b-form-input> 
                </td>

                <!-- Título de exibição -->
                <td class="td_titulo"> 
                  <b-form-textarea
                    id="custom_notify_title"
                    v-model="text"
                    placeholder="título da notificação"
                    rows="2"
                    max-rows="13"
                  ></b-form-textarea> 
                </td>

                <!-- Descrição -->
                <td class="td_descricao"> 
                  <b-form-textarea
                    id="custom_notify_desc"
                    v-model="text"
                    placeholder="Descrição da notificação"
                    rows="2"
                    max-rows="13"
                  ></b-form-textarea>
                </td>

                <!-- Tipo (badge) -->
                <td class="td_tag">
                  
                  <b-form-input list="my-list-id" placeholder="Pesquisar notif_id"></b-form-input>
                  <datalist id="my-list-id">
                    <option>Manual Option</option>
                    <!-- <option v-for="size in sizes">{{ size }}</option> -->
                  </datalist>

                  <div class="flex-sup tag-line">
                    <small> pré-vizualização: </small>
                    <span class="flex-sup tag-badge tag_type-mudanca"> MUDANÇA </span>
                  </div>

                </td>

                <!-- Opções -->
                <td class="td_edit">

                  <b-form-checkbox v-model="checked" name="check-button" switch>
                    Ligado
                  </b-form-checkbox>

                </td>

              </tr>

            </tbody>
          </table>

          </div>

        </div>

      </div>

    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<style >

  .content-wrapper {position: relative;}


/* Tooltip container */
#tooltip {
  position: relative !important; 
  display: inline-block !important;
  cursor: pointer !important;
}

/* Tooltip text */
#tooltip #tooltiptext {
  visibility: hidden !important;
  width:400px !important;
  min-width:auto !important;
  background-color: var(--dnc-preto);
  color: #fff !important;
  text-align: left !important;
  padding: 10px!important;
  border-radius: 6px !important;
 
  /* Position the tooltip text - see examples below! */
  top: -5px;
  left: 105%;
  position: absolute !important;
  z-index: 1 !important;
}

#tooltip #tooltiptext {
  opacity: 0;
  transition: opacity 1s;
}

#tooltip:hover #tooltiptext {
  opacity: 1;
}


/* Show the tooltip text when you mouse over the tooltip container */
#tooltip:hover #tooltiptext {
  visibility: visible !important;
}  
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      regras_notificacao_configurada: [],
      notificacao_condicional: false,
      notificacao_cron: false,
      notificacao_frequencia: null,
      notificacao_frequencia_dia: null,
      notificacao_frequencia_mes: null,
      erro_notificacao_condicional: "",
      erro_notificacao_identificacao: "",
      erro_notificacao_exibicao: "",
      erro_notificacao_frequencia_mes: "",
      erro_notificacao_frequencia_dia: "",
      erro_notificacao_descricao: "",
      erro_notificacao_tipo: "",
      erro_notificacao_data_liberacao: "",
      erro_notificacao_link: "",
      erro_notificacao_destinatarios: "",
      selecionar_turmas: false,
      selecionar_alunos: false,
      data_checked: false,
      bloquear_botao_adicionar: false,
      notificacao_indentificacao: null,
      notificacao_exibicao: null,
      notificacao_descricao: null,
      notificacao_tipo: null,
      notificacao_data: null,
      notificacao_link: null,
      destinatarios: [],
      text: "",
      checked: "",
      notificacoes: [],
      notificacao_tipos: [],
      cursos: [],
      turmas: [],
      alunos: [],
      curso_id_selecionado: null,
      turma_codigo_selecionado: null,
      aluno_id_selecionado: null,
      slots: [],
      turma_codigo: null,
      sizes: ['Small', 'Medium', 'Large', 'Extra Large'],
      options: [
        {value: 1, text: "Cursos"},
        {value: 2, text: "Turmas"},
        {value: 3, text: "Alunos"}
      ],
      frequencias: [
        {value: 'diariamente', text: 'Diariamente'},
        {value: 'semanalmente', text: 'Semanalmente'},
        {value: 'mensalmente', text: 'Mensalmente'}
      ],
      dias: [
        {value: 0, text: 'Domingo'},
        {value: 1, text: 'Segunda'},
        {value: 2, text: 'Terça'},
        {value: 3, text: 'Quarta'},
        {value: 4, text: 'Quinta'},
        {value: 5, text: 'Sexta'},
        {value: 6, text: 'Sábado'},
        
      ],
      todos_cursos: false,
      todas_turmas: true,
      todos_alunos: true,
      isAddCustomNotify: false,
      
      // tag select:
      selected: null,
        tag_options: [
          { value: null, text: 'selecione uma badge' },
          { value: 'a', text: 'Novo' },
          { value: 'b', text: 'Atualização' },
          { value: 'c', text: 'Lembrete' },
          { value: 'd', text: 'Mudança' },
          { value: 'd', text: 'Aviso' },
          { value: 'd', text: 'Advertência' },
          { value: 'd', text: 'Cashback' },
          { value: 'd', text: 'Sugestão' },
          { value: 'd', text: 'Oferta' },
          { value: 'd', text: 'Projeto' },
          { value: 'd', text: 'Certificado' }
        ],

        NSR_principle: [
          { value: null, text: 'Quando..' },
          { value: 'media_assignment', text: 'A média do assignments dos alunos...' },
          { value: 'quantidade_assignment', text: 'A quantidade de assignments entregues...' },
          { value: 'aluno_presenca', text: 'A quantidade de presenças...' },
        ],

        NSR_expression: [
          { value: null, text: 'For..' },
          { value: '>', text: 'For maior que...' },
          { value: '<', text: 'For menor que...' },
          { value: '=', text: 'For igual a...' }
        ],

    };
  },
  created() {
    this.lista_notificacoes()
    this.lista_notificacao_tipos()
    this.lista_cursos()
    this.preenche_todos_cursos()
    
    
  },
  methods: {
    limpa_frequencias(){
      this.notificacao_frequencia_dia = null
      this.notificacao_frequencia_mes = null
    },

    limpa_notificacoes_condicionais(){
      this.regras_notificacao_configurada = []
    },

    adicionar_condicao(){
      this.regras_notificacao_configurada.push(
        {
          notificacao_regra: '',
          notificacao_condicao: '',
          notificacao_condicao_valor: ''
        }
      )
    },

    remover_condicao(indice){
      this.regras_notificacao_configurada.splice(indice, 1)
    },


    lista_notificacoes(){
      this.notificacoes = []
      this.$http.get('notificacoes')
        .then((response) => {
          let notificacoes = response.data
          notificacoes.forEach((notificacao) => {
            this.notificacoes.push({
              notificacao_id: notificacao.notificacao_id,
              notificacao_exibicao: notificacao.notificacao_exibicao,
              notificacao_descricao: notificacao.notificacao_descricao,
              notificacao_tipo: notificacao.notificacao_tipo,
              notificacao_data_liberacao: notificacao.notificacao_data_liberacao,
              notificacao_destinatario: notificacao.notificacao_destinatario,
              notificacao_tipo_destinatario: notificacao.notificacao_tipo_destinatario,
              notificacao_link: notificacao.notificacao_link,
              notificacao_turma: notificacao.notificacao_turma
            })
          })
        })
    },
    notificacao_data_atual(){
      if(this.data_checked){
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        let dataAtual = ano + '-' + mes + '-' + dia;
        this.notificacao_data = dataAtual  
      }
      
    },

     salvar_notificacao(){
       
       let erro = false
       
       if(!this.notificacao_indentificacao){
         erro = true
         this.erro_notificacao_identificacao = "Insira a identificação da notificação!"
       }else{
         this.erro_notificacao_identificacao = ""
        }
        
        if(!this.notificacao_exibicao){
          erro = true
          this.erro_notificacao_exibicao = "Insiria a exibição da notificação"
        }else{
         this.erro_notificacao_exibicao = ""
        }
        
        if(!this.notificacao_descricao){
          erro = true
          this.erro_notificacao_descricao = "Insiria a descrição da notificação"
        }else{
         this.erro_notificacao_descricao = ""
        }

        if(!this.notificacao_tipo){
          erro = true
          this.erro_notificacao_tipo = "Selecione o tipo da notificação!"
        }else{
         this.erro_notificacao_tipo = ""
       }
       
        if(!this.notificacao_data){
          erro = true
          this.erro_notificacao_data_liberacao = "Selecione a data de liberação!"
        }else{
         this.erro_notificacao_data_liberacao = ""
        }
       
        if(!this.notificacao_link){
          erro = true
          this.erro_notificacao_link = "Insira o link da notificação"
        }else{
         this.erro_notificacao_link = ""
        }
       
        if(!this.destinatarios.length){
          erro = true
          this.erro_notificacao_destinatarios = "Selecione os destinatários!"
        }else{
            this.erro_notificacao_destinatarios = ""
        }

        if(this.notificacao_frequencia == "semanalmente" && !this.notificacao_frequencia_dia && this.notificacao_frequencia_dia != 0){
          erro = true
          this.erro_notificacao_frequencia_dia = "Selecione o dia!"
        }else{
          this.erro_notificacao_frequencia_dia = ""
        }

        if(this.notificacao_frequencia == "mensalmente" && !this.notificacao_frequencia_mes){
          erro = true
          this.erro_notificacao_frequencia_mes = "Informe o dia do mês!"
        }else if(this.notificacao_frequencia == "mensalmente" && (this.notificacao_frequencia_mes < 1 || this.notificacao_frequencia_mes > 31)){
          erro = true
          this.erro_notificacao_frequencia_mes = "Dia inválido!"
        }else{
          this.erro_notificacao_frequencia_mes = ""
        }

        if(this.notificacao_condicional){
          this.erro_notificacao_condicional = ""
          this.regras_notificacao_configurada.forEach((regra) => {
            if(!regra.notificacao_regra || !regra.notificacao_condicao || !regra.notificacao_condicao_valor){
              erro = true
              this.erro_notificacao_condicional = "Preencha todos os campos!"
            }
          })
        }


        const tipo_destinatario = this.destinatarios[0].tipo_destinatario

        this.destinatarios.forEach((destinatario) => {
          if(destinatario.tipo_destinatario != tipo_destinatario){
            erro = true
            this.erro_notificacao_destinatarios = "Não são permitidos diferentes tipos de destinatários!"
          }
        })
        
      if(erro){
        return false
      }
        
     this.$http.post('notificacoes', {
        identificacao: this.notificacao_indentificacao,
        exibicao: this.notificacao_exibicao,
        descricao: this.notificacao_descricao,
        tipo: this.notificacao_tipo,
        data_liberacao: this.notificacao_data,
        link: this.notificacao_link,
        destinatarios: this.destinatarios,
        notificacao_repetitiva: {
          tipo_frequencia: this.notificacao_frequencia,
          dia_semana_frequencia: this.notificacao_frequencia_dia,
          dia_mes_frequencia: this.notificacao_frequencia_mes
        },
        notificacao_condicional: {
          ativa: this.notificacao_condicional,
          configuracoes: this.regras_notificacao_configurada
        }
      })
        .then((response) => {
          this.$bvModal.msgBoxOk(response.data.mensagem, {
            title: "Sucesso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          })
          this.isAddCustomNotify = !this.isAddCustomNotify
          
        }).catch((exception) => {
          this.$bvModal.msgBoxOk(exception.response.data.mensagem, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          })
        })
    },

    confirma_troca_destinatario(){
      this.$bvModal
          .msgBoxConfirm(
            "A troca de tipo de destinatário resulta na redefinição da lista dos mesmos, deseja continuar?",
            {
              title: "Troca de tipo de destinatário",
              okVariant: "primary",
              okTitle: "Sim",
              cancelTitle: "Não",
              cancelVariant: "light",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            }
          )
          .then((value) => {
            if (value == true) {
              this.limpa_destinatarios()
              return value
            } else {
              this.$bvModal.msgBoxOk("A lista de destinatário se mantém!", {
                title: "Troca não realizada",
                okVariant: "light",
                okTitle: "Ok",
                headerBgVariant: "success",
                bodyBgVariant: "success",
                footerBgVariant: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    remove_destinatario(destinatario_remover){
      this.destinatarios.forEach((destinatario) => {
        if(destinatario.valor_destinatario == destinatario_remover.valor_destinatario){
          const index = this.destinatarios.indexOf(destinatario)
          this.destinatarios.splice(index, 1)
        }
      })
    },
    preenche_todos_cursos(){
      if(!this.todos_cursos){
        this.limpa_destinatarios()
        return false
      }
      this.limpa_destinatarios()
      this.cursos.forEach((curso) => {
        this.insere_destinatario(curso, 1)
      })
    },

    async preenche_todas_turmas(){
      this.limpa_destinatarios()
      if(!this.todas_turmas || !this.selecionar_turmas){
        return false
      }
      if(!this.curso_id_selecionado){
        this.selecionar_turmas = false
        this.$bvModal.msgBoxOk("Selecione um curso!", {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        })
        return false
      }
      await this.lista_curso_turmas_codigo()
      this.limpa_destinatarios()
      this.turmas.forEach((turma) => {
        this.insere_destinatario(turma, 2)
      })
    },

    lista_notificacao_tipos(){
      this.$http.get('notificacoes/tipos')
        .then((response) => {
          let tipos = response.data
          tipos.forEach((tipo) => {
            this.notificacao_tipos.push({
              text: tipo.notificacao_tipo,
              value: tipo.notificacao_tipo_id
            })
          })
        })
    },

    async preenche_todos_alunos(){
      if(!this.selecionar_alunos || (this.selecionar_turmas && !this.todos_alunos)){
        return false
      }

      if(!this.todos_alunos){
        this.limpa_destinatarios()
        return false
      }
      
      
      if(!this.turma_codigo_selecionado){
        this.selecionar_alunos = false
        this.$bvModal.msgBoxOk("Selecione uma turma!", {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        })
        return false
      }
      
      await this.lista_alunos_turma()
      this.limpa_destinatarios()
      this.alunos.forEach((aluno) => {
        this.insere_destinatario(aluno, 3)
      })
    },

    lista_cursos() {
        this.$http.get('cursos')
        .then((response) => {
          let cursos = response.data
          cursos.forEach((curso) => {
            this.cursos.push({
              text: curso.curso_titulo,
              value: curso.curso_id
            })
          })
        })
    },

    async lista_curso_turmas_codigo() {
      if(!this.selecionar_turmas){
        return false;
      }
      this.turmas = []
      await this.$http.get('cursos/'+this.curso_id_selecionado+'/turmas')
        .then((response) => {
          let turmas = response.data
          turmas.forEach((turma) => {
            this.turmas.push({
              text: turma.turma_codigo,
              value: turma.turma_codigo
            })
          })
        })
        .catch((exception) => {
          this.turmas = []
          this.$bvModal.msgBoxOk(exception.response.data.mensagem, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        })
        })
    },

    async lista_alunos_turma() {
      this.alunos = []
      await this.$http.get('turmas/'+this.turma_codigo_selecionado+'/alunos')
        .then((response) => {
          let alunos = response.data
          alunos.forEach((aluno) => {
            this.alunos.push({
              text: aluno.aluno_id + " - " + aluno.aluno_nome,
              value: aluno.aluno_id
            })
          })
        })
        .catch((exception) => {
          this.alunos = []
          alert(exception.response.data.mensagem)
        })
    },

    limpa_destinatarios(){
      this.destinatarios = []
    },

    insere_destinatario(novo_destinatario, tipo_destinatario){
      const existe_destinatario = this.destinatarios.find((destinatario_existente) => novo_destinatario.value == destinatario_existente.valor_destinatario)
      
      if(existe_destinatario){
        return false
      }
      this.destinatarios.push({
              nome_destinatario: novo_destinatario.text,
              valor_destinatario: novo_destinatario.value,
              tipo_destinatario: tipo_destinatario
            })
    },

    adiciona_notificacao(tipo_destinatario){
      this.bloquear_botao_adicionar = true
      let destinatario_existente = false
      if(tipo_destinatario == 1){
        if(!this.curso_id_selecionado){
          this.$bvModal.msgBoxOk("Selecione um curso!", {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        })
          return false
        }
        this.cursos.forEach((curso) => {
          if(curso.value == this.curso_id_selecionado){

            destinatario_existente = this.destinatarios.find(destinatario => destinatario.valor_destinatario == curso.value)

            if(destinatario_existente == undefined){
              this.insere_destinatario(curso, tipo_destinatario)
            }else{
              this.$bvModal.msgBoxOk("Curso já selecionado!", {
                title: "Aviso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "danger",
                bodyBgVariant: "danger",
                footerBgVariant: "danger",
              })
            }
          }
        })
      }else if(tipo_destinatario == 2){
        if(!this.turma_codigo_selecionado){
            this.$bvModal.msgBoxOk("Selecione uma turma!", {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          })
          return false
        }

        this.turmas.forEach((turma) => {
          if(turma.value == this.turma_codigo_selecionado){
            
            destinatario_existente = this.destinatarios.find(destinatario => destinatario.valor_destinatario == turma.value)
            if(destinatario_existente == undefined){
              this.insere_destinatario(turma, tipo_destinatario)
            }else{
              this.$bvModal.msgBoxOk("Turma já selecionada!", {
                title: "Aviso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "danger",
                bodyBgVariant: "danger",
                footerBgVariant: "danger",
              })
            }
          }
        })
      }else{
        if(!this.aluno_id_selecionado){
          this.$bvModal.msgBoxOk("Selecione um aluno!", {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          })
          return false
        }
        this.alunos.forEach((aluno) => {
          if(aluno.value == this.aluno_id_selecionado){
            destinatario_existente = this.destinatarios.find(destinatario => destinatario.valor_destinatario == aluno.value)
            if(destinatario_existente == undefined){
              this.insere_destinatario(aluno, tipo_destinatario)
            }else{
              alert("Aluno já selecionado!")
            }
          }
        })
      }
      this.bloquear_botao_adicionar = false
    },

    async add_new_custom_notify() {
      this.isAddCustomNotify = !this.isAddCustomNotify,
      document.getElementById("add_notify_popup").classList.toggle("dropPopup")
    },

  },
}
</script>