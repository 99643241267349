<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- familiar nav -->
      <div class="flex-sup familiar-nav">
        <h3 class="flex-sup">
          <small> Operações/Alunos </small> <i class="fas fa-angle-down"></i>
        </h3>

        <ul class="flex-sup fami-nav-list">
          <!-- variants: isAbaLoading, isActualPlace -->

          <!-- > gerenciamento -->
          <li class="flex-sup">
            <a class="flex-sup" href="/menu-alunos">
              <i class="far fa-clipboard"></i>
              <small> Gerenciar alunos </small>
              <img src="../assets/img/mini-load.png" alt="um momento" />
            </a>
          </li>

          <!-- > compras -->
          <li class="flex-sup">
            <a class="flex-sup" href="/compras">
              <i class="fas fa-shopping-cart"></i>
              <small> Gerenciar compras </small>
              <img src="../assets/img/mini-load.png" alt="um momento" />
            </a>
          </li>

          <!-- > turmas -->
          <li class="flex-sup isActualPlace">
            <a class="flex-sup" href="/menu-turmas">
              <i class="fas fa-users"></i> <small> Gerenciar turmas </small>
              <img src="../assets/img/mini-load.png" alt="um momento" />
            </a>
          </li>

          <!-- > grupos -->
          <li class="flex-sup">
            <a class="flex-sup" href="/aleatoriza-grupos">
              <i class="fas fa-random"></i> <small> Aleatorizar grupos </small>
              <img src="../assets/img/mini-load.png" alt="um momento" />
            </a>
          </li>

          <!-- > treinamentos -->
          <li class="flex-sup">
            <a class="flex-sup" href="/menu-treinamentos">
              <i class="fas fa-graduation-cap"></i>
              <small> Gerenciar treinamentos </small>
              <img src="../assets/img/mini-load.png" alt="um momento" />
            </a>
          </li>
        </ul>
      </div>

      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gerenciamento de alocação de facilitadores</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">AlocarFacilitadores</li>
              </ol>
            </div>

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="card">
        <div class="row">
          <div class="card-body col-sm-3">
            <button
              class="btn btn-lg bs-btn btn-success"
              v-show="btn_executar"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              :variant="varianteSubmit"
              @click="AlocarFacilitador()"
            >
              <span>Alocar facilitador <i class="fas fa-play"></i></span>
            </button>
          </div>
          <div class="card-body col-sm-3">
            <button
              class="btn btn-lg bs-btn btn-success"
              v-show="btn_executar"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              :variant="varianteSubmit"
              @click="AlocarFacilitadorMassa()"
            >
              <span
                >Alocar facilitadores em massa<i class="fas fa-play"></i
              ></span>
            </button>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body">
          <table id="example1" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Ações</th>
                <th>RID facilitador</th>
                <th>Nome</th>
                <th>CPF</th>
                <th>Cargo</th>
                <th>Turma nome</th>
                <th>Turma Codigo</th>
                <th>Grupo codigo</th>
                <th>Day</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <!-- MODAL DE ALOCAÇÃO EM MASSA -->
    <b-modal size="xl"  id="modal_alocacao_massa" class="modal_alocacao_massa" hide-footer>
      <template #modal-title> <h4 class="modal_alocacao_massa_h4"><b>{{ form_titulo }}</b></h4> </template>

      <b-form class="form-Alocacao_massa">
        <div v-show="modal_select">
           <!-- V-SHOW -->
          <div class="row">                          
            <div class="col-sm-12">     
              <h5>1º Passo: Baixe a Planilha de Exemplo:</h5>
              <p><a
                  class="btn btn-primary text-white btn-download-exemplo "
                  :href="caminho"
                  download
                >
                Baixar Planilha de Exemplo
                </a></p>                    

              <hr/>
            </div>
          </div>
      
          <div class="row">
            <div class="col-sm-12">                     
              <h5>2º Passo: Use as tabelas abaixo para preencher a planilha de exemplo e/ou faça o upload :</h5>
              <div class="row">
                <div class="col-sm-6 mt-2">
                  <div class="flex-sup file-upload-box">                    
                    <b-form-file
                      multiple
                      placeholder="Arraste o Arquivo para cá"
                      drop-placeholder="Jogue aqui!"
                      id="arquivo"
                      v-model="arquivo"
                      type="file"
                      @change="envia_arquivo($event)"
                    ></b-form-file>

                    <div class="flex-sup upload-indiq">
                      <strong> Solte os arquivos em cima </strong>
                      <i class="fas fa-arrow-up"></i>
                    </div>
                  </div>                 
                </div>              

                <div class="col-sm-6 col-md-3 text-center mt-3">                  
                  <input type="button"
                    class="btn btn-warning bg-warning  ml-1"
                    value="Trocar Arquivo"
                    @click="trocar_arquivo()"
                  >
                </div>
              </div>              

              <div class="row mt-4">                
                <div class="col-12 text-center">
                  <div class="spinner-border text-primary" role="status" v-show="loading_tela_informacoes_gera_planilha">
                    <span class="sr-only">Loading...</span>
                  </div>                
                </div>
              </div>

              <hr />
            </div>
          </div>

          <div class="row text-white p-3 mb-3" style="background-color:#303030;">            
            <div class="col-12 ">
              <h5 class="text-center">Tabelas de Apoio</h5>                   
            </div>
          </div>

          <div class="row ">
            <div class="col-md-5 col-sm-12">
              <h5>Escolha uma turma para ver os dados dela:</h5>            
              <b-form-select
                trim
                :options="turmas"
                v-model="turma_selecionado"
                @change="carregar_informacoes_turma()"
              ></b-form-select>              
            </div>

            <div class="row" v-show="aviso_infos_obrigatorias">
              <div class="col-sm-12 d-flex">
                <p>Preencha as informações para prosseguir!</p>
              </div>
            </div>
          </div>

          <div class="row" v-show="loading_tela_informacoes">
            <div class="col-12 text-center">
              <div class="spinner-border text-primary" role="status" >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="row p-3" v-show="tela_informacoes && (turma_selecionado != null)">
            <div class="col-md-6 col-sm-12">
              <span class="title-sob-table">Tipo</span>
              <table class=" table">
                <thead>
                  <tr>
                    <th class="table-title">ID</th>
                    <th class="table-title">TIPO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table-secondary" v-for="tipo,index_tipo in tipos" :key="index_tipo">
                    <td>{{tipo.softcheck_tipo_id}}</td>
                    <td>{{tipo.softcheck_tipo_titulo}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6 col-sm-12">
              <span class="title-sob-table">Função</span>
              <table class=" table">
                <thead>
                  <tr>
                    <th class="table-title">ID</th>
                    <th class="table-title">FUNÇÃO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table-secondary"  v-for="funcao,index_funcao in facilitador_funcoes" :key="index_funcao">
                    <td>{{funcao.funcao_id}}</td>
                    <td>{{funcao.funcao_nome}}</td>
                  </tr>
                </tbody>
              </table>

              <span class="title-sob-table">Grupos de Projeto</span>              
                <table class="table">
                  <thead>
                    <tr>
                      <th class="table-title">ID</th>
                      <th class="table-title">GRUPO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-secondary" v-for="grupo,index_grupo in softcheck_grupos" :key="index_grupo">
                      <td >{{grupo.softcheck_grupo_id}}</td>
                      <td >{{grupo.grupo_codigo}}</td>
                    </tr>
                  </tbody>
                </table>

                <span class="title-sob-table">Grupos Aleatórios</span>                 
                <table class="col-sm-12 table">
                  <thead>
                    <tr>
                      <th class="table-title">ID</th>
                      <th class="table-title">GRUPO</th>
                      <th class="table-title">Day</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-secondary" v-for="grupo_day,index_grupo_day in softcheck_grupos_days" :key="index_grupo_day">
                      <td >{{grupo_day.softcheck_grupo_id}}</td>
                      <td >{{grupo_day.grupo_codigo}}</td>
                      <td >{{grupo_day.softcheck_tipo_titulo}}</td>
                    </tr>
                  </tbody>
                </table> 
            </div>
           
          </div>

          
            
          
        
          <!-- V-SHOW -->

          <!-- TABELA DE PREVIEW -->
          <b-form v-show="tela_preview">
            <div v-show="modal_select">
              <div class="row">
                <div class="col-md-12 mt-1 text-center pageTableAlocacaoTitle">
                  <h1>PREVIEW</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-2 text-center">
                  <div class="row col-sm-12 text-center mt-1">                        
                    <div class="col-sm-12">
                      <div class="col-sm-12">
                        <div class="row">
                          <table class="col-sm-12 table table-preview-info">
                            <thead>
                              <tr class="text-center">
                                <th scope="col" class="table-title">#</th>
                                <th scope="col" class="table-title">Facilitador Id</th>
                                <th scope="col" class="table-title">Facilitador Nome</th>
                                <th scope="col" class="table-title">Turma Id</th>
                                <th scope="col" class="table-title">Turma Codigo</th>
                                <th scope="col" class="table-title">Grupo Id</th>
                                <th scope="col" class="table-title">Grupo Codigo</th>
                                <th scope="col" class="table-title">Tipo Id</th>
                                <th scope="col" class="table-title">Tipo Titulo</th>
                                <th scope="col" class="table-title">Funcao Id</th>
                                <th scope="col" class="table-title">Funcao Nome</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-secondary text-center" v-for="dado,index in dados_planilha" :key="index" v-bind:class="[dado.erro>0 ? errorClass : '']">
                                <td data-label="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" v-show="dado.erro!=0" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/></svg></td>
                                <td data-label="Facilitador Id">{{dado.facilitador_id}}</td>
                                <td data-label="Facilitador Nome">{{dado.facilitador_nome}}</td>
                                <td data-label="Turma Id">{{dado.turma_id}}</td>
                                <td data-label="Turma Codigo">{{dado.turma_codigo}}</td>
                                <td data-label="Grupo Id">{{dado.grupo_id}}</td>
                                <td data-label="Grupo Codigo">{{dado.grupo_codigo}}</td>
                                <td data-label="Tipo Id">{{dado.tipo_id}}</td>
                                <td data-label="Tipo Titulo">{{dado.tipo_titulo}}</td>
                                <td data-label="Funcao Id">{{dado.funcao_id}}</td>
                                <td data-label="Funcao Nome">{{dado.funcao_nome}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-show="erros_planilha.length">
                <div class="col-md-12 mt-1 text-center pageTableAlocacaoTitle">
                  <h1>ERROS</h1>
                </div>
              </div>
              <div class="row" v-show="erros_planilha.length">
                <div class="col-md-12 mt-2 text-center">
                  <div class="row col-sm-12 text-center mt-1">
                    <div class="col-sm-12">
                      <div class="col-sm-12">
                        <div class="row">
                          <table class="col-sm-12 table">
                            <thead>
                              <tr>
                                <th class="table-title col-md-2">#</th>
                                <th class="table-title text-center col-md-9">Descrição do erro</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-secondary msg_erro_table" v-for="erro,index in erros_planilha" :key="index" >
                                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill col-md-3" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/></svg></td>
                                <td class=" msg_erro_table_txt col-md-9">{{erro.mensagem}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
          <hr />
        

          <div class="input-group">
            <b-button            
              variant="warning"
              v-show="tela_preview"
              @click="tela_preview=false;tela_informacoes=true;trocar_arquivo()"
              >Voltar
            </b-button>
            <b-button
              class="ml-1"
              variant="success"
              v-show="tela_preview && erros_planilha.length==0"
              @click="cadastraAlocacaoMassa()"
              >Salvar
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- MODAL -->

    <!-- MODAL DE ALOCAÇÃO DE FACILITADOR UNICO -->
    <b-modal size="xl" id="modal_alocacao_facilitador" hide-footer>
      <template #modal-title> {{ form_titulo }} </template>

      <b-form>
        <div v-show="modal_select">
        <div class="row mb-4">
            <div class="col-sm-12">
            Escolha cada um dos Itens
            </div>
        </div>
          <div class="row">
            <div class="col-sm-2">
              <b-form-group id="input-1" label="Facilitador">
                <b-form-select
                  trim
                  :options="dado_facilitador"
                  v-model="formUnico.facilitador_escolhido"
                ></b-form-select>
                <!-- <label class="text-danger small">{{
                errosFormFacilitador.nome
              }}</label> -->
              </b-form-group>
            </div>
            <div class="col-sm-2">
              <b-form-group id="input-1" label="Turma">
                <b-form-select
                  trim
                  :options="turmas"
                  v-model="formUnico.turma_escolhida"
                ></b-form-select>
                <!-- <label class="text-danger small">{{
                errosFormFacilitador.nome
              }}</label> -->
              </b-form-group>
            </div>
            <div class="col-sm-2">
              <b-form-group id="input-1" label="Day">
                <b-form-select
                  @click.prevent
                  :options="days[formUnico.turma_escolhida]"
                  v-model="formUnico.day_escolhido"
                  @change="captura_day(formUnico.day_escolhido)"
                >
                </b-form-select>
                <!-- <label class="text-danger small">{{
                errosFormFacilitador.nome
              }}</label> -->
              </b-form-group>
            </div>            
            <div class="col-sm-2">
              <b-form-group id="input-1" label="Tipo Grupo">
                <b-form-select
                  trim
                  :options="tipo_grupos"
                  v-model="formUnico.tipo_grupo_escolhido"
                  @change="carrega_grupo()"
                ></b-form-select>
                <!-- <label class="text-danger small">{{
                errosFormFacilitador.nome
              }}</label> -->
              </b-form-group>
            </div>
            <div class="col-sm-2">
              <b-form-group id="input-1" label="Grupo">
                <b-form-select
                  trim
                  :options="grupos"
                  v-model="formUnico.grupo_escolhido"
                ></b-form-select>
                <!-- <label class="text-danger small">{{
                errosFormFacilitador.nome
              }}</label> -->
              </b-form-group>
            </div>
            <div class="col-sm-2">
              <b-form-group id="input-1" label="Função">
                <b-form-select
                  trim
                  :options="funcoes"
                  v-model="formUnico.funcao_escolhida"
                ></b-form-select>
                <!-- <label class="text-danger small">{{
                errosFormFacilitador.nome
              }}</label> -->
              </b-form-group>
            </div>            
          </div>
        </div>

        <hr />
        <div class="input-group">
          <b-button
            class="mr-1 align-items-right"
            @click="enviarFormAlocacao()"
            id="btn-cadastro"
            :variant="varianteSubmit"
            >{{ botaoSubmitAlocacao }}
          </b-button>
        </div>
      </b-form>

      
    </b-modal>


    
    <!--FIM DOS 2 MODAIS -->

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>

.form-Alocacao_massa{
  padding: 2px;
  margin:auto;
  overflow-x: visible;
}


/*container tables - pag109*/
.modal_alocacao_massa_h4{
  color: #0C70F2;
}

.btn-download-exemplo{
  color: #0C70F2;
  border: 1px solid #0C70F2;
  font-weight: 600;
  cursor: pointer;
}

.btn-download-exemplo:hover{
  color: white;
  background-color: #0C70F2;
  transition-duration: 5s;
}

.table-title{
  background-color: #303030;
  color: white;
}

.title-sob-table{
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 15px ;
}

.input-group{
  display: flex;
  justify-content: flex-end;
}

/*tablePageAlocaçao*/
.pageTableAlocacaoTitle h1{
  font-size: 30px;
  font-weight: 600;
}

.pageTableAlocacaoTitle span{
  font-weight: 500;
}

.alocar_facilitadores_btn_voltar_salvar{
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
}

.alocar_facilitadores_btn_voltar_salvar_voltar{
  background-color: #CF4045;
  color: white;
}

.alocar_facilitadores_btn_voltar_salvar_Salvar{
  background-color: #0C70F2;
  color: white;
  margin: 0 10px;
}

.bs-btn {
  align-items: center;
}
.bs-btn i {
  margin-left: 8px;
}

.errorClass{
  background-color: #CF4045 !important;
}


.msg_erro_table_txt{
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

@media (max-width: 800px){
  .table-preview-info{
    border: 0;
  }

  .table-preview-info thead{
    border: 0;
    clip: rect(0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table-preview-info tr{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625rem;
    padding: 0;
  }

  .table-preview-info td{
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1rem;
    text-align: right;
  }

  .table-preview-info td::before{
    content:attr(data-label);
    float: left;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  .table-preview-info .table-title{
    color: #CF4045;
  }
}
</style>


<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

function abreModalEdicao(facilitador_id) {
  window.elemento_vue.editaFacilitadorAlocacao(facilitador_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Facilitadores",
  components: {
    Preloader,
    Footer,
    Navbar,
    Menu
  },
  data() {

    return {
      arquivo:[],
      user: {},
      preloader_status: true,
      loading: false,
      page: "AlocacaoFacilitadores",
      form_titulo: "",
      botaoSubmitAlocacao: "",
      modal_tabela: false,
      modal_upload: false,
      modal_select: true,
      aviso_infos_obrigatorias: false,
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      btn_confirmar: null,
      alocacao: [],
      varianteSubmit: "",
      // Local arquivo exemplo
      caminho: "files/modelo_alocacao_facilitaor_massa.xlsx",
      //modal de alocacao unica
      dado_facilitador: [],
      grupos: [],
      tipo_grupos: [
        {text:'Projeto',value:'0'},
        {text:'Aleatorio',value:'1'}
      ],
      days_alocacao: [],
      formUnico: {
        facilitador_id: "",
        facilitador_escolhido: "",
        funcao_escolhida: "",
        day_escolhido: "",
        tipo_grupo_escolhido: "",
        grupo_escolhido: "",
        turma_escolhida: null,
      },
      //Modal de alocacao em massa
      turmas: [{ value: null, text: "selecione uma turma" }],
      turma_selecionado: null,
      days: [],
      day_turma: [],
      day: null,
      day_selecionado: [],
      funcoes: [],
      facilitadores: [],
      //Planilha
      json_campos: {
        facilitador_id: "facilitador_id",
        turma_id: "turma_id",
        turma_codigo: "turma_codigo",
        tipo_id: "tipo_id",
        tipo_nome: "tipo_nome",
        grupo_id: "grupo_id",
        grupo_codigo: "grupo_codigo",
        funcao_id: "funcao_id",
      },
      json_dados: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],

      //Tabela de confirmação de alocação em massa
      loading_modal: false,
      erros: [],
      confirmacaoAlocacao: {
        dados: [],
      },
      erroConfirmacaoAlocacao: false,
      // erroConfirmacaoAlocacao: {
      //   facilitador_id: "",
      //   turma_id: "",
      //   tipo_id: "",
      //   grupo_id: "",
      //   funcao_id: "",
      // },

      loadingArquivo: false,

      //Validação para cadastro unico
      errosFormUnico: {
        funcao_escolhida: "",
        day_escolhido: "",
        facilitador_escolhido: "",
        grupo_escolhido: "",
        turma_escolhida: null,
      },

      headers: [
        {text: 'ID', value:'id'},
        {text: 'Nome', value:'name'},
      ],

      lista: [{
        "id":1,
      }],
      tela_preview:false,
      tela_informacoes:true,
      dados_planilha:[],
      tipos:[],
      facilitador_funcoes:[],
      softcheck_grupos:[],
      softcheck_grupos_days:[],
      loading_tela_informacoes:false,
      loading_tela_informacoes_gera_planilha:false,
      errorClass:'table-danger',
      erros_planilha:[],
    };
  },
  created() {
    this.preloader_status = false;
    this.carregar_turmas_days();
    this.carregar_funcoes();
    this.carregar_facilitador();
    // this.carrega_grupo();
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {                                                                                                                                                                          
    tela1() {
      this.modal_tabela = false;
      this.modal_select = true;
      this.modal_upload = false;
      this.aviso_infos_obrigatorias = false;
    },
    tela2() {
      // Só vai para a próxima tela após selecionar a turma e day
      if (this.turma_selecionado != null && this.day != null){
        this.modal_tabela = true;
        this.modal_select = false;
        this.modal_upload = false;
        this.aviso_infos_obrigatorias = false;
        this.gerar_planilha();
      }
      else{
        this.aviso_infos_obrigatorias = true;
      }
    },
    tela3() {
      this.modal_tabela = false;
      this.modal_select = false;
      this.modal_upload = true;
    },

    async carregar_informacoes_turma(){
      this.loading_tela_informacoes = true;
      this.tela_informacoes = false;
      let turma = this.turmas.find((el) => el.value == this.turma_selecionado);
      let url_tipos = "lista/softcheck/tipos/"+turma.turma_id;
      let url_grupos = "lista/grupos/"+turma.turma_id;
      let url_grupos_days = "lista/grupos/days/"+turma.turma_id;
      let url_funcoes = "lista/funcoes";

      const response_tipos = await this.$http.get(url_tipos);
      const response_grupos = await this.$http.get(url_grupos);
      const response_grupos_days = await this.$http.get(url_grupos_days);
      const response_funcoes = await this.$http.get(url_funcoes);

      this.tipos = response_tipos.data;
      this.facilitador_funcoes = response_funcoes.data;
      this.softcheck_grupos = response_grupos.data;
      this.softcheck_grupos_days = response_grupos_days.data;
      this.loading_tela_informacoes = false;
      this.tela_informacoes = true;
    },
    
    async pageTableAlocacao(){
      this.limparVar();
      this.form_titulo = "Alocar facilitador";
      this.acao = "novo";
      this.tela_informacoes = false;
      this.tela_preview = true; 
    },

    async gerar_planilha() {
      let turma = this.turmas.find((el) => el.value == this.turma_selecionado);
      let url = "lista/grupos/" + turma.turma_id;
      const response = await this.$http.get(url);
      var json = new Array();
      response.data.forEach((grupo) => {
        json.push({
          facilitador_id: "",
          turma_id: turma.turma_id,
          turma_codigo: this.turma_selecionado,
          tipo_id: this.day_selecionado.value,
          tipo_nome: this.day_selecionado.text,
          grupo_id: grupo.softcheck_grupo_id,
          grupo_codigo: grupo.grupo_codigo,
          funcao_id: "",
        });
      });
      this.json_dados = json;
    },

    async carregar_turmas_days() {
      let url = "todos/days/turma";
      const response = await this.$http.get(url);
      let dados_days = new Array();
      let dados_final = new Array();
      let dados_id_days = new Array();
      this.turmas = [];
      response.data.forEach((turma) => {
        dados_days[turma.turma_codigo] = turma.titulo_day.split(",");
        dados_id_days[turma.turma_codigo] = turma.id_day.split(",");
        let days = new Array();
        dados_days[turma.turma_codigo].forEach((day, key) => {
          days.push({
            text: day,
            value: dados_id_days[turma.turma_codigo][key],
          });
        });
        dados_final[turma.turma_codigo] = days;
        this.turmas.push({
          text: turma.turma_id +' : '+turma.turma_codigo,
          value: turma.turma_codigo,
          turma_id: turma.turma_id,
        });
      });
      this.days = dados_final;
    },

    captura_day(day) {
      if (this.turma_selecionado != null) {
        this.day_selecionado = this.days[this.turma_selecionado].find(
          (item) => {
            let nome_day;
            if (item.value == day) {
              nome_day = item.text;
              return nome_day;
            }
          }
        );
      }
    },

    trocar_arquivo(){
      document.getElementById("arquivo").defaultValue
      this.arquivo = []
    },

    async carregar_funcoes() {
      let url = "lista/funcoes";
      const response = await this.$http.get(url);
      response.data.forEach((funcao) => {
        this.funcoes.push({
          value: funcao.funcao_id,
          text: funcao.funcao_nome,
        });
      });
    },

    async carregar_facilitador() {
      let url = "lista/facilitadores/alocacao";
      const response = await this.$http.get(url);
      response.data.forEach((facilitador) => {
        this.dado_facilitador.push({
          value: facilitador.softcheck_profissional_id,
          text: facilitador.softcheck_profissional_nome,
        });
      });
    },

    async carrega_grupo(grupo_escolhido) {
      var turma_id
      var url
      var erro
      if (this.formUnico.turma_escolhida != null) {
          turma_id = this.turmas.find(
          (el) => el.value == this.formUnico.turma_escolhida
        );
        
      }else{
        this.$bvModal.msgBoxOk("Selecione uma Turma!", {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
      }
      if (grupo_escolhido == null) {        
        if(this.formUnico.tipo_grupo_escolhido==0){
          url = "lista/grupos/" + turma_id.turma_id;
          erro = 0
        }else if(this.formUnico.tipo_grupo_escolhido==1){
          url = "lista/grupos/days/" + turma_id.turma_id;
          erro = 0
        }else{
          this.$bvModal.msgBoxOk("Selecione uma Tipo de grupo!", {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
          erro = 1
        }
        if(erro==0){
          const response = await this.$http.get(url);
          this.grupos = [];
          if(this.formUnico.tipo_grupo_escolhido==1){
            response.data.forEach((grupo) => {
              this.grupos.push({
                value: grupo.softcheck_grupo_id,
                text: 'Day ' + grupo.day + ' - ' +grupo.grupo_codigo,
                day: grupo.day
              });
            });
          }else{
            response.data.forEach((grupo) => {
              this.grupos.push({
                value: grupo.softcheck_grupo_id,
                text: grupo.grupo_codigo,
              });
            });
          }
        }      
      } else {
        if (this.formUnico.turma_escolhida != null) {
          let url = "lista/grupo/" + this.formUnico.turma_escolhida;
          const response = await this.$http.get(url);
          this.grupos = [];
          response.data.forEach((grupo) => {
            this.grupos.push({
              value: grupo.softcheck_grupo_id,
              text: grupo.grupo_codigo,
            });
          });
        }
        this.formUnico.grupo_escolhido = grupo_escolhido;
        erro=0;
      }
      if(erro==0){
        this.carregar_turmas_days();
      }
      
    },

    AlocarFacilitadorMassa() {
      this.limparVar();
      this.modal_tabela = false;
      this.modal_select = true;
      this.modal_upload = false;
      this.aviso_infos_obrigatorias = false;
      this.form_titulo = "Alocação em massa";
      this.botaoSubmitAlocacao = "Proximo";
      this.varianteSubmit = "primary";
      this.acao = "novoEmMassa";

      this.$bvModal.show("modal_alocacao_massa");
    },

    AlocarFacilitador() {
      this.limparVar();
      this.form_titulo = "Alocar facilitador";
      this.botaoSubmitAlocacao = "Cadastrar";
      this.varianteSubmit = "primary";
      this.acao = "novo";

      this.$bvModal.show("modal_alocacao_facilitador");
    },

    editaFacilitadorAlocacao(facilitador_id) {
      this.limparVar();
      this.form_titulo = "Editar facilitador";
      this.botaoSubmitAlocacao = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";
      this.modal_facilitador = true;

        this.facilitadores.forEach((facilitador) => {
          if (facilitador.facilitador_id == facilitador_id) {
            this.formUnico.facilitador_id = facilitador.facilitador_id,
            this.formUnico.softcheck_profissional_id =
              facilitador.softcheck_profissional_id;
            this.formUnico.facilitador_escolhido =
              facilitador.softcheck_profissional_id;
            this.formUnico.turma_escolhida = facilitador.softcheck_turma_codigo;

            this.carrega_grupo(facilitador.softcheck_grupo_id);

            this.formUnico.day_escolhido = facilitador.softcheck_tipo_id;
            this.formUnico.funcao_escolhida = facilitador.funcao_id;
          }
        });

      this.$bvModal.show("modal_alocacao_facilitador");
     
    },

    async lista_facilitadores() {
      let url = "lista/facilitadores";
      const response = await this.$http.get(url);

      this.facilitadores = response.data;
      let data_table = new Array();
      let status_cor = "text-secundary";

      this.facilitadores.forEach((facilitador) => {
        let botao_editar_facilitador =
          "<button class='btn btn-warning btn-sm mr-1 open-facilitador' type='button' id='" +
          facilitador.facilitador_id +
          "'><i id = " +
          facilitador.facilitador_id +
          " class = 'fas fa-edit'></i>Editar</button>";
        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_facilitador : "") +
            "</span>",

          "<span class=" +
            status_cor +
            ">" +
            facilitador.softcheck_profissional_id +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            facilitador.softcheck_profissional_nome +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            facilitador.softcheck_profissional_cpf +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            facilitador.funcao_nome +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            facilitador.turma_nome +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            facilitador.softcheck_turma_codigo +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            facilitador.grupo_codigo +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            facilitador.softcheck_tipo_titulo +
            "</span>",
        ]);
      });

      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
          drawCallback: function () {
            $(".open-facilitador").on("click", function (event) {
              abreModalEdicao(event.target.id);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
    },

    habilita_botoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "operacoes") {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }

        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },

    async enviarFormAlocacao() {
      
      let turma_id = this.turmas.find(
        (el) => el.value == this.formUnico.turma_escolhida
      );
      this.formUnico.turma_escolhida = turma_id.turma_id;
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let erros = false;
      // botaoCadastro.disabled = true;

      if (!this.formUnico.facilitador_escolhido) {
        this.errosFormUnico.facilitador_escolhido =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormUnico.facilitador_escolhido = "";
      }
      if (!this.formUnico.turma_escolhida) {
        this.errosFormUnico.turma_escolhida = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormUnico.turma_escolhida = "";
      }
      if (!this.formUnico.grupo_escolhido) {
        this.errosFormUnico.grupo_escolhido = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormUnico.grupo_escolhido = "";
      }
      if (!this.formUnico.day_escolhido) {
        this.errosFormUnico.day_escolhido = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormUnico.day_escolhido = "";
      }
      if (!this.formUnico.funcao_escolhida) {
        this.errosFormUnico.funcao_escolhida = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormUnico.funcao_escolhida = "";
      }

      if (!erros) {
        let retorno;
        if (this.acao == "novo") {
          let url = "cadastra/alocacao/unica";
          const response = await this.$http.post(url, {
            facilitador_escolhido: this.formUnico.facilitador_escolhido,
            turma_escolhida: this.formUnico.turma_escolhida,
            grupo_escolhido: this.formUnico.grupo_escolhido,
            funcao_escolhida: this.formUnico.funcao_escolhida,
            day_escolhido: this.formUnico.day_escolhido,

            page: this.page,
            profissional_id: this.user.dados.profissional_id,
            acao: "alocar facilitador",
          });
          retorno = response.data;
        }
        else if (this.acao == "editar") {
            let url = "atualiza/alocacao/unica";
            const response = await this.$http.patch(url, {
              dados : {
                facilitador_id: this.formUnico.facilitador_id,
                facilitador_escolhido:  this.formUnico.facilitador_escolhido,
                turma_escolhida: this.formUnico.turma_escolhida,
                grupo_escolhido: this.formUnico.grupo_escolhido,
                day_escolhido: this.formUnico.day_escolhido,
                funcao_escolhida: this.formUnico.funcao_escolhida,

                page: this.page,
                profissional_id: this.user.dados.profissional_id,
                acao: "editar facilitador"
              },
            });
            retorno = response.data;
          }

        if (retorno.erro) {
          this.$bvModal.msgBoxOk(retorno.erro, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        } else {
          this.$bvModal.msgBoxOk(retorno.mensagem, {
            title: "Sucesso!",
            okVariant: "success",
            okTitle: "ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          });
          this.$bvModal.hide("modal_alocacao_facilitador");
          this.lista_facilitadores();
        }
      }
      botaoCadastro.disabled = false;
    },

    async envia_arquivo(event) {//colocar o loading do bootstrap
      this.loading_modal = true;
      this.loading_tela_informacoes_gera_planilha = true;

      let formData = new FormData();
      formData.append(
        "file",
        event.target.files ? event.target.files[0] : event.dataTransfer.files[0]
      );

      
      let retorno;
      await this.$http
        .post("importar/arquivo/facilitadores", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.tela_informacoes = false;
          this.tela_preview = true;
          retorno = response.data;
          this.loading_tela_informacoes_gera_planilha = false;
          this.dados_planilha = retorno.dados;
          if(retorno.erros){
            this.erros_planilha = retorno.erros;
          }else{
            this.erros_planilha = "";
          }
        })
        .catch((erro)=>{
          this.$bvModal.msgBoxOk("Verifique se o arquivo que está sendo enviado é do tipo XLSX", {
            title: "Erro!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
          console.log(erro);
          this.tela_informacoes = true;
          this.tela_preview = false;
          this.loading_tela_informacoes_gera_planilha = false;


        });
      

      //this.loading_modal = false;
      //this.$bvModal.hide("modal_upload");
      //this.popularTabela(retorno);
      //this.erros = retorno.erros;
    },

    popularTabela(retorno) {
      this.confirmacaoAlocacao.dados = [];
      this.erroConfirmacaoAlocacao = false;
      retorno.dados.forEach((dado, index) => {
        let erro = 0;
        // let alerta_day = 0;
        let indicacao;
        this.btn_confirmar = false
        if (dado.erro > 0) {
          erro = "danger";
          indicacao = "Linha "+ parseInt(index+1) + " >"
          this.btn_confirmar = false
        }
        else {
          erro = "";
          indicacao = "";
          this.btn_confirmar = true
        }

        this.confirmacaoAlocacao.dados.push({
          indicacao,
          facilitador_id: dado.facilitador_id,
          turma_id: dado.turma_id,
          turma_codigo: dado.turma_codigo,
          tipo_id: dado.tipo_id,
          tipo_nome: dado.tipo_nome,
          grupo_id: dado.grupo_id,
          grupo_codigo: dado.grupo_codigo,
          funcao_id: dado.funcao_id,
          observacao: dado.alerta,
          _cellVariants: {
            facilitador_id: erro,
            turma_id: erro,
            turma_codigo: erro,
            tipo_id: erro,
            tipo_nome: erro,
            grupo_id: erro,
            grupo_codigo: erro,
            funcao_id: erro,
            funcao_nome: erro,
          },
        });
      });
      this.loadingUpload = false;
    },
    
    async cadastraAlocacaoMassa() {

      if (this.erros_planilha.length==0) {
        let retorno;
        
        let url = "cadastra/planilha/alocacao";
        const response = await this.$http.post(url, {
          dados: this.dados_planilha,

          page: this.page,
          profissional_id: this.user.dados.profissional_id,
          acao: "alocar facilitadores",
        });
        retorno = response.data;
        

        if (retorno.erro) {
          this.$bvModal.msgBoxOk(retorno.erro, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        } else {
          this.$bvModal.msgBoxOk(retorno.mensagem, {
            title: "Sucesso!",
            okVariant: "success",
            okTitle: "ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          });
          this.$bvModal.hide("modal_alocacao_massa");
          this.lista_facilitadores();
        }
      }

    },

    limparVar() {
      this.turma_selecionado = null;
    },
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    //quando liga a tela
    window.elemento_vue = this;
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    this.habilita_botoes();
    this.lista_facilitadores();
  },
};
</script>



