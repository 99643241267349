<template>
    <div id="root">
        <Menu />
        <Navbar />
        <div class="content-wrapper" v-show="!habilitar_pagina">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Usuário sem permissão de acesso!</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Boletim dos Alunos</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrapper" v-show="habilitar_pagina">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Boletim dos Alunos</h1>
                        </div>
                        <div class="col-sm-12">
                            <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Boletim dos Alunos</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="card">
                        <!-- upload box -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-2">
                                    <div>
                                        <b-form-group label="Turma" label-for="turma">
                                            <b-form-select 
                                                @click.prevent
                                                :options="turmas"
                                                v-model="turma_codigo">
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div><br></div>
                                    <button class="btn btn-lg bs-btn btn-success" type="button" @click="carrega_boletim()">
                                        <span>Verificar <i class="fas fa-play"></i></span>
                                    </button>
                                </div>
                                <div class="col-6"></div>
                                <div class="col-2">
                                    <div class="small-box bg-success" v-show="!loading && mostrar_tabela">
                                        <div class="inner text-center">
                                            <h3>{{coeficiente_geral}}</h3>
                                            <p>Coeficiente Geral</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3"> 
                                <p>Como é calculado? Cada coeficiente é dividido em 3 parâmetros com pesos diferentes: assignments (média das notas e nº de entregas), deliverables (média das notas) e presença nos days! <br>A tabela abaixo mostra de forma acumulativa a cada Slot.</p>
                            </div>
                            <!-- Exibição dos alunos de cada turma -->
                            <div class="mx-auto">
                                <div class="row" v-show="loading">
                                    <div class="mx-auto text-center">
                                        <b-img :src="require('../assets/loading.gif')" fluid width="100%"></b-img>
                                        <h2>Aguarde!</h2>
                                    </div>
                                </div> 
                                <div class="table-responsive" v-show="!loading && mostrar_tabela">
                                    <table id="alunos_da_turma" class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>RID</th>
                                            <th>Aluno</th>
                                            <th>Slot #1</th>
                                            <th>Slot #2</th>
                                            <th>Slot #3</th>
                                            <th>Slot #4</th>
                                            <th>Slot #5</th>
                                            <th>Slot #6</th>
                                            <th>Slot #7</th>
                                            <th>Slot #8</th>
                                            <th>Slot #9</th>
                                            <th>Slot #10</th>
                                            <th>Slot #11</th>
                                            <th>Slot #12</th>
                                        </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <b-modal id="modal_aviso">
            <h5 class="text-center">{{ msg_modal }}</h5>
        </b-modal>
        <Footer />
    </div>
</template>

<style scoped>
    .bs-btn i {margin-left: 5px;}
</style>

<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {
            user: {},
            page: "Boletim",
            turmas: [{
                text: "selecione",
                value: null
            }],
            turma_codigo: null,
            boletim: [],
            coeficiente_geral: "",
            // Auxiliares de visualização
            loading: false,
            mostrar_tabela: false,
            msg_modal: '',
            loading_modal: false,
            habilitar_pagina: false
        };
    },
    created() {
        this.carrega_turmas();
    },
    methods: {
        // Select de turmas
        async carrega_turmas(){
            this.loading = true;
            let url = "turmas/lms";
            const response = await this.$http.get(url);
            response.data.forEach(turma => {
                this.turmas.push({
                    text: turma.turma_codigo,
                    value: turma.turma_codigo
                });
            });
            this.loading = false;
        },
        // Busca das informações de boletim de uma turma
        async carrega_boletim(){
            if (this.turma_codigo != null){
                this.loading = true;
                let url = "boletim/" + this.turma_codigo;
                const response = await this.$http.get(url);

                this.boletim = [];
                let data_table = new Array();
                let colunas_escondidas = [1,2,3,4,5,6,7,8,9,10,11,12,13];
                response.data.forEach(aluno => {
                    let value_slot1 = '';
                    let value_slot2 = '';
                    let value_slot3 = '';
                    let value_slot4 = '';
                    let value_slot5 = '';
                    let value_slot6 = '';
                    let value_slot7 = '';
                    let value_slot8 = '';
                    let value_slot9 = '';
                    let value_slot10 = '';
                    let value_slot11 = '';
                    let value_slot12 = '';

                    // Tratativa das notas por slot
                    aluno.notas.forEach(nota => {
                        if(nota.Slot == 1){
                            value_slot1 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 2){
                            value_slot2 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 3){
                            value_slot3 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 4){
                            value_slot4 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 5){
                            value_slot5 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 6){
                            value_slot6 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 7){
                            value_slot7 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 8){
                            value_slot8 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 9){
                            value_slot9 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 10){
                            value_slot10 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 11){
                            value_slot11 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 12){
                            value_slot12 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        
                        for(let i=0; i<colunas_escondidas.length; i++){
                            if (colunas_escondidas[i] === nota.Slot) {
                                colunas_escondidas.splice(i, 1);
                            }
                        }
                    });

                    data_table.push([
                        "<span>" + aluno.aluno_id + "</span>",
                        "<span>" + aluno.aluno_nome + "</span>",
                        "<span>" + value_slot1 + "</span>",
                        "<span>" + value_slot2 + "</span>",
                        "<span>" + value_slot3 + "</span>",
                        "<span>" + value_slot4 + "</span>",
                        "<span>" + value_slot5 + "</span>",
                        "<span>" + value_slot6 + "</span>",
                        "<span>" + value_slot7 + "</span>",
                        "<span>" + value_slot8 + "</span>",
                        "<span>" + value_slot9 + "</span>",
                        "<span>" + value_slot10 + "</span>",
                        "<span>" + value_slot11 + "</span>",
                        "<span>" + value_slot12 + "</span>"
                    ]);
                });

                $("#alunos_da_turma").DataTable({
                    data: data_table,
                    destroy: true,
                    responsive: {
                        details: {
                        display: $.fn.dataTable.Responsive.display.modal({
                            header: function (row) {
                            var data = row.data();
                            return "Details for " + data[0] + " " + data[1];
                            },
                        }),
                        renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
                        },
                    },
                    lengthChange: false,
                    autoWidth: false,
                    dom: 'Bfrtip',
                    buttons: [            
                        {
                            extend: 'copy',
                            text: 'Copiar',
                            className:'btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio',
                        },
                        {
                            extend: 'csv',
                            text: 'CSV',
                            className:'btn btn-secondary buttons-csv buttons-html5',
                        },
                        {
                            extend: 'excel',
                            text: 'Excel',
                            className:'btn btn-secondary buttons-excel buttons-html5',
                        },
                        {
                            extend: 'pdf',
                            text: 'PDF',
                            className:'btn btn-secondary buttons-pdf buttons-html5',
                        },
                        {
                            extend: 'print',
                            text: 'Imprimir',
                            className:'btn btn-secondary buttons-print buttons-html5',
                        },
                        {
                            extend: 'colvis',
                            text: 'Exibir Colunas',
                            className:'btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim',              
                        }
                    ],
                    columnDefs: [{
                        targets: colunas_escondidas,
                        visible: false,
                    }],
                    bLengthChange: true,
                    bPaginate: true,
                    pageLength: 10,
                    bInfo: true,
                    language: {
                        sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                        sInfoEmpty: " ",
                        sLengthMenu: "_MENU_ resultados por página",
                        sSearch: "",
                        sEmptyTable: "Nenhum registro encontrado",
                        sInfoFiltered: "Filtrados de _MAX_ registros",
                        sLoadingRecords: "Carregando...",
                        sProcessing: "Processando...",
                        sInfoPostFix: "",
                        sInfoThousands: ".",
                        sSearchPlaceholder: "Pesquisar",
                        sZeroRecords:
                        "Nenhum registro encontrado <i class='far fa-frown'></i>",
                        paginate: {
                            sNext: "Próximo",
                            sPrevious: "Anterior",
                        },
                    }
                })
                .buttons()
                .container()
                .appendTo("#example_wrapper .col-md-6:eq(0)");

                this.busca_coeficiente();
                this.mostrar_tabela = true;
                this.loading = false;
            }else{
                this.mostrar_tabela = false;
            }
        },
        // Busca pelo coeficiente geral da turma
        async busca_coeficiente(){
            if(this.turma_codigo != null){
                this.loading = true;
                let url = "coeficiente/geral/" + this.turma_codigo;
                const response = await this.$http.get(url);
                this.coeficiente_geral = Math.round(response.data * 100) / 100;
                this.loading = false;
            }
        }
    },
    mounted() {
        window.elemento_vue = this; 
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);

        this.user.permissoes.forEach(permissao => {
            if (permissao.privilegio_tipo_id == 9 || permissao.privilegio_tipo_id == 12){
                this.habilitar_pagina = true;
            }
        });
    }
}
</script>