<template>
<div>
  <h1 class="m-0">Ops, página não encontrada!</h1>
</div>
  
</template>


<script>
export default {
  methods: {

    redirecionar(){
      window.location.href = '/'
    }
  },
  mounted() {
    setTimeout(() => this.redirecionar(), 3000)
  }
}
</script>