<template>
  <div class="mt-3">
    <b-container fluid="sm">
      <h3>Status dos Sistemas DNC</h3>
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-card-header
              >Status dos <b>Projetos</b> da DNC em relação aos
              servidores</b-card-header
            >
            <b-card-body>
              <b-table striped hover :items="items" :fields="fields">
                <template #cell(status)="data">
                  <b-spinner
                    variant="primary"
                    label="Carregando"
                    v-if="data.value==''"
                  ></b-spinner>
                  <b v-else
                    :class="
                      data.value == 'Online' ? 'text-success' : 'text-danger'
                    "
					
                    >{{ data.value }} </b
                  >
                  <span
                    class="fas fa-check-circle text-success"
                    v-if="data.value == 'Online'"
                  ></span>
                  <span
                    class="fas fa-exclamation-circle text-danger"
                    v-if="data.value == 'Offline'"
                  ></span>
                </template>
                <template #cell(acessar)="data">
                  <span v-html="data.value"></span>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-card-header
              >Status das <b>APIs</b> da DNC em relação aos servidores</b-card-header
            >
            <b-card-body>
              <b-table striped hover :items="items_apis" :fields="fields_apis">
                <template #cell(status)="data">
                  <b-spinner
                    variant="primary"
                    label="Carregando"
                    v-if="data.value==''"
                  ></b-spinner>
                  <b v-else
                    :class="
                      data.value == 'Online' ? 'text-success' : 'text-danger'
                    "
                    >{{ data.value }} </b
                  >
                  <span
                    class="fas fa-check-circle text-success"
                    v-if="data.value == 'Online'"
                  ></span>
                  <span
                    class="fas fa-exclamation-circle text-danger"
                    v-if="data.value == 'Offline'"
                  ></span>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'

export default {
  name: "DNCStatus",
  components: {
    // HelloWorld,
  },
  data() {
    return {
		fields: ["sistema", "ultima_checagem","acessar","url","status"],
      items: [
        {
          sistema: "DNC Group",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://dnc.group",
        },
        {
          sistema: "DNC Forms",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://forms.dnc.group",
        },
        {
          sistema: "DNC Softcheck",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://softcheck.dnc.group",
        },
        {
          sistema: "DNC Facilitador",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://facilitador.dnc.group/dncscore/",
        },
        {
          sistema: "DNC Aluno",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://aluno.dnc.group",
        },
        {
          sistema: "DNC Checkout",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://checkout.dnc.group",
        },
        {
          sistema: "Plataforma Administrativa",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://adm.dnc.group",
        },
        {
          sistema: "Plataforma Administrativa v2",
          ultima_checagem: "",
          status: "",
          acessar: "",
          url: "https://adm.dinamicatreinamentos.com",
        },
      ],
      fields_apis: ["sistema", "ultima_checagem", "status"],
      items_apis: [
        {
          sistema: "API Principal",
          ultima_checagem: "",
          status: "",
          url: "https://api.dinamicatreinamentos.com",
        },
        {
          sistema: "API Forms",
          ultima_checagem: "",
          status: "",
          url: "https://api.forms.dnc.group",
        },
        {
          sistema: "API Softcheck",
          ultima_checagem: "",
          status: "",
          url: "https://api.softcheck.dnc.group",
        },
        {
          sistema: "API Checkout",
          ultima_checagem: "",
          status: "",
          url: "https://api.carrinho.dnc.group",
        },
        {
          sistema: "API Administrativa",
          ultima_checagem: "",
          status: "",
          url: "https://api.admin.dnc.group",
        },
      ],
      data_hora: "",
    };
  },
  methods: {
    pega_hora() {
      let hoje = new Date();
      let data_agora =
        (hoje.getDate()<10 ? "0"+hoje.getDate() : hoje.getDate()) + "/" + ((hoje.getMonth() + 1)<10 ? "0"+(hoje.getMonth() + 1) : (hoje.getMonth() + 1)) + "/" + hoje.getFullYear();
      let hora_agora =
        (hoje.getHours()<10 ? "0"+hoje.getHours() : hoje.getHours()) + ":" + (hoje.getMinutes()<10 ? "0"+hoje.getMinutes() : hoje.getMinutes()) + ":" + (hoje.getSeconds()<10 ? "0"+hoje.getSeconds() : hoje.getSeconds());
      let data_hora = data_agora + " " + hora_agora;
      return data_hora;
    },

    async testa_sistemas() {
      let urls = this.items;
      let urls_apis = this.items_apis;
      let dthr = this.pega_hora();
      urls.forEach((item) => {
        item.status = this.checkOnline(
          item.url,
          function () {
            item.status = "Offline";
            item.ultima_checagem = dthr;
			item._rowVariant = "danger";
          },
          function () {
            item.status = "Online";
            item.ultima_checagem = dthr;
            item.acessar = '<a href="' + item.url + '">Acessar</a>';
          }
        );
      });

      urls_apis.forEach((item) => {
        item.status = this.checkOnline(
          item.url,
          function () {
            item.status = "Offline";
            item.ultima_checagem = dthr;
			item._rowVariant = "danger";
          },
          function () {
            item.status = "Online";
            item.ultima_checagem = dthr;
            item.acessar = '<a href="' + item.url + '">Acessar</a>';
          }
        );
      });
    },

    checkOnline(url, error, ok) {
      try {
        var scriptElem = document.createElement("script");
        scriptElem.type = "text/javascript";
        scriptElem.onerror = function () {
          error();
        };
        scriptElem.onload = function () {
          ok();
        };
        scriptElem.src = url;
        document.getElementsByTagName("body")[0].appendChild(scriptElem);
      } catch (err) {
        error(err);
      }
    },
  },
  mounted() {
    this.testa_sistemas();
    //this.checkOnline('http://www.google.com.br', function() {alert('Fora do ar!')}, function(){alert('Online')});
  },
};
</script>

