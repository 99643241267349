import axios from "axios";

var url_atual = window.location.href;
let url = url_atual.split("/");
let base = "";

if (process.env.VUE_APP_ENV == "local") {
  if(['mentoria', 'mentoria-v3','bulk-acessos-cursos', 'plano-estudos', 'gestao-notificacoes', 'gestao-notificacoes#', 'bulk-conteudo', 'bulk-arquivos', 'preview-bulk-conteudo', 'liberacao-slot', 'gestao-de-cursos', 'gestao-spaceids-turmas','gestao-de-versionamento', 'gestao-class'].includes(url[3])) {
    base = "https://api.app.dev/";
  }else if (['comunicados'].includes(url[3])) {
    base = 'http://localhost:5555/'
  }
   else {
    base = "https://dev.api.admin.dnc.group/";
  }
} else if (process.env.VUE_APP_ENV == "homolog") {
  if(['mentoria', 'mentoria-v3','bulk-acessos-cursos', 'plano-estudos', 'gestao-notificacoes', 'gestao-notificacoes#', 'bulk-conteudo', 'bulk-arquivos', 'preview-bulk-conteudo', 'liberacao-slot', 'gestao-de-cursos', 'gestao-spaceids-turmas','gestao-de-versionamento', 'gestao-class'].includes(url[3])) {
    base = process.env.VUE_APP_API_APP_DNC;
  }else if (['comunicados'].includes(url[3])) {
    base = process.env.VUE_APP_API_LMS_NODE
  }
   else {
    base = process.env.VUE_APP_API_ADMIN;
  }
} else {
  if(['mentoria', 'mentoria-v3','bulk-acessos-cursos', 'bulk-conteudo', 'plano-estudos',  'gestao-notificacoes', 'gestao-notificacoes#', 'bulk-arquivos', 'preview-bulk-conteudo', 'liberacao-slot', 'gestao-de-cursos', 'gestao-spaceids-turmas','gestao-de-versionamento', 'gestao-class'].includes(url[3])) {
    base = "https://api.app.dnc.group/";
  }else if (['comunicados'].includes(url[3])) {
    base = 'https://lms.api.app.node.dnc.group/'
  } 
  else {
    base = process.env.VUE_APP_API_ADMIN;
  }
}

const http = axios.create({
  baseURL: base,
});


export default http;
