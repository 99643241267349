<template>
  <div id="rootEmissao">
    <alert v-if="loading" :filas="filas" :porcentagem="porcentagem"> </alert>
    <Menu />
    <Navbar />

    <div class="content-wrapper">

      <!-- familiar nav -->
      <div class="flex-sup familiar-nav" >

          <h3 class="flex-sup"> <small> LMS / Uploads em massa </small> <i class="fas fa-angle-down"></i> </h3>

          <ul class="flex-sup fami-nav-list">

          <!-- variants: isAbaLoading, isActualPlace -->

          <!-- > turmas -->
          <li class="flex-sup isActualPlace"> 
              <a class="flex-sup" href="/bulk-arquivos"> <i class="fas fa-copy"></i> <small> Bulk de arquivos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > grupos -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/bulk-conteudo"> <i class="fas fa-cubes"></i> <small> Bulk de conteúdo </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > treinamentos -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/bulk-acessos-cursos"> <i class="fas fa-clipboard-check"></i> <small> Acesso aos cursos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          </ul>

      </div>

      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Subir arquivos em massa</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Bulk de arquivos</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <div class="container-fluid">
        <div class="card">
          <div class="row">
            <div class="card-body col-sm-4">
              <!-- Date range -->
              <div class="form-group">
                <label>Selecione o curso:</label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div>
                        <b-form-select v-model="curso_id_selecionado">
                          <b-form-select-option v-for="(curso, index) in cursos" :key="index" :value="curso.curso_id">
                            {{curso.curso_titulo}}
                          </b-form-select-option>
                        </b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body col-sm-4" v-if="curso_id_selecionado">
              <!-- Date range -->
              <div class="form-group">
                <label>Selecione a categoria do arquivo:</label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div>
                        <b-form-select v-model="categoria_id_selecionado">
                          <b-form-select-option value="1">
                            Vídeo
                          </b-form-select-option>
                          <b-form-select-option value="2">
                            Arquivo
                          </b-form-select-option>
                          <b-form-select-option value="3">
                            Legendas
                          </b-form-select-option>
                        </b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- upload box -->
            <div class="card-body col-sm-4" v-if="categoria_id_selecionado">
              <div class="form-group">
                <label>Selecionar arquivos:</label>
                <div class="form-group">

                    <!-- upload box -->
                    <div class="flex-sup file-upload-box" v-if="categoria_id_selecionado == 1">

                      <b-form-file
                        v-if="categoria_id_selecionado == 1"
                        multiple
                        placeholder="Arraste o vídeo para cá"
                        drop-placeholder="Jogue aqui!"
                        type="file"
                        v-model="files"
                      ></b-form-file>

                      <div class="flex-sup upload-indiq">
                        <strong> Solte os arquivos em cima  </strong>
                        <i class="fas fa-arrow-up"></i>
                      </div>

                    </div>

                    <b-button class="upload-file-btn" v-if="categoria_id_selecionado == 1" variant="default" @click="envia_videos()">Enviar</b-button>
                    
                    <div class="flex-sup file-upload-box" v-if="categoria_id_selecionado == 2">

                      <b-form-file
                        v-if="categoria_id_selecionado == 2"
                        multiple
                        v-model="files_arquivos"
                        placeholder="Arraste o arquivo para cá"
                        drop-placeholder="Jogue aqui!"
                        type="file"
                      ></b-form-file>

                      <div class="flex-sup upload-indiq">
                        <strong> Solte os arquivos em cima  </strong>
                        <i class="fas fa-arrow-up"></i>
                      </div>

                    </div>

                    <b-button class="upload-file-btn" v-if="categoria_id_selecionado == 2" variant="default" @click="envia_arquivos()">Enviar</b-button>


                    <div class="flex-sup file-upload-box" v-if="categoria_id_selecionado == 3">

                      <b-form-file
                        v-if="categoria_id_selecionado == 3"
                        multiple
                        placeholder="Arraste a legenda para cá"
                        drop-placeholder="Jogue aqui!"
                        type="file"
                        v-model="files_legenda"
                      ></b-form-file>

                      <div class="flex-sup upload-indiq">
                        <strong> Solte os arquivos em cima  </strong>
                        <i class="fas fa-arrow-up"></i>
                      </div>

                    </div>

                    <b-button class="upload-file-btn" v-if="categoria_id_selecionado == 3" variant="default" @click="envia_legendas()">Enviar</b-button>

                </div>
              </div>
            </div>
          </div>
          <div class="row example-line">
            <div class="card-body col-sm-4" v-if="categoria_id_selecionado">
              <b-button class="bs-btn bs-btn-alert" @click.prevent="baixar_planilha()">Baixar Planilha</b-button>
            </div>
          </div>

          <!-- /.card-header -->
          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th v-if="categoria_id_selecionado == 1">Nome do vídeo</th>
                  <th v-if="categoria_id_selecionado == 1">Data</th>
                  <th v-if="categoria_id_selecionado == 1">Status</th>
                  <th v-if="categoria_id_selecionado == 2">Nome do arquivo</th>
                  <th v-if="categoria_id_selecionado == 3">Nome do arquivo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(video, index) in videos" :key="index" v-show="categoria_id_selecionado == 1">
                  <td>{{index+1}}</td>
                  <td><a :href="video.url" target="_blank">{{video.file}}</a></td>
                  <td> {{video.data_criacao }}</td>
                  <td v-if="video.status == 2"> <i class="fas fa-check-circle" style="color:#2ECC71 "></i> </td>
                  <td v-if="video.status < 2" style="color: grey"> processando... </td>
                </tr>
                <tr v-for="(arquivo, index) in arquivos" :key="arquivo.file" v-show="categoria_id_selecionado == 2">
                  <td>{{index+1}}</td>
                  <td><a :href="arquivo.url" target="_blank">{{arquivo.file}}</a></td>
                </tr>
                <tr v-for="(arquivo, index) in arquivos" :key="arquivo.file" v-show="categoria_id_selecionado == 3">
                  <td>{{index+1}}</td>
                  <td><a :href="arquivo.url" target="_blank">{{arquivo.file}}</a></td>
                </tr>
              
              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style>

  .upload-file-btn {margin-top: 13px;}

  .btn {background-color: var(--dnc-mai);}

</style>

<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import Alert from "@/components/Alert.vue";
import axios from "axios";
import { mapState } from "vuex";

export default{
  components: {
    Footer,
    Navbar,
    Menu,
    Alert,
  },
  data() {
    return {
      cursos: [],
      curso_id_selecionado: 0,
      categoria_id_selecionado: 0,
      videos: [],
      arquivos: [],
      files: [],
      files_arquivos: [],
      files_legenda: [],
      arquivos_erro: [],
      videos_finalizados: 0,
      filas: [],
      porcentagem: 0,
    };
  },
  watch: {
    categoria_id_selecionado() {
      if(this.categoria_id_selecionado == 1) {
        this.lista_videos()
      } else if(this.categoria_id_selecionado == 2) {
        this.lista_arquivos()
      } else if(this.categoria_id_selecionado == 3) {
        this.lista_legendas()
      }
    }
  },
  created() {
    this.lista_cursos()
  },
  computed: {
    ...mapState(["loading"]),

    curso_path() {
      return this.cursos.filter((element) => element.curso_id == this.curso_id_selecionado).map((element) => element.curso_path)[0]
    },
    searchDncURL () {
      if (process.env.VUE_APP_ENV === 'development') {
        return 'http://localhost:3000/'
      }

      if (process.env.VUE_APP_ENV === 'homolog') {
        return process.env.VUE_APP_API_APP_DNC
      }

      return process.env.VUE_APP_API_APP_DNC
    }
  },
  methods: {
    createFormData(article) {
      var formData = new FormData();
      article.file.forEach((element, index) => {
        formData.append(`data${index}`, element);
      })
      formData.append('curso_path', this.curso_path)
      return formData;
    },

    async envia_videos() {
      this.$store.commit("CHANGE_LOADING")

      this.videos_finalizados = 0;

      this.filas = this.files

      for(var [index, file] of this.files.entries()) {
        var video_nome = file.name.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '').replaceAll('+', '_').replaceAll('%', '_').replaceAll('&', '_');

        let response = await axios.get('https://igteqb4uxf.execute-api.us-east-1.amazonaws.com/default/getPresignedURL?curso_path='+this.curso_path+'&arquivo_nome='+video_nome)
        
        var uploadURL = JSON.parse(response.data.body).uploadURL

        var config = {
          onUploadProgress: progressEvent => {
            this.filas[index].porcentagem = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          },
          headers: {
            'Content-Type': 'video/mp4'
          },
        }

        try {
          this.filas[index].failed = false
          response = await axios.put(uploadURL, file, config )
            .then(() => {
              axios.post(`${this.searchDncURL}video/aws/converter`, { video_path: 'videos/' + this.curso_path + '/' + video_nome })
            }).catch(() => {
              this.filas[index].failed = true
            })
        } catch(error) {
          this.arquivos_erro.push(file.name);
        }

        if(index == this.files.length - 1) this.$store.commit("CHANGE_STATUS_VIDEO")
      }

      //   await axios.get('https://igteqb4uxf.execute-api.us-east-1.amazonaws.com/default/getPresignedURL?curso_path='+this.curso_path+'&arquivo_nome='+video_nome)
      //     .then((response) => {
      //       const uploadURL = JSON.parse(response.data.body).uploadURL
      //       // 3 - Put pra enviar o arquivo.
      //       axios.put(uploadURL, file, 
      //         { 
      //           headers: { "Content-Type": 'video/mp4' } 
      //         }
      //       ).then(() => {
      //         // 4 - Post na API interna convertendo esse video.
      //         axios.post('https://api.app.dnc.group/video/aws/converter', {
      //           video_path: 'videos/' + this.curso_path + '/' + video_nome
      //         }).finally(() => {
                
      //         })
      //       }).catch(() => {
      //         this.arquivos_erro.push(file.name);
      //       })
      //     }).catch(() => {
      //       alert('Erro na importação')
      //     })
      // }
      
      // this.loading = true

      // let config = {
      //   onUploadProgress: progressEvent => {
      //     this.porcentagem = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      //   },
      //   headers: {
      //       'Content-Type': 'multipart/form-data'
      //   },
      // }

      // var article = {
      //   file: []
      // }

      // if(event.target.files) {
      //   event.target.files.forEach((element) => {
      //     article.file.push(element)
      //   })
      // } else {
      //   event.dataTransfer.files.forEach((element) => {
      //     article.file.push(element)
      //   })
      // }

      // const file = this.createFormData(article)
      
      // await this.$http.post('videos/importar',
      //   file, config
      // ).then(() => {
      //   this.loading = false
      //   this.lista_videos()
      // }).catch(() => {
      //   alert('Erro na importação')
      // })
    },

    async lista_videos() {
      await this.$http.post('videos/aws/listar', {
        curso_path: this.curso_path
      }).then((response) => {
        this.videos = response.data
      })
    },

    async envia_arquivos()  {
      this.$store.commit("CHANGE_LOADING")

      this.filas = this.files_arquivos

      var article = {
        file: []
      }

      for(var [index, file] of this.files_arquivos.entries()) {
        file.name.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '').replaceAll('+', '_').replaceAll('%', '_').replaceAll('&', '_')
        
        let config = {
          onUploadProgress: progressEvent => {
            this.filas[index].porcentagem = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          },
          headers: {
              'Content-Type': 'multipart/form-data'
          },
        }

        article.file.push(file)

        try {
          this.filas[index].failed = false
          await this.$http.post('arquivos/aws/importar', this.createFormData(article), config)
            .then(() => {
              this.lista_arquivos()
            }).catch(() => {
              this.filas[index].failed = true
            })
        } catch(error) {
          this.arquivos_erro.push(file.name);
        }

        if(index == this.files_arquivos.length - 1) this.$store.commit("CHANGE_STATUS_VIDEO")
      }
      
    },

    async envia_legendas()  {
      this.$store.commit("CHANGE_LOADING")

      this.filas = this.files_legenda
      var article = {
        file: []
      }

      for(var [index, file] of this.files_legenda.entries()) {
        file.name.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '').replaceAll('+', '_').replaceAll('%', '_').replaceAll('&', '_')
        
        let config = {
          onUploadProgress: progressEvent => {
            this.filas[index].porcentagem = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          },
          headers: {
              'Content-Type': 'multipart/form-data'
          },
        }

        article.file.push(file)

        try {
          this.filas[index].failed = false
          await this.$http.post('legenda', this.createFormData(article), config)
            .then(() => {
              this.lista_legendas()
            }).catch(() => {
              this.filas[index].failed = true
            })
        } catch(error) {
          this.arquivos_erro.push(file.name);
        }

        if(index == this.files_legenda.length - 1) this.$store.commit("CHANGE_STATUS_VIDEO")
      }
      
      
    },

    async lista_arquivos() {
      await this.$http.post('arquivos/aws/listar', {
        curso_path: this.curso_path
      }).then((response) => {
        this.arquivos = response.data
      })
    },

    async lista_legendas() {
      await this.$http.post('legendas/aws/listar', {
        curso_path: this.curso_path
      }).then((response) => {
        this.arquivos = response.data
      })
    },

    async lista_cursos() {
      await this.$http.get('curso/listar')
        .then((response) => {
          this.cursos = response.data.cursos
        })
    },

    async baixar_planilha() {
      if(this.categoria_id_selecionado == 1) window.location.href = `${this.searchDncURL}videos/aws/exportar/${this.curso_path}`
      else window.location.href = `${this.searchDncURL}arquivos/aws/exportar/${this.curso_path}`
    }
  }
}
</script>


