<template>
    <div id="root">  
        <Preloader v-show="preloader_status" />
        <Menu />
        <Navbar />
        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Perfil do Usuário</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <!--<li class="breadcrumb-item active">Dashboard v2</li>-->
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
                
            </div>     

            <div class="content">
                <div class="d-flex p-3" v-if="loading">
                    <i class="spinner-border text-primary m-auto" ></i><br>
                </div>
                <div class="container-fluid" v-show="!loading">
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Altere aqui os seus dados pessoais!</h3>
                        </div>
                        <!-- /.card-header -->
                        <!-- form start -->
                        <form>
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="exampleInputEmail1">Alterar Foto de Perfil</label>
                                            <div class="flex-sup file-upload-box" style="border:0px ;">
                                                <b-form-file
                                                    multiple
                                                    placeholder="Arraste a Foto aqui"
                                                    drop-placeholder="Jogue aqui!"
                                                    type="file"
                                                    v-model="arquivo"
                                                ></b-form-file>
                                                <div class="flex-sup upload-indiq">
                                                    <strong> Solte os arquivos em cima  </strong>
                                                    <i class="fas fa-arrow-up"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label for="exampleInputEmail1">Nome Completo</label>
                                            <b-form-input type="text" v-model="formUsuario.nome" class="form-control" id="exampleInputEmail1" placeholder="Digite seu nome completo" />
                                        </div>
                                        <div class="col-3">
                                            <label for="exampleInputEmail1">Usuário de Acesso:</label>
                                            <b-form-input type="text" v-model="formUsuario.user" class="form-control" disabled id="exampleInputEmail1" />
                                        </div>
                                    </div>
                                </div>            
                                <div class="form-group">
                                    <div class="row">                                    
                                        <div class="col-4">
                                            <label for="exampleInputEmail1">Email da Empresa</label>
                                            <div class="input-group mb-3">
                                                <b-form-input type="email" v-model="formUsuario.email" class="form-control" id="exampleInputEmail1" placeholder="Digite seu email da dinâmica" />
                                                <div class="input-group-append">
                                                    <span class="input-group-text">@dnc.group</span>
                                                </div>
                                            </div>                                        
                                        </div>
                                        <div class="col-4">
                                            <label for="exampleInputEmail1">Email Gmail da Empresa</label>
                                            <div class="input-group mb-3">
                                                <b-form-input type="email" v-model="formUsuario.email_gmail" class="form-control" id="exampleInputEmail1" placeholder="Digite seu email do gmail" />
                                                <div class="input-group-append">
                                                    <span class="input-group-text">@gmail.com</span>
                                                </div>
                                            </div>                                                                                
                                        </div>
                                        <div class="col-4">
                                            <label for="exampleInputEmail1">Telefone ou Celular:</label>
                                            <b-form-input type="text" class="form-control" v-model="formUsuario.telefone" v-mask="whatsapp_mask" id="exampleInputEmail1" placeholder="Digite de preferência seu celular" />
                                        </div>
                                    </div>
                                </div>            
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-4">
                                            <label for="exampleInputEmail1">CPF</label>
                                            <b-form-input type="text" v-mask="'###.###.###-##'" v-model="formUsuario.cpf" class="form-control" id="exampleInputEmail1" placeholder="Digite o número do seu CPF" />
                                        </div>
                                        <div class="col-4">
                                            <label for="exampleInputEmail1">RG</label>
                                            <b-form-input type="text" v-mask="'##.###.###-##'" v-model="formUsuario.rg" class="form-control" id="exampleInputEmail1" placeholder="Digite o número do seu RG" />
                                        </div>
                                        <div class="col-4">
                                            <label for="exampleInputEmail1">Data de nascimento</label>
                                            <b-form-datepicker
                                                id="example-datepicker"
                                                locale="pt-br"
                                                placeholder="Escolha a sua Data de Nascimento"
                                                :date-format-options="{
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                }"
                                                value-as-date
                                                size="sm"
                                                v-model="formUsuario.dtnasc"
                                                class="mb-2 calendar-box"
                                            ></b-form-datepicker>
                                        </div>
                                    </div>
                                </div>       
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="exampleInputEmail1">CEP</label>
                                            <b-form-input type="text" v-model="formUsuario.cep" v-mask="'#####-###'" class="form-control" id="exampleInputEmail1" placeholder="CEP" @focusout="verifica_cep()" />
                                        </div>
                                        <div class="col-7">
                                            <label for="exampleInputEmail1">Endereço</label>
                                            <b-form-input type="text" v-model="formUsuario.endereco" class="form-control" id="exampleInputEmail1" placeholder="Digite seu Endereço" />
                                        </div>
                                        <div class="col-2">
                                            <label for="exampleInputEmail1">Número:</label>
                                            <b-form-input type="text" v-model="formUsuario.endereco_numero" class="form-control" id="exampleInputEmail1" placeholder="Nº" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="exampleInputEmail1">Squad:</label>
                                            <b-form-select 
                                                id="squad"
                                                v-model="formUsuario.squad"
                                                :options="squads"
                                            ></b-form-select>
                                        </div>
                                        <div class="col-3">
                                            <label for="exampleInputEmail1">Tipo Contrato:</label>
                                            <b-form-select 
                                                id="squad"
                                                v-model="formUsuario.tipo_contrato"
                                                :options="tipos_contrato"
                                            ></b-form-select>
                                        </div>
                                        <div class="col-3">
                                            <label for="exampleInputEmail1">CNPJ ou Documento:</label>
                                            <b-form-input type="text" v-model="formUsuario.documento_contrato" class="form-control" id="exampleInputEmail1" placeholder="" />
                                        </div>
                                        <div class="col-3">
                                            <label for="exampleInputEmail1">Tamanho de Camisa:</label>
                                            <b-form-select 
                                                id="squad"
                                                v-model="formUsuario.tamanho_camisa"
                                                :options="tamanhos"
                                            ></b-form-select>
                                        </div>
                                    </div>
                                </div>     
                                <div class="form-group p-3 bg-warning" style="border:1px solid #c0c0c0;border-radius:5px">    
                                    <h5>Alterar Senha:</h5>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-3">
                                                <label for="exampleInputEmail1">Digite a senha atual:</label>
                                                <b-form-input type="password" v-model="formUsuario.senha" class="form-control" id="exampleInputPassword1" placeholder="Senha Atual" />                                    
                                            </div>
                                            <div class="col-3">
                                                <label for="exampleInputEmail1">Digite a nova senha:</label>
                                                <b-form-input type="password" v-model="formUsuario.nova_senha" class="form-control" id="exampleInputPassword1" placeholder="Nova Senha" />                                    
                                            </div>
                                            <div class="col-3">
                                                <label for="exampleInputEmail1">Repita a nova senha:</label>
                                                <b-form-input type="password" v-model="formUsuario.nova_senha2" class="form-control" id="exampleInputPassword1" placeholder="Repita a Nova Senha" />                                    
                                            </div>
                                        </div>
                                    </div> 
                                </div>                        
                            </div>
                            <!-- /.card-body -->
                            <div class="card-footer">                                
                                <input type="reset" class="btn btn-danger" value="Cancelar">
                                <a href="#" class="btn btn-primary float-right" @click="salvar_alteracoes()">Salvar Alterações</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>       
            
            <!-- /.content-header -->
        </div>
        <!-- /.content-wrapper -->

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
        
        <Footer />

    </div>
</template>



<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

    

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Login from '@/components/Login.vue'
// import store from '@/store'
import Preloader from '@/components/Preloader.vue'
import Navbar from '@/components/Navbar.vue'
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import Vue from "vue"
import VueMask from 'v-mask'
Vue.use(VueMask);


export default {
  name: 'Perfil',
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu
  },
  data(){
        return{
            preloader_status:false,
            loading:false,
            arquivo: [],
            formUsuario: {
                profissional_id: "",                
                nome: "",
                user: "",
                cpf:"",
                rg:"",
                telefone:"",
                dtnasc:"",
                email: "",
                email_gmail: "",
                senha:"",
                nova_senha:"",
                nova_senha2:"",
                squad: "",
                cep:"",
                endereco:"",
                endereco_numero:"",
                tipo_contrato:"",
                documento_contrato:"",
                tamanho_camisa:"",
            },
            squads: [
                { text: "Operações", value: 1 },
                { text: "Direção", value: 2 },
                { text: "Marketing", value: 3 },
                { text: "Vendas", value: 4 },
                { text: "People", value: 5 },
                { text: "Produto", value: 6 },
                { text: "Finanças", value: 8 },
                { text: "CX", value: 9 },
                { text: "Tecnologia", value: 10 },
                { text: "PX", value: 11 },
            ],
            tipos_contrato: [
                { text: "PJ", value: 'PJ' },
                { text: "Estágio", value: 'Estágio' },
                { text: "Outro", value: 'Outro' },                
            ],
            tamanhos: [
                { text: "GG ou XG", value: 'GG ou XG' },
                { text: "XG", value: 'XG' },
                { text: "GG", value: 'GG' },
                { text: "G", value: 'G' },
                { text: "M", value: 'M' },
                { text: "P", value: 'P' },
                { text: "PP", value: 'PP' },
                { text: "PP ou P", value: 'PP ou P' },             
            ],
            
        };
  },  
  computed: {
    whatsapp_mask() {
      if (this.formUsuario.telefone) {
        return this.formUsuario.telefone.length == 15
          ? "(##) #####-#####"
          : "(##) ####-#####";
      } else {
        return "(##) ####-#####";
      }
    },
  },
  created(){
    // this.preloader_status=false;
    let dados_usuario = JSON.parse(sessionStorage.dados_user);
    this.formUsuario.profissional_id = dados_usuario.profissional_id;
    this.formUsuario.user = dados_usuario.profissional_user;
    this.carrega_dados_usuario();
  },
  methods:{
    async verifica_cep() {
      let cep = this.formUsuario.cep.replace("-", "");
      let url = "https://viacep.com.br/ws/" + cep + "/json";
      const response = await this.$http.get(url);
      let viacep = response.data;
      this.formUsuario.endereco = viacep.logradouro +", "+ viacep.localidade + ", " + viacep.uf 
    },

    async salvar_alteracoes(){    
        this.loading=true;    
        let url = "atualiza/perfil/usuario";
        if(this.formUsuario.email != null){
            this.formUsuario.email = this.formUsuario.email.split('@')[0];
        }
        if(this.formUsuario.email_gmail != null){
            this.formUsuario.email_gmail = this.formUsuario.email_gmail.split('@')[0];    
        }

        this.formUsuario.email = this.formUsuario.email + '@dnc.group';
        this.formUsuario.email_gmail = this.formUsuario.email_gmail + '@gmail.com';
        if((this.formUsuario.nova_senha!=null) && (this.formUsuario.nova_senha2!=null) && (this.formUsuario.senha!=null)){
            if(this.formUsuario.nova_senha!=this.formUsuario.nova_senha2){
                this.$bvModal.msgBoxOk("Erro na confirmação de Senha! Verifique se as senhas novas são iguai!", {
                    title: "Erro na Validação de Senha",
                    okVariant: "light",
                    okTitle: "Ok",
                    headerBgVariant: "danger",
                    bodyBgVariant: "danger",
                    footerBgVariant: "danger",
                });
            }
        }
        let formData = new FormData();
            if(this.arquivo!=null){
                formData.append("arquivo", this.arquivo[0]);
            }
            else{
                formData.append("arquivo", null);
            }            
            formData.append("usuario", JSON.stringify(this.formUsuario));
            formData.append("page",this.page);
            formData.append("profissional_id", this.formUsuario.profissional_id);
            formData.append("acao", "alteracao perfil");
        const response = await this.$http.post(url, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        let retorno = response.data;
        if(this.formUsuario.email != null){
            this.formUsuario.email = this.formUsuario.email.split('@')[0];
        }
        if(this.formUsuario.email_gmail != null){
            this.formUsuario.email_gmail = this.formUsuario.email_gmail.split('@')[0];    
        }
        if(retorno.erro==0){
            this.$bvModal.msgBoxOk(retorno.mensagem, {
                title: "Atualização de Dados do Usuário",
                okVariant: "light",
                okTitle: "Ok",
                headerBgVariant: "success",
                bodyBgVariant: "success",
                footerBgVariant: "success",
            });
        }else{
            this.$bvModal.msgBoxOk(retorno.mensagem, {
                title: "Atualização de Dados do Usuário",
                okVariant: "light",
                okTitle: "Ok",
                headerBgVariant: "danger",
                bodyBgVariant: "danger",
                footerBgVariant: "dange",
            });
        }
        
        this.loading=false;    
    },

    async carrega_dados_usuario() {  
        this.loading=true;        
        let url = "lista/usuario/" + this.formUsuario.profissional_id;
        const response = await this.$http.get(url);
        let dados = response.data.usuario;
        this.formUsuario.nome = dados.profissional_nome;
        this.formUsuario.cpf = dados.profissional_cpf;
        this.formUsuario.rg = dados.profissional_rg;
        this.formUsuario.dtnasc = dados.profissional_dt_nasc;
        if(this.formUsuario.email != null){
            this.formUsuario.email = dados.profissional_email.split('@')[0];
        }
        if(this.formUsuario.email_gmail != null){
            this.formUsuario.email_gmail = dados.profissional_email_gmail.split('@')[0];     
        }
        this.formUsuario.cep = dados.profissional_cep;
        if(this.formUsuario.cep!=null){
            this.verifica_cep();
        }
        this.formUsuario.endereco_numero = dados.profissional_end_numero;
        this.formUsuario.squad = dados.squad_id;
        this.formUsuario.tipo_contrato = dados.profissional_tipo_contrato;
        this.formUsuario.documento_contrato = dados.profissional_documento_pj;
        this.formUsuario.tamanho_camisa = dados.profissional_tamanho_camisa;
        if(this.formUsuario.telefone != null){
            this.formUsuario.telefone = dados.profissional_telefone.split(" ").join("");
        }
        
        this.loading=false;    
    },
  }  
}

</script>



