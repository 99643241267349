<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2 page-cabecalho">
            <div class="col-sm-6">
              <h1 class="m-0">
                Emissão de Notas em Massa
                <i class="spinner-border text-primary" v-show="loading"></i>
              </h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6 breadcrumb-wpp">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Emissão em Massa</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="card">
        <div class="row p-3 align-items-end">
          <!-- TIPO DE PESQUISA -->
          <div class=" col-md-4">
            <label style="color: red">Tipo de Pesquisa:</label>
            <b-form-select
              v-model="select_pesquisa"
              :options="options_pesquisa"
            ></b-form-select>
          </div>
          <div class="col-md-8 "  >
            <button style="float:right;" 
              class="btn btn-lg bs-btn bs-btn-gen btn-success"
              :disabled="isDisabledExecutar"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              @click="alerta_geracao_notas()"
            >
              <span class="d-flex align-items-center"
                >Gerar Notas
                <span class="material-icons nav-icon"> play_arrow </span>
              </span>
            </button>
          </div>
        </div>
        
        <div class="row align-items-end">    
        <!-- PERIODO -->
          <div class="card-body col-md-4" v-if="
              select_pesquisa == 'periodo' ||
              select_pesquisa == 'periodo_status' ||
              select_pesquisa == 'periodo_agenda_status'
            ">     
              <!-- Date range -->
              <label>Escolha o Período a ser Analisado:</label>
              <div class="row d-flex align-items-end from-linha">
                <div class="col-md-6">
                  <label style="color: red" for="datepicker-invalid"
                    >*Início:</label
                  >
                  <b-form-datepicker
                    id="example-datepicker"
                    locale="pt-br"
                    placeholder="Escolha o Início"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    value-as-date
                    size="sm"
                    v-model="date_start"
                    class="mb-2 calendar-box"
                  ></b-form-datepicker>
                </div>
                <div class="col-md-6">
                  <label style="color: red" for="datepicker-invalid"
                    >*Fim:</label
                  >
                  <b-form-datepicker
                    id="example-datepicker2"
                    locale="pt-br"
                    placeholder="Escolha o Final"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    value-as-date
                    size="sm"
                    v-model="date_end"
                    class="mb-2 calendar-box"
                  ></b-form-datepicker>
                </div>
                <!-- <div class="col">
                  <label >Filtrar por Categoria:</label>
                  <b-form-select v-model="select_treinamento_categoria" :options="options_tipo"></b-form-select>
                </div> -->
              </div>

          </div>

        <!-- STATUS -->
          <div class="card-body col-md-2" v-if="
              select_pesquisa == 'agenda_status' ||
              select_pesquisa == 'periodo_status' ||
              select_pesquisa == 'periodo_agenda_status'
            ">
            <br>
            <label>Status:</label>
            <b-form-select
              v-model="select_pagamento"
              :options="options_pagamento"
            ></b-form-select>
          </div>

        <!-- AGENDA -->
          <div class="card-body col-md-4" v-if="
              select_pesquisa == 'agenda_status' ||
              select_pesquisa == 'periodo_agenda_status'
            ">
            <br /><label>Filtro por Agenda:</label>
            <b-form-select
              v-model="select_agenda_id"
              :options="options_cursos"
            ></b-form-select>
          </div>

          <!-- <div class="row">
              <div class="col-md-2">
                  <br /><button
                    class="btn bs-btn bs-btn-filter btn-primary"
                    @click="lista_carrinhos_pagos()"
                  >
                    <i class="fas fa-filter"></i>Filtrar por Agenda
                  </button>
                </div>
            </div> -->

        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-2">
              <button
                class="btn btn-primary"
                type="button"
                @click="lista_carrinhos_pagos()"
              >
              <span class="d-flex bs-btn align-items-center"
                  ><i class="fas fa-filter"></i> &nbsp;Filtrar Dados
              </span>                
              </button>
            </div>
            <div class="col-2">
              <button
                class="btn btn-success"
                type="button"
                @click="selecionar_todos()"
              >
                <span class="d-flex bs-btn align-items-center"
                  >Selecionar Todos
                </span>
              </button>
            </div>
            <div class="col-2">
              <button
                class="btn btn-danger"
                type="button"
                @click="deselecionar_todos()"
              >
                <span class="d-flex bs-btn align-items-center"
                  >Desmarcar Todos
                </span>
              </button>
            </div>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body">
          <table id="example1" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Status Nota</th>
                <th>Carrinho ID</th>
                <th>RID</th>
                <th>Nome</th>
                <th>CPF</th>
                <th>Compra</th>
                <th>Tag</th>
                <th>Valor</th>
                <th>Status</th>
                <th>Forma Pagamento</th>
                <th>Data Compra</th>
                <th>ID Pagamento (Pagarme/Provi)</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<style scoped>
.form-control-sm {
  background: #fff !important;
}

.bs-btn-filter i {
  margin-right: 8px;
}

.bs-btn-gen {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-content: center;
}
.bs-btn-gen span.material-icons {
  transform: translateY(2px);
}
</style>

<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

function controleCarrinho(marcado, carrinho_id) {
  window.elemento_vue.controleCarrinho(marcado, carrinho_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
var dados_checks = new Array();
window.dados_checks = dados_checks;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";


export default {
  name: "EmissaoNotaFiscal",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      dados_checks: [],
      user: {},
      preloader_status: true,
      loading: false,
      page: "",
      date_start: "",
      date_end: "",
      /*********Financeiro */
      resposta: [],
      dados_carrinhos_pagos: [],
      dados_compras_pagas: [],
      intervalo: "",
      carSelect: [],
      carrinhos_id: [],
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      select_agenda_id: null,
      checks_confirmacao: [],
      options_cursos: [{ value: null, text: "Escolha um Curso" }],
      // select_treinamento_categoria:null,
      // options_tipo:[
      //   {value: null, text:'Escolha uma Categoria'},
      //   {value: '1', text:'Online'},
      //   {value: '2', text:'Certificações'},
      //   {value: '3', text:'Produto Físico'},
      //   {value: '4', text:'Híbrido'},
      // ],
      select_pagamento: "paid",
      options_pagamento: [
        { value: "paid", text: "Pago" },
        { value: "refused", text: "Recusado" },
        { value: "refunded", text: "Estornado" },
        { value: "waiting_payment", text: "Aguardando Pagamento" },
      ],
      select_pesquisa: "periodo",
      options_pesquisa: [
        { value: "periodo", text: "Periodo" },
        { value: "agenda_status", text: "Agenda Id e Status" },
        { value: "periodo_agenda_status", text: "Periodo, Agenda ID e Status" },
        { value: "periodo_status", text: "Periodo e Status" },
      ],
    };
  },
  created() {
    this.preloader_status = false;
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {
    controleCarrinho(marcado, carrinho_id) {
      if (marcado) {
        if (!this.dados_checks.find((element) => element == carrinho_id)) {
          this.dados_checks.push(carrinho_id);
        }
      } else {
        let indice = this.dados_checks.indexOf(carrinho_id);
        this.dados_checks.splice(indice, 1);
      }

      this.checks_confirmacao = [];
      this.dados_checks.forEach((carrinho_id) => {
        this.dados_carrinhos_pagos.forEach((carrinho_id_pagos) => {
          if (carrinho_id == carrinho_id_pagos.carrinho_id) {
            this.checks_confirmacao.push(carrinho_id);
          }
        });
      });

      this.dados_checks = this.checks_confirmacao;
      console.log(this.checks_confirmacao);
    },
    async alerta_geracao_notas() {
      this.$bvModal
        .msgBoxConfirm(
          "Você deseja realmente executar a geração das notas fiscais dos carrinhos selecionados?",
          {
            title: "Geração de Notas Fiscais",
            okVariant: "warning",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "light",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          }
        )
        .then((value) => {
          if (value == true) {
            this.gerar_notas();
          } else {
            this.$bvModal.msgBoxOk("As Notas NÃO foram geradas!", {
              title: "Geração de Notas Fiscais",
              okVariant: "light",
              okTitle: "Ok",
              headerBgVariant: "success",
              bodyBgVariant: "success",
              footerBgVariant: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async gerar_notas() {
      console.log(this.dados_checks);
      if (this.dados_checks.length > 0) {
        this.carSelect = this.dados_checks;
        let carrinhos = new Array();
        this.carSelect.forEach((carrinho_id) => {
          this.dados_carrinhos_pagos.forEach((dados) => {
            if (dados.carrinho_id == carrinho_id) {
              carrinhos.push({
                carrinho_id: carrinho_id,
                aluno_id: dados.aluno_id,
                aluno_cidade: dados.aluno_cidade,
                aluno_endereco: dados.aluno_endereco,
                aluno_numero: dados.aluno_numero,
                aluno_bairro: dados.aluno_bairro,
                aluno_cep: dados.aluno_cep,
                aluno_nome: dados.aluno_nome,
                aluno_email: dados.aluno_email,
                aluno_cpf: dados.aluno_cpf,
                aluno_telefone: dados.aluno_telefone,
                carrinho_idpagarme: dados.carrinho_idpagarme,
                carrinho_data: dados.carrinho_data,
                provi_id: dados.provi_id,
                carrinho_valor: dados.carrinho_valor.replace(/[^0-9,]+/g, ""),
              });
            }
          });
        });

        this.carrinhos_id = JSON.stringify(carrinhos);

        let url = "gerar/notas/massa";
        var formData = new FormData();
        formData.append("carrinhos_id", this.carrinhos_id);
        formData.append("id_profissional", this.user.dados.profissional_id);
        await this.$http.post(url, formData);
        this.$bvModal.msgBoxOk(
          "Notas Geradas e Enviadas com Sucesso para o eNotas!",
          {
            title: "Geração de Notas Fiscais",
            okVariant: "light",
            okTitle: "Ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          }
        );
        this.lista_carrinhos_pagos();
      } else {
        alert("Selecione algum carrinho para emitir notas.");
      }
    },

    async lista_carrinhos_pagos() {
      this.dados_checks = [];
      this.checks_confirmacao = [];
      let start = this.date_start.toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let end = this.date_end.toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      this.habilita_botoes();
      var data_1;
      var data_2;
      this.loading = true;

      if (start == undefined && end == undefined) {
        // Obtém a data/hora atual
        let data = new Date();

        // Guarda cada pedaço em uma letiável
        let dia = data.getDate(); // 1-31
        let mes = data.getMonth(); // 0-11 (zero=janeiro)
        let ano = data.getFullYear(); // 2 dígitos

        data_2 = dia + "/" + mes + " / " + ano;
        if (mes == 1) {
          mes = 12;
        } else {
          mes = mes - 1;
        }
        data_1 = "01/" + mes + "/" + ano;
      } else {
        data_1 = start;
        data_2 = end;
      }
      this.intervalo = data_1 + " - " + data_2;

      let url = "lista/carrinhos/status";
      // var formData = new FormData();
      // formData.append("status", "paid");
      // formData.append("data1", data_1);
      // formData.append("data2", data_2);
      this.loading = true;      
      const response = await this.$http.post(url, {
        tipo_pesquisa: this.select_pesquisa, 
        status: this.select_pagamento,
        agenda_id: this.select_agenda_id,
        data1: data_1,
        data2: data_2,
      });
      this.loading = false;
      this.resposta = response.data.carrinhos;
      this.dados_carrinhos_pagos = response.data;
      // this.dados_carrinhos_pagos = response.data.carrinhos;
      this.dados_compras_pagas = response.data.compras;

      let data_table = new Array();
      let carrinho_anterior = "";
      this.dados_carrinhos_pagos.forEach((dados_carrinho) => {
        // Formatando ID de pagamento
        let pagto;
        if (dados_carrinho.carrinho_forma == 3) {
          pagto = dados_carrinho.provi_id;
        } else {
          if (
            dados_carrinho.carrinho_forma == 2 ||
            dados_carrinho.carrinho_forma == 1
          ) {
            pagto = dados_carrinho.carrinho_idpagarme;
          } else {
            pagto = " ";
          }
        }

        // Formatando Forma de pagamento
        if (dados_carrinho.carrinho_forma == 1) {
          dados_carrinho.carrinho_forma =
            "<i class='far fa-credit-card'></i> Cartão ";
        }
        if (dados_carrinho.carrinho_forma == 2) {
          dados_carrinho.carrinho_forma =
            "<i class='fas fa-file-invoice'></i> Boleto Pagar.me ";
        }
        if (dados_carrinho.carrinho_forma == 3) {
          dados_carrinho.carrinho_forma =
            "<i class='fas fa-file-signature'></i> Boleto Provi ";
        }
        if (dados_carrinho.carrinho_forma == 4) {
          dados_carrinho.carrinho_forma = "<i class='fas fa-qrcode'></i> PIX ";
        }
        if (dados_carrinho.carrinho_forma == "") {
          dados_carrinho.carrinho_forma = null;
        }

        // Formatando Valor de Pagamento
        let dinheiro = dados_carrinho.carrinho_valor;
        dados_carrinho.carrinho_valor = Intl.NumberFormat("pt-br", {
          style: "currency",
          currency: "BRL",
        }).format(dinheiro);

        let caixa_selecao;
        let status_notafiscal;
        let status_cor;
        let status_pagamento;

        if (dados_carrinho.carrinho_status == "paid") {
          status_pagamento =
            "<i class='fas fa-grin-hearts fa-2x' style='color:#fbd000'> </i> <b style='color:#fbd000'>Pago</b> ";
          if (dados_carrinho.carrinho_status_notafiscal == "Gerada") {
            status_notafiscal =
              "<a href='https://app.enotas.com.br/venda#c=edit&a=" +
              dados_carrinho.enotas_venda_id +
              "' target='_blank'><i class='fas fa-print text-primary'></i><b class='text-primary'> " +
              dados_carrinho.enotas_venda_id +
              "</b></a> ";
            status_cor = "text-primary";

            if (carrinho_anterior != dados_carrinho.carrinho_id) {
              caixa_selecao =
                "<div class='form-check'><input id = " +
                dados_carrinho.carrinho_id +
                " class='form-check-input check-box' v-if='adicionar === false' onchange='dados_checks[" +
                dados_carrinho.carrinho_id +
                "]=this.checked;' disabled value='" +
                dados_carrinho.carrinho_id +
                "' type='checkbox'></div >";
            } else {
              caixa_selecao = "";
            }
          } else {
            if (dados_carrinho.carrinho_status_notafiscal == "Falha") {
              status_notafiscal =
                "<i class='fas fa-exclamation text-danger'></i> <b class='text-danger'> Falha </b> ";
              status_cor = "text-danger text-uppercase";

              if (carrinho_anterior != dados_carrinho.carrinho_id) {
                caixa_selecao =
                  "<div class='form-check'><input id = " +
                  dados_carrinho.carrinho_id +
                  " class='form-check-input check-box' v-if='adicionar === false' onchange='dados_checks[" +
                  dados_carrinho.carrinho_id +
                  "]=this.checked;'  value='" +
                  dados_carrinho.carrinho_id +
                  "' type='checkbox'></div >";
              } else {
                caixa_selecao = "";
              }
            } else {
              status_notafiscal =
                "<i class='fas fa-file text-secondary'></i><b class='text-secondary'> Não Gerada </b>";
              status_cor = "text-secondary";
              if (carrinho_anterior != dados_carrinho.carrinho_id) {
                caixa_selecao =
                  "<div class='form-check'><input id = " +
                  dados_carrinho.carrinho_id +
                  " class='form-check-input check-box' v-if='adicionar === false' onchange='dados_checks[" +
                  dados_carrinho.carrinho_id +
                  "]=this.checked'  value='" +
                  dados_carrinho.carrinho_id +
                  "' type='checkbox'></div >";
              } else {
                caixa_selecao = "";
              }
            }
          }
          carrinho_anterior = dados_carrinho.carrinho_id;
        } else {
          if (dados_carrinho.carrinho_status == "waiting_payment") {
            status_pagamento =
              "<i class='fas fa-meh fa-2x' style='color:orange'></i> Aguardando Pagamento";
          }
          if (dados_carrinho.carrinho_status == "refused") {
            status_pagamento =
              "<i class='fas fa-angry fa-2x' style='color:red'></i> Pagamento recusado";
          }
          if (dados_carrinho.carrinho_status == "refunded") {
            status_pagamento =
              "<i class='fas fa-frown-open fa-2x' style='color:gray'></i> Estornado";
          }
          status_notafiscal = "<i class='far fa-frown'></i>";
          caixa_selecao = "<i class='far fa-frown'></i>";
        }

        data_table.push([
          "<span class=" + status_cor + ">" + caixa_selecao + "</span>",
          status_notafiscal,
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_id +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.aluno_id +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.aluno_nome +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.aluno_cpf +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.treinamento_nome +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            (dados_carrinho.compra_tag == null
              ? " <i class='far fa-frown'></i> "
              : dados_carrinho.compra_tag) +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_valor +
            "</span>",
          "<span class=" + status_cor + ">" + status_pagamento + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_forma +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_data +
            "</span>",
          "<span class=" + status_cor + ">" + pagto + "</span>",
        ]);
      });
      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: true,
          autoWidth: false,
          buttons: [            
            {
              extend: 'copy',
              text: 'Copiar',
              className:'btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio',
            },
            {
              extend: 'csv',
              text: 'CSV',
              className:'btn btn-secondary buttons-csv buttons-html5',
            },
            {
              extend: 'excel',
              text: 'Excel',
              className:'btn btn-secondary buttons-excel buttons-html5',
            },
            {
              extend: 'pdf',
              text: 'PDF',
              className:'btn btn-secondary buttons-pdf buttons-html5',
            },
            {
              extend: 'print',
              text: 'Imprimir',
              className:'btn btn-secondary buttons-print buttons-html5',
            },
            {
              extend: 'colvis',
              text: 'Exibir Colunas',
              className:'btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim',              
            }
          ],
          drawCallback: function () {
            $(".check-box").on("click", function (event) {
              controleCarrinho(this.checked, event.target.id);
            });
          },
          bLengthChange: true,
          bPaginate: true,
          pageLength: 10,
          bInfo: true,
          language: {
            sEmptyTable: "Nenhum registro encontrado",
            sInfo: "Mostrando de _START_ at&eacute; _END_ de _TOTAL_ registros",
            sInfoEmpty: "Mostrando 0 at&eacute; 0 de 0 registros",
            sInfoFiltered: "(Filtrados de MAX registros)",
            sInfoPostFix: "",
            sInfoThousands: "",
            sLengthMenu: "_MENU_ resultados por p&aacute;gina",
            sLoadingRecords: "Carregando...",
            sProcessing: "Processando...",
            sZeroRecords: "Nenhum registro encontrado",
            sSearch: "Pesquisar",
            sSearchPlaceholder: "qualquer dado..",
            oPaginate: {
              sNext: "Pr&oacute;ximo",
              sPrevious: "Anterior",
              sFirst: "Primeiro",
              sLast: "&Uacute;ltimo",
            },
            oAria: {
              sSortAscending: ": Ordenar colunas de forma ascendente",
              sSortDescending: ": Ordenar colunas de forma descendente",
            },
          },
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
      this.loading = false;
    },

    async lista_cursos() {
      this.loading = true;
      let url = "lista/opcoes/compras";
      const response = await this.$http.get(url);
      this.loading = false;
      let dados_cursos = response.data.agendas;
      let cursos = new Array();
      cursos.push({
        value: null,
        text: "Escolha um Curso pela Agenda Id Antigo",
      });
      dados_cursos.forEach((agendas) => {
        cursos.push({
          value: agendas.agenda_id,
          text:
            agendas.agenda_id +
            " - " +
            agendas.treinamento_nome +
            " - Início em: " +
            agendas.data_inicio +
            "",
        });
      });
      this.options_cursos = cursos;
    },

    habilita_botoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (
          this.user.permissoes[item]["privilegio_tipo_menu"] == "financeiro"
        ) {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }

        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }

      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },
    selecionar_todos() {
      $(".form-check-input").prop("checked", true);
      var mvar = [];
      $(".form-check-input").each(function () {
        mvar.push($(this).val());
      });
      console.log(mvar);
      this.dados_checks = mvar;
      mvar = [];
      console.log(this.dados_checks);
    },
    deselecionar_todos() {
      $(".form-check-input").prop("checked", false);
      console.log(this.dados_checks);
      this.dados_checks = [];
      this.checks_confirmacao = [];
    },
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    window.elemento_vue = this;
    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);

    this.lista_carrinhos_pagos();
    this.lista_cursos();
  },
};
</script>


