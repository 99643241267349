<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Emissão de Notas em Massa</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Emissão em Massa</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="card">
        <div class="row">
          <div class="card-body col-sm-6">
            <!-- Date range -->
            <div class="form-group">
              <label>Escolha o Periodo a ser Analisado:</label>
              <div class="form-group">
                <div class="row">
                  <div class="col-4">
                    <label for="datepicker-invalid">Início:</label>
                    <b-form-datepicker
                      id="example-datepicker"
                      locale="pt-br"
                      placeholder="Escolha o Início"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      value-as-date
                      size="sm"
                      v-model="date_start"
                      class="mb-2"
                    ></b-form-datepicker>
                  </div>
                  <div class="col-4">
                    <label for="datepicker-invalid">Fim:</label>
                    <b-form-datepicker
                      id="example-datepicker2"
                      locale="pt-br"
                      placeholder="Escolha o Final"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      value-as-date
                      size="sm"
                      v-model="date_end"
                      class="mb-2"
                    ></b-form-datepicker>
                  </div>
                  <div class="col-4">
                    <br /><button
                      class="btn btn-primary"
                      @click="lista_carrinhos_pagos()"
                    >
                      <i class="fas fa-filter"></i>Filtrar
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="far fa-calendar-alt"></i>
                  </span>
                </div>
                
                <input
                  type="text"
                  class="form-control"
                  v-model="intervalo"
                  id="reservation"                 
                  @change="lista_carrinhos_pagos()"
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  class="spinner-border text-primary"
                  role="status"
                  v-show="loading"
                ></div>
              </div>-->

              <!-- /.input group -->
            </div>
          </div>
          <div class="card-body col-sm-6">
            <div class="input-group justify-content-md-end">
              <button
                class="btn btn-lg btn-success"
                :disabled="isDisabledExecutar"
                data-toggle="modal"
                data-target="#modal-success"
                type="button"
                @click="alerta_geracao_notas()"
              >
                <span>Gerar Notas <i class="fas fa-play"></i></span>
              </button>
            </div>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body">
          <table id="example1" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Status Nota</th>
                <th>Carrinho ID</th>
                <th>RID</th>
                <th>Nome</th>
                <th>CPF</th>
                <th>Valor</th>
                <th>Forma Pagamento</th>
                <th>Data Compra</th>
                <th>ID Pagamento (Pagarme/Provi)</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;


import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";


export default{
  name:"EmissaoNotaFiscal",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      preloader_status: true,
      loading: false,
      page: "",
      date_start: "",
      date_end: "",
      /*********Financeiro */
      resposta: [],
      dados_carrinhos_pagos: [],
      dados_compras_pagas: [],
      intervalo: "",
      carSelect: [],
      carrinhos_id: [],
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
    };
  },
  created() {
    this.preloader_status = false;
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {
    async alerta_geracao_notas() {
      this.$bvModal
        .msgBoxConfirm(
          "Você deseja realmente executar a geração das notas fiscais dos carrinhos selecionados?",
          {
            title: "Geração de Notas Fiscais",
            okVariant: "warning",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "light",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          }
        )
        .then((value) => {
          if (value == true) {
            this.gerar_notas();
          } else {
            this.$bvModal.msgBoxOk("As Notas NÃO foram geradas!", {
              title: "Geração de Notas Fiscais",
              okVariant: "light",
              okTitle: "Ok",
              headerBgVariant: "success",
              bodyBgVariant: "success",
              footerBgVariant: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async gerar_notas() {
      if (dados_checks.length > 0) {
        this.carSelect = dados_checks;
        var cont = 0;
        var carrinhos = new Array();
        var carrinhos_pagos = new Array();

        carrinhos_pagos = this.dados_carrinhos_pagos;
        this.carSelect.forEach(funcao);        
        // eslint-disable-next-line no-inner-declarations
        function funcao(item, index) {          
          if (item == true) {
           carrinhos_pagos.forEach((dados) => {
              if (dados.carrinho_id == index) {
                carrinhos[cont] = {
                  carrinho_id: index,
                  aluno_id: dados.aluno_id,
                  aluno_cidade: dados.aluno_cidade,
                  aluno_endereco: dados.aluno_endereco,
                  aluno_numero: dados.aluno_numero,
                  aluno_bairro: dados.aluno_bairro,
                  aluno_cep: dados.aluno_cep,
                  aluno_nome: dados.aluno_nome,
                  aluno_email: dados.aluno_email,
                  aluno_cpf: dados.aluno_cpf,
                  aluno_telefone: dados.aluno_telefone,
                  carrinho_idpagarme: dados.carrinho_idpagarme,
                  carrinho_data: dados.carrinho_data,
                  provi_id: dados.provi_id,
                  carrinho_valor: dados.carrinho_valor.replace(/[^0-9,]+/g, ""),
                };
              }
            });
            cont++;
          }
        }
        this.carrinhos_id = JSON.stringify(carrinhos);
        let url = "gerar/notas/massa";
        var formData = new FormData();
        formData.append("carrinhos_id", this.carrinhos_id);
        formData.append("id_profissional", this.dados_user.profissional_id);
        await this.$http.post(url, formData);        
        this.$bvModal.msgBoxOk("Notas Geradas e Enviadas com Sucesso para o eNotas!", {
              title: "Geração de Notas Fiscais",
              okVariant: "light",
              okTitle: "Ok",
              headerBgVariant: "success",
              bodyBgVariant: "success",
              footerBgVariant: "success",
            });
        this.lista_carrinhos_pagos();
      } else {
        alert("Selecione algum carrinho para emitir notas.");
      }
    },

    async lista_carrinhos_pagos() {
      let start = this.date_start.toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let end = this.date_end.toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      this.habilita_botoes();
      var data_1;
      var data_2;
      this.loading = true;

      if (start == undefined && end == undefined) {
        // Obtém a data/hora atual
        let data = new Date();

        // Guarda cada pedaço em uma letiável
        let dia = data.getDate(); // 1-31
        let mes = data.getMonth(); // 0-11 (zero=janeiro)
        let ano = data.getFullYear(); // 2 dígitos

        data_2 = dia + "/" + mes + " / " + ano;
        if (mes == 1) {
          mes = 12;
        } else {
          mes = mes - 1;
        }
        data_1 = "01/" + mes + "/" + ano;
      } else {
        data_1 = start;
        data_2 = end;
      }
      this.intervalo = data_1 + " - " + data_2;

      let url = "lista/carrinhos/status";
      var formData = new FormData();
      formData.append("status", "paid");
      formData.append("data1", data_1);
      formData.append("data2", data_2);
      const response = await this.$http.get(url, {
        params: {
          status: "paid",
          data1: data_1,
          data2: data_2,
        },
      });
      this.resposta = response.data.carrinhos;

      this.dados_carrinhos_pagos = response.data.carrinhos;
      this.dados_compras_pagas = response.data.compras;

      let data_table = new Array();
      this.dados_carrinhos_pagos.forEach((dados_carrinho) => {
        // Formatando ID de pagamento
        let pagto;
        if (dados_carrinho.carrinho_forma == 3) {
          pagto = dados_carrinho.provi_id;
        } else {
          if (
            dados_carrinho.carrinho_forma == 2 ||
            dados_carrinho.carrinho_forma == 1
          ) {
            pagto = dados_carrinho.carrinho_idpagarme;
          } else {
            pagto = " ";
          }
        }

        // Formatando Forma de pagamento
        if (dados_carrinho.carrinho_forma == 1) {
          dados_carrinho.carrinho_forma =
            "<i class='far fa-credit-card'></i> Cartão ";
        }
        if (dados_carrinho.carrinho_forma == 2) {
          dados_carrinho.carrinho_forma =
            "<i class='fas fa-file-invoice'></i> Boleto Pagar.me ";
        }
        if (dados_carrinho.carrinho_forma == 3) {
          dados_carrinho.carrinho_forma =
            "<i class='fas fa-file-signature'></i> Boleto Provi ";
        }
        if (dados_carrinho.carrinho_forma == 4) {
          dados_carrinho.carrinho_forma = "<i class='fas fa-qrcode'></i> PIX ";
        }
        if (dados_carrinho.carrinho_forma == "") {
          dados_carrinho.carrinho_forma = null;
        }

        // Formatando Valor de Pagamento
        let dinheiro = dados_carrinho.carrinho_valor;
        dados_carrinho.carrinho_valor = Intl.NumberFormat("pt-br", {
          style: "currency",
          currency: "BRL",
        }).format(dinheiro);

        let caixa_selecao;
        let status_notafiscal;
        let status_cor;
        if (dados_carrinho.carrinho_status_notafiscal == "Gerada") {
          status_notafiscal =
            "<a href='https://app.enotas.com.br/venda#c=edit&a=" +
            dados_carrinho.enotas_venda_id +
            "' target='_blank'><i class='fas fa-print text-primary'></i><b class='text-primary'> " +
            dados_carrinho.enotas_venda_id +
            "</b></a> ";
          status_cor = "text-primary";

          caixa_selecao =
            "<div class='form-check'><input class='form-check-input' v-if='adicionar === false' onchange='dados_checks[" +
            dados_carrinho.carrinho_id +
            "]=this.checked;' disabled  value='" +
            dados_carrinho.carrinho_id +
            "' type='checkbox'></div >";
        } else {
          if (dados_carrinho.carrinho_status_notafiscal == "Falha") {
            status_notafiscal =
              "<i class='fas fa-exclamation text-danger'></i> <b class='text-danger'> Falha </b> ";
            status_cor = "text-danger text-uppercase";

            caixa_selecao =
              "<div class='form-check'><input class='form-check-input' v-if='adicionar === false' onchange='dados_checks[" +
              dados_carrinho.carrinho_id +
              "]=this.checked;'  value='" +
              dados_carrinho.carrinho_id +
              "' type='checkbox'></div >";
          } else {
            status_notafiscal =
              "<i class='fas fa-file text-secondary'></i><b class='text-secondary'> Não Gerada </b>";
            status_cor = "text-secondary";

            caixa_selecao =
              "<div class='form-check'><input class='form-check-input' v-if='adicionar === false' onchange='dados_checks[" +
              dados_carrinho.carrinho_id +
              "]=this.checked;'  value='" +
              dados_carrinho.carrinho_id +
              "' type='checkbox'></div >";
          }
        }

        data_table.push([
          "<span class=" + status_cor + ">" + caixa_selecao + "</span>",
          status_notafiscal,
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_id +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.aluno_id +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.aluno_nome +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.aluno_cpf +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_valor +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_forma +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_carrinho.carrinho_data +
            "</span>",
          "<span class=" + status_cor + ">" + pagto + "</span>",
        ]);
      });
      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
      this.loading = false;
    },

    habilita_botoes() {
      let privilegio = this.dados_user.privilegio_financeiro;
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    this.dados_user = JSON.parse(sessionStorage.dados_user);

    this.lista_carrinhos_pagos();
  },
};
</script>


