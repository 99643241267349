<template>
    <!-- Main Footer -->
    <footer class="main-footer" id="footer_root">
        <strong>Copyright &copy; 2020-2021 <a href="https://dnc.group">DNC Group</a>.</strong>
        All rights reserved.
        <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.11.0.21
        </div>
    </footer>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Login from '@/components/Login.vue'
// import store from '@/store'


export default {
  name: 'Footer',
  components: {
    // HelloWorld,
    // Login
  },
  data(){
      return{
        
      }
  },  
  
}

</script>
