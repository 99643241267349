<template>
  <div id="root">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />

    <div class="content-wrapper" v-show="!habilitar_pagina">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Usuário sem permissão de acesso!</h1>
            </div>
            <div class="col-sm-12">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Alunos</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODALS -->
      <b-modal :size="modalTamanho" id="modal-aluno" hide-footer>
        <template #modal-title> {{ formTitulo }} {{ alunoNome }} </template>
        <div v-show="modal_aluno">
          <b-form>
            <div class="row">
              <b-form-input
                v-show="false"
                id="rid"
                v-model="formAluno.id"
                trim
              ></b-form-input>
              <div class="col-sm-4">
                <b-form-group id="input-1" label="Nome" label-for="name">
                  <b-form-input
                    id="name"
                    placeholder="Nome completo"
                    v-model="formAluno.nome"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormAluno.nome
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-4">
                <b-form-group
                  id="input-2"
                  label="E-mail de acesso"
                  label-for="email"
                >
                  <b-form-input
                    type="email"
                    id="email"
                    placeholder="examplo@gmail.com"
                    v-model="formAluno.email"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormAluno.email
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-4">
                <b-form-group id="input-3" label="Telefone" label-for="telefone">
                  <b-form-input
                    id="telefone"
                    placeholder="ex.: (12) 99658-6518"
                    v-model="formAluno.telefone"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormAluno.telefone
                  }}</label>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <b-form-group id="input-4" label="CPF" label-for="cpf">
                  <b-form-input
                    id="cpf"
                    v-mask="'###.###.###-##'"
                    placeholder="000.000.000-00"
                    v-model="formAluno.cpf"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormAluno.cpf
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-4">
                <b-form-group id="input-5" label="CEP" label-for="cep">
                  <b-form-input
                    id="cep"
                    maxlength="9"
                    v-mask="'#####-###'"
                    placeholder="ex.: 12954-731"
                    @change="buscaCep()"
                    v-model="formAluno.cep"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormAluno.cep
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-4">
                <b-form-group id="input-6" label="Cidade" label-for="cidade">
                  <b-form-input
                    id="cidade"
                    placeholder="ex.: São José dos Campos"
                    v-model="formAluno.cidade"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <b-form-group id="input-7" label="Estado" label-for="estado">
                  <b-form-input
                    id="estado"
                    placeholder="SP, MG, RJ..."
                    v-model="formAluno.estado"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-5">
                <b-form-group id="input-8" label="Endereço" label-for="endereco">
                  <b-form-input
                    id="endereco"
                    placeholder="ex.: Rua Justino Cobra"
                    v-model="formAluno.endereco"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group id="input-9" label="Numero" label-for="numero">
                  <b-form-input
                    id="numero"
                    placeholder="ex.: 61"
                    v-model="formAluno.numero"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormAluno.numero
                  }}</label>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <b-form-group id="input-10" label="Bairro" label-for="bairro">
                  <b-form-input
                    id="bairro"
                    placeholder="ex.: Vila Ema"
                    v-model="formAluno.bairro"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <b-form-group
                  id="input-11"
                  label="Complemento"
                  label-for="complemento"
                >
                  <b-form-input
                    id="complemento"
                    placeholder="APTO ou Sala"
                    v-model="formAluno.complemento"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="spinner-border text-primary"
                role="status"
                v-show="loadingAluno"
              ></div>
            </div>
            <div class="input-group justify-content-md-end">
              <b-button
                @click="limparFormAluno()"
                id="btn-limpa-form"
                variant="danger"
                >Limpar formulário</b-button
              >
              <b-button
                class="mr-1"
                @click="enviarFormAluno()"
                id="btn-cadastro"
                :variant="varianteSubmit"
                >{{ botaoSubmitAluno }}</b-button
              >
            </div>
          </b-form>
        </div>

        <div v-show="modal_compras">
          <b-form>
            <div class="row">
              <div class="col-sm-2">
                <b-form-group label="Nome" label-for="nome">
                  <b-form-input
                    v-model="carrinho.aluno_nome"
                    readonly
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-1">
                <b-form-group label="RID" label-for="rid">
                  <b-form-input
                    readonly
                    v-model="carrinho.aluno_id"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group id="input-16" label="Status" label-for="status">
                  <b-form-select
                    id="status"
                    v-model="carrinho.status"
                    :options="statusCompra"
                  ></b-form-select>
                  <label class="text-danger small">{{
                    errosFormCarrinho.status
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group label="Pagamento" label-for="pagamento">
                  <b-form-select
                    name="pagamento"
                    v-model="carrinho.pagamento"
                    :options="pagamentoCompra"
                  ></b-form-select>
                  <label class="text-danger small">{{
                    errosFormCarrinho.pagamento
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group id="input-17" label="Pagar me" label-for="pagar-me">
                  <b-form-input
                    id="pagar-me"
                    v-model="carrinho.pagarme"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group label="Provi" label-for="Provi">
                  <b-form-input
                    id="Provi"
                    v-model="carrinho.provi"
                    trim
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-1">
                <b-form-group id="input-17" label="Total" label-for="total">
                  <b-form-input
                    id="total"
                    type="number"
                    v-model="carrinho.total"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormCarrinho.total
                  }}</label>
                </b-form-group>
              </div>
            </div>
            <hr />
            <div
              class="row"
              v-for="(compra, index) in carrinho.compras"
              :key="index"
            >
              <div class="col-sm-6">
                <b-form-group id="input-12" label="agenda" label-for="id-agenda">
                  <b-form-select
                    v-model="compra.agenda_id"
                    :options="comprasOpcoes.agendas"
                  ></b-form-select>
                  <label class="text-danger small">{{
                    errosFormCarrinho.compras[index].agenda
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group id="input-14" label="Valor" label-for="valor">
                  <b-form-input
                    @change="atualizaTotal()"
                    placeholder="EX: 999,99"
                    type="number"
                    v-model="compra.valor"
                    trim
                  ></b-form-input>
                  <label class="text-danger small">{{
                    errosFormCarrinho.compras[index].valor
                  }}</label>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group id="input-15" label="Cupom" label-for="cupom">
                  <b-form-select
                    v-model="compra.cupom"
                    :options="comprasOpcoes.cupons"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-sm-2">
                <b-form-group id="input-18" label="Tag" label-for="tag">
                  <b-form-input v-model="compra.tag" trim></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="spinner-border text-primary"
                role="status"
                v-show="loadingCompra"
              ></div>
            </div>
            <div class="row">
              <div class="input-group justify-content-md-end">
                <b-button
                  class="mr-1"
                  @click="confirmaCompras()"
                  id="btn-compra"
                  variant="primary"
                  :disabled="loadingCompra"
                  >{{ botaoSubmitCompra }}</b-button
                >
                <b-button
                  class="mr-1"
                  @click="adicionarLinhaCompra()"
                  id="btn-adicionar"
                  variant="warning"
                  :disabled="loadingCompra"
                  >{{ botaoInserirCompra }}</b-button
                >
                <b-button
                  class="mr-1"
                  @click="removerLinhaCompra()"
                  id="btn-remover"
                  variant="danger"
                  :disabled="loadingCompra"
                  >{{ botaoRemoverCompra }}</b-button
                >
              </div>
            </div>
          </b-form>
        </div>
      </b-modal>

      <b-modal size="xl" id="modal-aluno-lote" hide-footer>
        <template #modal-title> Visualização </template>
        <div>
          <b-table striped hover :items="cadastroAlunoLote.dados"></b-table>
        </div>
        <div>
          <div v-show="this.erroCadastroAlunoLote">
            <label>Legenda:</label>
            <br />
            <label class="text-warning small"
              >Quantidade Duplicados: {{ qtd_duplicado }}</label
            >
            <br />
            <label class="text-danger small">Emails Duplicados:</label>
            <ul>
              <li v-for="email in email_duplicado" :key="email">
                {{ email }}
              </li>
            </ul>
            <br />
            <label class="text-warning small">CPF Duplicados:</label>
            <ul>
              <li v-for="cpf in cpf_duplicado" :key="cpf">
                {{ cpf }}
              </li>
            </ul>
          </div>
          <div class="row justify-content-center">
            <div
              class="spinner-border text-primary"
              role="status"
              v-show="loadingArquivo"
            ></div>
          </div>
        </div>
        <div class="input-group justify-content-md-end">
          <b-button
            :disabled="loadingArquivo"
            class="mr-1"
            @click="confirmarEnvioLote()"
            variant="primary"
            >Confirmar</b-button
          >
        </div>
      </b-modal>

      <b-modal size="xl" id="modal_compras"
      @hidden="ocultaEdicaoCompra()"
      >
        <template #modal-title>Compras de {{ nome_aluno }}</template>
        <div class="container w-100 text-center" v-show="exibicaoLoadingCompras">
          <i class="spinner-border text-primary"></i>
        </div>
        <a name="#edicao" id="edicao_compras"></a>
        <div class="overflow-auto" v-show="exibicaoCompras">
          <!-- EDICAO DE COMPRAS -->
          
          <div class="card bg-light" v-show="edicaoCompra">
            <div class="card-body">
            
              <h5 class="card-title text-bold">
                Edição de Compra
              </h5>
              <b-form class="card-text">                     
                <div class="row">
                    <div class="col-sm-3">
                      <b-form-group id="input-12" label="Carrinho ID" label-for="id-agenda">
                        <b-form-input
                          v-model="form_edicao_compra.carrinho_id"
                          readonly
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-sm-3">
                      <b-form-group id="input-12" label="Treinamento" label-for="id-agenda">
                        <b-form-select
                          v-model="form_edicao_compra.agenda_id"
                          :options="comprasOpcoes.agendas"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div class="col-sm-3">
                      <b-form-group id="input-12" label="Valor Compra" label-for="altera_valor_compra">
                        <b-input-group prepend="R$" id="input-11" >
                          <b-form-input
                            type="number"
                            id="altera_valor_compra"                    
                            v-model="form_edicao_compra.valor_compra"
                            trim
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col-sm-3">
                      <b-form-group id="input-12" label="Total Carrinho" label-for="altera_valor_carrinho">
                        <b-input-group prepend="R$" id="input-11" >
                          <b-form-input                         
                            type="number"
                            id="altera_valor_carrinho"                    
                            v-model="form_edicao_compra.valor_carrinho"
                            trim
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </div>
                </div>  
                <div class="row">     
                  <div class="col-sm-6">                  
                    <b-form-group label="Status" label-for="altera_status_pagamento">
                      <b-form-select 
                        id="altera_status_pagamento"
                        @click.prevent
                        :options="form_edicao_compra.status_pagamento_valores"
                        v-model="form_edicao_compra.status_pagamento">
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6">                
                    <b-form-group label="Forma de Pagamento" label-for="altera_forma_pagamento">
                      <b-form-select 
                        id="altera_forma_pagamento"
                        @click.prevent
                        :options="form_edicao_compra.forma_pagamento_valores"                      
                        v-model="form_edicao_compra.forma_pagamento">
                      </b-form-select>
                    </b-form-group>
                  </div>                           
                </div>                  
                  <div class="input-group justify-content-md-end">                  
                    <b-button
                      class="mr-1" 
                      @click="ocultaEdicaoCompra()"
                      id="btn-cancela-edicao-compra"
                      variant="danger"
                      >Cancelar</b-button
                    > 
                    <b-button    
                      class="mr-1"                
                      @click="atualizaEdicaoCompra()"
                      id="btn-atualiza-compra"
                      variant="primary"
                      >Atualizar</b-button
                    >                                    
                  </div>
                <p></p>                
              </b-form>
            </div>
          </div>

          <table
            id="tabela_compras_aluno"
            class="table table-bordered table-hover"
            style="font-size:10pt"
          >
            <thead>
              <tr>
                <th>Ações</th> 
                <th>Data</th>
                <th>Treinamento</th>
                <th>Carrinho ID</th>
                <th>Carrinho Valor</th>
                <th>Compra ID</th>
                <th>Compra valor</th>                
                <th>Status</th>
                <th>Forma de pagamento</th>        
              </tr>
            </thead>
            <tbody>
              <tr v-for="compra in aluno_compras_arr" :key="compra.compra_id">
                <th><button class='btn btn-warning btn-sm mr-1 open-aluno' title='Editar aluno' id ='' type='button' @click="showEdicaoCompra(compra.compra_id)">
                      <i id = "" class = 'fas fa-edit'></i>
                    </button>
                </th>
                <th>{{ compra.data.split(" ")[0] }} <br> {{ compra.data.split(" ")[1] }}</th>
                <th>{{ compra.treinamento }}</th>
                <th>{{ compra.carrinho_id }}</th>
                <th>R$ {{ compra.carrinho_valor }}</th>
                <th>{{ compra.compra_id }}</th>
                <th>R$ {{ compra.compra_valor }}</th>                
                <th class="text-center"><i v-bind:class="[compra.carrinho_status_icon]" v-bind:title="[compra.carrinho_status]"></i></th>
                <th>{{ compra.carrinho_forma }}</th>             
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>

      <b-modal size="sm" id="modal_sem_compras">
        <div class="overflow-auto text-center">Aluno sem compras!</div>
      </b-modal>

      <b-modal size="xl" id="modal_acesso_massa">
        <template #modal-title>
          Fornecer Acesso em Masssa
        </template>
        <b-card bg-variant="light">
          <label>Preencha o modelo e faça o donwload em XLSX para fazer o upload:</label>
          <div class="row">                        
            <div class="col-4">
                <a
                class="btn btn-primary"
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/1r1UnZM6x0Jx_PQ3bHuao61QbyRQJswY3Pci8-snVHGQ/edit?usp=sharing"
              >Acessar Exemplo <i class="fas fa-download"></i></a>
            </div>
            <div class="col-8">
              <b-form-file
                v-model="file_acesso_massa"
                :state="Boolean(file_acesso_massa)"
                placeholder="Escolha ou arraste um arquivo"
                @change="le_arquivo($event)"
              ></b-form-file>
            </div>                                                
          </div>  
          <div class="row">
            <div class="col-12 mt-1 text-right">
              <button
                class="btn btn-warning"
                @click="file_acesso_massa = []; dados_acesso=[]"                
              >
                Limpar
              </button>
            </div>   
          </div>
          
          <div class="row mt-3" >
            <div class="col-12 mt-1 text-center" v-show="loadingAcesso">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p>Carregando Planilha...</p>
            </div>
            <div class="col-12 mt-1 text-center" v-show="comprasSucesso">              
              <p>{{comprasSucesso}}</p>
            </div>
            <table class=" table table-hover dataTable dtr-inline" v-show="dados_acesso.length>0">
              <thead>
                <tr>
                  <th>Status Aluno</th>
                  <th>Aluno</th>
                  <th>Curso</th>
                  <th>Valor</th>
                  <th>Tipo de Pagamento</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dado,index) in dados_acesso" :key="index"> 
                  <td class="text-center">
                    <span class="material-icons text-green" v-if="dado.carrinho.sera_cadastrado==0" data-toggle="tooltip" data-placement="top" title="Aluno Cadastrado">
                      done
                    </span>
                    <span class="material-icons text-red" data-toggle="tooltip" data-placement="top" title="Aluno não cadastrado" v-else>
                      person_add
                    </span>
                  </td>
                  <td>{{dado.carrinho.aluno_id ? dado.carrinho.aluno_id+'-' :'' }} {{dado.carrinho.aluno_nome}} {{dado.carrinho.aluno_cpf}} {{dado.carrinho.aluno_email}}</td>
                  <td>{{dado.carrinho.compras[0].agenda_id}} - {{dado.carrinho.compras[0].treinamento}}</td>
                  <td>R$ {{dado.carrinho.compras[0].valor}}</td>
                  <td>{{dado.carrinho.pagamento_forma}}</td>
                </tr>
              </tbody>              
            </table>

          </div>
          <div class="row"> 
            <div class="col-12 mt-1 text-center">
              <button style="background-color:orange !important"
                class="btn btn-lg btn-warning mr-3"
                v-on:click="enviarArquivoAcessoMassa()"
                :disabled="loadingUpload"
                v-show="dados_acesso.length>0"
              >
                Realizar Acessos
              </button>
            </div>
          </div>
        </b-card>
      </b-modal>
    <!-- FIM MODALS -->

    <div class="content-wrapper" v-show="habilitar_pagina">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gerenciamento de Alunos
                <i class="spinner-border text-primary" v-show="loadingDatatable"></i>
              </h1>
            </div>

            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Alunos</li>
              </ol>
            </div>
          </div>          
        </div>
      </div>

      <div class="content" v-show="habilitar_pagina">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Tela de Gerenciamento e edicão de dados dos alunos.
              </h3>
            </div>

            <div class="card-body col-sm-12" v-show="!loadingDatatable && btn_executar">
              <div class="form-group">

                <div class="row">
                  <div class="col-md-6 col-sm-6 mb-1">
                      <!-- <b-button
                        class="btn btn-lg bs-btn btn-success mr-3"
                        data-toggle="modal"
                        data-target="#modal-success"
                        type="button"
                        @click="cadastraAluno()"
                      >Cadastro Aluno <i class="fas fa-plus"></i>
                      </b-button> -->

                      <b-button
                        class="btn btn-lg bs-btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-success"
                        type="button"
                        @click="acessoMassa()"
                      >Acesso em Massa <i class="fas fa-upload"></i>
                      </b-button>
                  </div>
                  <!-- <div class="col-md-6 col-sm-12">
                    <b-card bg-variant="light">
                      <label>Inserir Alunos em massa:</label>
                      <div class="row">                        
                        <div class="col-4">
                            <a
                            class="btn btn-primary"
                            target="_blank"
                            href="https://api.dinamicatreinamentos.com/_uploads/_arquivo/bulk%20alunos.xlsx"
                          >Baixar Exemplo <i class="fas fa-download"></i></a>
                        </div>
                        <div class="col-8">
                          <b-form-file
                            v-model="file"
                            :state="Boolean(file)"
                            placeholder="Escolha ou arraste um arquivo"
                          ></b-form-file>
                        </div>                                                
                      </div>  
                      <div class="row">
                        <div class="col-12 mt-1 text-right">
                          <button
                            class="btn btn-success"
                            v-on:click="enviarArquivo()"
                            :disabled="loadingUpload"
                          >
                            Enviar Arquivo
                          </button>
                        </div>   
                      </div>     
                    </b-card>        
                  </div> -->
                </div>

                <div class="row">
                  <div class="col-sm-3">
                    Aluno ID
                    <b-form-input type="text" v-model="pesquisa_id"></b-form-input>
                    <b-button class="btn btn-success" @click.prevent="pesquisa_aluno(1)">Pesquisar</b-button>
                  </div>
                  <div class="col-sm-3">
                    CPF
                    <b-form-input v-mask="'###.###.###-##'" type="text" v-model="pesquisa_cpf"></b-form-input>
                    <b-button class="btn btn-success" @click.prevent="pesquisa_aluno(2)">Pesquisar</b-button>
                  </div>
                  <div class="col-sm-3">
                    Email
                    <b-form-input type="text" v-model="pesquisa_email"></b-form-input>
                    <b-button class="btn btn-success" @click.prevent="pesquisa_aluno(3)">Pesquisar</b-button>
                  </div>
                  <div class="col-sm-3">
                    Nome
                    <b-form-input type="text" v-model="pesquisa_nome"></b-form-input>
                    <b-button class="btn btn-success" @click.prevent="pesquisa_aluno(4)">Pesquisar</b-button>
                  </div>
                  <div class="col-sm-3">
                    Telefone
                    <b-form-input type="text" v-model="pesquisa_telefone"></b-form-input>
                    <b-button class="btn btn-success" @click.prevent="pesquisa_aluno(5)">Pesquisar</b-button>
                  </div>
                </div>

                <div class="row" v-show="loadingUpload">
                  <i class="spinner-border text-primary"></i>
                </div>
              </div>


              <div class="table-responsive" >
                <table id="tableAlunos" class="table table-bordered table-hover" style="font-size:12pt">
                  <thead>
                    <tr>
                      <th>Ações</th>
                      <th>RID</th>
                      <th>Nome</th>
                      <th>CPF</th>
                      <th>Email</th>
                      <th>Telefone</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Footer />
  </div>
</template>

<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

function abreModalEdicao(aluno_id) {
  window.elemento_vue.editaAluno(aluno_id);
}

function abreModalCompras(dados_aluno) {
  window.elemento_vue.novaCompra(dados_aluno);
}

function abreModalComprasAluno(aluno_id) {
  window.elemento_vue.aluno_compras(aluno_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Aluno",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user: {},
      exibicaoCompras:true,
      exibicaoLoadingCompras:false,
      form_edicao_compra: {
        agenda_id:"",
        profissional_id:"",
        carrinho_id:"",
        valor_compra:0,
        compra_id:"",        
        forma_pagamento:"",
        forma_pagamento_valores:[],
        status_pagamento:"",
        status_pagamento_valores:[
          {text:"Paid", value: "paid"},
          {text:"Waiting Payment", value: "waiting_payment"},
          {text:"Refused", value: "refused"},
          {text:"Refunded", value: "refunded"}
        ]
      },
      habilitar_pagina: false,
      btn_executar: false,
      btn_editar: false,
      btn_excluir: false,
      loadingDatatable: true,
      loadingAluno: false,
      loadingArquivo: false,
      loadingUpload: false,
      loadingCompra: false,
      preloader_status: true,
      qtd_duplicado: 0,
      cpf_duplicado: [],
      email_duplicado: [],
      file: [],
      alunoNome: "",
      page: "Aluno",
      dados_alunos: [],
      count: 0,
      erroCadastroAlunoLote: false,
      edicaoCompra: false,
      cadastroAlunoLote: {
        dados: [],
      },
      formAluno: {
        id: "",
        nome: "",
        email: "",
        telefone: "",
        cpf: "",
        cep: "",
        cidade: "",
        estado: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
      },

      carrinho: {
        aluno_nome: "",
        aluno_id: "",
        status: "",
        pagamento: "",
        pagarme: "",
        provi: "",
        compras: [],
        total: "",
      },

      comprasOpcoes: {
        agendas: [],
        cupons: [{ text: "Selecione", value: "" }],
      },
      teste: [{ text: "cupom", value: "paid" }],
      statusCompra: [
        { text: "Paid", value: "paid" },
        { text: "Refused", value: "refused" },
        { text: "Refunded", value: "refunded" },
      ],
      pagamentoCompra: [
        { text: "Cartão", value: 1 },
        { text: "Boleto", value: 2 },
        { text: "Provi", value: 3 },
        { text: "Pix", value: 4 },
        { text: "Revelo", value: 5 },
      ],
      pesquisa_cpf: null,
      pesquisa_id: null,
      pesquisa_email: null,
      pesquisa_nome: null,
      pesquisa_telefone: null,
      errosFormAluno: {
        nome: "",
        email: "",
        cpf: "",
        cep: "",
        numero: "",
        telefone: "",
      },
      errosFormCarrinho: {
        status: "",
        pagamento: "",
        total: "",
        compras: [],
      },
      formTitulo: "",
      botaoSubmitAluno: "",
      botaoSubmitCompra: "Finalizar compras",
      botaoInserirCompra: "Adicionar linha",
      botaoRemoverCompra: "Remover linha",
      varianteSubmit: "",
      acao: "",
      modalTamanho: "lg",
      modal_aluno: true,
      modal_compras: false,
      aluno_compras_arr: [],
      nome_aluno: "",
      file_acesso_massa:[],
      dados_acesso:[],
      loadingAcesso: false,
      comprasSucesso: ""
    }
  },
  created() {
    this.preloader_status = false;
  },

  computed: {
    alunos_para_cadastrar() {
      return this.cadastroAlunoLote.dados
        .filter((element) =>
          !this.cpf_duplicado.includes(element.cpf) ? element : null
        )
        .filter((element) =>
          !this.email_duplicado.includes(element.email) ? element : null
        );
    },
  },

  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    async enviarArquivo() {
      this.loadingUpload = true;
      let formData = new FormData();
      formData.append("arquivo", this.file);

      let url = "valida/cadastro/alunos";
      let retorno;
      const response = await this.$http.post(url, formData);
      retorno = response.data;

      this.popularTabela(retorno);
    },

    async enviarArquivoAcessoMassa(){ 
      this.loadingAcesso = true;
      let formData = new FormData();
      formData.append("file", this.file_acesso_massa);      

      let url = "fornecer/acesso/massa";
      await this.$http.post(url, formData, {headers: {
          "Content-Type": "multipart/form-data",
      }})
      .then((response) => {
          this.comprasSucesso = response.data.mensagem
          this.dados_acesso = []
      })
      .catch((e)=> {
          console.log(e)
          alert("Falha ao fornecer acesso")
      })

      this.loadingAcesso = false      
    },

    async le_arquivo(event){
      this.loadingAcesso = true
      let formData = new FormData();
      formData.append("file", event.target.files ? event.target.files[0] : event.dataTransfer.files[0])
      let url = "ler/acesso/massa"
      let retorno;
      
      await this.$http.post(url, formData, {headers: {
          "Content-Type": "multipart/form-data",
        }})
        .then((response) => {
          retorno = response.data
          this.dados_acesso = retorno
        })
        .catch((e)=> {
          console.log(e)
          alert("Erro ao ler o Arquivo. Verifique se tudo está devidamente correto.")
        })
      this.loadingAcesso = false
    },

    popularTabela(retorno) {
      this.cadastroAlunoLote.dados = [];
      this.erroCadastroAlunoLote = false;
      this.qtd_duplicado = retorno.qtd_duplicado;
      this.email_duplicado = retorno.email_duplicado;
      this.cpf_duplicado = retorno.cpf_duplicado;
      retorno.dados.forEach((dado, index) => {
        this.criaLinhaTable(retorno.erros[index]);
        this.cadastroAlunoLote.dados[index].nome = dado.nome;
        this.cadastroAlunoLote.dados[index].cpf = dado.cpf;
        this.cadastroAlunoLote.dados[index].telefone = dado.telefone;
        this.cadastroAlunoLote.dados[index].email = dado.email;
        this.cadastroAlunoLote.dados[index].cep = dado.cep;
        this.cadastroAlunoLote.dados[index].estado = dado.estado;
        this.cadastroAlunoLote.dados[index].cidade = dado.cidade;
        this.cadastroAlunoLote.dados[index].bairro = dado.bairro;
        this.cadastroAlunoLote.dados[index].logradouro = dado.endereco;
        this.cadastroAlunoLote.dados[index].numero = dado.numero;
        this.cadastroAlunoLote.dados[index].complemento = dado.complemento;
      });

      this.$bvModal.show("modal-aluno-lote");
      this.loadingUpload = false;
    },

    criaLinhaTable(erros) {
      let variantes = {
        email: "",
        cpf: "",
        cep: "",
      };
      if (erros.email) {
        variantes.email = "danger";
        this.erroCadastroAlunoLote = true;
      }
      if (erros.email_existe) {
        variantes.email = "danger";
        this.erroCadastroAlunoLote = true;
      }
      if (erros.cpf_invalido) {
        variantes.cpf = "danger";
        this.erroCadastroAlunoLote = true;
      } else if (erros.cpf_existe) {
        variantes.cpf = "warning";
        this.erroCadastroAlunoLote = true;
      }
      if (erros.cep) {
        variantes.cep = "danger";
        this.erroCadastroAlunoLote = true;
      }
      this.cadastroAlunoLote.dados.push({
        nome: "",
        cpf: "",
        telefone: "",
        email: "",
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
        _cellVariants: {
          email: variantes.email,
          cpf: variantes.cpf,
          cep: variantes.cep,
        },
      });
    },

    confirmarEnvioLote() {
      // if (this.erroCadastroAlunoLote) {
      //   this.$bvModal.msgBoxOk("Corrija os erros antes de continuar!", {
      //     title: "Aviso!",
      //     okVariant: "light",
      //     okTitle: "ok",
      //     headerBgVariant: "danger",
      //     bodyBgVariant: "danger",
      //     footerBgVariant: "danger",
      //   });
      // } else {
      this.$bvModal
        .msgBoxConfirm(
          "Você deseja realmente cadastrar todos os alunos informados?",
          {
            title: "Cadastro em lote",
            okVariant: "primary",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "light",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          }
        )
        .then((value) => {
          if (value == true) {
            this.cadatraAlunoLote();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    },

    async cadatraAlunoLote() {
      this.loadingArquivo = true;
      let retorno;
      try {
        let url = "cadastra/alunos";
        const response = await this.$http.post(url, {
          alunos: this.alunos_para_cadastrar,
          page: this.page,
          profissional_id: this.user.dados.profissional_id,
          acao: "cadastro de aluno em lote",
        });
        retorno = response.data;
        if (!retorno.erro) {
          this.$bvModal.hide("modal-aluno-lote");
          this.$bvModal.msgBoxOk("Alunos cadastrados com sucesso!", {
            title: "Cadastro de aluno em lote",
            okVariant: "light",
            okTitle: "Ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          });
          this.listaAlunos();
        }
      } catch (err) {
        let msg = "Erro ao cadastrar os alunos!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
      this.loadingArquivo = false;
    },

    atualizaTotal() {
      let total = 0;
      this.carrinho.compras.forEach((compra) => {
        total = total + parseFloat(compra.valor.replace(",", "."));
      });
      this.carrinho.total = parseFloat(total.toFixed(2));
    },

    cadastraAluno() {
      this.alunoNome = "";
      this.limparFormAluno();
      this.formAluno.id = "";
      this.formTitulo = "Novo Aluno";
      this.botaoSubmitAluno = "Cadastrar";
      this.varianteSubmit = "primary";
      this.acao = "novo";
      this.modalTamanho = "lg";
      this.modal_aluno = true;
      this.modal_compras = false;
      this.carrinho.compras = [];
      this.carrinho.total = "";
      this.errosFormAluno = {
        nome: "",
        email: "",
        cpf: "",
        cep: "",
        numero: "",
      };
      this.$bvModal.show("modal-aluno");
    },

    acessoMassa(){
      this.$bvModal.show("modal_acesso_massa");
    },

    async editaAluno(aluno_id) {
      this.limparFormAluno();
      this.formTitulo = aluno_id;
      this.formAluno.id = aluno_id;
      this.botaoSubmitAluno = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";

      let url = "lista/aluno/" + aluno_id;

      try {
        const response = await this.$http.get(url);
        let dados_aluno = response.data;
        this.formAluno.nome = dados_aluno.aluno_nome;
        this.alunoNome = " - " + dados_aluno.aluno_nome;
        this.formAluno.email = dados_aluno.aluno_email;
        this.formAluno.telefone = dados_aluno.aluno_telefone;
        this.formAluno.cpf = dados_aluno.aluno_cpf;
        this.formAluno.cep = dados_aluno.aluno_cep;
        this.formAluno.cidade = dados_aluno.aluno_cidade;
        this.formAluno.estado = dados_aluno.aluno_estado;
        this.formAluno.endereco = dados_aluno.aluno_endereco;
        this.formAluno.bairro = dados_aluno.aluno_bairro;
        this.formAluno.numero = dados_aluno.aluno_numero;
        this.formAluno.complemento = dados_aluno.aluno_complemento;
        this.modal_compras = false;
        this.modal_aluno = true;
        this.$bvModal.show("modal-aluno");
      } catch (err) {
        let msg = "Erro ao buscar dados do aluno!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
    },

    async enviarFormAluno() {
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let botaoLimpaForm = document.querySelector("#btn-limpa-form");
      let erros = false;
      botaoCadastro.disabled = true;
      botaoLimpaForm.disabled = true;

      if (!this.formAluno.nome) {
        this.errosFormAluno.nome = "O nome é obrigatório!";
        erros = true;
      } else {
        this.errosFormAluno.nome = "";
      }

      if (this.formAluno.cpf && !this.$cpf(this.formAluno.cpf)) {
        this.errosFormAluno.cpf = "O CPF é inválido!";
        erros = true;
      } else {
        this.errosFormAluno.cpf = "";
      }

      if(this.formAluno.cep) {
        if (
          (!this.formAluno.estado && this.formAluno.cep) ||
          (this.formAluno.cep &&
            this.formAluno.cep.length != 9 &&
            this.formAluno.cep.length != 8)
        ) {
          this.errosFormAluno.cep = "O CEP é inválido!";
          erros = true;
        } else {
          this.errosFormAluno.cep = "";
        }
      } else {
        this.errosFormAluno.cep = "";
      }


      if (!this.formAluno.numero && this.formAluno.cep) {
        this.errosFormAluno.numero = "O número é obrigatório!";
        erros = true;
      } else {
        this.errosFormAluno.numero = "";
      }

      if (!this.validateEmail(this.formAluno.email)) {
        this.errosFormAluno.email = "E-mail inválido!";
        erros = true;
      } else {
        this.errosFormAluno.email = "";
      }

      if(this.formAluno.telefone) {
        if (this.formAluno.telefone.length < 10) {
          this.errosFormAluno.telefone =
            "Tamanho inválido! (lembre de informar o DDD)";
          erros = true;
        } else {
          this.errosFormAluno.telefone = "";
        }
      } else {
        this.errosFormAluno.telefone = "";
      }

      if (!erros) {
        this.loadingAluno = true;
        try {
          let retorno;

          if (this.acao == "novo") {
            let url = "cadastra/aluno";
            const response = await this.$http.post(url, {
              aluno: {
                nome: this.formAluno.nome,
                email: this.formAluno.email,
                telefone: this.formAluno.telefone,
                cpf: this.formAluno.cpf,
                cep: this.formAluno.cep,
                cidade: this.formAluno.cidade,
                estado: this.formAluno.estado,
                endereco: this.formAluno.endereco,
                numero: this.formAluno.numero,
                bairro: this.formAluno.bairro,
                complemento: this.formAluno.complemento,
              },
              page: this.page,
              profissional_id: this.user.dados.profissional_id,
              acao: "cadastrar aluno",
            });
            retorno = response.data;
          } else if (this.acao == "editar") {
            let url = "atualiza/aluno";
            const response = await this.$http.patch(url, {
              aluno: {
                id: this.formAluno.id,
                nome: this.formAluno.nome,
                email: this.formAluno.email,
                telefone: this.formAluno.telefone,
                cpf: this.formAluno.cpf,
                cep: this.formAluno.cep,
                cidade: this.formAluno.cidade,
                estado: this.formAluno.estado,
                endereco: this.formAluno.endereco,
                numero: this.formAluno.numero,
                bairro: this.formAluno.bairro,
                complemento: this.formAluno.complemento,
              },
              page: this.page,
              profissional_id: this.user.dados.profissional_id,
              acao: "editar aluno",
            });
            retorno = response.data;
          }

          if (retorno.erro) {
            this.$bvModal.msgBoxOk(retorno.erro, {
              title: "Aviso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            });
          } else {
            this.$bvModal.msgBoxOk(retorno.mensagem, {
              title: "Sucesso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "success",
              bodyBgVariant: "success",
              footerBgVariant: "success",
            });
            this.alunoNome = " - " + this.formAluno.nome;
            if (this.acao == "novo") {
              this.carrinho.aluno_id = retorno.aluno_id;
              this.carrinho.aluno_nome = retorno.aluno_nome;
              this.modal_aluno = false;
              this.modal_compras = true;
              this.formTitulo = "Compras";
              this.modalTamanho = "xl";
              this.adicionarLinhaCompra();
            }
            this.listaAlunos();
          }
        } catch (err) {
          let msg;
          if (this.acao == "novo") {
            msg = "Um erro aconteceu ao tentar cadastrar o aluno!";
          } else if (this.acao == "editar") {
            msg = "Um erro aconteceu ao tentar atualizar o aluno!";
          }
          this.$bvModal.msgBoxOk(msg, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          }).then(value => {
            this.boxOne = value
          })
          .catch(err => {
            // An error occurred
            console.log(err);
          });
        }
      }
      this.loadingAluno = false;
      botaoCadastro.disabled = false;
      botaoLimpaForm.disabled = false;
    },

    limparFormAluno() {
      this.formAluno.nome = "";
      this.formAluno.email = "";
      this.formAluno.telefone = "";
      this.formAluno.cpf = "";
      this.formAluno.cep = "";
      this.formAluno.cidade = "";
      this.formAluno.estado = "";
      this.formAluno.endereco = "";
      this.formAluno.numero = "";
      this.formAluno.bairro = "";
      this.formAluno.complemento = "";
      this.loadingAluno = false;
    },

    async enviarFormCompras() {
      this.loadingCompra = true;
      try {
        let retorno;
        let url = "cadastra/compras";
        const response = await this.$http.post(url, {
          carrinho: this.carrinho,
          page: this.page,
          profissional_id: this.user.dados.profissional_id,
          acao: "Cadastrar aluno",
        });

        retorno = response.data;
        this.$bvModal.msgBoxOk(retorno.mensagem, {
          title: "Sucesso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "success",
          bodyBgVariant: "success",
          footerBgVariant: "success",
        });
        this.$bvModal.hide("modal-aluno");
      } catch (err) {
        let msg = "Erro ao cadastras as compras!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
      this.loadingCompra = false;
    },

    confirmaCompras() {
      let erro = false;

      if (!this.carrinho.status) {
        this.errosFormCarrinho.status = "Preencha o status!";
        erro = true;
      } else {
        this.errosFormCarrinho.status = "";
      }

      if (!this.carrinho.pagamento) {
        this.errosFormCarrinho.pagamento = "Escolha a forma de pagamento!";
        erro = true;
      } else {
        this.errosFormCarrinho.pagamento = "";
      }

      this.carrinho.compras.forEach((compra, index) => {
        if (!compra.agenda_id) {
          this.errosFormCarrinho.compras[index].agenda = "Escolha a agenda!";
          erro = true;
        } else {
          this.errosFormCarrinho.compras[index].agenda = "";
        }

        if (!compra.valor) {
          this.errosFormCarrinho.compras[index].valor = "Preencha o valor!";
          erro = true;
        } else {
          this.errosFormCarrinho.compras[index].valor = "";
        }
      });
      if (!erro) {
        this.$bvModal
          .msgBoxConfirm(
            "Você deseja realmente inserir todas as compras informadas?",
            {
              title: "Finalização de compras",
              okVariant: "primary",
              okTitle: "Sim",
              cancelTitle: "Não",
              cancelVariant: "light",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            }
          )
          .then((value) => {
            if (value == true) {
              this.enviarFormCompras();
            } else {
              this.$bvModal.msgBoxOk("As compras não foram inseridas!", {
                title: "Finalização de compras",
                okVariant: "light",
                okTitle: "Ok",
                headerBgVariant: "success",
                bodyBgVariant: "success",
                footerBgVariant: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    novaCompra(dados_aluno) {
      let aluno_id = dados_aluno.split("|")[0];
      let aluno_nome = dados_aluno.split("|")[1];
      this.carrinho.aluno_nome = aluno_nome;
      this.carrinho.aluno_id = aluno_id;
      this.carrinho.status = "";
      this.carrinho.pagarme = "";
      (this.carrinho.compras = [
        {
          agenda_id: "",
          valor: "",
          cupom: "",
          tag: "",
        },
      ]),
        (this.errosFormCarrinho.compras = [
          {
            agenda: "",
            valor: "",
          },
        ]),
        (this.compra_aluno_id = aluno_id);
      this.modal_aluno = false;
      this.modal_compras = true;
      this.formTitulo = "Carrinho";
      this.alunoNome = "";
      this.modalTamanho = "xl";
      this.$bvModal.show("modal-aluno");
    },

    adicionarLinhaCompra() {
      this.carrinho.compras.push({
        agenda_id: "",
        valor: "",
        cupom: "",
        tag: "",
      });
      this.errosFormCarrinho.compras.push({
        agenda: "",
        valor: "",
      });
    },

    removerLinhaCompra() {
      this.carrinho.compras.splice(1, 1);
    },

    validateEmail(email) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
          email
        )
      ) {
        return 1;
      } else {
        return 0;
      }
    },

    async buscaCep() {
      let cep = document.querySelector("#cep").value.replace("-", "");
      let url = "https://viacep.com.br/ws/" + cep + "/json";
      const response = await this.$http.get(url);
      let viacep = response.data;
      this.formAluno.cidade = viacep.localidade;
      this.formAluno.estado = viacep.uf;
      this.formAluno.endereco = viacep.logradouro;
      this.formAluno.bairro = viacep.bairro;
    },

    async listaOpcoesCompras() {
      let url = "lista/opcoes/compras";
      const response = await this.$http.get(url);
      let opcoes = response.data;
      opcoes.agendas.forEach((agenda) => {
        this.comprasOpcoes.agendas.push({
          text: agenda.agenda_id + " - " + agenda.treinamento_nome,
          value: agenda.agenda_id_compra,
        });
      });

      opcoes.cupons.forEach((cupom) => {
        this.comprasOpcoes.cupons.push({ text: cupom.nome, value: cupom.id });
      });
    },

    popula_data_table() {
      let data_table = new Array();
      this.dados_alunos.forEach((dados_aluno) => {
        let status_cor = "text-secundary";

        let botao_editar_aluno =
          "<button class='btn btn-warning btn-sm mr-1 open-aluno' title='Editar aluno' id = " +
          dados_aluno.aluno_id +
          " type='button'><i id = " +
          dados_aluno.aluno_id +
          " class = 'fas fa-edit'></i></button>";

        let botao_add_compras =
          "<button class='btn btn-warning btn-sm open-compras' title='Adicionar compras' id = " +
          dados_aluno.aluno_id +
          "|" +
          dados_aluno.aluno_nome +
          " type='button'><i id = " +
          dados_aluno.aluno_id +
          "|" +
          dados_aluno.aluno_nome +
          " class = 'fas fa-plus-square'></i></button>";

        let botao_compras_aluno =
          "<button class='btn btn-warning btn-sm mr-1 open-compras-aluno' title='Ver compras' id = " +
          dados_aluno.aluno_id +
          " type='button'><i id = " +
          dados_aluno.aluno_id +
          " class = 'fas fa-shopping-cart'></i></button>";

        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_aluno : "") +
            "&nbsp;&nbsp;" +
            (this.btn_executar ? botao_add_compras : "") +
            "&nbsp;&nbsp;" +
            (this.btn_executar ? botao_compras_aluno : "") +
            "</span>",

          "<span class=" + status_cor + ">" + dados_aluno.aluno_id + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_aluno.aluno_nome +
            "</span>",
          "<span class=" + status_cor + ">" + dados_aluno.aluno_cpf + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_aluno.aluno_email +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_aluno.aluno_telefone +
            "</span>",
        ]);
      });
      $("#tableAlunos")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          language: {
            sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            sInfoEmpty: " ",
            sLengthMenu: "_MENU_ resultados por página",
            sSearch: "",
            sEmptyTable: "Nenhum registro encontrado",
            sInfoFiltered: "Filtrados de _MAX_ registros",
            sLoadingRecords: "Carregando...",
            sProcessing: "Processando...",
            sInfoPostFix: "",
            sInfoThousands: ".",
            sSearchPlaceholder: "Pesquisar",
            sZeroRecords:
              "Nenhum registro encontrado <i class='far fa-frown'></i>",
            paginate: {
              sNext: "Próximo",
              sPrevious: "Anterior",
            },
          },
          buttons: [
            {
              extend: "copy",
              text: "Copiar",
              className:
                "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
            },
            {
              extend: "csv",
              text: "CSV",
              className: "btn btn-secondary buttons-csv buttons-html5",
            },
            {
              extend: "excel",
              text: "Excel",
              className: "btn btn-secondary buttons-excel buttons-html5",
            },
            {
              extend: "pdf",
              text: "PDF",
              className: "btn btn-secondary buttons-pdf buttons-html5",
            },
            {
              extend: "print",
              text: "Imprimir",
              className: "btn btn-secondary buttons-print buttons-html5",
            },
            {
              extend: "colvis",
              text: "Exibir Colunas",
              className:
                "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
            },
          ],
          drawCallback: function () {
            $(".open-aluno").on("click", function (event) {
              abreModalEdicao(event.target.id);
            });

            $(".open-compras").on("click", function (event) {
              abreModalCompras(event.target.id);
            });

            $(".open-compras-aluno").on("click", function (event) {
              abreModalComprasAluno(event.target.id);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#tableAlunos_wrapper .col-md-6:eq(0)"); //#example1_wrapper .col-md-6:eq(0)
      this.loadingDatatable = false;
    },

    habilitaBotoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "operacoes") {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }

        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }

      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },

    async listaAlunos() {
      this.habilitaBotoes();
      this.loadingDatatable = true;

      let url = "lista/alunos";
      const response = await this.$http.get(url);

      this.dados_alunos = response.data;

      this.popula_data_table()
    },

    async pesquisa_aluno(tipo) {
      this.loadingUpload = true

      if(tipo === 1) {
        this.pesquisa_cpf = null
        this.pesquisa_email = null
        this.pesquisa_nome = null
        this.pesquisa_telefone = null

        await this.$http.post('lista/alunos/filtro', {
          aluno_id: this.pesquisa_id,
          aluno_email: this.pesquisa_email,
          aluno_cpf: this.pesquisa_cpf,
          aluno_nome: this.pesquisa_nome,
          aluno_telefone: this.pesquisa_telefone
        })
          .then((response) => {
            this.dados_alunos = response.data;

            this.popula_data_table()
            
            this.loadingUpload = false
          })
      }

      if(tipo === 2) {
        this.pesquisa_id = null
        this.pesquisa_email = null
        this.pesquisa_nome = null
        this.pesquisa_telefone = null

        await this.$http.post('lista/alunos/filtro', {
          aluno_id: this.pesquisa_id,
          aluno_email: this.pesquisa_email,
          aluno_cpf: this.pesquisa_cpf,
          aluno_nome: this.pesquisa_nome,
          aluno_telefone: this.pesquisa_telefone
          
        })
          .then((response) => {
            this.dados_alunos = response.data;

            this.popula_data_table()

            this.loadingUpload = false
          })
      }

      if(tipo === 3) {
        this.pesquisa_cpf = null
        this.pesquisa_id = null
        this.pesquisa_nome = null
        this.pesquisa_telefone = null

        await this.$http.post('lista/alunos/filtro', {
          aluno_id: this.pesquisa_id,
          aluno_email: this.pesquisa_email,
          aluno_cpf: this.pesquisa_cpf,
          aluno_nome: this.pesquisa_nome,
          aluno_telefone: this.pesquisa_telefone
        })
          .then((response) => {
            this.dados_alunos = response.data;

            this.popula_data_table()

            this.loadingUpload = false
          })
      }

      if(tipo === 4) {
        this.pesquisa_cpf = null
        this.pesquisa_id = null
        this.pesquisa_email = null
        this.pesquisa_telefone = null

        await this.$http.post('lista/alunos/filtro', {
          aluno_id: this.pesquisa_id,
          aluno_email: this.pesquisa_email,
          aluno_cpf: this.pesquisa_cpf,
          aluno_nome: this.pesquisa_nome,
          aluno_telefone: this.pesquisa_telefone
        })
          .then((response) => {
            this.dados_alunos = response.data;

            this.popula_data_table()

            this.loadingUpload = false
          })
      }
      if(tipo === 5) {
        this.pesquisa_cpf = null
        this.pesquisa_id = null
        this.pesquisa_email = null
        this.pesquisa_nome = null
        

        await this.$http.post('lista/alunos/filtro', {
          aluno_id: this.pesquisa_id,
          aluno_email: this.pesquisa_email,
          aluno_cpf: this.pesquisa_cpf,
          aluno_nome: this.pesquisa_nome,
          aluno_telefone: this.pesquisa_telefone
        })
          .then((response) => {
            this.dados_alunos = response.data;

            this.popula_data_table()

            this.loadingUpload = false
          })
      }
    },

    // Função para trazer as compras de um aluno
    async aluno_compras(aluno_id) {
      let url = "aluno/compras/" + aluno_id;
      const response = await this.$http.get(url);
      // Limpo o array de compras
      this.aluno_compras_arr = [];
      this.nome_aluno = "";
      // Monto a tabela
      response.data.forEach((compra) => {        
        this.nome_aluno = compra.aluno_nome; 
        let status 
       
        if(compra.carrinho_status=="paid"){
          status = "fas fa-check fa-lg text-success"
        }
        if(compra.carrinho_status=="waiting_payment"){
          status = "fas fa-history fa-lg text-warning"

        }
        if(compra.carrinho_status=="refused"){
          status = "fas fa-times-circle fa-lg text-danger"
        }
        if(compra.carrinho_status=="refounded"){
          status = "fas fa-wallet fa-lg text-info"

        }
        if(compra.carrinho_status=="processing"){
          status = "fas fa-cog fa-lg text-secondary"

        }
        
        this.aluno_compras_arr.push({
          data: compra.carrinho_data,
          treinamento:
            compra.agenda_id_antigo + " - " + compra.treinamento_nome,
          compra_id: compra.compra_id,
          compra_valor: compra.compra_valor,
          carrinho_id: compra.carrinho_id,
          carrinho_status: compra.carrinho_status,
          carrinho_status_icon:status,
          carrinho_forma: compra.carrinho_forma_descricao,
          carrinho_forma_id: compra.carrinho_forma,
          carrinho_valor: compra.carrinho_valor
        });
      });

      // Se aluno sem compras
      if (this.nome_aluno == "") {
        this.$bvModal.show("modal_sem_compras");
      } else {
        this.$bvModal.show("modal_compras");
      }
    },

    atualizaEdicaoCompra(){      

      this.$bvModal.msgBoxConfirm('Você tem certeza que deseja alterar esta compra?',{
            title: 'ATENÇÃO',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'SIM',
              cancelTitle: 'NÃO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
          }).then(value => {
            if(value){          
              this.exibicaoCompras = false;
              this.exibicaoLoadingCompras = true;    
              this.executa_atualizacao_compra();              
            }            
          }).catch(err => {
            console.log(err);
            this.exibicaoCompras = true;
            this.exibicaoLoadingCompras = false;
            // An error occurred
          })
    
    },

    async executa_atualizacao_compra(){
      let url = "/atualiza/carrinho/compra";
      this.form_edicao_compra.profissional_id = this.user.dados.profissional_id;
      const response = await this.$http.post(url,this.form_edicao_compra);      
      try{
          if(response.data.erro==0){
          this.$bvModal.msgBoxOk(response.data.mensagem, {
            title: "Sucesso!",
            okVariant: "light",
            okTitle: "Ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          });
          this.exibicaoCompras = true;
          this.exibicaoLoadingCompras = false;
        }else{
          this.$bvModal.msgBoxOk(response.data.erro,{
            title: "Erro!",
            okVariant: "light",
            okTitle: "Ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
          this.exibicaoCompras = true;
          this.exibicaoLoadingCompras = false;
        }     
      }catch(err){
        this.exibicaoCompras = true;
        this.exibicaoLoadingCompras = false;
        console.log(err);
      }
      
    },

    //Habilita e Desabilita edição de compras do aluno na parte de gerenciamento de alunos
    showEdicaoCompra(compra_id){
      window.location.href='#edicao_compras';
      this.edicaoCompra = true;
      this.aluno_compras_arr.forEach(compra => {
        if(compra.compra_id == compra_id){
          let agendas = this.comprasOpcoes.agendas
          agendas.forEach(agenda => {
              if(compra.treinamento == agenda.text){
                this.form_edicao_compra.agenda_id = agenda.value
              }            
          });
          this.form_edicao_compra.valor_compra = compra.compra_valor
          this.form_edicao_compra.valor_carrinho = compra.carrinho_valor
          this.form_edicao_compra.status_pagamento = compra.carrinho_status
          this.form_edicao_compra.forma_pagamento = compra.carrinho_forma_id
          this.form_edicao_compra.carrinho_id = compra.carrinho_id
          this.form_edicao_compra.compra_id = compra.compra_id
        }
      });
      
    },


    ocultaEdicaoCompra(){
      this.edicaoCompra = false;
    },
    
    async monta_formas_pagamento(){
      let url="lista/formas";
      const response = await this.$http.get(url);
      let dados = response.data      
      dados.forEach((valor) => {
        this.form_edicao_compra.forma_pagamento_valores.push({text:valor.carrinho_forma_descricao, value:valor.carrinho_forma_id})
      });      
    },

  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    this.loadingAluno = false;
    window.elemento_vue = this;
    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);

    // Somente operações conseguem ver esta página
    this.user.permissoes.forEach((permissao) => {
      if (permissao.privilegio_tipo_id == 2) {
        this.habilitar_pagina = true;
        this.listaAlunos();
        this.listaOpcoesCompras();
      }
    });
    this.monta_formas_pagamento();
  },

};
</script>
