<template>
  <div class="hold-transition login-page" style="background-color: #efebe4">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Login />
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Login from '@/components/Login.vue'
// import store from '@/store'


export default {
  name: 'Home',
  components: {
    // HelloWorld,
    Login
  },
  data(){
      return{
        
      };
  },  

  
}

</script>

