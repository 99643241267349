import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import JsonExcel from "vue-json-excel";
import dotenv from 'dotenv'

dotenv.config()
Vue.config.productionTip = false
//Bootstrap-vue
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';

import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.snow.css';

// <!-- jQuery -->
import '../node_modules/admin-lte/plugins/jquery/jquery.min.js'

// <!-- Bootstrap -->
import '../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
// <!-- overlayScrollbars -->
import '../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js'
// <!-- AdminLTE App -->
import '../node_modules/admin-lte/dist/js/adminlte.js'

// jQuery Mapael
import '../node_modules/admin-lte/plugins/jquery-mousewheel/jquery.mousewheel.js'
import '../node_modules/admin-lte/plugins/raphael/raphael.min.js'
import '../node_modules/admin-lte/plugins/jquery-mapael/jquery.mapael.min.js'
import '../node_modules/admin-lte/plugins/jquery-mapael/maps/usa_states.min.js'

// <!-- ChartJS -->
import '../node_modules/admin-lte/plugins/chart.js/Chart.min.js'

// <!-- AdminLTE for demo purposes -->
// import '../node_modules/admin-lte/dist/js/demo'

// <!-- SweetAlert2 -->
import '../node_modules/admin-lte/plugins/sweetalert2/sweetalert2.min.js'
// <!-- Toastr -->
import '../node_modules/admin-lte/plugins/toastr/toastr.min.js'

// custom CSS
require("../src/assets/css/reset.css");
require("../src/assets/css/global.css");
require("../src/assets/css/uxup.css");
require("../src/assets/css/notificacoes.css");
require("../src/assets/css/softcheck.css");

var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");


// Datatable CSS
import "./assets/css/datatable/bootstrap.css"
import "./assets/css/datatable/buttons.bootstrap4.min.css"
import "./assets/css/datatable/dataTables.bootstrap4.min.css"

Vue.use(BootstrapVue)
Vue.use(VueQuillEditor)
// import 'bootstrap/dist/css/bootstrap.css'

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

Vue.component("downloadExcel", JsonExcel);


import cpf from "@/cpf_checker";
Vue.prototype.$cpf = cpf;



// <!-- DataTables  & Plugins -->
import '../node_modules/admin-lte/plugins/datatables/jquery.dataTables.min.js'
import '../node_modules/admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js'
import '../node_modules/admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js'
import '../node_modules/admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js'
import '../node_modules/admin-lte/plugins/datatables-buttons/js/dataTables.buttons.min.js'
import '../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js'
import '../node_modules/admin-lte/plugins/jszip/jszip.min.js'
import '../node_modules/admin-lte/plugins/pdfmake/pdfmake.min.js'
import '../node_modules/admin-lte/plugins/pdfmake/vfs_fonts.js'
import '../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.html5.min.js'
import '../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.print.min.js'
import '../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.colVis.min.js'

import '../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js'

//FORCANDO CONFIG CSS DO DATATABLE
import './assets/css/datatable_force.css'

//VUE MASK
import VueMask from "v-mask";
Vue.use(VueMask);

// PDF Make
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
window.pdfMake = require( 'pdfmake' );
window.pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

Vue.config.productionTip = false

import moment from 'moment';
moment().format();

//Vue Cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

//Configuração do HTTP para chamada de API com AXIOS
import http from '@/http'
import vuetify from './plugins/vuetify'
Vue.prototype.$http = http;

new Vue({
  router,  
  store,
  render: h => h(App),
  data() {
    return{
      dados_user:[],
    }
  },
  vuetify,
  mounted(){
    this.dados_user = JSON.parse(sessionStorage.dados_user);
  }
}).$mount('#app')

// Configuração do APM
import { init as initApm } from '@elastic/apm-rum';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    try {
      let environment = 'production'; // Valor padrão para ambiente de produção
      
      // Verifica se a variável de ambiente NUXT_ENV está definida e atribui o valor correspondente
      if (process.env.NUXT_ENV) {
        environment = process.env.NUXT_ENV === 'homolog' ? 'development' : 'production';
      }
      
      const apm = initApm({
        serviceName: 'Adm v3',
        serverUrl: 'https://apm-elastic.dnc.group:8200',
        environment: environment
      });
      Vue.prototype.$apm = apm;
    } catch (error) {
      console.log(error);
    }
  }
}).$mount('#app')