<template>
    <div id="rootGrupos">
        <Menu />
        <Navbar />

        <div class="content-wrapper" v-show="!habilitar_pagina">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Usuário sem permissão de acesso!</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Gerenciamento de Grupos</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrapper" v-show="habilitar_pagina">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Gerenciamento de Grupos</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Gerenciamento de Grupos</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="row">
                    <div class="card-body">
                        <div class="row">
                            <h5 style="padding-left:10px;padding-bottom:15px;font-size:24px;"><strong>Formação de Grupos de Projetos</strong></h5>
                        </div>
                        <div class="row">
                            <div class="col-3" style="padding-bottom:15px">
                                <button class="btn btn-lg bs-btn btn-success" type="button" @click="upload_arquivo()">
                                    <span>Upload <i class="fas fa-file-download"></i></span>
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <h5 style="padding-left:10px;padding-bottom:15px;font-size:24px;"><strong>Aleatorização de Grupos para Dinâmicas</strong></h5>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <b-form-group label="Turma" label-for="turma" >
                                    <b-form-select 
                                        id="turma"
                                        :options="turmas"
                                        v-model="turma">
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-3">
                                <b-form-group label="Quantidade de Grupos" label-for="qtd_grupos">
                                    <b-form-input 
                                        @keypress="isNumber($event)"
                                        id="qtd_grupos"
                                        v-model="qtd_grupos"
                                        placeholder="Digite um número">
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-3">
                                <b-form-group label="Prefixo do Grupo" label-for="prefixo">
                                    <b-form-input
                                        id="prefixo"
                                        v-model="prefixo_grupo"
                                        placeholder="Defina uma sigla para o prefixo">
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-3">
                                <div><br></div>
                                <button class="btn btn-lg bs-btn btn-success" type="button" @click="aleatoriza_turma()">
                                    <span>Executar <i class="fas fa-play"></i></span>
                                </button>
                            </div>
                        </div>
                        <div class="row" v-show="turma != null">
                            <div class="col-3">
                                <button class="btn btn-lg bs-btn btn-success" type="button" @click="gera_impressao()">
                                    <span>Download <i class="fas fa-file-download"></i></span>
                                </button>
                            </div>
                        </div>
                        <hr>
                        <!-- Exibição dos novos grupos -->
                        <div class="mx-auto">
                            <div class="spinner-border text-primary" v-show="loading"></div>
                            <div v-show="!loading">
                                <div class="card-columns">
                                    <div class="card" v-for="grupo in grupos" :key="grupo.grupo_id">
                                        <div class="card-header text-white bg-primary mb-3">
                                            <h5 class="card-title"><b>{{ grupo.grupo_codigo }}</b></h5>
                                        </div>
                                        <div class="card-body">
                                            <div v-for="aluno in grupo.alunos" :key="aluno.aluno_id">
                                                <p class="card-text text-capitalize"><b>{{aluno.aluno_id}}</b> - {{aluno.aluno_nome.toLowerCase()}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="modal_upload" hide-footer>
            <template #modal-title>Upload de Grupos</template>
            <div class="overflow-auto">
                <div class="text-center"> 
                    <a :href="caminho" download>Arquivo exemplo para upload</a>
                    <hr>
                </div>
                <div class="flex-sup file-upload-box">
                    <b-form-file
                        multiple
                        placeholder="Arraste o vídeo para cá"
                        drop-placeholder="Jogue aqui!"
                        type="file"
                        @change="envia_arquivo($event)"
                    ></b-form-file>
                    <div class="flex-sup upload-indiq">
                        <strong> Solte os arquivos em cima  </strong>
                        <i class="fas fa-arrow-up"></i>
                    </div>
                </div>
                <hr>
                <div class="input-group justify-content-md-end">
                    <div class="mx-auto" v-show="loading_modal">
                        <div class="mx-auto text-center">
                            <div class="spinner-border text-primary"></div>
                        </div>
                    </div> 
                    <b-button
                        class="mr-1 btn btn-danger"
                        type="submit"
                    >Fechar</b-button>
                </div>
            </div>
        </b-modal>

        <b-modal id="modal_response">
            <div class="overflow-auto">
                {{mensagem_response}}
            </div>
        </b-modal>

        <Footer />
    </div>
</template>

<style scoped>

    .bs-btn i {margin-left: 8px;}

</style>

<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {
            user: {},
            page: "Aleatoriza Grupos",
            habilitar_pagina: false,
            turmas: [{
                text: 'selecione',
                value: null
            }],
            turma: null,
            prefixo_grupo: '',
            loading: false,
            grupos: [],
            qtd_grupos: '',
            file: [],
            loading_modal: false,
            mensagem_response: '',
            caminho: '/files/grupos_projeto_exemplo.xlsx'
        };
    },
    computed: {
    },
    created() {
        this.carrega_turmas();
    },
    methods: {
        // Select de turmas
        async carrega_turmas(){
            let url = "carrega/turmas";
            const response = await this.$http.get(url);
            response.data.forEach(turma => {
                this.turmas.push({
                    text: turma.softcheck_turma_codigo,
                    value: turma.softcheck_turma_codigo
                });
            });
        },
        // Chamada da API para aleatorização das turmas
        async aleatoriza_turma(){
            if (this.turma != null && this.prefixo_grupo != '' && (this.qtd_grupos != '' || this.qtd_grupos > 0)){
                this.loading = true;
                let url = "aleatorizacao/grupos";
                const response = await this.$http.post(url,{
                    page: this.page,
                    profissional_id: this.user.dados.profissional_id,
                    acao: "Aleatorizacao dos grupos de day da turma " + this.turma,
                    turma_codigo: this.turma,
                    prefixo_grupo: this.prefixo_grupo,
                    qtd_grupos: this.qtd_grupos
                });
                this.grupos = [];
                response.data.forEach(grupo => {
                    this.grupos.push({
                        grupo_codigo: grupo.grupo_codigo,
                        grupo_id: grupo.grupo_id,
                        alunos: grupo.alunos
                    });
                });
                this.loading = false;
            }
            else{
                alert('Preencha valores válidos em todos os campos!');
            }
        },
        // Garante que o usuário colocará somente números na campo
        isNumber: function(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        // Abre uma nova aba com a tabela
        async gera_impressao(){
            window.open("/gera-grupos?turma=" + this.turma);
        },
        // Aciona modal para upload
        upload_arquivo(){
            // Aciona modal
            this.$bvModal.show("modal_upload");
        },
        // Envia o arquivo para back atualizar o BD
        async envia_arquivo(event){
            this.loading_modal = true;

            let formData = new FormData();
            formData.append("file", event.target.files ? event.target.files[0] : event.dataTransfer.files[0]);

            await this.$http.post('importar/arquivo/grupo', 
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response) => {
                if (response.data.length > 0){
                    this.mensagem_response = "Arquivo salvo com sucesso!";
                    //console.log(response.data);
                }
                else{
                    this.mensagem_response = "Ocorreu um erro. Entre em contato com tech.";
                }
            })

            this.loading_modal = false;
            this.$bvModal.hide("modal_upload");
            this.$bvModal.show("modal_response");
        }
    },
    mounted() {
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);

        // Somente operações conseguem ver esta página
        this.user.permissoes.forEach(permissao => {
            if (permissao.privilegio_tipo_id == 2 && (permissao.privilegio_status == "LE" || permissao.privilegio_status == "A")){
                this.habilitar_pagina = true;
            }
        });
    }
}
</script>