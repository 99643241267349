<template>
  <div id="root">
    <Preloader v-show="preloader_status" />

    <Menu />
    <Navbar />
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-center">
              <h1 class="m-0">Área Administrativa - DNC Group</h1>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
      <div class="content">
            <div class="card card-widget widget-user">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header" style="background-color: #ffbb59">
                    <h3 class="widget-user-username">
                    {{ user.dados.profissional_nome }}
                    </h3>
                    <h5 class="widget-user-desc">
                    Squad de {{ user.dados.squad_nome }}
                    </h5>
                </div>
                <div class="widget-user-image">
                    <b-avatar
                    variant="primary"
                    class="img-circle elevation-2"
                    size="6em"
                    :src="profissional_url_foto"
                    v-if="profissional_url_foto != ''"
                    ></b-avatar>
                    <b-avatar variant="primary" v-else></b-avatar>
                </div>
                <div class="card-footer">
                    <div class="row">
                    <div class="col-sm-2 border-right">
                        <div class="description-block">
                        <h5 class="description-header">SECOND HOME</h5>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <div class="col-sm-2 border-right">
                        <div class="description-block">
                        <h5 class="description-header">SMILE</h5>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-4 border-right">
                        <div class="description-block">
                        <h5 class="description-header">HARDWORK</h5>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-2">
                        <div class="description-block">
                        <h5 class="description-header">PLURAL</h5>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <div class="col-sm-2">
                        <div class="description-block">
                        <h5 class="description-header">GOODWILL</h5>
                        </div>
                        <!-- /.description-block -->
                    </div>
                    <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
            </div>
             <div class="card card-widget widget-user">
                 <b-embed
                    type="iframe"
                    aspect="16by9"
                    src="https://www.youtube.com/embed/videoseries?list=PLFWVHlDdL6J1e7Dw7H1-HVUtZVkNK4cKb"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen                                        
                ></b-embed>                
            </div>
        </div>
    </div>
    <!-- /.content-wrapper -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->

    <Footer />
  </div>
</template>



<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Login from '@/components/Login.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Desktop",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      preloader_status: true,
      user: {},
      usuario_nome: "",
      profissional_url_foto: "",
    };
  },
  created() {
    this.puxa_dados();
  },
  methods: {
    async puxa_dados() {
      this.user.dados = JSON.parse(sessionStorage.dados_user);
      this.user.permissoes = JSON.parse(sessionStorage.permissoes);
      this.usuario_nome = this.user.dados.profissional_nome.split(" ")[0];
      if (this.user.dados.profissional_url_foto != "") {
        this.profissional_url_foto = this.user.dados.profissional_url_foto;
      }
      this.preloader_status = false;
    },
  },
};
</script>


