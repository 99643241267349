<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-3">
              <h1 class="m-0">Notas assignments</h1>
            </div>
            <div class="col-sm-2">
              <div
                  class="spinner-border text-primary"
                  role="status"
                  v-show="pesquisando"
                ></div>
            </div>
            <!-- /.col -->
            <div class="col-sm-12">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Assignments</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <div class="card">
        <div class="row">
          <!-- upload box -->
          <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <div>
                    <b-form-group label="Turmas" label-for="turma">
                    <b-form-select 
                        id="turma"
                        :options="turmas"
                        v-model="turma"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-4">
                  <div>
                    <b-form-group label="Assignment" label-for="assignment">
                    <b-form-select 
                        id="assignment"
                        :options="assignments"
                        v-model="assignment_escolhido"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-4">
                  <button
              class="btn btn-lg bs-btn btn-success mb-2"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              @click="lista_notas()"
              :disabled = "pesquisando"
            >
            <span>Filtrar <i class="fas fa-play"></i></span>
            </button>
                </div>
            </div>
            <div v-show="!assignment_ativo">
            <table  id="tableMultiplasNotas" class="table table-bordered table-hover">
            <thead>
              <tr>
                <!-- <th>Ações</th>
                <th>RID</th> -->
                <th v-for="(coluna, index) in colunas" :key="index">{{coluna.nome}}</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          </div>
          <div v-show="assignment_ativo">
          <table  id="tableNota" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>RID</th>
                <th>Nome</th>
                <th>{{assignment_escolhido}}</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>

  .bs-btn i {margin-left: 5px;}

</style>


<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  components: {
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      pesquisando: false,
      videos: [],
      lista: [],
      turmas: [],
      assignments: [{
        text: "todos",
        value: 0
        }
      ],
      nomeVideo: "",
      turma: "",
      assignment_escolhido: "",
      assignment_ativo: false,
      colunas:[]
    };
  },
  computed: {
      listaFiltrada(){
        return this.videos.filter(video => {
        return video.titulo.toLowerCase().includes(this.nomeVideo.toLowerCase())
      })
    }
  },
  created() {
    for(let i = 1; i <= 24; i++){
      this.assignments.push({
          text: i,
          value: i
          }
      )
    } 
    this.lista_colunas()
    this.lista_turmas()

  },

  methods: {

    async lista_turmas(){
      let url = "lista/turmas/assignment";
      const response = await this.$http.get(url);
      let retorno = response.data
      retorno.forEach(turma => {
        this.turmas.push({
          text: turma.softcheck_turma_codigo,
          value: turma.softcheck_turma_codigo
        })
      })
    },

    lista_colunas(){

       this.colunas.push(
        {nome:"RID"},
        {nome:"Nome"})

        for(let i = 0; i < 24; i++){
          this.colunas.push(
            {nome: (i+1)}
          )
        }

    },
    async lista_notas(){
      if(!this.turma){
        alert("Selecione uma turma!")
        return false
      }
      this.pesquisando = true
      if(this.assignment_escolhido){
        this.assignment_ativo = true
      }else{
        this.assignment_ativo = false
      }

      try{
      let url = "lista/notas/assignments"
      const response = await this.$http.post(url, {
        turma: this.turma,
        assignment: this.assignment_escolhido ? this.assignment_escolhido : null
        });
        let notas_alunos = response.data.notas ? response.data.notas : []
        let assignment_max = response.data.assignment_max 
        this.pesquisando = false
        let data_table = new Array();
        console.log(response.data)
        

        notas_alunos.forEach((nota_aluno) => {
            let status_cor = "text-secundary";
            let linha = []
        
        linha.push(
          "<span class=" + status_cor + ">" + nota_aluno.aluno_id + "</span>"
        );
        linha.push(
          "<span class=" + status_cor + ">" + nota_aluno.aluno_nome + "</span>"
        );
        for (let i = 0; i < assignment_max; i++){
          linha.push("<span class=" + status_cor + ">" + nota_aluno.assignment_nota[i] + "</span>")
        }
        
        data_table.push(linha)
      });
      
      if(this.assignment_escolhido){
        $("#tableNota")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          language: {
            sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            sInfoEmpty: " ",
            sLengthMenu: "_MENU_ resultados por página",
            sSearch: "",
            sEmptyTable: "Nenhum registro encontrado",
            sInfoFiltered: "Filtrados de _MAX_ registros",
            sLoadingRecords: "Carregando...",
            sProcessing: "Processando...",
            sInfoPostFix: "",
            sInfoThousands: ".",
            sSearchPlaceholder: "Pesquisar",
            sZeroRecords:
              "Nenhum registro encontrado <i class='far fa-frown'></i>",
            paginate: {
              sNext: "Próximo",
              sPrevious: "Anterior",
            },
          },
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
        })
        .buttons()
        .container()
        .appendTo("#tableMultiplasNotas_wrapper .col-md-6:eq(0)");
      }else{
      $("#tableMultiplasNotas")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          language: {
            sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            sInfoEmpty: " ",
            sLengthMenu: "_MENU_ resultados por página",
            sSearch: "",
            sEmptyTable: "Nenhum registro encontrado",
            sInfoFiltered: "Filtrados de _MAX_ registros",
            sLoadingRecords: "Carregando...",
            sProcessing: "Processando...",
            sInfoPostFix: "",
            sInfoThousands: ".",
            sSearchPlaceholder: "Pesquisar",
            sZeroRecords:
              "Nenhum registro encontrado <i class='far fa-frown'></i>",
            paginate: {
              sNext: "Próximo",
              sPrevious: "Anterior",
            },
          },
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
        })
        .buttons()
        .container()
        .appendTo("#tableMultiplasNotas_wrapper .col-md-6:eq(0)");
        }
        }
        catch(e){
          //alert("Erro na busca!")
          console.log(e.message)
        }

        this.pesquisando = false
    },
    
    
  },
  
}
</script>