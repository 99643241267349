<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />
    <div class="content-wrapper">

      <!-- familiar nav -->
      <div class="flex-sup familiar-nav">

          <h3 class="flex-sup"> <small> LMS / Uploads em massa </small> <i class="fas fa-angle-down"></i> </h3>

          <ul class="flex-sup fami-nav-list">

          <!-- variants: isAbaLoading, isActualPlace -->

          <!-- > turmas -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/bulk-arquivos"> <i class="fas fa-copy"></i> <small> Bulk de arquivos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > grupos -->
          <li class="flex-sup isActualPlace"> 
              <a class="flex-sup" href="/bulk-conteudo"> <i class="fas fa-cubes"></i> <small> Bulk de conteúdo </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > treinamentos -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/bulk-acessos-cursos"> <i class="fas fa-clipboard-check"></i> <small> Acesso aos cursos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          </ul>

      </div>

      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Subir conteúdos em massa</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Bulk de vídeos</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="container-fluid">
        <div class="card">
          <div class="row upload_head">

            <div class="col-md-4 mt-5">
              <div class="form-group">
                <label>Selecione o curso:</label>
                <div>
                  <b-form-select v-model="curso_id_selecionado">
                    <b-form-select-option v-for="(curso, index) in cursos" :key="index" :value="curso.curso_id">
                      {{curso.curso_titulo}}
                    </b-form-select-option>
                  </b-form-select>
                </div>
              </div>
            </div>

            <div class="col-md-4" v-if="curso_id_selecionado">
              <div class="form-group">
                <label>Selecionar planilha:</label>

                <!-- upload box -->
                <div class="flex-sup file-upload-box">

                  <b-form-file
                    @change="envia_planilha_conteudos($event)"
                    placeholder="Arraste a planilha para cá"
                    drop-placeholder="Jogue aqui!"
                    type="file"
                  ></b-form-file>

                  <div class="flex-sup upload-indiq">
                    <strong> Solte os arquivos em cima  </strong>
                    <i class="fas fa-arrow-up"></i>
                  </div>

                </div>


              </div>
            </div>


          </div>
          <div class="row">
            <div class="card-body col-sm-4">
              <b-button class="bs-btn bs-btn-alert" href="https://spreadsheets.google.com/feeds/download/spreadsheets/Export?key=1lBR9IaJDfHT8cjoyGg-Ru4HUi7GM-U3aGxrQspzZCS0">Baixar Planilha de Exemplo</b-button>
            </div>
            <div class="card-body col-sm-4" v-if="curso_id_selecionado">
              <b-button variant="default" :href="`${searchDncURL}conteudos/versao/ativa/exportar/`+curso_id_selecionado" target="_blank">Baixar Planilha Versão Atual</b-button>
            </div>
          </div>
          <!-- preview conteúdo -->

          <!-- /.card-header -->
          <div class="card-body">
            <h3> Preview do conteúdo a ser gerado: </h3>
            <b-alert variant="warning" show><strong> Verifique abaixo </strong> a integridade dos dados a serem populados no banco de dados:</b-alert>
            
            <!-- preview card -->
            <div class="d-flex previewCard" v-for="(slot, index) in step_conteudos" :key="index">
              <ul class="d-flex previHeader">
                <li> <strong> SLOT {{slot.slot_numero}}</strong> </li> <i class="fas fa-angle-right"></i>
                <li> <span> Modulo {{slot.modulo_numero}} - {{slot.modulo_titulo}} </span> </li> <i class="fas fa-angle-right"></i>
                <li> <span> Step {{slot.step_numero}} - {{slot.step_titulo}} </span> </li>
              </ul>
              <!-- list -->
              <ul class="d-flex contentList">
                <li v-for="(conteudo, index) in slot.conteudos" :key="index"> 
                  <span> <b> Titulo: </b> {{conteudo.conteudo_titulo}} </span> <small> - </small>
                  <span> <b> Tipo: </b> {{conteudo.conteudo_categoria}} </span> <small> - </small>
                  <span> <b> URL: </b> <a :href="conteudo.conteudo_url"> {{conteudo.conteudo_url}} </a> </span>
                </li>
              </ul>
            </div>

            <!-- options -->
            <div class="row d-flex justify-content-end">
              <div class="col-4 d-flex justify-content-end m-3">
                <b-button class="bs-btn bs-btn-sucess" :disabled="erro" v-b-modal.submitStatus variant="success m-1" @click.prevent="conteudo_importacao_confirmar()">Confirmar</b-button>
              </div>
            </div>
            <!-- Status modal -->
            <b-modal id="submitStatus" title="Status da requisição:" >

              <b-alert variant="warning" show v-if="!success && !fail">Aguarde, estamos populando os minutos!</b-alert>

              <b-alert variant="success" show v-if="success">Conteúdo submetido com sucesso!</b-alert>
              <b-alert variant="danger" show v-if="fail">Falha em submeter o conteúdo...</b-alert>
            </b-modal>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<style scoped>

  /* preview */

  .previewCard {
    flex-direction: column; background: #fff; border: #ccc 1px solid;
    border-radius: 5px; margin-bottom: 21px; padding: 0;
  }
    .previHeader {
      align-items: center; list-style: none; width: 100%; padding: 8px;
      border-bottom: 2px solid rgb(180, 180, 180); background: rgb(233, 233, 233);
      margin-bottom: 0;
    }
      .previHeader li strong, .previHeader li span {font-size: 1.3em;}
      .previHeader i {margin: 0 13px; color: rgb(119, 119, 119);}

      .contentList {
        width: 100%; flex-direction: column; list-style: none; padding: 0; margin-bottom: 0;
      }
         .contentList li {width: 100%; padding: 8px; border-bottom: 1px solid #ccc;}
         .contentList li:nth-child(even) {background: rgb(243, 243, 243);}

         .contentList li span strong {color: rgb(70, 70, 70);}

    @media screen and (max-width: 375px) {
      .previHeader {flex-direction: column;}  
        .previHeader i {transform: rotate(90deg); margin: 5px 0;}
        .contentList li {display: flex; flex-direction: column;}
    }

</style>

<script>
import Navbar from '@/components/Navbar.vue'
import Menu from '@/components/Menu.vue'

export default {
  data() {
    return {
      text: '',
      step_conteudos: [],
      conteudos: [],
      modulos: [],
      slots: [],
      steps: [],
      cursos: [],
      curso_id_selecionado: 0,
      success: false,
      fail: false,
      erro: false
    }
  },
  components: {
    Navbar,
    Menu
  },
  computed: {
    searchDncURL () {
      if (process.env.VUE_APP_ENV === 'development') {
        return 'http://localhost:3000'
      }

      if (process.env.VUE_APP_ENV === 'homolog') {
        return process.env.VUE_APP_API_APP_DNC
      }

      return process.env.VUE_APP_API_APP_DNC
    }
  },
  created() {
    this.lista_cursos()
  },
  methods: {
    createFormData(article) {
      var formData = new FormData();
      formData.append("data", article.file);
      formData.append("curso_id", this.curso_id_selecionado);
      return formData;
    },

    async envia_planilha_conteudos(event) {
      const file = this.createFormData({
        file: event.target.files ? event.target.files[0] : event.dataTransfer.files[0],
      })
      await this.$http.post('conteudo/excel/importar', 
        file,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) => {
        if(response.data.erros.length >= 1) {
          this.erro = true
          alert(response.data.erros)
        }else{
          this.erro = false;
        }
        this.step_conteudos = response.data.step_conteudos
        this.conteudos = response.data.conteudos
        this.modulos = response.data.modulos
        this.slots = response.data.slots
        this.steps = response.data.steps
      })
    },

    async lista_cursos() {
      await this.$http.get('curso/listar')
        .then((response) => {
          this.cursos = response.data.cursos
        })
    },

    async conteudo_importacao_confirmar() {
      if(this.erro === false) {
        await this.$http.post('conteudo/importacao/confirmar', {
          step_conteudos: this.step_conteudos,
          slots: this.slots,
          modulos: this.modulos,
          steps: this.steps,
          conteudos: this.conteudos,
          curso_id: this.curso_id_selecionado
        }).then((response) => {
          var versao_id = response.data.versao_id;
          this.$http.get('versao/conteudos/minutos/versao/' + versao_id);
          this.success = true
          
          this.step_conteudos = []
          this.slots = []
          this.modulos = []
          this.steps = []
          this.conteudos = []
          this.curso_id_selecionado = 0
          
        }).catch(() => {
          this.fail = true
          this.step_conteudos = []
          this.slots = []
          this.modulos = []
          this.steps = []
          this.conteudos = []
          this.curso_id_selecionado = 0
        })
      }
    }
  }
}
</script>


