<template>
    <div id="root" v-show="hash">
      <div class="content-wrapper">
          <div class="content-header">
              <div class="container-fluid">
                  <div class="row mb-2">
                      <div class="col-sm-6">
                          <h1 class="m-0">Compras</h1>
                      </div>
                  </div>			
              </div>
          </div>
  
          <div class="content">
              <div class="container-fluid">
                  <div class="card">
                      <div class="card-header">
                          <h3 class="card-title">
                              Veja as compras realizadas pelos alunos!
                          </h3>
                      </div>
                      <div class="card-body col-sm-12">
                          <div class="form-group">
                              <div class="row">
                                  <div class="col-sm-3">
                                      <label for="datepicker-invalid">Início:</label>
                                      <b-form-datepicker
                                      id="example-datepicker"
                                      locale="pt-br"
                                      placeholder="Escolha o Início"
                                      :date-format-options="{
                                          year: 'numeric',
                                          month: '2-digit',
                                          day: '2-digit',
                                      }"
                                      value-as-date
                                      size="sm"
                                      v-model="date_start"
                                      class="mb-2"
                                      ></b-form-datepicker>
                                  </div>
                                  <div class="col-sm-3">
                                      <label for="datepicker-invalid">Fim:</label>
                                      <b-form-datepicker
                                      id="example-datepicker2"
                                      locale="pt-br"
                                      placeholder="Escolha o Final"
                                      :date-format-options="{
                                          year: 'numeric',
                                          month: '2-digit',
                                          day: '2-digit',
                                      }"
                                      value-as-date
                                      size="sm"
                                      v-model="date_end"
                                      class="mb-2"
                                      ></b-form-datepicker>
                                  </div>
                                  <div class="col-sm-4">
                                      <b-form-group label="Status" label-for="status">
                                      <b-form-select
                                          name="status"
                                          v-model="statusSelecionado"
                                          :options="status"
                                          @change="lista_compras()"
                                      ></b-form-select>
                                      </b-form-group>
                                  </div>
                                  <div class="col-sm-2">
                                      <br /><button
                                      class="btn bs-btn bs-btn-primary"
                                      @click="lista_compras()"
                                      >
                                      <i class="fas fa-filter"></i>Filtrar
                                      </button>
                                  </div>
                              </div>		
                              <h5>Filtros:</h5>		                            	
                              <div class="row">
                                  <div class="col-3">
                                  <b-form-group label="UTM campaign" label-for="campaign">
                                      <b-form-select
                                      id="campaign"
                                      :options="campaigns"
                                      v-model="campaign"
                                      @change="filtro_utm()"
                                      >
                                      </b-form-select>
                                  </b-form-group>
                                  <!-- <SelectUtmCampaign v-model="campaign" v-on:change.native="filtro_utm()"/> -->
                                  </div>
                                  <div class="col-3">
                                  <b-form-group label="UTM source" label-for="source">
                                      <b-form-select
                                      id="source"
                                      :options="sources"
                                      v-model="source"
                                      @change="filtro_utm()"
                                      >
                                      </b-form-select>
                                  </b-form-group>
                                  <!-- <SelectUtmSource v-model="source"  v-on:change.native="filtro_utm()"/> -->
                                  </div>
                                  <div class="col-3">
                                      <b-form-group label="UTM Content" label-for="content">
                                          <b-form-select
                                          id="content"
                                          :options="contents"
                                          v-model="content"
                                          @change="filtro_utm()"
                                          >
                                          </b-form-select>
                                      </b-form-group>
                                      <!-- <SelectUtmContent v-model="content" v-on:change.native="filtro_utm()"/> -->
                                  </div>
                                  <div class="col-3">
                                      <b-form-group label="UTM medium" label-for="medium">
                                          <b-form-select
                                          id="medium"
                                          :options="mediums"
                                          v-model="medium"
                                          @change="filtro_utm()"
                                          >
                                          </b-form-select>
                                      </b-form-group>
                                      <!-- <SelectUtmMedium v-model="medium" v-on:change.native="filtro_utm()"/> -->
                                  </div>
                              </div>
  
                              <div class="row" v-show="loading">
                                  <i class="spinner-border text-primary"></i>
                              </div>
                          
                              <div class="table-responsive" v-show="!loading">
                                  <table id="example" class="table table-striped table-bordered">
                                  <thead>
                                      <tr>
                                      <th>Agenda</th>
                                      <th>ID carrinho</th>
                                      <th>ID compra</th>
                                      <th>RID</th>
                                      <th>Nome</th>
                                      <th>CPF</th>
                                      <th>Valor Carrinho</th>
                                      <th>Valor Compra</th>
                                      <th>Data</th>
                                      <th>Status do Carrinho</th>
                                      <th>Método de pagamento</th>
                                      <th>UTM Campaign</th>
                                      <th>UTM Source</th>
                                      <th>UTM Content</th>
                                      <th>UTM Medium</th>
                                      </tr>
                                  </thead>
                                  <tbody></tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>		
      </div>
      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
          <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->
  
    </div>
    <!-- /.content-wrapper -->
  </template>
  
  <style scoped>
  .bs-btn i {
    transform: translateX(-5px);
  }
  
  .bs-btn-primary {
    color: var(--wht-3);
  }
  .bs-btn-primary:hover {
    color: #203d92;
  }
  .content-wrapper{
    margin-left: 0 !important;
}
  </style>
  
  
  <script>
  var body = document.body;
  body.classList.add("hold-transition");
  // body.classList.add("dark-mode");
  body.classList.add("sidebar-mini");
  body.classList.add("layout-fixed");
  body.classList.add("layout-navbar-fixed");
  body.classList.add("layout-footer-fixed");
  
  var dados_checks = new Array();
  window.dados_checks = dados_checks;
  
  import jQuery from "jquery";
  const $ = jQuery;
  window.$ = $;
  
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  // import store from '@/store'
  
  // import SelectUtmContent from "@/components/SelectUtmContent.vue";
  // import SelectUtmSource from "@/components/SelectUtmSource.vue";
  // import SelectUtmCampaign from "@/components/SelectUtmCampaign.vue";
  // import SelectUtmMedium from "@/components/SelectUtmMedium.vue";
  
  
  export default {
    name: "Compras",
    components: {
      // SelectUtmContent,
      // SelectUtmMedium,
      // SelectUtmCampaign,
      // SelectUtmSource,
    },
    data() {
      return {
        preloader_status: true,
        loading: false,
        page: "",
        date_start: "",
        date_end: "",
        statusSelecionado: null /* [!] */,
        selected: null,
        status: [
          { text: "Todos", value: null },
          { text: "Paid", value: "paid" },
          { text: "Refused", value: "refused" },
          { text: "Waiting Payment", value: "waiting_payment" },
          { text: "Refunded", value: "refunded" },
          { text: "Processing", value: "processing" },
        ],
        intervalo: "",
        btn_executar: true,
        btn_excluir: true,
        btn_editar: true,
        hash: '',
        data_table: [],
        contents: [
          {
            text: "Selecione",
            value: null,
          },
        ],
        content: null,
        campaigns: [
          {
            text: "Selecione",
            value: null,
          },
        ],
        campaign: null,
        sources: [
          {
            text: "Selecione",
            value: null,
          },
        ],
        source: null,
        mediums: [
          {
            text: "Selecione",
            value: null,
          },
        ],
        medium: null,
      };
    },
    created() {
      this.preloader_status = false;
      this.fetchDadosEmbed()
    },
    computed: {
      isDisabledExecutar: function () {
        return !this.btn_executar;
      },
      isDisabledExcluir: function () {
        return !this.btn_excluir;
      },
      isDisabledEditar: function () {
        return !this.btn_editar;
      },
      
    },
    methods: {
      // Select de 
      async fetchDadosEmbed() {
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.hash = urlParams.get('habilitado')
            this.user = urlParams.get('id')
        },
      async carrega_utms() {
        let url = "lista/utms";
        const response = await this.$http.get(url);
        response.data.content.forEach((content) => {
          this.contents.push({
            text: content.utm_content,
            value: content.utm_content,
          });
        });
        response.data.source.forEach((source) => {
          this.sources.push({
            text: source.utm_source,
            value: source.utm_source,
          });
        });
        response.data.medium.forEach((medium) => {
          this.mediums.push({
            text: medium.utm_medium,
            value: medium.utm_medium,
          });
        });
        response.data.campaign.forEach((campaign) => {
          this.campaigns.push({
            text: campaign.utm_campaign,
            value: campaign.utm_campaign,
          });
        });
      },
  
      async filtro_utm() {
        let dados;
  
        if (this.campaign != null) {
          dados = this.compras.filter((item) => {
            return item.compra.utm_campaign == this.campaign;
          });
        }
        if (this.source != null) {
          dados = this.compras.filter((item) => {
            return item.compra.utm_source == this.source;
          });
        }
        if (this.content != null) {
          dados = this.compras.filter((item) => {
            return item.compra.utm_content == this.content;
          });
        }
        if (this.medium != null) {
          dados = this.compras.filter((item) => {
            return item.compra.utm_medium == this.medium;
          });
        }
        if (
          this.medium == null &&
          this.source == null &&
          this.content == null &&
          this.campaign == null
        ) {
          dados = this.compras;
        }
  
        let data_table = this.monta_dados(dados);
  
        $("#example")
          .DataTable({
            data: data_table,
            destroy: true,
            responsive: {
              details: {
                display: $.fn.dataTable.Responsive.display.modal({
                  header: function (row) {
                    var data = row.data();
                    return "Details for " + data[0] + " " + data[1];
                  },
                }),
                renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
              },
            },
            lengthChange: false,
            autoWidth: false,
            buttons: [
              {
                extend: "copy",
                text: "Copiar",
                className:
                  "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
              },
              {
                extend: "csv",
                text: "CSV",
                className: "btn btn-secondary buttons-csv buttons-html5",
              },
              {
                extend: "excel",
                text: "Excel",
                className: "btn btn-secondary buttons-excel buttons-html5",
              },
              {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-secondary buttons-pdf buttons-html5",
              },
              {
                extend: "print",
                text: "Imprimir",
                className: "btn btn-secondary buttons-print buttons-html5",
              },
              {
                extend: "colvis",
                text: "Exibir Colunas",
                className:
                  "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
              },
            ],
            columnDefs: [
              {
                targets: [10, 11, 13],
                visible: false,
              },
            ],
          })
          .buttons()
          .container()
          .appendTo("#example_wrapper .col-md-6:eq(0)");
      },
  
      async lista_compras() {
        let start = this.date_start.toLocaleString({
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        let end = this.date_end.toLocaleString({
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        this.habilita_botoes();
        var data_1;
        var data_2;
        this.loading = true;
  
        if (start == undefined && end == undefined) {
          // Obtém a data/hora atual
          let data = new Date();
  
          // Guarda cada pedaço em uma letiável
          let dia = data.getDate(); // 1-31
          let mes = data.getMonth(); // 0-11 (zero=janeiro)
          let ano = data.getFullYear(); // 2 dígitos
  
          data_2 = dia + "/" + mes + " / " + ano;
          if (mes == 1) {
            mes = 12;
          } else {
            mes = mes - 1;
          }
          data_1 = "01/" + mes + "/" + ano;
        } else {
          data_1 = start;
          data_2 = end;
        }
        this.intervalo = data_1 + " - " + data_2;
  
        let url = "lista/compras";
        const response = await this.$http.post(url, {
          status: this.statusSelecionado,
          data1: data_1,
          data2: data_2,
        });
  
        this.compras = response.data;
        
  
        let data_table = new Array();
  
        data_table = this.monta_dados(this.compras);
  
        $("#example")
          .DataTable({
            data: data_table,
            destroy: true,
            responsive: {
              details: {
                display: $.fn.dataTable.Responsive.display.modal({
                  header: function (row) {
                    var data = row.data();
                    return "Details for " + data[0] + " " + data[1];
                  },
                }),
                renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
              },
            },
            lengthChange: false,
            autoWidth: false,
            buttons: [
              {
                extend: "copy",
                text: "Copiar",
                className:
                  "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
              },
              {
                extend: "csv",
                text: "CSV",
                className: "btn btn-secondary buttons-csv buttons-html5",
              },
              {
                extend: "excel",
                text: "Excel",
                className: "btn btn-secondary buttons-excel buttons-html5",
              },
              {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-secondary buttons-pdf buttons-html5",
              },
              {
                extend: "print",
                text: "Imprimir",
                className: "btn btn-secondary buttons-print buttons-html5",
              },
              {
                extend: "colvis",
                text: "Exibir Colunas",
                className:
                  "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
              },
            ]
          })
          .buttons()
          .container()
          .appendTo("#example_wrapper .col-md-6:eq(0)");
        this.loading = false;
      },
  
      monta_dados(dados) {
        let data_table = new Array();
        let status_cor = "text-secundary";
        dados.forEach((compras_todas) => {
          let compra = compras_todas.compra;
          let aluno = compras_todas.aluno;
          if(compras_todas.aluno!=null)
          {
            
            let status;
            if (compra.carrinho_status == "paid") {
              status = "Paid";
              status_cor = "text-bold text-success";
            } else if (compra.carrinho_status == "refused") {
              status = "Refused";
              status_cor = "text-bold text-warning";
            } else if (compra.carrinho_status == "waiting_payment") {
              status = "Waiting_payment";
              status_cor = "text-bold text-info";
            } else if (compra.carrinho_status == "refunded") {
              status = "Refunded";
              status_cor = "text-bold text-danger";
            } else if (compra.carrinho_status == "processing") {
              status = "Processing";
              status_cor = "text-bold text-muted";
            }
  
            let metodo = '';
            if(compra.carrinho_forma == 1){
              metodo = 'Cartão';
            }
            else if(compra.carrinho_forma == 2){
              metodo = 'Boleto';
            }
            else if(compra.carrinho_forma == 3){
              metodo = 'Provi';
            }
            else if(compra.carrinho_forma == 4){
              metodo = 'Pix';
            }
            else if(compra.carrinho_forma == 5){
              metodo = 'Carrinho ADM';
            }
            else if(compra.carrinho_forma == 6){
              metodo = 'Multimeios';
            }
  
            data_table.push([
              "<span class='" +
                status_cor +
                "'>" +
                compra.agenda_id_antigo +
                " - " +
                compra.treinamento_nome +
                "</span>",
              "<span class='" + status_cor + "'>" + compra.carrinho_id + "</span>",
              "<span class='" + status_cor + "'>" + compra.compra_id + "</span>",
              "<span class='" + status_cor + "'>" + aluno.aluno_id + "</span>",
              "<span class='" + status_cor + "'>" + aluno.aluno_nome + "</span>",
              "<span class='" + status_cor + "'>" + aluno.aluno_cpf + "</span>",
              "<span class='" +
                status_cor +
                "'> R$ " +
                Number(compra.carrinho_valor).toFixed(2) +
                "</span>",
              "<span class='" +
                status_cor +
                "'> R$ " +
                Number(compra.compra_valor).toFixed(2) +
                "</span>",
              "<span class='" +
                status_cor +
                "'>" +
                compra.carrinho_data +
                "</span>",
              "<span class='" + status_cor + "'>" + status + "</span>",
              "<span class='" + status_cor + "'>" + metodo + "</span>",
              "<span class='" + status_cor + "'>" + compra.utm_campaign + "</span>",
              "<span class='" + status_cor + "'>" + compra.utm_source + "</span>",
              "<span class='" + status_cor + "'>" + compra.utm_content + "</span>",
              "<span class='" + status_cor + "'>" + compra.utm_medium + "</span>",
            ]);
  
          }
          
        });
  
        return data_table;
      },
  
      habilita_botoes() {
        let privilegio = "A";
        if (privilegio == "A") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = true;
        } else {
          if (privilegio == "LE") {
            this.btn_executar = true;
            this.btn_editar = true;
            this.btn_excluir = false;
          } else {
            if (privilegio == "LX") {
              this.btn_executar = false;
              this.btn_editar = false;
              this.btn_excluir = true;
            } else {
              this.btn_executar = false;
              this.btn_editar = false;
              this.btn_excluir = false;
            }
          }
        }
      },
    },
    filters: {
      dinheiro: function (value) {
        value = parseFloat(value);
        var dinheiro = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          useGrouping: true,
        });
        return dinheiro;
      },
    },
    mounted() {
      Date.prototype.addDias = function (dias) {
        this.setDate(this.getDate() + dias);
      };
      this.carrega_utms();
  
      let hoje = new Date();
      let amanha = new Date();
      amanha.addDias(1);
      this.date_start = hoje;
      this.date_end = amanha;
  
      this.lista_compras();
    },
  };
  </script>

<style scoped>
.content-wrapper{
    margin-left: 0 !important;
}
</style>
  
  