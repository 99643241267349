<template>
    <div id="rootEmissao">
  
      <div class="content-wrapper" v-show="hash">
        <!-- custom page body -->
        <div class="flex-sup customPageBody">
          <div class="flex-sup adm-card head-adm-card">
            <h1>Gerenciamento do DNC.class</h1>
            
          </div>
  
          <!-- > Notificações Personalizadas -->
          <div class="flex-sup adm-card">
            <h3>Editar cursos que estão no dnc.class</h3>
  
            <!-- filter/head line -->
            <div class="flex-sup adm-card-headline">
              <!-- left:filter cards -->
  
              <div class="flex-sup filter-left">
                <div class="flex-sup head-line-card">
                  <h5>Curso:</h5>
                  <b-form-select
                    v-model="curso_id_selecionado"
                    placeholder="Todos os cursos"
                  >
                    <b-form-select-option :value="curso.curso_id" v-for="curso in cursos_habilidade" :key="curso.curso_id">
                      {{curso.curso_titulo}}
                    </b-form-select-option>
                  </b-form-select>
                </div>
  
                <div class="flex-sup head-line-card add-hdLineCard">
                  <a
                    href=""
                    class="flex-sup add-button"
                    @click.prevent="adiciona_curso_class()"
                  >
                    <i class="fas fa-plus"></i>
                    <small> Adicionar ao class </small>
                  </a>
                </div>
              </div>
            </div>
  
            <!-- Tabela: notificações personalizadas -->
  
            <div class="table-wrapper">
              <table class="table table-bordered notify-table">
                <thead>
                  <tr>
                    <th class="td_titulo">Nome do curso</th>
                    <th class="td_data">Data de liberação</th>
                    <th class="td_edit">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(curso_class, index) in cursos_class" :key="index">
                    <!-- nome: -->
                    <td class="td_titulo">
                      <p class="table-output">{{curso_class.curso_titulo}}</p>
                    </td>
                    <!-- data: -->
                    <td class="td_data">
                      <p class="table-output">{{curso_class.data_created}}</p>
                    </td>
                    <!-- Opções: -->
                    <td class="td_edit">
                      <a href="" class="flex-sup edit-button" @click.prevent="remove_curso_class(curso_class.curso_id)">
                        <i class="fas fa-trash-alt"></i> <small> Remover </small>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  
    </div>
    <!-- /.content-wrapper -->
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        cursos_habilidade: [],
        curso_id_selecionado: 0,
        cursos_class: [],
        hash: 2,
        user: ''
      };
    },
    computed: {
    searchDncURL () {
      if (process.env.VUE_APP_ENV === 'development') {
        return 'http://localhost:3000'
      }

      if (process.env.VUE_APP_ENV === 'homolog') {
        return process.env.VUE_APP_API_APP_DNC
      }

      return process.env.VUE_APP_API_APP_DNC
    }
  },
    created() {
      this.fetch_cursos_habilidade()
      this.fetch_cursos_class()
      this.fetchDadosEmbed();
    },
    methods: {
    
        async fetchDadosEmbed() {
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.hash = urlParams.get('habilitado')
            this.user = urlParams.get('id')
        },

      async fetch_cursos_habilidade() {
        await this.$http.get(`${this.searchDncURL}class/cursos/habilidade`)
          .then((response) => {
            this.cursos_habilidade = response.data
            this.curso_id_selecionado = this.cursos_habilidade[0].curso_id
          })
      },
  
      async fetch_cursos_class() {
        await this.$http.get(`${this.searchDncURL}class/cursos`)
          .then((response) => {
            this.cursos_class = response.data
          })
      },
  
      async remove_curso_class(cursoId) {
        await this.$http.post(`${this.searchDncURL}class/curso/remover`, {curso_id: cursoId})
          .then(() => {
            this.fetch_cursos_class()
            alert('Curso excluído com sucesso!')
          })
      },
  
      async adiciona_curso_class() {
        await this.$http.post(`${this.searchDncURL}class/curso`, {curso_id: this.curso_id_selecionado})
          .then(() => {
            this.fetch_cursos_class()
            alert('Curso adicionado com sucesso!')
          })
      }
    },
  };
  </script>
  
  <style scoped>
  /* global: */
  .content-wrapper {
    position: relative;
    margin-left: 0 !important;
  }
  
  /* table: */
  .table-output {
    text-align: left;
  }
  
  .edit-button * {
    color: var(--rosa);
  }
  .edit-button small {
    font-family: monts-bo;
  }
  
  /* head: */
  
  .add-button {
    text-decoration: none !important;
  }
  .save-wrapper {
    align-items: center;
  }
  .save-btn {
    align-items: center;
    background: var(--verde);
    min-height: 34px;
    border-radius: 8px;
    padding: 5px 8px;
    text-decoration: none;
  }
  
  .save-btn * {
    color: var(--bsblue);
  }
  .save-btn small {
    font: 0.9em monts-me;
  }
  .save-btn span {
    margin-left: 8px;
  }
  
  .save-btn:hover {
    background: var(--bsblue);
    cursor: pointer;
  }
  .save-btn:hover * {
    color: var(--wht-1);
  }
  
  @media (max-width: 576px) {
    .right-save {
      width: 100%;
    }
    .save-wrapper {
      width: 100%;
      justify-content: flex-end;
      margin-top: 8px;
    }
  
    .add-hdLineCard {
      width: 100%;
      margin-top: 13px;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .add-button {
      width: max-content;
    }
    .add-button i {
      width: 34px;
      height: 34px;
    }
  
    .table-search,
    .search-card {
      width: 100%;
    }
  }
  </style>