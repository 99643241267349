<template>
    <div id="root" v-show="hash">  
      <!-- MODALS -->
        <b-modal size="xl" id="modal-usuario" hide-footer>
          <template #modal-title> {{ formTitulo }} </template>
          <div>
            <b-form>
              <div class="row">
                <b-form-input
                  v-show="false"
                  id="rid"
                  v-model="formAgenda.id"
                  trim
                  :disabled="bloqueado"
                ></b-form-input>
                <div class="col-sm-2">
                  <b-form-group id="input-1" label="Tipo" label-for="tipo">
                    <b-form-select
                      id="tipo"
                      v-model="formAgenda.tipo"
                      :options="tipos"
                    ></b-form-select>
                    <label class="text-danger small">{{
                      errosFormAgenda.tipo
                    }}</label>
                  </b-form-group>
                </div>
                <div class="col-sm-2">
                  <b-form-group
                    id="input-2"
                    label="Status"
                    label-for="status"
                  >
                    <b-form-select
                      id="status"
                      v-model="formAgenda.status"
                      :options="status"
                    ></b-form-select>
                    <label class="text-danger small">{{
                      errosFormAgenda.status
                    }}</label>
                  </b-form-group>
                </div>
                <div class="col-sm-2">
                  <b-form-group label="Polo" label-for="polo">
                    <b-form-select
                      id="polo"
                      :options="polos"
                      v-model="formAgenda.polo"
                      trim
                    ></b-form-select>
                    <label class="text-danger small">{{
                      errosFormAgenda.polo
                    }}</label>
                  </b-form-group>
                </div>
  
                <div class="col-sm-4">
                  <b-form-group label="Treinamento" label-for="treinamento">
                    <b-form-select
                      id="treinamento"
                      v-model="formAgenda.treinamento"
                      :options="treinamentos"
                      trim
                    ></b-form-select>
                    <label class="text-danger small">{{
                      errosFormAgenda.treinamento
                    }}</label>
                  </b-form-group>
                </div>
  
                <div class="col-sm-2">
                  <b-form-group id="input-2" label="Full" label-for="full">
                    <b-form-input
                      id="full"
                      v-model="formAgenda.full"
                      trim
                    ></b-form-input>
                    <label class="text-danger small">{{
                      errosFormAgenda.full
                    }}</label>
                  </b-form-group>
                </div>
              </div>
              <label for="">Days</label>
              <hr />
              <div class="row">
                <div class="input-group justify-content-md-end" style="margin-right:10px;">
                  <b-button
                    class="mr-1 btn-sm"
                    @click="adicionaData(datas.length)"
                    variant="primary"
                    >Adicionar Day</b-button
                  >
                  <b-button
                    class="btn-sm"
                    @click="removeData()"
                    variant="danger"
                    >Remover Day</b-button
                  >
                </div>
              </div>
              <div class="row p-2">
                <div
                  class="col-md-4 pr-1 m-0 p-0"
                  v-for="(data, index) in datas"
                  :key="index"                
                >
                  <div class="row border rounded m-1 pt-4 p-1  bg-light">
                      <div class="col-8">
                        <b-form-group :label="data.day+':'" label-for="" class="text-bold">                                    
                          <b-form-input
                            v-model="data.valor"
                            placeholder="Dia, Mês, Ano"
                            v-mask="'##/##/####'"
                          ></b-form-input>                                                       
                        <label class="text-danger small">{{ data.erro }}</label>  
                        </b-form-group>
                      </div>                 
                      <div class="col-4">
                        <b-form-group :label="'Início:'" label-for="" class="text-bold">                                    
                          <b-form-input
                            v-model="data.horario_inicio_day"
                            placeholder="Xh"
                          ></b-form-input>
                        <label class="text-danger small">{{ data.erro }}</label> 
                        </b-form-group>
                      </div>                
                    </div>
                  </div>
              </div>
              <div class="row justify-content-center">
                <div
                  class="spinner-border text-primary"
                  role="status"
                  v-show="loadingCadastro"
                ></div>
              </div>
              <br />
              <div class="row">
                <div class="input-group justify-content-md-end" style="margin-right:10px;">
                  <b-button
                    @click="limparformAgenda()"
                    id="btn-limpa-form"
                    variant="danger"
                    >Limpar formulário</b-button
                  >
                  <b-button
                    class="mr-1"
                    @click="enviarformAgenda()"
                    id="btn-cadastro"
                    :variant="varianteSubmit"
                    >{{ botaoSubmitUsuario }}</b-button
                  >
                </div>
              </div>
            </b-form>
          </div>
        </b-modal>
      <!-- FIM MODALS -->    
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Gerenciamento de Agenda</h1>
              </div>
  
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                  <li class="breadcrumb-item active">Usuários</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
  
        <div class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Gerenciamento e criação de Agendas de Formações e Cursos
                </h3>
              </div>
              <div class="card-body col-sm-12">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <button
                        class="btn btn-lg bs-btn btn-success"
                        v-show="!loadingDatatable && btn_executar"
                        data-toggle="modal"
                        data-target="#modal-success"
                        type="button"
                        @click="cadastraUsuario()"
                      >
                        <span>Nova Agenda <i class="fas fa-play"></i></span>
                        </button>
                      </div>
                    </div>  
                  </div>
                  
                  <div class="row" v-show="loadingDatatable">
                    <i class="spinner-border text-primary"></i>
                  </div>
  
                  <div class="table-responsive" v-show="!loadingDatatable">
                    <table id="tableUsuarios" class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Ações</th>
                          <th>Agenda id</th>
                          <th>Tipo</th>
                          <th>Status</th>
                          <th>Polo</th>
                          <th>Treinamento</th>
                          <th>Id antigo</th>
                          <th>Full</th>
                          <th>Data total</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.content-wrapper -->
  </template>
  
  <style scoped>
  .bs-btn {
    margin-left: 5px;
  }
  .content-wrapper{
    margin-left: 0 !important;
}
  </style>
  
  <script>
  var body = document.body;
  body.classList.add("hold-transition");
  // body.classList.add("dark-mode");
  body.classList.add("sidebar-mini");
  body.classList.add("layout-fixed");
  body.classList.add("layout-navbar-fixed");
  body.classList.add("layout-footer-fixed");
  
  var dados_checks = new Array();
  window.dados_checks = dados_checks;
  
  function abreModalEdicao(agenda_id) {
    window.elemento_vue.editaUsuario(agenda_id);
  }
  
  import jQuery from "jquery";
  const $ = jQuery;
  window.$ = $;
  
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  // import store from '@/store'
  
  export default {
    name: "Agenda",
    data() {
      return {
        dados_agendas: [],
        datas: [],
        treinamentos: [],
        polos: [],
        user: '',
        btn_executar: false,
        btn_editar: false,
        btn_excluir: false,
        loadingDatatable: false,
        loadingCadastro: false,
        loadingArquivo: false,
        loadingCompra: false,
        preloader_status: true,
        file: "",
        page: "Usuarios",
  
        formAgenda: {
          id: "",
          tipo: "",
          status: "",
          polo: "",
          treinamento: "",
          full: "",
          data_total: "",
        },
  
        errosFormAgenda: {
          tipo: "",
          status: "",
          polo: "",
          treinamento: "",
          full: "",
        },
        status: [
          { text: "Cancelado", value: "Cancelado" },
          { text: "Desativado", value: "Desativado" },
          { text: "Ativo", value: "Ativo" },
        ],
        tipos: [
          { text: "Inscrição", value: 1 },
          { text: "Pré-Inscrição", value: 2 },
        ],
  
        formTitulo: "",
        bloqueado: false,
        botaoSubmitUsuario: "",
        varianteSubmit: "",
        acao: "",
        modalTamanho: "lg",
        hash: '',
      };
    },
  
    methods: {
        async fetchDadosEmbed() {
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.hash = urlParams.get('habilitado')
            this.user = urlParams.get('id')
        },

      adicionaData(day) {
        if (this.datas.length < 35) {
          day = day + 1;
          this.datas.push({
            day: "Day " + day,
            valor: "",
            erro: "",
          });
        }
      },
  
      removeData() {
        if (this.datas.length > 1) {
          let indice = this.datas.length;
          this.datas.splice(indice - 1, 1);
        }
      },
  
      cadastraUsuario() {
        this.limparErrosForm();
        this.limparformAgenda();
        this.bloqueado = false;
        this.formTitulo = "Nova agenda";
        this.varianteSubmit = "primary";
        this.botaoSubmitUsuario = "Cadastrar";
        this.$bvModal.show("modal-usuario");
        this.acao = "novo";
        this.modalTamanho = "lg";
      },
  
      async editaUsuario(agenda_id) {
        this.limparErrosForm();
        this.limparformAgenda();
        this.formTitulo = "Editar agenda";
        this.bloqueado = true;
        this.botaoSubmitUsuario = "Salvar";
        this.varianteSubmit = "warning";
        this.acao = "editar";
        this.modalTamanho = "lg";
  
        let url = "lista/agenda/" + agenda_id;
  
        try {
          const response = await this.$http.get(url);
          let status;
          let dados_agenda = response.data.agendas;
          let dados_data = response.data.datas;
  
          if (dados_agenda.agenda_status == 0) {
            status = "Desativado";
          } else if (dados_agenda.agenda_status == 1) {
            status = "Ativo";
          } else {
            status = "Cancelado";
          }
          this.formAgenda.id = agenda_id;
          this.formAgenda.tipo = dados_agenda.agenda_tipo;
          this.formAgenda.status = status;
          this.formAgenda.polo = dados_agenda.polo_id;
          this.formAgenda.treinamento = dados_agenda.treinamento_id;
          this.formAgenda.full = dados_agenda.agenda_full;
          this.datas = [];
          let data_total = dados_agenda.agenda_data_total
            ? dados_agenda.agenda_data_total.split(",")
            : null;
  
          if (data_total) {
            dados_data.forEach((data) =>{
              // let day = index + 1;
              if(data.data_mes<10){
                data.data_mes = '0'+data.data_mes
              }
              if(data.data_dia<10){
                data.data_dia = '0'+data.data_dia
              }  
              this.datas.push({
                data_id: data.data_id,
                day: "Day " + data.data_refer_id,
                valor: data.data_dia+'/'+data.data_mes+'/'+data.data_ano,
                erro: "",
                horario_inicio_day:data.horario_inicio_day,
              })
            })
            // data_total.forEach((data, index) => {
            //   let day = index + 1;
            //   this.datas.push({
            //     day: "Day " + day,
            //     valor: data.trim(),
            //     erro: "",
            //     horario_inicio_day: 
            //   });
            // });
          }
          this.$bvModal.show("modal-usuario");
        } catch {
          let msg = "Erro ao buscar dados da agenda!";
          this.$bvModal.msgBoxOk(msg, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        }
      },
  
      limparErrosForm() {
        this.errosFormAgenda = {
          tipo: "",
          status: "",
          polo: "",
          treinamento: "",
          full: "",
        };
      },
  
      async enviarformAgenda() {
        let botaoCadastro = document.querySelector("#btn-cadastro");
        let botaoLimpaForm = document.querySelector("#btn-limpa-form");
        let erros = false;
        botaoCadastro.disabled = true;
        botaoLimpaForm.disabled = true;
  
        if (!this.formAgenda.tipo) {
          erros = true;
          this.errosFormAgenda.tipo = "Escolha o tipo!";
        } else {
          this.errosFormAgenda.tipo = "";
        }
  
        if (!this.formAgenda.status) {
          erros = true;
          this.errosFormAgenda.status = "Escolha o status!";
        } else {
          this.errosFormAgenda.status = "";
        }
  
        if (!this.formAgenda.polo) {
          erros = true;
          this.errosFormAgenda.polo = "Escolha o polo!";
        } else {
          this.errosFormAgenda.polo = "";
        }
  
        if (!this.formAgenda.treinamento) {
          erros = true;
          this.errosFormAgenda.treinamento = "Escolha o treinamento!";
        } else {
          this.errosFormAgenda.treinamento = "";
        }
  
        this.datas.forEach((data) => {
          // let ano
          // ano = data.valor.split("/")[3]
          let mes, dia;
          dia = data.valor.split("/")[0];
          mes = data.valor.split("/")[1];
          if (!data.valor) {
            erros = true;
            data.erro = "Preencha a data!";
          } else if (mes > 12 || dia > 31) {
            erros = true;
            data.erro = "Data inválida";
          } else {
            data.erro = "";
          }
        });
  
        let data_total = "";
        this.datas.forEach((data) => {
          data_total = data_total + data.valor + ", ";
        });
        data_total = data_total.replace(/,\s*$/, ""); //remove a ultima virgula
  
        if (!erros) {
          this.loadingCadastro = true;
          try {
            let retorno;
            let url;
            if (this.acao == "novo") {
              url = "cadastra/agenda";
              const response = await this.$http.post(url, {
                agenda: {
                  tipo: this.formAgenda.tipo,
                  status: this.formAgenda.status,
                  polo: this.formAgenda.polo,
                  treinamento: this.formAgenda.treinamento,
                  full: this.formAgenda.full,
                  data_total: this.datas,
                },
                page: this.page,
                profissional_id: this.user,
                acao: "cadastrar agenda",
              });
  
              retorno = response.data;
            } else if (this.acao == "editar") {
              url = "atualiza/agenda";
              const response = await this.$http.patch(url, {
                agenda: {
                  id: this.formAgenda.id,
                  tipo: this.formAgenda.tipo,
                  status: this.formAgenda.status,
                  polo: this.formAgenda.polo,
                  treinamento: this.formAgenda.treinamento,
                  full: this.formAgenda.full,
                  data_total: this.datas,
                },
                page: this.page,
                profissional_id: this.user,
                acao: "editar agenda",
              });
  
              retorno = response.data;
            }
  
            if (retorno.erro) {
              this.$bvModal.msgBoxOk(retorno.erro, {
                title: "Aviso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "danger",
                bodyBgVariant: "danger",
                footerBgVariant: "danger",
              });
            } else {
              this.$bvModal.msgBoxOk(retorno.mensagem, {
                title: "Sucesso!",
                okVariant: "light",
                okTitle: "ok",
                headerBgVariant: "success",
                bodyBgVariant: "success",
                footerBgVariant: "success",
              });
              this.$bvModal.hide("modal-usuario");
              this.listaAgendas();
            }
          } catch (err) {
            console.log(err);
            this.$bvModal.msgBoxOk("Ocorreu um erro!", {
              title: "Aviso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            });
          }
        }
        this.loadingCadastro = false;
        botaoCadastro.disabled = false;
        botaoLimpaForm.disabled = false;
      },
  
      limparformAgenda() {
        this.formAgenda.id = "";
        this.formAgenda.tipo = "";
        this.formAgenda.status = "";
        this.formAgenda.polo = "";
        this.formAgenda.treinamento = "";
        this.formAgenda.full = "";
        this.datas = [];
        this.datas.push({
          day: "Day 1",
          valor: "",
          erro: "",
        });
      },
  
      async listaOpcoes() {
        let url = "lista/opcoes";
  
        const response = await this.$http.get(url);
  
        let opcoes = response.data;
  
        opcoes.treinamentos.forEach((treinamento) => {
          this.treinamentos.push({
            text: treinamento.treinamento_nome,
            value: treinamento.treinamento_id,
          });
        });
  
        opcoes.polos.forEach((polo) => {
          this.polos.push({
            text: polo.polo_nome + " - " + polo.polo_estado,
            value: polo.polo_id,
          });
        });
      },
  
      async listaAgendas() {
        this.habilitaBotoes();
        this.loadingDatatable = true;
  
        let url = "lista/agendas";
        const response = await this.$http.get(url);
  
        this.dados_agendas = response.data;
  
        let data_table = new Array();
        this.dados_agendas.forEach((dados_agenda) => {
          let status_cor = "text-secundary";
  
          let botao_editar_agenda =
            "<button class='btn btn-warning btn-sm mr-1 open-usuario' id = " +
            dados_agenda.agenda_id +
            " type='button'><i id = " +
            dados_agenda.agenda_id +
            " class = 'fas fa-edit'></i>Editar</button>";
          let agenda_tipo;
          let agenda_status;
  
          dados_agenda.tipo == 1
            ? (agenda_tipo = "Inscrição")
            : (agenda_tipo = "Pré-Inscrição");
  
          if (dados_agenda.agenda_status == 0) {
            agenda_status = "Desativado";
          } else if (dados_agenda.agenda_status == 1) {
            agenda_status = "Ativo";
          } else {
            agenda_status = "Cancelado";
          }
  
          data_table.push([
            "<span class=" +
              status_cor +
              ">" +
              (this.btn_editar ? botao_editar_agenda : "") +
              "</span>",
  
            "<span class=" +
              status_cor +
              ">" +
              dados_agenda.agenda_id +
              "</span>",
            "<span class=" + status_cor + ">" + agenda_tipo + "</span>",
            "<span class=" + status_cor + ">" + agenda_status + "</span>",
            "<span class=" +
              status_cor +
              ">" +
              dados_agenda.polo_nome +
              " - " +
              dados_agenda.polo_estado +
              "</span>",
            "<span class=" +
              status_cor +
              ">" +
              dados_agenda.treinamento_nome +
              "</span>",
            "<span class=" +
              status_cor +
              ">" +
              dados_agenda.agenda_id_antigo +
              "</span>",
            "<span class=" + status_cor + ">" + dados_agenda.agenda_full
              ? dados_agenda.agenda_full
              : "" + "</span>",
            "<span class=" + status_cor + ">" + dados_agenda.agenda_data_total
              ? dados_agenda.agenda_data_total
              : "" + "</span>",
          ]);
        });
  
        $("#tableUsuarios")
          .DataTable({
            data: data_table,
            destroy: true,
            responsive: {
              details: {
                display: $.fn.dataTable.Responsive.display.modal({
                  header: function (row) {
                    var data = row.data();
                    return "Details for " + data[0] + " " + data[1];
                  },
                }),
                renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
              },
            },
            lengthChange: false,
            autoWidth: false,
            language: {
              sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
              sInfoEmpty: " ",
              sLengthMenu: "_MENU_ resultados por página",
              sSearch: "",
              sEmptyTable: "Nenhum registro encontrado",
              sInfoFiltered: "Filtrados de _MAX_ registros",
              sLoadingRecords: "Carregando...",
              sProcessing: "Processando...",
              sInfoPostFix: "",
              sInfoThousands: ".",
              sSearchPlaceholder: "Pesquisar",
              sZeroRecords:
                "Nenhum registro encontrado <i class='far fa-frown'></i>",
              paginate: {
                sNext: "Próximo",
                sPrevious: "Anterior",
              },
            },
            buttons: [
              {
                extend: "copy",
                text: "Copiar",
                className:
                  "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
              },
              {
                extend: "csv",
                text: "CSV",
                className: "btn btn-secondary buttons-csv buttons-html5",
              },
              {
                extend: "excel",
                text: "Excel",
                className: "btn btn-secondary buttons-excel buttons-html5",
              },
              {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-secondary buttons-pdf buttons-html5",
              },
              {
                extend: "print",
                text: "Imprimir",
                className: "btn btn-secondary buttons-print buttons-html5",
              },
              {
                extend: "colvis",
                text: "Exibir Colunas",
                className:
                  "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
              },
            ],
            drawCallback: function () {
              $(".open-usuario").on("click", function (event) {
                abreModalEdicao(event.target.id);
              });
            },
          })
          .buttons()
          .container()
          .appendTo("#tableUsuarios_wrapper .col-md-6:eq(0)"); //#example1_wrapper .col-md-6:eq(0)
        this.loadingDatatable = false;
      },
  
      habilitaBotoes() {
        let privilegio;
        let admin = true;
        
  
        if (admin) {
          privilegio = "A";
        }
  
        if (privilegio == "A") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = true;
        } else {
          if (privilegio == "LE") {
            this.btn_executar = true;
            this.btn_editar = true;
            this.btn_excluir = false;
          } else {
            if (privilegio == "LX") {
              this.btn_executar = false;
              this.btn_editar = false;
              this.btn_excluir = true;
            } else {
              this.btn_executar = false;
              this.btn_editar = false;
              this.btn_excluir = false;
            }
          }
        }
      },
    },
    filters: {
      dinheiro: function (value) {
        value = parseFloat(value);
        var dinheiro = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          useGrouping: true,
        });
        return dinheiro;
      },
    },
    created() {
      this.preloader_status = false;
    },
    mounted() {
      window.elemento_vue = this;
      this.listaAgendas();
      this.listaOpcoes();
      this.fetchDadosEmbed();
      this.adicionaData(0);
      this.habilitaBotoes();
      this.fetchDadosEmbed();
    },
  };
  </script>
  