<template>
    <div id="rootGrupos">
        <Menu />
        <Navbar />
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Configuração de Regras</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Configuração de Regras</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card">
                    <div class="row">
                        <!-- upload box -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-3">
                                    <div>
                                        <b-form-group label="Turmas" label-for="turma">
                                            <b-form-select 
                                                id="turma"
                                                @click.prevent
                                                :options="turmas"
                                                @change="lista_regras()"
                                                v-model="turma">
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div><br></div>
                                    <button class="btn btn-lg btn-success float-right" type="button" @click="adicionarRegra()">
                                        <span>Adicionar Regra <i class="fas fa-plus"></i></span>
                                    </button>
                                </div>
                            </div>
                            <!-- Exibição das provas -->
                            <div class="row">
                                <div class="mx-auto" v-show="loading">
                                    <div class="mx-auto text-center">
                                        <div class="spinner-border text-primary"></div>
                                    </div>
                                </div> 
                                <table id="regras_turma" class="table table-bordered table-hover" v-show="!loading">
                                    <thead>
                                    <tr>
                                        <th><i class="fas fa-plus"></i></th>
                                        <th>#</th>
                                        <th>Turma</th>
                                        <th>Nota Máxima Assignment</th>
                                        <th>Pontos Ranking Assignment</th>
                                        <th>Nota Máxima Deliverable</th>
                                        <th>Pontos Ranking Deliverable</th>
                                        <th>Nota Provas Intermediárias</th>
                                        <th>Nota Provas Finais</th>
                                    </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal size="lg" id="modal_regra" hide-footer>
            <template #modal-title> {{ form_titulo }} </template>
            <b-form @submit.prevent="salva_edicao_regra">
                <div class="row">
                    <div class="col-3">
                        <b-form-group id="input-1" label="Turma">
                            <b-form-select
                                v-show="!modo_edicao"
                                v-model="edit_turma_codigo"
                                :options="todas_turmas"
                            ></b-form-select>
                            <b-form-input
                                v-show="modo_edicao"
                                v-model="edit_turma_codigo"
                                :disabled="true"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-card>
                            <b-form-group
                            label="Assignment"
                            label-align="left"
                            >
                                <b-form-row>
                                    <b-col>
                                        <b-form-group 
                                        id="input-2" 
                                        description="Nota Máxima"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_asn_nota_max"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-27"
                                        description="Quantidade"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_asn_qtd"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-3"
                                        description="Pontos no Ranking"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_asn_ponto_max_ranking   "
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-card>
                            <b-form-group
                            label="Deliverable"
                            label-align="left"
                            > 
                                <b-form-row>
                                    <b-col>
                                        <b-form-group 
                                        id="input-4" 
                                        description="Nota Máxima"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_del_nota_max"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-30"
                                        description="Quantidade"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_del_qtd"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-26"
                                        description="Pontos no Ranking"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_del_ponto_max_ranking"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-card>
                            <b-form-group
                            label="Provas Intermediárias"
                            label-align="left"
                            >
                                <b-form-row>
                                    <b-col>
                                        <b-form-group 
                                        id="input-7"
                                        description="Aprovação (%)"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_porcentagem_aprovacao_intermediaria"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-6" 
                                        description="Nota Máxima"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_nota_provas_intermediarias"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-6" 
                                        description="Quantidade"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_pi_qtd"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-29"
                                        description="Pontos no Ranking"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_pi_ponto_ranking"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-29"
                                        description="Max Pontos Ranking"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_pi_ponto_ranking"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-card>
                            <b-form-group
                            label="Provas Finais"
                            label-align="left"
                            >
                                <b-form-row>
                                    <b-col>
                                        <b-form-group 
                                        id="input-9"
                                        description="Aprovação (%)"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_porcentagem_aprovacao_final"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-8" 
                                        description="Nota Máxima"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_nota_provas_finais"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-28"
                                        description="Quantidade"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_pf_qtd"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-28"
                                        description="Pontos no Ranking"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_pf_ponto_ranking"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group 
                                        id="input-29"
                                        description="Max Pontos Ranking"
                                        >
                                            <b-form-input
                                                trim
                                                v-model="edit_regra_pf_ponto_ranking"
                                                @keypress="isNumber($event)"
                                                required
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Projeto Excelência"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-10" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_projeto_excelencia"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-11"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_projeto_excelencia_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Maior Impacto"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-12" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_projeto_maior_impacto"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-13"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_projeto_maior_impacto_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Alunos Honra"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-14" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_alunos_honra"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-15"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_alunos_honra_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Alunos Excelência"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-16" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_alunos_excelencia"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-17"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_alunos_excelencia_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Top 3 Projetos"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-18" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_top3_projetos"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-19"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_top3_projetos_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Top 3 Alunos"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-20" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_top3"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-21"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_top3_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Top 5 Alunos"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-22" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_top5"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-23"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_top5_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                    <div class="col-6">
                        <b-card>
                            <b-form-group
                            label="Top 10 Alunos"
                            label-align="center"
                            >
                                <b-form-group 
                                id="input-24" 
                                description="Possui?"
                                >
                                    <b-form-select
                                        v-model="edit_regra_stp_top10"
                                        :options="op_binarias"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                                <b-form-group 
                                id="input-25"
                                description="Descrição"
                                >
                                    <b-form-textarea
                                        style="height: 60px;"
                                        trim
                                        v-model="edit_regra_stp_top10_desc"
                                        required
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                </div>
                <hr>
                <div class="input-group justify-content-md-end">
                    <div class="mx-auto" v-show="loading_modal">
                        <div class="mx-auto text-center">
                            <div class="spinner-border text-primary"></div>
                        </div>
                    </div> 
                    <b-button
                        class="mr-1 btn btn-danger"
                        id="btn-cadastro"
                        type="submit"
                    >Salvar</b-button>
                </div>
            </b-form>
        </b-modal>
        <Footer />
    </div>
</template>

<script>
function abreModalEdicao(prova_id) {
    window.elemento_vue.editaRegra(prova_id);
}
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {
            user: {},
            page: "Configuração de Regras",
            loading: false,
            loading_modal: false,
            regras: [],
            turmas: [{
                text: "Todas",
                value: "sem_filtro"
            }],
            op_binarias: [{
                    text: "Sim",
                    value: 1
                },{
                    text: "Não",
                    value: 0
                }
            ],
            todas_turmas: [],
            turma: "sem_filtro",
            modal_regra: true,
            modo_edicao: true,
            form_titulo: '',
            edit_turma_codigo: '',
            edit_regra_asn_nota_max: '',
            edit_regra_asn_ponto_max_ranking   : '',
            edit_regra_del_nota_max: '',
            edit_regra_del_ponto_max_ranking: '',
            edit_regra_nota_provas_intermediarias: '',
            edit_regra_porcentagem_aprovacao_intermediaria: '',
            edit_regra_nota_provas_finais: '',
            edit_regra_porcentagem_aprovacao_final: '',
            edit_regra_stp_projeto_excelencia_desc: '',
            edit_regra_stp_projeto_excelencia: '',
            edit_regra_stp_projeto_maior_impacto_desc: '',
            edit_regra_stp_projeto_maior_impacto: '',
            edit_regra_stp_top3_projetos_desc: '',
            edit_regra_stp_top3_projetos: '',
            edit_regra_stp_alunos_honra_desc: '',
            edit_regra_stp_alunos_honra: '',
            edit_regra_stp_alunos_excelencia_desc: '',
            edit_regra_stp_alunos_excelencia: '',
            edit_regra_stp_top10_desc: '',
            edit_regra_stp_top10: '',
            edit_regra_stp_top5_desc: '',
            edit_regra_stp_top5: '',
            edit_regra_stp_top3_desc: '',
            edit_regra_stp_top3: '',
            edit_regra_asn_qtd: '',
            edit_regra_del_qtd: '',
            edit_regra_pi_ponto_ranking: '',
            edit_regra_pf_ponto_ranking: '',
            edit_regra_banca: '',
            edit_regra_ne1_titulo: '',
            edit_regra_ne1_nota: '',
            edit_regra_ne1_qtd: '',
            edit_regra_ne1_ranking: '',
            edit_regra_ne2_titulo: '',
            edit_regra_ne2_nota: '',
            edit_regra_ne2_qtd: '',
            edit_regra_ne2_ranking: '',
            edit_regra_pi_qtd: '',
            edit_regra_pf_qtd: ''
        };
    },
    created() {
        this.busca_turmas();
        this.lista_regras();
        this.busca_todas_turmas();
    },
    methods: {
        // Carregamento do select das turmas
        async busca_turmas(){
            this.loading = true;
            let url = "busca/turmas/regras";
            const response = await this.$http.get(url);

            response.data.forEach(turma => {
                this.turmas.push({
                    text: turma.turma_codigo,
                    value: turma.turma_codigo
                });
            });

            this.loading = false;
        },
        // Carregamento do select de todas as turmas
        async busca_todas_turmas(){
            let url = "busca/todas/turmas";
            const response = await this.$http.get(url);

            response.data.forEach(turma => {
                this.todas_turmas.push({
                    text: turma.softcheck_turma_codigo,
                    value: turma.softcheck_turma_codigo
                });
            });
        },
        // Carregamento das informações da tabela
        async lista_regras(){
            this.loading = true;
            let url = "busca/todas/regras/turma";
            const response = await this.$http.post(url,{
                turma_codigo: this.turma
            });

            let data_table = new Array();
            response.data.forEach(regra => {
                // Botão para editar a regra
                let botao_editar=
                    "<button class='btn btn-warning btn-sm mr-1 open-edicao' id = " +
                    regra.regra_id +
                    " type='button'><i id = " +
                    regra.regra_id +
                    " class = 'fas fa-edit'></i>Editar</button>";

                data_table.push([
                    "<span>" + botao_editar + "</span>",
                    "<span>" + regra.regra_id + "</span>",
                    "<span>" + regra.turma_codigo + "</span>",
                    "<span>" + regra.regra_asn_nota_max + "</span>",
                    "<span>" + regra.regra_asn_ponto_max_ranking+ "</span>",
                    "<span>" + regra.regra_del_nota_max+ "</span>",
                    "<span>" + regra.regra_del_ponto_max_ranking+ "</span>",
                    "<span>" + regra.regra_nota_provas_intermediarias + "</span>",
                    "<span>" + regra.regra_nota_provas_finais + "</span>"
                ]);

                this.regras.push(regra);
            });

            $("#regras_turma").DataTable({
                data: data_table,
                destroy: true,
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                language: {
                    sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                    sInfoEmpty: " ",
                    sLengthMenu: "_MENU_ resultados por página",
                    sSearch: "",
                    sEmptyTable: "Nenhum registro encontrado",
                    sInfoFiltered: "Filtrados de _MAX_ registros",
                    sLoadingRecords: "Carregando...",
                    sProcessing: "Processando...",
                    sInfoPostFix: "",
                    sInfoThousands: ".",
                    sSearchPlaceholder: "Pesquisar",
                    sZeroRecords:
                    "Nenhum registro encontrado <i class='far fa-frown'></i>",
                    paginate: {
                        sNext: "Próximo",
                        sPrevious: "Anterior",
                    },
                },
                drawCallback: function() {
                    $(".open-edicao").on("click", function(event) {
                        abreModalEdicao(event.target.id);
                    });
                }
            });

            this.loading = false;
        },
        // Modal de edição de regra
        editaRegra(id_regra){
            this.form_titulo = "Editar Regra";
            this.modo_edicao = true;
            this.limpa_campos();

            // Montando as informações do modal de edição
            this.regras.forEach(regra => {
                if(regra.regra_id == id_regra){
                    this.edit_turma_codigo = regra.turma_codigo;
                    this.edit_regra_asn_nota_max = regra.regra_asn_nota_max;
                    this.edit_regra_asn_ponto_max_ranking= regra.regra_asn_ponto_max_ranking    ;
                    this.edit_regra_del_nota_max= regra.regra_del_nota_max;
                    this.edit_regra_del_ponto_max_ranking= regra.regra_del_ponto_max_ranking;
                    this.edit_regra_nota_provas_intermediarias = regra.regra_nota_provas_intermediarias;
                    this.edit_regra_porcentagem_aprovacao_intermediaria = regra.regra_porcentagem_aprovacao_intermediaria;
                    this.edit_regra_nota_provas_finais = regra.regra_nota_provas_finais;
                    this.edit_regra_porcentagem_aprovacao_final = regra.regra_porcentagem_aprovacao_final;
                    this.edit_regra_stp_projeto_excelencia_desc = regra.regra_stp_projeto_excelencia_desc;
                    this.edit_regra_stp_projeto_excelencia = regra.regra_stp_projeto_excelencia;
                    this.edit_regra_stp_projeto_maior_impacto_desc = regra.regra_stp_projeto_maior_impacto_desc;
                    this.edit_regra_stp_projeto_maior_impacto = regra.regra_stp_projeto_maior_impacto;
                    this.edit_regra_stp_top3_projetos_desc = regra.regra_stp_top3_projetos_desc;
                    this.edit_regra_stp_top3_projetos = regra.regra_stp_top3_projetos;
                    this.edit_regra_stp_alunos_honra_desc = regra.regra_stp_alunos_honra_desc;
                    this.edit_regra_stp_alunos_honra = regra.regra_stp_alunos_honra;
                    this.edit_regra_stp_alunos_excelencia_desc = regra.regra_stp_alunos_excelencia_desc;
                    this.edit_regra_stp_alunos_excelencia = regra.regra_stp_alunos_excelencia;
                    this.edit_regra_stp_top10_desc = regra.regra_stp_top10_desc;
                    this.edit_regra_stp_top10 = regra.regra_stp_top10;
                    this.edit_regra_stp_top5_desc = regra.regra_stp_top5_desc;
                    this.edit_regra_stp_top5 = regra.regra_stp_top5;
                    this.edit_regra_stp_top3_desc = regra.regra_stp_top3_desc;
                    this.edit_regra_stp_top3 = regra.regra_stp_top3;
                    this.edit_regra_asn_qtd = regra.regra_asn_qtd;
                    this.edit_regra_del_qtd = regra.regra_del_qtd;
                    this.edit_regra_pi_ponto_ranking = regra.regra_pi_ponto_ranking;
                    this.edit_regra_pf_ponto_ranking = regra.regra_pf_ponto_ranking;
                    this.edit_regra_banca = regra.regra_banca;
                    this.edit_regra_ne1_titulo = regra.regra_ne1_titulo;
                    this.edit_regra_ne1_nota = regra.regra_ne1_nota;
                    this.edit_regra_ne1_qtd = regra.regra_ne1_qtd;
                    this.edit_regra_ne1_ranking = regra.regra_ne1_ranking;
                    this.edit_regra_ne2_titulo = regra.regra_ne2_titulo;
                    this.edit_regra_ne2_nota = regra.regra_ne2_nota;
                    this.edit_regra_ne2_qtd = regra.regra_ne2_qtd;
                    this.edit_regra_ne2_ranking = regra.regra_ne2_ranking;
                    this.edit_regra_pi_qtd = regra.regra_pi_qtd;
                    this.edit_regra_pf_qtd = regra.regra_pf_qtd;
                }
            });

            this.modal_regra = true;
            this.$bvModal.show("modal_regra");
        },
        // Modal de inserção de regra
        adicionarRegra(){
            this.form_titulo ="Adicionar Regra";
            this.modo_edicao = false;
            this.limpa_campos();
            this.modal_regra = true;
            this.$bvModal.show("modal_regra");
        },
        // Função para limpar os campos
        limpa_campos(){
            this.edit_turma_codigo = '';
            this.edit_regra_asn_nota_max = '';
            this.edit_regra_asn_ponto_max_ranking= '';
            this.edit_regra_del_nota_max= '';
            this.edit_regra_del_ponto_max_ranking= '';
            this.edit_regra_nota_provas_intermediarias = '';
            this.edit_regra_porcentagem_aprovacao_intermediaria = '';
            this.edit_regra_nota_provas_finais = '';
            this.edit_regra_porcentagem_aprovacao_final = '';
            this.edit_regra_stp_projeto_excelencia_desc = '';
            this.edit_regra_stp_projeto_excelencia = '';
            this.edit_regra_stp_projeto_maior_impacto_desc = '';
            this.edit_regra_stp_projeto_maior_impacto = '';
            this.edit_regra_stp_top3_projetos_desc = '';
            this.edit_regra_stp_top3_projetos = '';
            this.edit_regra_stp_alunos_honra_desc = '';
            this.edit_regra_stp_alunos_honra = '';
            this.edit_regra_stp_alunos_excelencia_desc = '';
            this.edit_regra_stp_alunos_excelencia = '';
            this.edit_regra_stp_top10_desc = '';
            this.edit_regra_stp_top10 = '';
            this.edit_regra_stp_top5_desc = '';
            this.edit_regra_stp_top5 = '';
            this.edit_regra_stp_top3_desc = '';
            this.edit_regra_stp_top3 = '';
            this.edit_regra_asn_qtd = '';
            this.edit_regra_del_qtd = '';
            this.edit_regra_pi_ponto_ranking = '';
            this.edit_regra_pf_ponto_ranking = '';
            this.edit_regra_banca = '';
            this.edit_regra_ne1_titulo = '';
            this.edit_regra_ne1_nota = '';
            this.edit_regra_ne1_qtd = '';
            this.edit_regra_ne1_ranking = '';
            this.edit_regra_ne2_titulo = '';
            this.edit_regra_ne2_nota = '';
            this.edit_regra_ne2_qtd = '';
            this.edit_regra_ne2_ranking = '';
            this.edit_regra_pi_qtd = '';
            this.edit_regra_pf_qtd = '';
        },
        // Garante que o usuário colocará somente números na campo
        isNumber: function(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        // Envia as alterações para a edição/inserção
        async salva_edicao_regra(){
            if (this.edit_turma_codigo != ''){
                this.loading_modal = true;
                if (this.form_titulo == "Editar Regra"){
                    let url = "edita/regra";
                    await this.$http.post(url,{
                        page: this.page,
                        profissional_id: this.user.dados.profissional_id,
                        acao: "Edição de Regra",
                        turma_codigo: this.edit_turma_codigo,
                        regra_asn_nota_max: this.edit_regra_asn_nota_max,
                        regra_asn_ponto_max_ranking: this.edit_regra_asn_ponto_max_ranking    ,
                        regra_del_nota_max: this.edit_regra_del_nota_max,
                        regra_del_ponto_max_ranking: this.edit_regra_del_ponto_max_ranking,
                        regra_nota_provas_intermediarias: this.edit_regra_nota_provas_intermediarias,
                        regra_porcentagem_aprovacao_intermediaria: this.edit_regra_porcentagem_aprovacao_intermediaria,
                        regra_nota_provas_finais: this.edit_regra_nota_provas_finais,
                        regra_porcentagem_aprovacao_final: this.edit_regra_porcentagem_aprovacao_final,
                        regra_stp_projeto_excelencia_desc: this.edit_regra_stp_projeto_excelencia_desc,
                        regra_stp_projeto_excelencia: this.edit_regra_stp_projeto_excelencia,
                        regra_stp_projeto_maior_impacto_desc: this.edit_regra_stp_projeto_maior_impacto_desc,
                        regra_stp_projeto_maior_impacto: this.edit_regra_stp_projeto_maior_impacto,
                        regra_stp_top3_projetos_desc: this.edit_regra_stp_top3_projetos_desc,
                        regra_stp_top3_projetos: this.edit_regra_stp_top3_projetos,
                        regra_stp_alunos_honra_desc: this.edit_regra_stp_alunos_honra_desc,
                        regra_stp_alunos_honra: this.edit_regra_stp_alunos_honra,
                        regra_stp_alunos_excelencia_desc: this.edit_regra_stp_alunos_excelencia_desc,
                        regra_stp_alunos_excelencia: this.edit_regra_stp_alunos_excelencia,
                        regra_stp_top10_desc: this.edit_regra_stp_top10_desc,
                        regra_stp_top10: this.edit_regra_stp_top10,
                        regra_stp_top5_desc: this.edit_regra_stp_top5_desc,
                        regra_stp_top5: this.edit_regra_stp_top5,
                        regra_stp_top3_desc: this.edit_regra_stp_top3_desc,
                        regra_stp_top3: this.edit_regra_stp_top3,
                        regra_asn_qtd: this.edit_regra_asn_qtd,
                        regra_del_qtd: this.edit_regra_del_qtd,
                        regra_pi_ponto_ranking: this.edit_regra_pi_ponto_ranking,
                        regra_pf_ponto_ranking: this.edit_regra_pf_ponto_ranking,
                        regra_banca: this.edit_regra_banca,
                        regra_ne1_titulo: this.edit_regra_ne1_titulo,
                        regra_ne1_nota: this.edit_regra_ne1_nota,
                        regra_ne1_qtd: this.edit_regra_ne1_qtd,
                        regra_ne1_ranking: this.edit_regra_ne1_ranking,
                        regra_ne2_titulo: this.edit_regra_ne2_titulo,
                        regra_ne2_nota: this.edit_regra_ne2_nota,
                        regra_ne2_qtd: this.edit_regra_ne2_qtd,
                        regra_ne2_ranking: this.edit_regra_ne2_ranking
                    });
                }
                else if(this.form_titulo == "Adicionar Regra"){
                    let url = "insere/regra";
                    await this.$http.post(url,{
                        page: this.page,
                        profissional_id: this.user.dados.profissional_id,
                        acao: "Adição de Regra",
                        turma_codigo: this.edit_turma_codigo,
                        regra_asn_nota_max: this.edit_regra_asn_nota_max,
                        regra_asn_ponto_max_ranking: this.edit_regra_asn_ponto_max_ranking   ,
                        regra_del_nota_max: this.edit_regra_del_nota_max,
                        regra_del_ponto_max_ranking: this.edit_regra_del_ponto_max_ranking,
                        regra_nota_provas_intermediarias: this.edit_regra_nota_provas_intermediarias,
                        regra_porcentagem_aprovacao_intermediaria: this.edit_regra_porcentagem_aprovacao_intermediaria,
                        regra_nota_provas_finais: this.edit_regra_nota_provas_finais,
                        regra_porcentagem_aprovacao_final: this.edit_regra_porcentagem_aprovacao_final,
                        regra_stp_projeto_excelencia_desc: this.edit_regra_stp_projeto_excelencia_desc,
                        regra_stp_projeto_excelencia: this.edit_regra_stp_projeto_excelencia,
                        regra_stp_projeto_maior_impacto_desc: this.edit_regra_stp_projeto_maior_impacto_desc,
                        regra_stp_projeto_maior_impacto: this.edit_regra_stp_projeto_maior_impacto,
                        regra_stp_top3_projetos_desc: this.edit_regra_stp_top3_projetos_desc,
                        regra_stp_top3_projetos: this.edit_regra_stp_top3_projetos,
                        regra_stp_alunos_honra_desc: this.edit_regra_stp_alunos_honra_desc,
                        regra_stp_alunos_honra: this.edit_regra_stp_alunos_honra,
                        regra_stp_alunos_excelencia_desc: this.edit_regra_stp_alunos_excelencia_desc,
                        regra_stp_alunos_excelencia: this.edit_regra_stp_alunos_excelencia,
                        regra_stp_top10_desc: this.edit_regra_stp_top10_desc,
                        regra_stp_top10: this.edit_regra_stp_top10,
                        regra_stp_top5_desc: this.edit_regra_stp_top5_desc,
                        regra_stp_top5: this.edit_regra_stp_top5,
                        regra_stp_top3_desc: this.edit_regra_stp_top3_desc,
                        regra_stp_top3: this.edit_regra_stp_top3,
                        regra_asn_qtd: this.edit_regra_asn_qtd,
                        regra_del_qtd: this.edit_regra_del_qtd,
                        regra_pi_ponto_ranking: this.edit_regra_pi_ponto_ranking,
                        regra_pf_ponto_ranking: this.edit_regra_pf_ponto_ranking,
                        regra_banca: this.edit_regra_banca,
                        regra_ne1_titulo: this.edit_regra_ne1_titulo,
                        regra_ne1_nota: this.edit_regra_ne1_nota,
                        regra_ne1_qtd: this.edit_regra_ne1_qtd,
                        regra_ne1_ranking: this.edit_regra_ne1_ranking,
                        regra_ne2_titulo: this.edit_regra_ne2_titulo,
                        regra_ne2_nota: this.edit_regra_ne2_nota,
                        regra_ne2_qtd: this.edit_regra_ne2_qtd,
                        regra_ne2_ranking: this.edit_regra_ne2_ranking
                    });
                }

                this.loading_modal = false;
                this.modal_regra = false;
                this.$bvModal.hide("modal_regra");

                alert("Alterações salvas com sucesso!");
                location.reload();
            }
            else{
                alert("Campo de Turma obrigatório!");
            }
        }
    },
    mounted() {
        window.elemento_vue = this;
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    }
}
</script>