<template>
  <div>
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gestão de versionamento</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Gestão de cursos</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>        
      

      <!-- >> head -->      
      <div class="content-header  customPageBody">
        <div class="row gestao-head d-flex versions-head">

          <!-- > curso -->

          <div class="card-col card" v-if="cursos">
            <b-form-group label="Selecione o curso" label-for="status">
                <b-form-select v-model="curso_selecionado" name="status" @change="validar_curso()">
                  <b-form-select-option v-for="(curso, index) in cursos" :key="index" :value="curso">
                    {{curso.curso_titulo}}
                  </b-form-select-option>
                </b-form-select> 
            </b-form-group>
          </div>

          <!-- > turma_codigo -->
          

          <div class="card-col card" v-if="curso_selecionado && curso_selecionado.curso_categoria == 1">
            <b-form-group label="Código da turma (turma_codigo)" label-for="status">
              <b-form-select v-model="turma_codigo" name="status" @change="valida_turma()">
                <b-form-select-option  v-for="(turma, index) in turmas" :key="index" :value="turma">
                  {{turma.softcheck_turma_codigo}}
                </b-form-select-option>
              </b-form-select>

            </b-form-group>
          </div>

          <div class="card-col card" v-if="versoes.length">
            <b-form-group label="Versão do curso" label-for="status">
              <b-form-select v-model="versao_selecionado" name="status">
                <b-form-select-option   v-for="versao in versoes" :key="versao.versao_id" :value="versao.versao_id">
                  {{versao.versao_id}} - data criação: {{ versao.versao_data_criada }}
                </b-form-select-option>
              </b-form-select>

            </b-form-group>
          </div>

          <div class="flex-sup version-options">
              <b-button class="bs-btn" variant="success m-1" @click="versao_alteracao_confirmar()">Confirmar</b-button>
              <b-button class="bs-btn sheet-download" variant="success m-1" @click="baixar_versao()"> <i class="fas fa-cloud-download-alt"></i> Baixar versão selecionada</b-button>
          </div>
      

        </div>
      </div>
    


      <!-- >> body -->

      <div class="row form-body">

        <!-- : lista de versões -->
        <!-- <b-form-group label="Lista de versões"  class="radio-list">
          <b-form-radio v-model="versao_selecionado" name="some-radios" :value="versao.versao_id" v-for="versao in versoes" :key="versao.versao_id">Versão ID: {{ versao.versao_id }} - <small>data criação: {{ versao.versao_data_criada }}</small></b-form-radio>
        </b-form-group> -->

      </div>      
      
    </div>
    <Footer />
  </div>
</template>

<style>
.card-col{
  margin:2px;
  padding:10px;
}
</style>
<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      curso_selecionado: null,
      cursos: [],
      turma_codigo: null,
      turmas: null,
      versao_selecionado: "",
      versoes: []
    }
  },
  computed: {
    searchDncURL () {
      if (process.env.VUE_APP_ENV === 'development') {
        return 'http://localhost:3000'
      }

      if (process.env.VUE_APP_ENV === 'homolog') {
        return process.env.VUE_APP_API_APP_DNC
      }

      return process.env.VUE_APP_API_APP_DNC
    }
  },
  created() {
    this.listar_curso()
  },
  methods: {
    
    baixar_versao(){
      if(this.curso_selecionado.curso_categoria == 2){
        window.open(`${this.searchDncURL}conteudos/versao/ativa/exportar/${this.curso_selecionado.curso_id}`, '_blank')
      }else{
        window.open(`${this.searchDncURL}conteudos/versao/ativa/turma/${this.turma_codigo.softcheck_turma_codigo}/exportar/${this.curso_selecionado.curso_id}`, '_blank')
      }
    },
    validar_curso(){
      this.versoes = [];
      if(this.curso_selecionado.curso_categoria == 1){
        this.lista_turma_codigos();
      }else{
        this.turmas = null;
        this.turma_codigo = null;
        this.listar_versoes();
        this.versao_selecionado = this.curso_selecionado.versao_id_selecionado;
      }
    },

    listar_curso() {
      this.$http.get('curso/listar')
        .then((response) => {          
          this.cursos = response.data.cursos
        })
    },

    altera_selecao(){
      
    },

    async lista_turma_codigos() {
      await this.$http.get(`turma/codigo/listar/${this.curso_selecionado.curso_id}`)
        .then((response) => {
          this.turmas = response.data.turmas
          this.turma_codigo = null
        })
    },

    valida_turma(){
      if( this.turma_codigo != undefined ){
        this.listar_versoes();
        this.versao_selecionado = this.turma_codigo.versao_id;
      }
    },

    async listar_versoes() {
      await this.$http.get(`versoes/listar/${this.curso_selecionado.curso_id}`)
        .then((response) => {
          this.versoes = response.data
        })
    },

    async versao_alteracao_confirmar() {
      if(confirm("tem certeza?")){
        if(this.curso_selecionado.curso_categoria == 1){
          await this.$http.put('versao/alterar/turma', {
            turma_codigo: this.turma_codigo.softcheck_turma_codigo,
            versao_id: this.versao_selecionado
          }).then(() => {
            alert('Versão da Turma alterada com sucesso!')
          })
        }else{
          console.log(this.versao_selecionado);
          await this.$http.put('versao/alterar/curso', {
            curso_id: this.curso_selecionado.curso_id,
            versao_id: this.versao_selecionado
          }).then(() => {
            alert('Versão do Curso alterado com sucesso!')
          })
        }
        this.validar_curso();
      }

    }
  }
}
</script>