<template>
    <div id="rootGrupos">
        <Menu />
        <Navbar />
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Abono de Faltas</h1>
                        <div class="text-danger text-bold">
                            Não é necessário executar o script de presença após o abono!
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Abono de Faltas</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="row">
                    <!-- upload box -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col-2">
                                <div>
                                    <b-form-group label="Turma" label-for="turma">
                                        <b-form-select 
                                            @click.prevent
                                            :options="turmas"
                                            @change="seleciona_days(turma_codigo)"
                                            v-model="turma_codigo">
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="col-2" v-show="loading_days">
                                <div>
                                    <b-form-group label="Day" label-for="day">
                                        <b-form-select 
                                            @click.prevent
                                            :options="days_turma"
                                            @change="lista_alunos()"
                                            v-model="day">
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <!--
                            <div class="col-2">
                                <div><br></div>
                                <button class="btn btn-lg bs-btn btn-success" type="button" @click="abonar_falta()">
                                    <span>Executar <i class="fas fa-play"></i></span>
                                </button>
                            </div>
                            -->
                        </div>
                        <!-- Exibição dos alunos de cada turma -->
                        <div class="mx-auto">
                            <div class="row" v-show="loading">
                                <div class="mx-auto text-center">
                                    <div class="spinner-border text-primary"></div>
                                </div>
                            </div> 
                            <div v-show="!loading && mostrar_tabela">
                                <div class="row">
                                    <div class="col-8"> 
                                        <table id="alunos_da_turma" class="table table-bordered table-hover">
                                            <thead>
                                            <tr>
                                                <th>Abonar Falta</th>
                                                <th>RID</th>
                                                <th>Aluno</th>
                                            </tr>
                                            </thead>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                    <div class="col-4"> 
                                        <div class="row">
                                            <div class="col-6"> 
                                                <button class="btn btn-lg btn-secondary float-right" type="button" @click="limpar_selecao()">
                                                    <span>Limpar <i class="fas fa-trash-alt"></i></span>
                                                </button>
                                            </div>
                                            <div class="col-6">
                                                <button class="btn btn-lg btn-info" type="button" @click="confirmacao_abono()">
                                                    <span>Executar <i class="fas fa-play"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                        <br><br>
                                        <div class="row justify-content-center">
                                            <b-card bg-variant="light" style="width:350px" class="text-center" v-for="aluno in alunos_confirmacao" :key="aluno.aluno_id">
                                                {{ aluno.aluno_id }} - {{ aluno.aluno_nome }}
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal_confirmacao" hide-footer>
            <template #modal-title>Confirmação de Abono de Faltas</template>
            <b-form @submit.prevent="abonar_falta">
                <div class="row justify-content-center">
                    <h5>{{ turma_codigo }} - {{ day }}</h5>
                </div>
                <div class="row m-2">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="aluno in alunos_confirmacao" :key="aluno.aluno_id">
                            {{ aluno.aluno_id }} - {{ aluno.aluno_nome }}
                        </li>
                    </ul>
                </div>
                <hr>
                <div class="input-group justify-content-md-end">
                    <div class="mx-auto" v-show="loading_modal">
                        <div class="mx-auto text-center">
                            <div class="spinner-border text-primary"></div>
                        </div>
                    </div> 
                    <b-button
                        class="mr-1 btn btn-secondary"
                        @click="$bvModal.hide('modal_confirmacao');"
                    >Cancelar</b-button>
                    <b-button
                        class="mr-1 btn btn-danger"
                        id="btn-cadastro"
                        type="submit"
                    >Executar</b-button>
                </div>
            </b-form>
        </b-modal>
        
        <b-modal id="modal_aviso">
            <h5 class="text-center">{{ msg_modal }}</h5>
        </b-modal>
        <Footer />
    </div>
</template>

<style scoped>

    .bs-btn i {margin-left: 5px;}

</style>

<script>
var alunos_id = new Array();
window.alunos_id = alunos_id;

function controleTabela(marcado, aluno_id) {
    window.elemento_vue.controleTabela(marcado, aluno_id);    
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {
            user: {},
            page: "Abono de Faltas",
            turmas: [{
                text: "selecione",
                value: null
            }],
            days: [],
            day_turma: [],
            alunos_turma: [],
            days_turma: [],            
            turma_codigo: null,
            turma_id: null,
            day: null,
            alunos_id: [],
            alunos: [],
            alunos_confirmacao: [],
            loading: false,
            mostrar_tabela: false,
            msg_modal: '',
            loading_modal: false,
            loading_days: false
        };
    },
    created() {
        this.carrega_turmas_e_days();
    },
    methods: {
        // Select de turmas e seus days
        async carrega_turmas_e_days(){
            this.loading = true;
            this.loading_days = false;
            let url = "days/por/turma";
            const response = await this.$http.get(url);            
            response.data.forEach(turma => {
                this.turmas.push({
                    text: turma.turma_codigo,
                    value: turma.turma_codigo,
                    turma_id: turma.turma_id
                });
            });

            this.lista_days_turmas();
            this.loading = false;
            this.limpar_selecao(); 
              
        },

        async seleciona_days(turma_codigo){
            this.days_turma = []
            this.days.forEach(day => {
                if(day.turma_codigo==turma_codigo){
                    this.days_turma.push(day)
                }   
            
            });        
            this.limpar_selecao();                     
            this.loading_days = true;
        },

        async lista_days_turmas(){
            this.loading = true;
            let url = "lista/days/turmas";
            const response = await this.$http.get(url);
            response.data.forEach(days => {                
                this.days.push({
                    text: 'Day '+ days.data_refer_id,
                    value: days.data_refer_id,
                    turma_id: days.softcheck_turma_id,
                    turma_codigo: days.softcheck_turma_codigo
                });
            });          
                        
            this.loading = false;
        },
        

        // Select dinâmico para mostrar somente os days da turma
        async lista_alunos(){
            let turma_selecionada = this.turmas.find(el => el.value == this.turma_codigo);
            this.turma_id = turma_selecionada.turma_id;

            // Mostra a tabela de alunos e realiza a busca
            if (this.turma_id != null){
                this.loading = true;
                // Busca pelos alunos da turma
                let url = "busca/alunos/turma";
                const response = await this.$http.post(url,{
                    turma_codigo: this.turma_codigo
                });

                this.alunos_id = [];
                let data_table = new Array();
                response.data.forEach(aluno => {
                    this.alunos.push(aluno);
                    data_table.push([
                        "<span><input class='check-box' id='" + aluno.aluno_id + "' onchange='alunos_id[" +
                            aluno.aluno_id + "]=this.checked' type='checkbox'></span>",
                        "<span>" + aluno.aluno_id + "</span>",
                        "<span>" + aluno.aluno_nome + "</span>",
                    ]);
                });

                $("#alunos_da_turma").DataTable({
                    data: data_table,
                    destroy: true,
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    language: {
                        sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                        sInfoEmpty: " ",
                        sLengthMenu: "_MENU_ resultados por página",
                        sSearch: "",
                        sEmptyTable: "Nenhum registro encontrado",
                        sInfoFiltered: "Filtrados de _MAX_ registros",
                        sLoadingRecords: "Carregando...",
                        sProcessing: "Processando...",
                        sInfoPostFix: "",
                        sInfoThousands: ".",
                        sSearchPlaceholder: "Pesquisar",
                        sZeroRecords:
                        "Nenhum registro encontrado <i class='far fa-frown'></i>",
                        paginate: {
                            sNext: "Próximo",
                            sPrevious: "Anterior",
                        },
                    },
                    drawCallback: function() {
                        $(".check-box").on("click", function(event) {

                            controleTabela(this.checked, event.target.id);
                        });
                    }
                });

                this.mostrar_tabela = true;
                this.loading = false;
            }else{
                this.mostrar_tabela = false;
            }
        },
        // Controle de adição no checkbox
        controleTabela(marcado, aluno_id){            
            if(marcado){
                if (!this.alunos_id.find(element => element == aluno_id)){
                    this.alunos_id.push(aluno_id);
                }
            }else{
                let indice = this.alunos_id.indexOf(aluno_id);
                this.alunos_id.splice(indice, 1);
            }

            this.alunos_confirmacao = [];
            this.alunos_id.forEach(id => {
                this.alunos.forEach(aluno => {
                    if(id == aluno.aluno_id){     
                         if(!this.alunos_confirmacao.find(element=>element.aluno_id==aluno.aluno_id)){        
                            this.alunos_confirmacao.push(aluno);         
                        }                                              
                    }
                    
                });
            });
        
        },
        // Confirmação de abono de falta
        confirmacao_abono(){
            // Verificar se todos os campos estão preenchidos
            if (this.turma_codigo != null && this.turma_id != null && this.day != null && this.alunos_id.length > 0){
                this.alunos_confirmacao = [];
                this.alunos_id.forEach(id => {
                    this.alunos.forEach(aluno => {
                        if(id == aluno.aluno_id){
                            if(!this.alunos_confirmacao.find(element=>element.aluno_id==aluno.aluno_id)){
                                this.alunos_confirmacao.push(aluno);
                            }
                        }  
                    });
                });

                this.modal_confirmacao = true;
                this.$bvModal.show("modal_confirmacao");
            }
            else{
                this.msg_modal = "Preencha as informações em todos os campos!";
                this.$bvModal.show("modal_aviso");
            }
        },
        // Chamada da API para abonar a falta
        async abonar_falta(){
            this.loading = true;
            this.modal_confirmacao = false;
            this.$bvModal.hide("modal_confirmacao");

            let url = "abonar/falta";
            await this.$http.post(url,{
                page: this.page,
                profissional_id: this.user.dados.profissional_id,
                acao: "Abono de Falta dos RIDs " + this.alunos_id + " no " + this.day + " da " + this.turma_codigo,
                turma_codigo: this.turma_codigo,
                turma_id: this.turma_id,
                alunos_id: this.alunos_id.join(","),
                day: this.day
            })
            .then((response) => {
                console.log(response)
                if (response.data != "erro") {
                    this.msg_modal = "Abono de falta realizado com sucesso!";
                } else {
                    this.msg_modal = "Ocorreu um erro. Entre em contato com tech.";
                }
            });
            
            this.loading = false;
            this.$bvModal.show("modal_aviso");
        },
        // Limpar seleção de aluno
        limpar_selecao(){            
            this.alunos_id = [];                        
            this.alunos_confirmacao = [];  
            this.alunos_confirmacao.length = 0;
            $(':checkbox').each(function() {
                this.checked = false;                        
            });
            window.alunos_id = [];
            window.alunos_id.length =0; 
            
        }
    },
    mounted() {
        window.elemento_vue = this; 
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    }
}
</script>