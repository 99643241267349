import { render, staticRenderFns } from "./ControleGeral.vue?vue&type=template&id=bacb2842&scoped=true"
import script from "./ControleGeral.vue?vue&type=script&lang=js"
export * from "./ControleGeral.vue?vue&type=script&lang=js"
import style0 from "./ControleGeral.vue?vue&type=style&index=0&id=bacb2842&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bacb2842",
  null
  
)

export default component.exports