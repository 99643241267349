<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Liberação de Slots</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Liberação de Slots</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="container-fluid">
        <div class="card">
          <div class="row">
            <!-- upload box -->
            <div class="card-body col-sm-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-8">
                    <div>
                      <label>Escolha o Curso:</label>
                      <b-form-select v-model="curso_id_selecionado">
                        <b-form-select-option v-for="(curso, index) in cursos" :key="index" :value="curso.curso_id">
                          {{curso.curso_titulo}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="turmas && curso_categoria === 1">
                  <div class="col-4">
                    <div>
                      <b-form-select v-model="turma_codigo">
                        <b-form-select-option v-for="(turma, index) in turmas" :key="index" :value="turma.softcheck_turma_codigo">
                          {{turma.softcheck_turma_codigo}}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- /.card-header -->
          <div class="card-body">
            <div class="table-responsive" >
              <table id="example1" class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Número do Slot</th>
                    <th>Data de Liberação do Slot</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(slot, index) in slots" :key="index">
                    <td>{{slot.slot_numero}}</td>
                    <td>
                      <b-form-datepicker :id="index.toString()" v-model="slot.liberacao_data"></b-form-datepicker>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="row d-flex justify-content-end">
            <div class="col-4 d-flex justify-content-end m-3">
              <b-button class="bs-btn" v-b-modal.submitStatus variant="success m-1" @click.prevent="liberacao_slot_confirmar()">Confirmar</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>


<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      cursos: [],
      curso_id_selecionado: 0,
      slots: [],
      turma_codigo: null,
      turmas: null
    };
  },
  watch: {
    curso_id_selecionado() {
      if(this.cursos.filter((element) => element.curso_id === this.curso_id_selecionado).map((element) => element.curso_categoria)[0] === 2) {
        this.lista_slots()
      } else {
        this.lista_turma_codigos()
      }
    },
    turma_codigo() {
      this.lista_slots()
    },
    curso_categoria() {
      if(this.curso_categoria === 2) {
        this.turma_codigo = null
      }
    }
  },
  computed: {
    curso_categoria() {
      return this.cursos.filter((element) => element.curso_id === this.curso_id_selecionado).map((element) => element.curso_categoria)[0]
    }
  },
  created() {
    this.lista_cursos()
  },
  methods: {
    async lista_cursos() {
      return await this.$http.get('curso/listar')
        .then((response) => {
          this.cursos = response.data.cursos
          this.curso_id_selecionado = this.cursos[0].curso_id
        })
    },

    async lista_slots() {
      await this.$http.post('slot/listar', {
        'curso_id': this.curso_id_selecionado,
        'turma_codigo': this.turma_codigo
      })
        .then((response) => {
          this.slots = response.data.slots
        })
    },

    async lista_turma_codigos() {
      await this.$http.get(`turma/codigo/listar/${this.curso_id_selecionado}`)
        .then((response) => {
          this.turmas = response.data.turmas
          this.turma_codigo = this.turmas[0].softcheck_turma_codigo
        })
    },

    async liberacao_slot_confirmar() {
      await this.$http.post('slot/liberacao/confirmar', {
        slots: this.slots,
        curso_id: this.curso_id_selecionado,
        turma_codigo: this.turma_codigo
      }).then(() => {
        alert('Liberação confirmada com sucesso')
      })
    }
  },
}
</script>