<template>
  <div id="root">
    <Menu />
    <Navbar />

    <div class="content-wrapper" v-show="!habilitar_pagina">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Usuário sem permissão de acesso!</h1>
            </div>
            <div class="col-sm-12">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Gerenciamento de Cupons</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODALS -->
    <b-modal size="lg" id="modal_cupom" hide-footer>
      <template #modal-title> {{ form_titulo }} </template>
      <b-form @submit.prevent="salva_edicao_cupom">
        <div class="row">
          <div class="col-5">
            <b-form-group id="input-1" label="Código">
              <b-form-input
                trim
                v-model="edit_cupom_codigo"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group id="input-2" label="Desconto (%)">
              <b-form-input
                trim
                v-model="edit_cupom_desconto"
                @keypress="isNumber($event)"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group id="input-3" label="Tipo">
              <b-form-select
                v-model="edit_cupom_optional"
                :options="opcoes_cupons"
                required
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group id="input-6" label="Limite">
              <b-form-input
                trim
                v-model="edit_cupom_limite"
                @keypress="isNumber($event)"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group id="input-4" label="Status">
              <b-form-select
                v-model="edit_cupom_status"
                :options="status_edit"
                required
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group id="input-4" label="Data de Expiração">
              <b-form-datepicker
                id="example-datepicker"
                locale="pt-br"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                value-as-date
                size="sm"
                v-model="edit_cupom_data"
                class="mb-2 calendar-box"
              ></b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group id="input-4" label="Sem Juros">
              <b-form-select
                v-model="edit_cupom_sem_juros"
                :options="opcoes_juros"
                required
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-show="edit_cupom_optional == 1">
            <b-form-group id="input-4" label="Agenda ID Antigo">
              <b-form-select
                v-model="edit_agenda_id"
                :options="agenda_ids"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
              id="input-5"
              label="Treinamento"
              v-show="edit_cupom_optional == 2"
            >
              <b-form-select
                v-model="edit_treinamento_id"
                :options="treinamentos"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <hr />
        <div class="input-group justify-content-md-end">
          <div class="mx-auto" v-show="loading_modal">
            <div class="mx-auto text-center">
              <div class="spinner-border text-primary"></div>
            </div>
          </div>
          <b-button class="mr-1 btn btn-danger" id="btn-cadastro" type="submit"
            >Salvar</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <b-modal id="modal_aviso" hide-footer>
      <div class="text-center">
        <h5 class="text-center">{{ msg_modal }}</h5>
      </div>
      <div class="input-group justify-content-md-end">
        <b-button class="mr-1 btn btn-secondary" @click="reload_page()"
          >Ok</b-button
        >
      </div>
    </b-modal>

    <!-- FIM MODALS -->

    <div class="content-wrapper" v-show="habilitar_pagina">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gerenciamento de Cupons - Checkout 3.0</h1>
            </div>
            <div class="col-sm-12">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Gerenciamento de Cupons</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Gerenciamento e criação de cupons para geração de compras
              </h3>
            </div>
            <div class="card-body col-sm-12">
              <div class="row">
                <div class="col-2">
                  <div>
                    <b-form-group label="Status" label-for="cupom_status">
                      <b-form-select
                        id="cupom_status"
                        @click.prevent
                        :options="status"
                        @change="lista_cupons()"
                        v-model="cupom_status"
                      >
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-10" v-show="habilitar_botao">
                  <div><br /></div>
                  <button
                    class="btn btn-lg btn-success float-right"
                    type="button"
                    @click="adicionarCupom()"
                  >
                    <span>Adicionar Cupom <i class="fas fa-plus"></i></span>
                  </button>
                </div>
              </div>

              <div class="row" v-show="loading">
                <i class="spinner-border text-primary"></i>
              </div>

              <div class="table-responsive" v-show="!loading">
                <table
                  id="tabela_cupons"
                  class="table table-bordered table-hover"
                >
                  <thead>
                    <tr>
                      <th style="word-break: break-all">Editar</th>
                      <th style="word-break: break-all">#</th>
                      <th style="word-break: break-all">Código</th>
                      <th>Desconto</th>
                      <th style="word-break: break-all">Tipo</th>
                      <th style="word-break: break-all">Treinamento</th>
                      <th style="word-break: break-all">Agenda</th>
                      <th>Limite</th>
                      <th>Disponível</th>
                      <th style="word-break: break-all">Status</th>
                      <th style="word-break: break-all">Sem Juros</th>
                      <th style="word-break: break-all">Data Expiração</th>
                      <th style="word-break: break-all">Última Atualização</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
function abreModalEdicao(cupom_id) {
  window.elemento_vue.editaCupom(cupom_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user: {},
      page: "Gerenciamento de Cupons",
      habilitar_pagina: false,
      habilitar_botao: false,
      loading: false,
      loading_modal: false,
      cupom_status: "A",
      status: [
        {
          text: "Todos",
          value: null,
        },
        {
          text: "Ativos",
          value: "A",
        },
        {
          text: "Inativos",
          value: "I",
        },
      ],
      opcoes_cupons: [
        {
          text: "Agenda ID",
          value: 1,
        },
        {
          text: "Treinamento",
          value: 2,
        },
        {
          text: "Online",
          value: 3,
        },
        {
          text: "Todos",
          value: 4,
        },
      ],
      status_edit: [
        {
          text: "Ativo",
          value: "A",
        },
        {
          text: "Inativo",
          value: "I",
        },
      ],
      opcoes_juros: [
        {
          text: "Sim",
          value: 1,
        },
        {
          text: "Não",
          value: 0,
        },
      ],
      cupons: [],
      agenda_ids: [],
      treinamentos: [],
      modal_cupom: true,
      msg_modal: "",
      edit_cupom_codigo: "",
      edit_cupom_id: "",
      edit_cupom_desconto: 0,
      edit_cupom_optional: "",
      edit_agenda_id: "",
      edit_treinamento_id: "",
      edit_cupom_limite: 0,
      edit_cupom_qtd_disponivel: "",
      edit_cupom_status: "",
      edit_cupom_data: "",
      edit_cupom_sem_juros: 0,
      cupom_compra: "",
      compras: [],
      fields: [
        "data_inscricao",
        "nome",
        "curso",
        "e_mail",
        "telefone",
        "cpf",
        "valor",
        "metodo",
        "pagarme",
        "carrinho_id",
      ],
    };
  },
  created() {
    this.lista_cupons();
    this.listar_agenda_ids();
    this.listar_treinamentos();
  },
  methods: {
    // Select de Agenda IDs
    async listar_agenda_ids() {
      let url = "listar/agenda_ids_antigo";
      const response = await this.$http.get(url);

      response.data.forEach((agenda_id) => {
        this.agenda_ids.push({
          text: agenda_id.agenda_id_antigo + " - " + agenda_id.treinamento_nome,
          value: agenda_id.agenda_id_antigo,
          agenda_id_antigo: agenda_id.agenda_id_antigo,
          treinamento_id: agenda_id.treinamento_id
        });
      });
    },
    // Select de Treinamentos
    async listar_treinamentos() {
      let url = "listar/treinamentos/new";
      const response = await this.$http.get(url);

      response.data.forEach((treinamento) => {
        this.treinamentos.push({
          text:
            treinamento.treinamento_id + " - " + treinamento.treinamento_nome,
          value: treinamento.treinamento_id,
        });
      });
    },
    // Carregamento das informações da tabela
    async lista_cupons() {
      this.loading = true;

      let response;
      this.cupons = [];
      if (this.cupom_status == "A") {
        let url = "listar/cupons/ativos";
        response = await this.$http.get(url);
      } else if (this.cupom_status == "I") {
        let url = "listar/cupons/inativos";
        response = await this.$http.get(url);
      } else {
        let url = "listar/cupons/new";
        response = await this.$http.get(url);
      }

      let data_table = new Array();
      response.data.forEach((cupom) => {
        // Botão para editar cupom
        let botao_editar = " - ";
        if (this.habilitar_botao) {
          botao_editar =
            "<button class='btn btn-warning btn-sm mr-1 open-edicao' id = " +
            cupom.cupom_id +
            " type='button'><i id = " +
            cupom.cupom_id +
            " class = 'fas fa-edit'></i>Editar</button>";
        }

        // Ativo e Inativo
        let botao_ativo = "";
        if (cupom.cupom_status == "I") {
          botao_ativo =
            "<div class='btn btn-danger disabled' style='cursor:not-allowed !important;pointer-events: none !important;width:82px;'>Inativo</div>";
        } else if (cupom.cupom_status == "A") {
          botao_ativo =
            "<div class='btn btn-success disabled' style='cursor:not-allowed !important;pointer-events: none !important;width:82px;'>Ativo</div>";
        }

        // Data de Expiração
        let data_limite = cupom.cupom_data_limite;
        if (cupom.cupom_data_limite == null) {
          data_limite = "";
        }

        // Cartão de crédito sem juros
        let sem_juros = "Sim";
        if (cupom.cupom_sem_juros == 0) {
          sem_juros = "Não";
        }

        // Agenda ID
        let agenda_a = cupom.agenda_id;
        if (cupom.agenda_id == null){
          agenda_a = '';
        }
        
        // Treinamento
        let treinamento_o = cupom.treinamento_id + " - " + cupom.treinamento_nome;
        if (cupom.treinamento_id == null){
          treinamento_o = '';
        }

        data_table.push([
          "<span>" + botao_editar + "</span>",
          "<span>" + cupom.cupom_id + "</span>",
          "<span>" + cupom.cupom_codigo + "</span>",
          "<span>" +
            Math.round(cupom.cupom_desconto_porcentagem * 100) / 100 +
            "%</span>",
          "<span>" + cupom.cupom_tipo + "</span>",
          "<span>" + treinamento_o + "</span>",
          "<span>" + agenda_a + "</span>",
          "<span>" + cupom.cupom_limite + "</span>",
          "<span>" + cupom.cupom_qtd_disponivel + "</span>",
          "<span>" + botao_ativo + "</span>",
          "<span>" + sem_juros + "</span>",
          "<span>" + data_limite + "</span>",
          "<span>" + cupom.data_atualizacao + "</span>",
        ]);

        this.cupons.push(cupom);
      });

      $("#tabela_cupons").DataTable({
        data: data_table,
        destroy: true,
        responsive: true,
        lengthChange: false,
        autoWidth: false,
        language: {
          sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
          sInfoEmpty: " ",
          sLengthMenu: "_MENU_ resultados por página",
          sSearch: "",
          sEmptyTable: "Nenhum registro encontrado",
          sInfoFiltered: "Filtrados de _MAX_ registros",
          sLoadingRecords: "Carregando...",
          sProcessing: "Processando...",
          sInfoPostFix: "",
          sInfoThousands: ".",
          sSearchPlaceholder: "Pesquisar",
          sZeroRecords:
            "Nenhum registro encontrado <i class='far fa-frown'></i>",
          paginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
          },
        },
        drawCallback: function () {
          $(".open-edicao").on("click", function (event) {
            abreModalEdicao(event.target.id);
          });
        },
      });

      this.loading = false;
    },
    // Modal de edição de cupom
    editaCupom(cupom_id) {
      this.form_titulo = "Editar Cupom";
      this.limpa_campos();

      // Montando as informações do modal de edição
      this.cupons.forEach((cupom) => {
        if (cupom.cupom_id == cupom_id) {
          this.edit_cupom_id = cupom.cupom_id;
          this.edit_cupom_codigo = cupom.cupom_codigo;
          this.edit_cupom_desconto = cupom.cupom_desconto_porcentagem;
          this.edit_cupom_optional = cupom.cupom_tipo_id;
          this.edit_treinamento_id = cupom.treinamento_id;
          this.edit_cupom_limite = cupom.cupom_limite;
          this.edit_cupom_status = cupom.cupom_status;
          this.edit_cupom_data = cupom.cupom_data_limite;
          this.edit_agenda_id = cupom.agenda_id;
          this.edit_cupom_sem_juros = cupom.cupom_sem_juros;
        }
      });

      this.modal_cupom = true;
      this.$bvModal.show("modal_cupom");
    },
    // Modal de inserção de cupom
    adicionarCupom() {
      this.form_titulo = "Adicionar Cupom";
      this.limpa_campos();
      this.modal_cupom = true;
      this.$bvModal.show("modal_cupom");
    },
    // Função para limpar os campos
    limpa_campos() {
      this.edit_cupom_id = "";
      this.edit_cupom_codigo = "";
      this.edit_cupom_desconto = 0;
      this.edit_cupom_optional = "";
      this.edit_treinamento_id = "";
      this.edit_cupom_limite = 0;
      this.edit_cupom_qtd_disponivel = "";
      this.edit_cupom_status = "";
      this.edit_agenda_id = "";
      this.edit_cupom_sem_juros = "";
      this.edit_cupom_data = "";
    },
    // Garante que o usuário colocará somente números na campo
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // Envia as alterações para a edição/inserção
    async salva_edicao_cupom() {
      this.loading_modal = true;

      // Verificar infos de agenda_id e treinamento_id
      if (this.edit_cupom_optional == 1){
        this.agenda_ids.forEach(agenda => {
          if (agenda.agenda_id_antigo == this.edit_agenda_id){
            this.edit_treinamento_id = agenda.treinamento_id;
          }
        });
      }
      else if (this.edit_cupom_optional == 2){
        this.edit_agenda_id = null;
      }
      else{
        this.edit_treinamento_id = null;
        this.edit_agenda_id = null;
      }

      if (this.form_titulo == "Editar Cupom") {
        let url = "editar/cupom";
        await this.$http.post(url, {
          page: this.page,
          profissional_id: this.user.dados.profissional_id,
          cupom_id: this.edit_cupom_id,
          cupom_codigo: this.edit_cupom_codigo,
          cupom_desconto_porcentagem: this.edit_cupom_desconto,
          cupom_tipo_id: this.edit_cupom_optional,
          agenda_id: this.edit_agenda_id,
          cupom_limite: this.edit_cupom_limite,
          cupom_status: this.edit_cupom_status,
          cupom_sem_juros: this.edit_cupom_sem_juros,
          cupom_data_limite: this.edit_cupom_data,
          treinamento_id: this.edit_treinamento_id,
        });
      } else if (this.form_titulo == "Adicionar Cupom") {
        let url = "inserir/cupom";
        await this.$http.post(url, {
          page: this.page,
          profissional_id: this.user.dados.profissional_id,
          cupom_id: this.edit_cupom_id,
          cupom_codigo: this.edit_cupom_codigo,
          cupom_desconto_porcentagem: this.edit_cupom_desconto,
          cupom_tipo_id: this.edit_cupom_optional,
          agenda_id: this.edit_agenda_id,
          cupom_limite: this.edit_cupom_limite,
          cupom_status: this.edit_cupom_status,
          cupom_sem_juros: this.edit_cupom_sem_juros,
          cupom_data_limite: this.edit_cupom_data,
          treinamento_id: this.edit_treinamento_id,
        });
      }

      this.loading_modal = false;
      this.modal_cupom = false;
      this.$bvModal.hide("modal_cupom");

      this.msg_modal = "Alterações salvas com sucesso!";
      this.$bvModal.show("modal_aviso");
    },
    // Recarrega a tabela
    reload_page() {
      this.lista_cupons();
      this.$bvModal.hide("modal_aviso");
    },
  },
  mounted() {
    window.elemento_vue = this;
    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);

    this.user.permissoes.forEach((permissao) => {
      if (
        permissao.privilegio_tipo_id == 6 ||
        permissao.privilegio_tipo_id == 2
      ) {
        this.habilitar_pagina = true;
        if (
          permissao.privilegio_status == "LE" ||
          permissao.privilegio_status == "A"
        ) {
          this.habilitar_botao = true;
        }
      }
    });
  },
};
</script>