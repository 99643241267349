<template>
    <div>
      <!-- >> header: -->
    <nav class="navbar navbarResetSenha">
        <span class="navbar-brand mb-0 h1"><img src="../assets/img/Admin.png" alt=""></span>
    </nav>

    <main class="flex-sup sec-wrapp main-wrapp">
      <div id="pageLimiter">
        <div class="loginBox">
          <div class="row">
          <div class="col-12">
            <h3>Cadastro de nova senha</h3>
          </div>
        </div>
        <hr class="hr-spacer spc-hei-8">
        <div class="row">
          <div class="col-12">
            <label for="">Nova senha:</label>
          </div>
          <div class="col-12">
            <input v-model="password" type="text" class="col-12 form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="">Confirmar nova senha:</label>
          </div>
          <div class="col-12">
            <input v-model="passwordConfirm" type="text" class="col-12 form-control">
          </div>
        </div>
        <hr class="hr-spacer spc-hei-8">
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary col-12" @click.prevent="funcionario_reset_confirmar()">Confirmar</button>
          </div>
        </div>
        </div>
      </div>
      <div class="row txtDireitosReservados">
        <div class="col-12">
          © 2021 DNC.Group - Todos os direitos reservados. | DTC Treinamentos & Consultoria LTDA | CNPJ: 27.995.577/0001-37
  R. Justino Cobra, 61 - Vila Ema | São José dos Campos - SP | CEP 12243-030
        </div>
    </div>
    </main>
    </div>
  </template>

<script>
  export default {
    data(){
      return{
        asyncData ({ redirect, route }) {
          const token = route.query.token

          if (!token) {
            redirect('/')
          }
        },
        password:'',
        passwordConfirm:'',
        token:''
      }
    },
  mounted () {
    this.token = this.$route.query.token
  },
    methods:{
      funcionario_reset_confirmar(){
        if(this.senha_requisito_minimo() && this.senha_requisito_diferente()){
          this.senha_nova_salvar();
        }
      },

      senha_requisito_minimo () {
      if (this.password.length < 5) {
        alert('A senha deve conter pelo menos 6 caracteres')
        return 0
      } else {
        return 1
      }
    },

    senha_requisito_diferente() {
      if (this.password !== this.passwordConfirm) {
        alert('As senhas precisam ser iguais!')
        return 0
      } else {
        return 1
      }
    },

    async senha_nova_salvar() {
      await this.$http.post('atualizar/senha', { token: this.token, senha: this.password })
        .then(() => {
          alert('Senha resetada com sucesso!')
          this.$router.push('/')
        }).catch(() => {
          alert('Esse link já foi usado!')
        })
    },
    }
  }
</script>
  
  <style lang="css">
    main{
      display: flex;
      align-items: center;
      flex-direction:column;
    }

    h3{
      text-align: center;
      color: var(--dnc-preto);
      font-weight: 600;
    }

    .navbarResetSenha{
        background-color: #303030;
    }

    #pageLimiter{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        height: 70vh;
    }

    .loginBox{
        background-color: aliceblue;
        padding: 30px 30px;
    }

    .txtDireitosReservados{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      text-align: center;
      font: .8em monts-re;
      color: #817c8a;
      color: var(--blc-5);
      font-weight: 600;
    }
  </style>
  