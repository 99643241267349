<template>
    <div id="rootGrupos">
        <div class="container text-center" v-show="habilitar_pagina">
            <div class="row justify-content-center align-items-center" style="padding-top:20px;background-color:#f4f4f4;">
                <div class="col-3"></div>
                <div class="col-6">
                    <h2 style="color:#1e2f91; text-shadow: 1px 1px 2px grey;"><strong>{{turma}}</strong></h2> 
                    <h2 style="color:#1e2f91; text-shadow: 1px 1px 2px grey;"><strong>GRUPOS DINÂMICA</strong></h2>
                </div>
                <div class="col-3">
                    <img src="../assets/img/logo-azul.png" class="d-inline-block align-top" height="60px" alt="">
                </div>
            </div>
            <div v-for="grupo in grupo_aluno" :key=grupo.grupo_codigo>
                <div class="row mb-2 bg-white" style="padding-top:30px;"></div>
                <div class="row justify-content-center">
                    <table id="tabela_cupons" width="100%">
                        <thead bgcolor="#e5588a" style="color:white;">
                            <tr>
                                <th width="20%"><h5><strong>RID</strong></h5></th>
                                <th width="60%"><h5><strong>Nome</strong></h5></th>
                                <th width="20%"><h5><strong>Grupo</strong></h5></th>
                            </tr>
                        </thead>
                        <tbody bgcolor="#f4f4f4" style="font-size: 20px; color:#1e2f91;">
                            <tr v-for="aluno in grupo.alunos" :key=aluno.rid>
                                <td> 
                                    <strong>{{aluno.rid}}</strong>
                                </td>
                                <td> 
                                    <strong>{{aluno.nome}}</strong>
                                </td>
                                <td> 
                                    <strong>{{aluno.grupo_codigo}}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

    .bs-btn i {margin-left: 8px;}

</style>

<script>

export default {
    data() {
        return {
            user: {},
            habilitar_pagina: false,
            turma: null,
            day: null,
            grupo_aluno: []
        };
    },
    computed: {
    },
    created() {
    },
    methods: {
        // Busca das turmas salvas no banco de dados
        async gera_impressao(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            this.turma = params.get("turma");
            this.day = params.get("day");

            let url = "listar/aluno/grupo/" + this.turma + "/" + this.day;
            const response = await this.$http.get(url);

            response.data.forEach(turma => {
                let alunos = [];
                let aux_grupo = [];

                // Separo os alunos
                alunos = turma.alunos.split(',');
                alunos.forEach(aluno => {
                    let infos = aluno.split(" - ");
                    aux_grupo.push({
                        grupo_codigo: turma.grupo_codigo,
                        nome: infos[1],
                        rid: infos[0]
                    });
                });
                // Separo pro grupo
                this.grupo_aluno.push({
                    grupo_codigo: turma.grupo_codigo,
                    alunos: aux_grupo
                });
            });

            // Delay para montar as tabelas antes de chamar a impressão
            const delay = ms => new Promise(res => setTimeout(res, ms));
            await delay(2000);
            window.print();
        }
    },
    mounted() {
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);

        // Somente operações conseguem ver esta página
        this.user.permissoes.forEach(permissao => {
            if (permissao.privilegio_tipo_id == 2){
                this.habilitar_pagina = true;
                this.gera_impressao();
            }
        });
    }
}
</script>