<template>
  <div class="hold-transition login-page" style="background-color: #22409A !important">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div v-show="mostra_card">
      <b-card
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem"
        class="mb-2"
      >
      <img src="../assets/img/dnc_studio.png" class="img-fluid">
        <b-card-text> Digite o seu CPF: </b-card-text>
        
            <b-form-input 
            v-model="cpf"
            v-mask="'###.###.###-##'" 
            placeholder="Digite CPF">
            </b-form-input>
            <br>
            <b-button @click="marca_presenca()" 
            class="w-100"
            id="btn-marcar"
            variant="primary">Marcar presença</b-button>            
      </b-card>
    </div>
      <div v-show="!mostra_card" >
        <b-card
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem"
          class="mb-2"
        >
        <img src="../assets/img/dnc_masp.png" class="img-fluid">
        <center>
          <b-card-text> Muito obrigado! </b-card-text>
          <i class="fas fa-smile-wink fa-5x" style="color:#ffbb59;" ></i>
        </center>
      </b-card>   
    </div>
  <div  v-show="mostra_datas">
      <b-card
        style="width: 20rem"
        class="mb-2"
      >
      <b>Dias do mês com presenças registradas no MASP:</b>
      <p></p>
        <dl>          
          <dt v-for="presenca in minhas_presencas" :key="presenca.presenca_masp_id"> 
            {{presenca.presenca_masp_dthr.split("-")[2]}}/{{presenca.presenca_masp_dthr.split("-")[1]}}
          </dt>
        </dl>
      </b-card>
  </div>
  </div>
</template>

<style>
body{
  background-color: '#22409A' !important;
}
</style>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'

export default {
  name: "PresencaGravacao",
  components: {},
  data() {
    return {
      cpf: "",
      mostra_card: true,
      mostra_datas: false,
      value: '',
      minhas_presencas: [],
      dias_semanas: '',
    };    
  },
  methods: {
    
    async marca_presenca(){
        let retorno
            let url = "registrar/presenca/studio";
            const response = await this.$http.post(url, {
                profissional_cpf: this.cpf,
            });
            retorno = response.data;
            this.minhas_presencas = retorno.presencas;  
            if(retorno.erro!=0){
              this.$bvModal.msgBoxOk(retorno.mensagem, {title:'Alerta', okVariant:'success'});
              this.mostra_datas= true;
            }
            else{
              this.$bvModal.msgBoxOk(retorno.mensagem, {title:'Sucesso', okVariant:'success'});
              this.mostra_card= false;              
            }
            this.cpf="";   
            this.mostra_datas= true;

    },
  
    
  },

 
};
</script>