<template>
    <div id="root" v-show="hash">
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Vendas por vendedor - Checkout 3.0</h1>
              </div>
  
              
            </div>
          </div>
        </div>
  
        <div class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Veja as vendas realizadas por vendedor do Checkout 3.0!
                </h3>
              </div>
              <div class="card-body col-sm-12">
                <div class="form-group">
                  <div class="row" v-show="loading">
                    <i class="spinner-border text-primary"></i>
                  </div>
  
                  <div class="table-responsive" v-show="!loading">
                    <table
                      id="example"
                      class="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>Carrinho pagamento id</th>
                          <th>Nome aluno</th>
                          <th>Aluno telefone</th>
                          <th>Carrinho status</th>
                          <th>Compra valor original</th>
                          <th>Compra valor desconto</th>
                          <th>Valor Compra</th>
                          <th>Cupom codigo</th>
                          <th>Agenda id</th>
                          <th>Treinamento nome</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
    </div>
    <!-- /.content-wrapper -->
  </template>
  
  <style scoped>
  .bs-btn i {
    transform: translateX(-5px);
  }
  
  .bs-btn-primary {
    color: var(--wht-3);
  }
  .bs-btn-primary:hover {
    color: #203d92;
  }
  </style>
  
  
  <script>
  var body = document.body;
  body.classList.add("hold-transition");
  body.classList.add("sidebar-mini");
  body.classList.add("layout-fixed");
  body.classList.add("layout-navbar-fixed");
  body.classList.add("layout-footer-fixed");
  
  var dados_checks = new Array();
  window.dados_checks = dados_checks;
  
  import jQuery from "jquery";
  const $ = jQuery;
  window.$ = $;
  
  
  export default {
    name: "Vendas",
    data() {
      return {
        profissional_id: "",
        loading: false,
        data_table: [],
        content: null,
        hash:'',
        user: '',
      };
    },
    created() {
      this.preloader_status = false;
      this.fetchDadosEmbed(); 
    },
    methods: {
        fetchDadosEmbed() {            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.hash = urlParams.get('habilitado')
            this.user = urlParams.get('id')
        },
      // Vendas por vendedor
      async listarVendasPorVendedor() {
        let url = "listar/vendas/vendedor/carrinho";
        const response = await this.$http.post(url, {
          profissional_id: this.profissional_id,
        });
  
        this.vendas = response.data;
        let data_table = new Array();
        data_table = this.monta_dados(this.vendas);
  
        $("#example")
          .DataTable({
            data: data_table,
            destroy: true,
            responsive: {
              details: {
                display: $.fn.dataTable.Responsive.display.modal({
                  header: function (row) {
                    var data = row.data();
                    return "Details for " + data[0] + " " + data[1];
                  },
                }),
                renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
              },
            },
            order: [[ 0, "desc" ]],
            lengthChange: false,
            autoWidth: false,
            buttons: [
              {
                extend: "copy",
                text: "Copiar",
                className:
                  "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
              },
              {
                extend: "csv",
                text: "CSV",
                className: "btn btn-secondary buttons-csv buttons-html5",
              },
              {
                extend: "excel",
                text: "Excel",
                className: "btn btn-secondary buttons-excel buttons-html5",
              },
              {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-secondary buttons-pdf buttons-html5",
              },
              {
                extend: "print",
                text: "Imprimir",
                className: "btn btn-secondary buttons-print buttons-html5",
              },
              {
                extend: "colvis",
                text: "Exibir Colunas",
                className:
                  "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
              },
            ],
          })
          .buttons()
          .container()
          .appendTo("#example_wrapper .col-md-6:eq(0)");
        this.loading = false;
      },
      // Todas as vendas
      async listarVendasCarrinho() {
        let url = "listar/vendas/carrinho";
        const response = await this.$http.get(url);
  
        this.vendas = response.data;
        let data_table = new Array();
        data_table = this.monta_dados(this.vendas);
  
        $("#example")
          .DataTable({
            data: data_table,
            destroy: true,
            responsive: {
              details: {
                display: $.fn.dataTable.Responsive.display.modal({
                  header: function (row) {
                    var data = row.data();
                    return "Details for " + data[0] + " " + data[1];
                  },
                }),
                renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
              },
            },
            order: [[ 0, "desc" ]],
            lengthChange: false,
            autoWidth: false,
            buttons: [
              {
                extend: "copy",
                text: "Copiar",
                className:
                  "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
              },
              {
                extend: "csv",
                text: "CSV",
                className: "btn btn-secondary buttons-csv buttons-html5",
              },
              {
                extend: "excel",
                text: "Excel",
                className: "btn btn-secondary buttons-excel buttons-html5",
              },
              {
                extend: "pdf",
                text: "PDF",
                className: "btn btn-secondary buttons-pdf buttons-html5",
              },
              {
                extend: "print",
                text: "Imprimir",
                className: "btn btn-secondary buttons-print buttons-html5",
              },
              {
                extend: "colvis",
                text: "Exibir Colunas",
                className:
                  "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
              },
            ],
          })
          .buttons()
          .container()
          .appendTo("#example_wrapper .col-md-6:eq(0)");
        this.loading = false;
      },
      // Monta as informações da tabela
      monta_dados(vendas) {
        let data_table = new Array();
        let status_cor = "text-secundary";
        vendas.forEach((venda) => {
          let cupom = venda.cupom_codigo == null ? '' : venda.cupom_codigo;
          if (venda != undefined) {
            data_table.push([
              "<span class='" +
                status_cor +
                "'>" +
                venda.carrinho_pagamento_id +
                "</span>",
              "<span class='" + status_cor + "'>" + venda.aluno_nome + "</span>",
              "<span class='" +
                status_cor +
                "'>" +
                venda.aluno_telefone +
                "</span>",
              "<span class='" +
                status_cor +
                "'>" +
                venda.carrinho_status +
                "</span>",
              "<span class='" +
                status_cor +
                "'> R$ " +
                venda.compra_valor_original +
                "</span>",
              "<span class='" +
                status_cor +
                "'> R$ " +
                venda.compra_valor_desconto +
                "</span>",
              "<span class='" +
                status_cor +
                "'> R$ " +
                venda.compra_valor +
                "</span>",
              "<span class='" +
                status_cor +
                "'>" +
                cupom +
                "</span>",
              "<span class='" + status_cor + "'>" + venda.agenda_id + "</span>",
              "<span class='" +
                status_cor +
                "'>" +
                venda.treinamento_nome +
                "</span>",
            ]);
          }
        });
  
        return data_table;
      }
    },
    mounted() {
        this.listarVendasPorVendedor();      
        this.listarVendasCarrinho();
      
    },
  };
  </script>
  <style scoped>
  .content-wrapper{
      margin-left: 0 !important;
  }
  </style>
  