<template>
  <div id="root">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Painel de Controle Geral
                <i class="spinner-border text-primary" v-show="loadingDatatable || !tabelaControle"></i>
                <small style="font-size:10pt;padding-left:5px;" v-show="loadingDatatable || !tabelaControle">Carregando...</small>
              </h1>
              <div class="text-danger text-bold" v-show="desabilita">
                <i class="nav-icon fas fa-ban"></i> Script em Execução! Aguarde.
              </div>
            </div>

            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Controle Geral</li>
              </ol>
            </div>
          </div>
        </div>
      </div>                        

      <div class="content">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Execução e Liberações de alguns scripts importantes!
              </h3>
            </div>            
            <div class="card-body row" v-show="tabelaControle">
              <div class="col-2">
                <button :disabled="desabilita" class="btn btn-lg bs-btn btn-success" type="button" @click="script_presenca()">
                  <span>Executar Presenças <i class="fas fa-play"></i></span>
                </button>
              </div>
              <!-- <div class="col-2">
                <button class="btn btn-lg bs-btn btn-success" type="button" @click="script_ranking()">
                  <span>Ranking <i class="fas fa-play"></i></span>
                </button>
              </div> -->
              <div class="col-2" v-show="false">
                <button class="btn btn-lg bs-btn btn-success" type="button" @click="script_certificados()">
                  <span>Executar Certificados <i class="fas fa-play"></i></span>
                </button>
              </div>
            </div>

            <div class="card-body">
              <div class="row" v-show="!tabelaControle">
                <div class="mx-auto text-center">
                  <b-img :src="require('../assets/loading.gif')" fluid width="100%"></b-img>
                  <h2>Executando script... Aguarde!</h2>
                </div>
              </div> 
              <table id="tableControleGeral" class="table table-bordered table-hover" v-show="tabelaControle">
                <thead>
                  <tr>
                    <th>Turma Código</th>
                    <th>Aviso de Provas</th>
                    <th>Acervo</th>
                    <th>Ranking</th>
                    <th>Acervo Certificados</th>
                    <th>Exibição</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="dado in dados_controle" :key=dado.controle_id>
                    <td> 
                      {{dado.turma_codigo}}
                    </td>
                    <td> 
                      <b-form-checkbox  v-model="provas[dado.controle_id]" :disabled="desabilita" switch size="lg" @change="salvar_controle(dado.controle_id, 'controle_aviso_provas', provas[dado.controle_id])"></b-form-checkbox>
                    </td>
                    <td> 
                      <b-form-checkbox :disabled="desabilita"  v-model="presenca[dado.controle_id]" switch size="lg" @change="salvar_controle(dado.controle_id, 'controle_atualizacao_presencas', presenca[dado.controle_id])"></b-form-checkbox>
                    </td>
                    <td> 
                      <!-- <b-form-checkbox v-model="ranking[dado.controle_id]" switch size="lg" @change="salvar_controle(dado.controle_id, 'controle_atualizacao_ranking', ranking[dado.controle_id])"></b-form-checkbox> -->
                      <b-button :disabled="desabilita" variant='success' v-model="ranking[dado.controle_id]" size="sm" @click="script_ranking(dado.controle_id, 'controle_atualizacao_ranking', ranking[dado.controle_id])">Executar Ranking para {{dado.turma_codigo}}</b-button>
                    </td>
                    <td> 
                      <b-button :disabled="desabilita" variant='success' style="background-color:green" v-model="ranking[dado.controle_id]" size="sm" @click="script_acervo(dado.turma_codigo)">Rodar Acervo {{dado.turma_codigo}}</b-button>                      
                    </td>
                    <td> 
                      <b-form-checkbox :disabled="desabilita" v-model="exibicao[dado.controle_id]" switch size="lg" @change="salvar_controle(dado.controle_id, 'controle_exibicao_turma', exibicao[dado.controle_id])"></b-form-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>

  .bs-btn i {
    margin-left: 5px;
  }

</style>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Controle_Geral",
  components: {
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      executando: 0,
      btn_executar:false,
      btn_editar:false,
      btn_excluir:false,
      loadingDatatable: true,
      preloader_status: true,
      alunoNome: "",
      page: "Controle_Geral",
      count: 0,
      provas: [],
      presenca: [],
      ranking: [],
      certificado: [],
      exibicao: [],
      dados_controle: [],
      tabelaControle: true,
      desabilita: false,
    };
  },
  computed: {
    searchDinamicaTreinamentoUrl () {
      if (process.env.VUE_APP_ENV === 'development') {
        return 'http://localhost:3000'
      }

      if (process.env.VUE_APP_ENV === 'homolog') {
        return process.env.VUE_APP_API_DINAMICATREINAMENTOS
      }

      return process.env.VUE_APP_API_DINAMICATREINAMENTOS
    }
  },
  created() {
    this.preloader_status = false;
  },

  methods: {
    // Exibição da tabela de Controle
    async populaTabela() {
      this.loadingDatatable = true;

      // Pegar informações do controle de presença no back
      let url = "lista/controle";
      const response = await this.$http.get(url);
      this.dados_controle = response.data;

      // Definir valores conforme liberação para atualização
      this.dados_controle.forEach(element => {
        // Provas
        if(element.controle_aviso_provas == 'NL'){
          this.provas[element.controle_id] = false;
        }else{
          this.provas[element.controle_id] = true;
        }

        // Presenças
        if(element.controle_atualizacao_presencas == 'NL'){
          this.presenca[element.controle_id] = false;
        }else{
          this.presenca[element.controle_id] = true;
        }

        // Ranking
        if(element.controle_atualizacao_ranking == 'NL'){
          this.ranking[element.controle_id] = false;
        }else{
          this.ranking[element.controle_id] = true;
        }

        // Certificado
        if(element.controle_popula_certificado == 'NL'){
          this.certificado[element.controle_id] = false;
        }else{
          this.certificado[element.controle_id] = true;
        }

        // Exibição para tech controlar
        if(element.controle_exibicao_turma == 'NL'){
          this.exibicao[element.controle_id] = false;
        }else{
          this.exibicao[element.controle_id] = true;
        }
      });
      this.loadingDatatable = false;
    },
    // Salva as alterações na tabela controle_geral
    async salvar_controle(controle_id, coluna, valor){
      this.executando += 1
      this.loadingArquivo = true;
      let url = "altera/controle";
      await this.$http.post(url,{
          controle_id: controle_id,
          coluna: coluna,
          valor: valor,
          page: this.page,
          profissional_id: this.dados_user.profissional_id,
          acao: "Alteração controle_geral"
        }
      );
      this.executando -= 1
      if(!this.executando){
        this.populaTabela();
        this.loadingArquivo = false;
      }
    },
    // Roda o script de Presença
    async script_presenca() {
      this.tabelaControle = false;
      let url = "presenca/alunos/gerar";
      await this.$http.post(url,{
          page: this.page,
          profissional_id: this.dados_user.profissional_id,
          acao: "Script de Presença"
        }
      );
      this.tabelaControle = true;
    },
    // Roda o script de Ranking
    async script_ranking(controle_id, coluna, valor) {
      console.log(valor);
     
      this.tabelaControle = false;
      let url = "dados/ranking";
      await this.$http.post(url,{
          page: this.page,
          profissional_id: this.dados_user.profissional_id,
          controle: controle_id,
          acao: "Script de Ranking"
        }
      );      
      this.tabelaControle = true;
    },

    // Roda o script de Ranking
    async script_acervo(turma_codigo) {
      this.tabelaControle = false;
      let url = `${this.searchDinamicaTreinamentoUrl}acervo/novo/gerar/${turma_codigo}`;
      await this.$http.get(url);      
      this.tabelaControle = true;
    },
    
    // Roda o script de Certificados
    async script_certificados() {
      alert("Ainda não implementado :(");
    }
  },
  mounted() {
    window.elemento_vue = this;
    this.dados_user = JSON.parse(sessionStorage.dados_user);
    this.populaTabela();
  },
};
</script>
