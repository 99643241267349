import { render, staticRenderFns } from "./GestaoAcessos.vue?vue&type=template&id=e19845ec&scoped=true"
import script from "./GestaoAcessos.vue?vue&type=script&lang=js"
export * from "./GestaoAcessos.vue?vue&type=script&lang=js"
import style0 from "./GestaoAcessos.vue?vue&type=style&index=0&id=e19845ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e19845ec",
  null
  
)

export default component.exports