<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">

      <!-- familiar nav -->
      <div class="flex-sup familiar-nav">

          <h3 class="flex-sup"> <small> Operações/Alunos </small> <i class="fas fa-angle-down"></i> </h3>

          <ul class="flex-sup fami-nav-list">

          <!-- variants: isAbaLoading, isActualPlace -->

          <!-- > gerenciamento -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/menu-alunos"> <i class="far fa-clipboard"></i> <small> Gerenciar alunos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > compras -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/compras"> <i class="fas fa-shopping-cart"></i> <small> Gerenciar compras </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>
          
          <!-- > turmas -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/menu-turmas"> <i class="fas fa-users"></i> <small> Gerenciar turmas </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > grupos -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/aleatoriza-grupos"> <i class="fas fa-random"></i> <small> Aleatorizar grupos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > treinamentos -->
          <li class="flex-sup isActualPlace"> 
              <a class="flex-sup" href="/menu-treinamentos"> <i class="fas fa-graduation-cap"></i> <small> Gerenciar treinamentos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          </ul>

      </div>

      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gerenciamento de treinamentos</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">treinamentos</li>
              </ol>
            </div>

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="card">
        <div class="row">
          <div class="card-body col-sm-3">
            <button
              class="btn btn-lg bs-btn btn-success"
              v-show="btn_executar"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              :variant="varianteSubmit"
              @click="cadastraTreinamento()"
            >
              <span
                >Cadastrar novo treinamento <i class="fas fa-play"></i
              ></span>
            </button>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body">
          <table id="example1" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Ações</th>
                <th>RID treinamento</th>
                <th>Treinamento nome</th>
                <th>Descrição treinamento</th>
                <th>Treinamento vista</th>
                <th>Treinamento prazo</th>
                <th>Categoria do treinamento</th>
                <th>Tipo do treinamento</th>
                <th>Status</th>
                <th>Nota mínima</th>
                <th>Imagem treinamento</th>
                <th>Carga horaria</th>
                <th>Carga horaria online</th>
                <th>Certificado</th>
                <th>Quantidade de certificados</th>
                <th>Vista ead</th>
                <th>Prazo ead</th>
                <th>Prazo early</th>
                <th>Vista early</th>
                <th>Vista ead early</th>
                <th>Prazo ead early</th>
                <th>Injeta provi</th>
                <th>Provi cupom</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal size="lg" id="modal_treinamento" hide-footer>
      <template #modal-title> {{ form_titulo }} </template>

      <b-form>
        <div class="row">
          <div class="col-sm-6">
            <b-form-group label="Nome">
              <b-form-input
                id="treinamento_nome"
                trim
                placeholder="Nome de exibição do treinamento"
                v-model="formTreinamento.treinamento_nome"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTreinamento.treinamento_nome
              }}</label>
            </b-form-group>
          </div>
          <div class="col-sm-3">
            <b-form-group label="Categoria">
              <b-form-select
                id="treinamento_categoria"
                trim
                v-model="formTreinamento.treinamento_categoria"
                :options="categoriaTreinamento"
              ></b-form-select>
              <label class="text-danger small">{{
                errosFormTreinamento.treinamento_categoria
              }}</label>
            </b-form-group>
          </div>
          <div class="col-sm-3">
            <b-form-group label="Status">
              <b-form-select
                id="status"
                v-model="formTreinamento.status"
                :options="statusTreinamento"
                trim
              ></b-form-select>
              <label class="text-danger small">{{
                errosFormTreinamento.status
              }}</label>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <b-form-group label="Descrição">
              <b-form-textarea
                id="treinamento_descricao "
                v-model="formTreinamento.treinamento_descricao"
                trim
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <b-form-group label="Carga Hor. treinamento">
              <b-form-input
                id="treinamento_carga"
                trim
                placeholder="Presencial + online (Hrs)"
                v-model="formTreinamento.treinamento_carga"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTreinamento.treinamento_carga
              }}</label>
            </b-form-group>
          </div>
          <div class="col-sm-4">
            <b-form-group label="Carga Hor. online">
              <b-form-input
                id="treianamento_carga_online"
                trim
                placeholder="Apenas Online (Hrs)"
                v-model="formTreinamento.treinamento_carga_online"
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- <div class="col-sm-1">
            <b-form-group label="Tipo trein.">
              <b-form-input
                id="treinamento_tipo"
                trim
                v-model="formTreinamento.treinamento_tipo"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTreinamento.treinamento_tipo
              }}</label>
            </b-form-group>
          </div> -->
          <div class="col-sm-4">
            <b-form-group label="Nota mínima">
              <b-form-input
                id="treinamento_nota_minima"
                trim
                v-model="formTreinamento.treinamento_nota_minima"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2">
            <b-form-group label="Imagem">
              <b-form-input
                id="treinamento_imagem"
                trim
                placeholder="https://"
                v-model="formTreinamento.treinamento_imagem"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-3">
            <b-form-group label="Tem certificado?">
              <b-form-select
                id="treianemento_certificado"
                trim
                v-model="formTreinamento.treinamento_certificado"
                :options="temCertificado"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-sm-3">
            <b-form-group label="Qtd. certificado">
              <b-form-input
                id="treinamento_certificado_quantidade"
                trim
                placeholder="defina.."
                v-model="formTreinamento.treinamento_certificado_quantidade"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-2">
            <b-form-group label="Injeta provi">
              <b-form-select
                id="injeta_provi"
                trim
                v-model="formTreinamento.treinamento_injeta_provi"
                :options="proviTreinamento"
              ></b-form-select>
              <label class="text-danger small">{{
                errosFormTreinamento.treinamento_injeta_provi
              }}</label>
            </b-form-group>
          </div>
          <div class="col-sm-2">
            <b-form-group label="Provi cupom">
              <b-form-input
                id="provi_cupom"
                trim
                placeholder="EARLY"
                v-model="formTreinamento.treinamento_provi_cupom"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <h5>Preços</h5>
        <hr />

        <div class="row">
          <div class="col-sm-3">
            <b-form-group label="Preço à Vista">
              <b-form-input
                id="treinamento_vista "
                v-model="formTreinamento.treinamento_vista"
                trim
                placeholder="R$ 0000,00"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTreinamento.treinamento_vista
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-3">
            <b-form-group label="Preço à prazo">
              <b-form-input
                id="treinamento_prazo"
                v-model="formTreinamento.treinamento_prazo"
                trim
                placeholder="R$ 0000,00"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTreinamento.treinamento_prazo
              }}</label>
            </b-form-group>
          </div>
          <div class="col-sm-3">
            <b-form-group label="Preço à vista (EAD)">
              <b-form-input
                id="vista_ead"
                trim
                placeholder="R$ 0000,00"
                v-model="formTreinamento.vista_ead"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-3">
            <b-form-group label="Preço à prazo (EAD)">
              <b-form-input
                id="prazo_ead"
                trim
                placeholder="R$ 0000,00"
                v-model="formTreinamento.prazo_ead"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group label="Preço à prazo (EARLY)">
              <b-form-input
                id="prazo_early"
                trim
                placeholder="R$ 0000,00"
                v-model="formTreinamento.prazo_early"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group label="Preço à vista (EARLY)">
              <b-form-input
                id="vista_early"
                trim
                placeholder="R$ 0000,00"
                v-model="formTreinamento.vista_early"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group label="Preço à vista (EAD EARLY)">
              <b-form-input
                id="vista_ead_early"
                trim
                placeholder="R$ 0000,00"
                v-model="formTreinamento.vista_ead_early"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group label="Preço à prazo (EAD EARLY)">
              <b-form-input
                id="prazo_ead_early"
                trim
                placeholder="R$ 0000,00"
                v-model="formTreinamento.prazo_ead_early"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <hr />

        <div class="input-group justify-content-md-end">
          <b-button
            @click="limparFormTreinamento()"
            id="btn-limpa-form"
            variant="danger"
            >Limpar formulário</b-button
          >
          <b-button
            class="mr-1"
            @click="enviarFormTreinamento()"
            id="btn-cadastro"
            :variant="varianteSubmit"
            >{{ botaoSubmitTreinamento }}</b-button
          >

        </div>
      </b-form>
    </b-modal>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>

  .bs-btn i {margin-left: 5px;}

</style>

<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

function abreModalEdicao(treinamento_id) {
  window.elemento_vue.editaTreinamento(treinamento_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Treinamento",
  components: {
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user: {},
      preloader_status: true,
      loading: false,
      page: "Treinamentos",
      form_titulo: "",
      botaoSubmitTreinamento: "",
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      agendas: [],
      treinamentos: [],
      varianteSubmit: "",
      temCertificado: [
        { text: "Sim", value: "1" },
        { text: "Não", value: "0" },
      ],
      statusTreinamento: [
        { text: "Ativo", value: "1" },
        { text: "Inativo", value: "2" },
      ],
      categoriaTreinamento: [
        { text: "Treinamento online", value: "1" },
        { text: "Certificações", value: "2" },
        { text: "Produto físico", value: "3" },
        { text: "Treinamento Hibrido", value: "4" },
      ],
      proviTreinamento: [
        { text: "Sim", value: "S" },
        { text: "Não", value: "N" },
      ],

      formTreinamento: {
        treinamento_id: "",
        treinamento_nome: "",
        treinamento_descricao: "",
        treinamento_vista: "",
        treinamento_prazo: "",
        treinamento_categoria: "",
        treinamento_tipo: "2",
        status: "",
        treinamento_nota_minima: "70",
        treinamento_imagem: "",
        treinamento_carga: "",
        carga_horaria_online: "",
        treinamento_certificado: "",
        quanti_certificado: "",
        vista_ead: "",
        prazo_ead: "",
        prazo_early: "",
        vista_early: "",
        vista_ead_early: "",
        prazo_ead_early: "",
        treinamento_injeta_provi: "",
        treinamento_provi_cupom: "",
      },
      errosFormTreinamento: {
        treinamento_nome: "",
        treinamento_vista: "",
        treinamento_prazo: "",
        treinamento_carga: "",
        status: "",
        treinamento_categoria: "",
        treinamento_injeta_provi: "",
      },
    };
  },
  created() {
    this.preloader_status = false;
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {
    async cadastraTreinamento() {
      this.limparFormTreinamento();
      this.form_titulo = "Cadastrar novo treinamento";
      this.botaoSubmitTreinamento = "Cadastrar treinamento";
      this.varianteSubmit = "primary";
      this.acao = "novo";

      this.errosFormTreinamento = {
        treinamento_nome: "",
        treinamento_vista: "",
        treinamento_prazo: "",
        treinamento_carga: "",
        status: "",
        treinamento_categoria: "",
        treinamento_injeta_provi: "",
      };
      this.$bvModal.show("modal_treinamento");
    },

    async editaTreinamento(treinamento_id) {
      this.limparFormTreinamento();
      this.form_titulo = "Editar treinamento";
      this.botaoSubmitTreinamento = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";
      this.modal_treinamento = true;

      let url = "lista/treinamento/" + treinamento_id;

      try {
        const response = await this.$http.get(url);
        let dados_treinamento = response.data;
        (this.formTreinamento.treinamento_id =
          dados_treinamento.treinamento_id),
          (this.formTreinamento.treinamento_nome =
            dados_treinamento.treinamento_nome),
          (this.formTreinamento.treinamento_descricao =
            dados_treinamento.treinamento_descricao),
          (this.formTreinamento.treinamento_vista =
            dados_treinamento.treinamento_vista),
          (this.formTreinamento.treinamento_prazo =
            dados_treinamento.treinamento_prazo),
          (this.formTreinamento.treinamento_categoria =
            dados_treinamento.treinamento_categoria),
          (this.formTreinamento.treinamento_tipo =
            dados_treinamento.treinamento_tipo),
          (this.formTreinamento.status = dados_treinamento.status),
          (this.formTreinamento.treinamento_nota_minima =
            dados_treinamento.treinamento_nota_minima),
          (this.formTreinamento.treinamento_imagem =
            dados_treinamento.treinamento_imagem),
          (this.formTreinamento.treinamento_carga =
            dados_treinamento.treinamento_carga),
          (this.formTreinamento.carga_horaria_online =
            dados_treinamento.treinamento_carga_online),
          (this.formTreinamento.treinamento_certificado =
            dados_treinamento.treinamento_certificado),
          (this.formTreinamento.treinamento_certificado_quantidade =
            dados_treinamento.treinamento_certificado_quantidade),
          (this.formTreinamento.vista_ead = dados_treinamento.vista_ead),
          (this.formTreinamento.prazo_ead = dados_treinamento.prazo_ead),
          (this.formTreinamento.prazo_early = dados_treinamento.prazo_early),
          (this.formTreinamento.vista_early = dados_treinamento.vista_early),
          (this.formTreinamento.vista_ead_early =
            dados_treinamento.vista_ead_early),
          (this.formTreinamento.prazo_ead_early =
            dados_treinamento.prazo_ead_early),
          (this.formTreinamento.treinamento_injeta_provi =
            dados_treinamento.treinamento_injeta_provi),
          (this.formTreinamento.treinamento_provi_cupom =
            dados_treinamento.treinamento_provi_cupom);
        this.$bvModal.show("modal_treinamento");
      } catch (err) {
        let msg = "Erro ao buscar dados da treinamento!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
    },

    async lista_treinamentos() {
      let url = "lista/treinamentos";
      const response = await this.$http.get(url);
      this.treinamentos = response.data;
      let data_table = new Array();
      let status_cor = "text-secundary";

      this.treinamentos.forEach((treinamento) => {
        let botao_editar_treinamento =
          "<button class='btn btn-warning btn-sm mr-1 open_treinamento' id = " +
          treinamento.treinamento_id +
          " type='button'><i id = " +
          treinamento.treinamento_id +
          " class = 'fas fa-edit'></i>Editar</button>";

        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_treinamento : "") +
            "</span>",

          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_id +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_nome +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_descricao +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_vista +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_prazo +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_categoria +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_tipo +
            "</span>",
          "<span class=" + status_cor + ">" + treinamento.status + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_nota_minima +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_imagem +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_carga +
            "</span>",

          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_carga_online +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_certificado +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_certificado_quantidade +
            "</span>",
          "<span class=" + status_cor + ">" + treinamento.vista_ead + "</span>",
          "<span class=" + status_cor + ">" + treinamento.prazo_ead + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.prazo_early +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.vista_early +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.vista_ead_early +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.prazo_ead_early +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_injeta_provi +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            treinamento.treinamento_provi_cupom +
            "</span>",
        ]);
      });

      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
          drawCallback: function () {
            $(".open_treinamento").on("click", function (event) {
              abreModalEdicao(event.target.id);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
    },

    habilita_botoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "operacoes") {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }

        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },

    async enviarFormTreinamento() {
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let botaoLimpaForm = document.querySelector("#btn-limpa-form");
      let erros = false;
      botaoCadastro.disabled = true;
      botaoLimpaForm.disabled = true;

      if (!this.formTreinamento.treinamento_nome) {
        this.errosFormTreinamento.treinamento_nome =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTreinamento.treinamento_nome = "";
      }
      if (!this.formTreinamento.treinamento_vista) {
        this.errosFormTreinamento.treinamento_vista =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTreinamento.treinamento_vista = "";
      }
      if (!this.formTreinamento.treinamento_prazo) {
        this.errosFormTreinamento.treinamento_prazo =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTreinamento.treinamento_prazo = "";
      }
      if (!this.formTreinamento.treinamento_carga) {
        this.errosFormTreinamento.treinamento_carga =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTreinamento.treinamento_carga = "";
      }
      if (!this.formTreinamento.status) {
        this.errosFormTreinamento.status = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTreinamento.status = "";
      }
      if (!this.formTreinamento.treinamento_categoria) {
        this.errosFormTreinamento.treinamento_categoria =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTreinamento.treinamento_categoria = "";
      }
      if (!this.formTreinamento.treinamento_injeta_provi) {
        this.errosFormTreinamento.treinamento_injeta_provi =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTreinamento.treinamento_injeta_provi = "";
      }
      console.log(erros);
      console.log(this.errosFormTreinamento);
      if (!erros) {
        let retorno;
        if (this.acao == "novo") {
          let url = "cadastra/treinamento";
          const response = await this.$http.post(url, {
            treinamento: {
              treinamento_id: this.formTreinamento.treinamento_id,
              treinamento_nome: this.formTreinamento.treinamento_nome,
              treinamento_descricao: this.formTreinamento.treinamento_descricao,
              treinamento_vista: this.formTreinamento.treinamento_vista,
              treinamento_prazo: this.formTreinamento.treinamento_prazo,
              treinamento_categoria: this.formTreinamento.treinamento_categoria,
              treinamento_tipo: this.formTreinamento.treinamento_tipo,
              status: this.formTreinamento.status,
              treinamento_nota_minima:
                this.formTreinamento.treinamento_nota_minima,
              treinamento_imagem: this.formTreinamento.treinamento_imagem,
              treinamento_carga: this.formTreinamento.treinamento_carga,
              carga_horaria_online: this.formTreinamento.carga_horaria_online,
              treinamento_certificado:
                this.formTreinamento.treinamento_certificado,
              quanti_certificado: this.formTreinamento.quanti_certificado,
              vista_ead: this.formTreinamento.vista_ead,
              prazo_ead: this.formTreinamento.prazo_ead,
              prazo_early: this.formTreinamento.prazo_early,
              vista_early: this.formTreinamento.vista_early,
              vista_ead_early: this.formTreinamento.vista_ead_early,
              prazo_ead_early: this.formTreinamento.prazo_ead_early,
              treinamento_injeta_provi:
                this.formTreinamento.treinamento_injeta_provi,
              treinamento_provi_cupom:
                this.formTreinamento.treinamento_provi_cupom,
            },
            page: this.page,
            profissional_id: this.user.dados.profissional_id,
            acao: "cadastrar treinamento",
          });
          retorno = response.data;
          console.log(retorno);
        } else if (this.acao == "editar") {
          let url = "atualiza/treinamento";
          const response = await this.$http.patch(url, {
            treinamento: {
              treinamento_id: this.formTreinamento.treinamento_id,
              treinamento_nome: this.formTreinamento.treinamento_nome,
              treinamento_descricao: this.formTreinamento.treinamento_descricao,
              treinamento_vista: this.formTreinamento.treinamento_vista,
              treinamento_prazo: this.formTreinamento.treinamento_prazo,
              treinamento_categoria: this.formTreinamento.treinamento_categoria,
              treinamento_tipo: this.formTreinamento.treinamento_tipo,
              status: this.formTreinamento.status,
              treinamento_nota_minima:
                this.formTreinamento.treinamento_nota_minima,
              treinamento_imagem: this.formTreinamento.treinamento_imagem,
              treinamento_carga: this.formTreinamento.treinamento_carga,
              carga_horaria_online: this.formTreinamento.carga_horaria_online,
              treinamento_certificado:
                this.formTreinamento.treinamento_certificado,
              quanti_certificado: this.formTreinamento.quanti_certificado,
              vista_ead: this.formTreinamento.vista_ead,
              prazo_ead: this.formTreinamento.prazo_ead,
              prazo_early: this.formTreinamento.prazo_early,
              vista_early: this.formTreinamento.vista_early,
              vista_ead_early: this.formTreinamento.vista_ead_early,
              prazo_ead_early: this.formTreinamento.prazo_ead_early,
              treinamento_injeta_provi:
                this.formTreinamento.treinamento_injeta_provi,
              treinamento_provi_cupom:
                this.formTreinamento.treinamento_provi_cupom,
            },
            page: this.page,
            profissional_id: this.user.dados.profissional_id,
            acao: "editar treinamento",
          });
          retorno = response.data;
          console.log(retorno);
        }

        if (retorno.erro) {
          this.$bvModal.msgBoxOk(retorno.erro, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        } else {
          this.$bvModal.msgBoxOk(retorno.mensagem, {
            title: "Sucesso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          });
          this.$bvModal.hide("modal_treinamento");
          this.lista_treinamentos();
        }
      }
      botaoCadastro.disabled = false;
      botaoLimpaForm.disabled = false;
    },

    limparFormTreinamento() {
      this.formTreinamento.treinamento_id = "";
      this.formTreinamento.treinamento_nome = "";
      this.formTreinamento.treinamento_descricao = "";
      this.formTreinamento.treinamento_vista = "";
      this.formTreinamento.treinamento_prazo = "";
      this.formTreinamento.treinamento_categoria = "";
      this.formTreinamento.treinamento_tipo = "";
      this.formTreinamento.status = "";
      this.formTreinamento.treinamento_nota_minima = "70";
      this.formTreinamento.treinamento_imagem = "";
      this.formTreinamento.treinamento_carga = "";
      this.formTreinamento.treinamento_carga_online = "";
      this.formTreinamento.treinamento_certificado = "";
      this.formTreinamento.treinamento_certificado_quantidade = "";
      this.formTreinamento.vista_ead = "";
      this.formTreinamento.prazo_ead = "";
      this.formTreinamento.prazo_early = "";
      this.formTreinamento.vista_early = "";
      this.formTreinamento.vista_ead_early = "";
      this.formTreinamento.prazo_ead_early = "";
      this.formTreinamento.treinamento_injeta_provi = "";
      this.formTreinamento.treinamento_provi_cupom = "";

      Object.keys(this.errosFormTreinamento).forEach((item) => {
        console.log(item);
        this.errosFormTreinamento[item] = "";
      });
    },
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    //quando liga a tela
    window.elemento_vue = this;
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    this.habilita_botoes();
    this.lista_treinamentos();
  },
};
</script>


