<template>
    <div id="root">
        <div class="content-wrapper" v-show="!hash">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Usuário sem permissão de acesso!</h1>                        
                    </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- MODALS -->
            <b-modal size="lg" id="modal_cupom" hide-footer>
                <template #modal-title> {{ form_titulo }} </template>
                <b-form @submit.prevent="salva_edicao_cupom">
                    <div class="row">
                        <div class="col-4">
                            <b-form-group id="input-1" label="Código">
                                <b-form-input
                                    trim
                                    v-model="edit_cupom_codigo"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-2">
                            <b-form-group id="input-2" label="Desconto (%)">
                                <b-form-input
                                    trim
                                    v-model="edit_cupom_desconto"
                                    @keypress="isNumber($event)"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-3">
                            <b-form-group id="input-3" label="Tipo">
                                <b-form-select
                                    v-model="edit_cupom_optional"
                                    :options="opcoes_cupons"
                                    required
                                ></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-1">
                            <b-form-group id="input-6" label="Limite">
                                <b-form-input
                                    trim
                                    v-model="edit_cupom_limite"
                                    @keypress="isNumber($event)"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-2">
                            <b-form-group id="input-4" label="Status">
                                <b-form-select
                                    v-model="edit_cupom_status"
                                    :options="status_edit"
                                    required
                                ></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" v-show="edit_cupom_optional == 'agenda_id'">
                            <b-form-group id="input-4" label="Agenda ID Antigo">
                                <b-form-select
                                    v-model="edit_agenda_id"
                                    :options="agenda_ids"
                                ></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <b-form-group id="input-5" label="Treinamento" v-show="edit_cupom_optional == 'treinamento'">
                                <b-form-select
                                    v-model="edit_cupom_value"
                                    :options="treinamentos"
                                ></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <hr>
                    <div class="input-group justify-content-md-end">
                        <div class="mx-auto" v-show="loading_modal">
                            <div class="mx-auto text-center">
                                <div class="spinner-border text-primary"></div>
                            </div>
                        </div> 
                        <b-button
                            class="mr-1 btn btn-danger"
                            id="btn-cadastro"
                            type="submit"
                        >Salvar</b-button>
                    </div>
                </b-form>
            </b-modal>

            <b-modal id="modal_aviso" hide-footer>
                <div class="text-center">
                    <h5 class="text-center">{{ msg_modal }}</h5>
                    <div v-show="edit_cupom_optional == 'agenda_id'"><a :href=edit_cupom_link target='_blank'>Open Link</a></div>
                    <hr>
                </div>
                <div class="input-group justify-content-md-end">
                    <b-button
                        class="mr-1 btn btn-secondary"
                        @click="reload_page();"
                    >Ok</b-button>
                </div>
            </b-modal>

            <b-modal size="xl" id="modal_compras">
                <template #modal-title> Cupom {{cupom_compra}}</template>
                    <div class="overflow-auto">
                        <b-table striped hover sticky-header :items="compras" :fields="fields">
                        </b-table>
                    </div>
            </b-modal>

        <!-- FIM MODALS -->

        <div class="content-wrapper" v-show="hash">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Gerenciamento de Cupons</h1>
                    </div>
                    
                    </div>
                </div>
            </div>

            <div class="content" >
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">
                                Gerenciamento e Criação de Cupons para geração de compras
                            </h3>
                        </div>
                        <div class="card-body col-sm-12">
                            <div class="row">
                                <div class="col-2">
                                    <div>
                                        <b-form-group label="Status" label-for="cupom_status">
                                            <b-form-select 
                                                id="cupom_status"
                                                @click.prevent
                                                :options="status"
                                                @change="lista_cupons()"
                                                v-model="cupom_status">
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="col-10" v-show="habilitar_botao">
                                    <div><br></div>
                                    <button class="btn btn-lg btn-success float-right" type="button" @click="adicionarCupom()">
                                        <span>Adicionar Cupom <i class="fas fa-plus"></i></span>
                                    </button>
                                </div>
                            </div>

                            <div class="row" v-show="loading">
								<i class="spinner-border text-primary"></i>
							</div>

                            <div class="table-responsive" v-show="!loading">                                                               
                                <table id="tabela_cupons" class="table table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th style ="word-break:break-all;">Editar</th>
                                        <th style ="word-break:break-all;">#</th>
                                        <th style ="word-break:break-all;">Código</th>
                                        <th>Desconto</th>
                                        <th style ="word-break:break-all;">Tipo</th>
                                        <th style ="word-break:break-all;">Treinamento</th>
                                        <th>Limite</th>
                                        <th>Disponível</th>
                                        <th style ="word-break:break-all;">Status</th>
                                        <th>Carrinho</th>
                                        <th style ="word-break:break-all;">Última Atualização</th>
                                    </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>            
        </div>
        
    </div>
</template>

<script>
function abreModalEdicao(cupom_id) {
    window.elemento_vue.editaCupom(cupom_id);
}

function mostraCompras(cupom_id) {
    window.elemento_vue.mostraCompras(cupom_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;


export default {
    data() {
        return {
            user: {},
            page: "Gerenciamento de Cupons",
            habilitar_pagina: true,
            habilitar_botao: true,
            loading: false,
            loading_modal: false,
            cupom_status: 'Ativos',
            status: [{
                    text: "Todos",
                    value: "Todos"
                },{
                    text: "Ativos",
                    value: "Ativos"
                },{
                    text: "Inativos",
                    value: "Inativos"
                }
            ],
            opcoes_cupons: [{
                    text: "Agenda ID",
                    value: "agenda_id"
                },{
                    text: "Treinamento",
                    value: "treinamento"
                }
            ],
            status_edit: [{
                    text: "Ativo",
                    value: "1"
                },{
                    text: "Inativo",
                    value: "0"
                }
            ],
            cupons: [],
            agenda_ids: [],
            treinamentos: [{
                    text: "todos",
                    value: "*"
                },{
                    text: "online",
                    value: "online"
                }
            ],
            modal_cupom: true,
            msg_modal: '',
            edit_cupom_codigo: '',
            edit_cupom_id: '',
            edit_cupom_desconto: 0,
            edit_cupom_optional: '',
            edit_agenda_id: '',
            edit_cupom_value: '',
            edit_cupom_limite: 0,
            edit_cupom_qtd_disponivel: '',
            edit_cupom_status: '',
            edit_cupom_link: '',
            cupom_compra: '',
            compras: [],
            hash: '',
            fields: ['data_inscricao', 'nome', 'curso', 'e_mail', 'telefone', 'cpf', 'valor', 'metodo', 'pagarme', 'carrinho_id']
        };
    },
    created() {
        this.lista_cupons();
        this.listar_agenda_ids();
        this.listar_treinamentos();
        this.fetchDadosEmbed();
    },
    methods: {
        async fetchDadosEmbed() {
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.hash = urlParams.get('habilitado')            
            this.user = urlParams.get('id')
        },
        // Select de Agenda IDs
        async listar_agenda_ids(){
            let url = "listar/agenda_ids_antigo";
            const response = await this.$http.get(url);

            response.data.forEach(agenda_id => {
                this.agenda_ids.push({
                    text: agenda_id.agenda_id_antigo + " - " + agenda_id.treinamento_nome,
                    value: agenda_id.agenda_id,
                    agenda_id_antigo: agenda_id.agenda_id_antigo
                });
            });
        },
        // Select de Treinamentos
        async listar_treinamentos(){
            let url = "listar/treinamentos";
            const response = await this.$http.get(url);

            response.data.forEach(treinamento => {
                this.treinamentos.push({
                    text: treinamento.treinamento_nome,
                    value: treinamento.treinamento_nome
                });
            });
        },
        // Carregamento das informações da tabela
        async lista_cupons(){
            this.loading = true;
            let url = "listar/cupons";
            const response = await this.$http.post(url,{
                opcao: this.cupom_status
            });

            let data_table = new Array();
            response.data.forEach(cupom => {
                // Botão para editar cupom
                let botao_editar = " - ";
                if (this.habilitar_botao){
                    botao_editar =
                    "<button class='btn btn-warning btn-sm mr-1 open-edicao' id = " +
                    cupom.cupom_id +
                    " type='button'><i id = " +
                    cupom.cupom_id +
                    " class = 'fas fa-edit'></i>Editar</button>";
                }
                // Tratamento das opções
                let opcao = '';
                let treino = '';
                let link = '';
                if (cupom.cupom_optional == 'treinamento'){
                    opcao = 'Treinamento';
                    if (cupom.cupom_value == "*"){
                        treino = "todos";
                    }
                    else{
                        treino = cupom.cupom_value;
                    }
                }
                else if (cupom.cupom_optional == 'agenda_id'){
                    opcao = 'Agenda ID';
                    treino = cupom.agenda_id_antigo + " - " +cupom.treinamento_nome;
                    // Link para o carrinho com o cupom
                    let aux = 'https://checkout.dnc.group/?id=' + cupom.agenda_id_antigo + '&cupom=' + cupom.cupom_codigo;
                    link = "<a href='" + aux + "' target='_blank'>Open Link</a>";
                }
                else{
                    opcao = cupom.cupom_optional;
                    treino = cupom.treinamento_nome;
                }
                // Ativo e Inativo
                let botao_ativo = '';
                if (cupom.cupom_status == 0){
                    botao_ativo = "<div class='btn btn-danger disabled' style='cursor:not-allowed !important;pointer-events: none !important;width:82px;'>Inativo</div>";
                }
                else if (cupom.cupom_status == 1){
                    botao_ativo = "<div class='btn btn-success disabled' style='cursor:not-allowed !important;pointer-events: none !important;width:82px;'>Ativo</div>";
                }
                // Link para o carrinho com cupom
                cupom.link = link;

                // Modal com infos de cupons
                let compras_cupom = cupom.cupom_codigo;
                if (cupom.cupom_limite > cupom.cupom_qtd_disponivel){
                    compras_cupom = "<a class='open-compras' href='#' id='"+ cupom.cupom_id +"'>" + cupom.cupom_codigo + "</a>";
                }

                data_table.push([
                    "<span>" + botao_editar + "</span>",
                    "<span>" + cupom.cupom_id + "</span>",
                    "<span>" + compras_cupom + "</span>",
                    "<span>" + Math.round(cupom.cupom_desconto*100)/100 + "%</span>",
                    "<span>" + opcao + "</span>",
                    "<span>" + treino + "</span>",
                    "<span>" + cupom.cupom_limite + "</span>",
                    "<span>" + cupom.cupom_qtd_disponivel + "</span>",
                    "<span>" + botao_ativo + "</span>",
                    "<span>" + link + "</span>",
                    "<span>" + cupom.cupom_log_dthr + "</span>"
                ]);

                this.cupons.push(cupom);
            });

            $("#tabela_cupons").DataTable({
                data: data_table,
                destroy: true,
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                language: {
                    sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                    sInfoEmpty: " ",
                    sLengthMenu: "_MENU_ resultados por página",
                    sSearch: "",
                    sEmptyTable: "Nenhum registro encontrado",
                    sInfoFiltered: "Filtrados de _MAX_ registros",
                    sLoadingRecords: "Carregando...",
                    sProcessing: "Processando...",
                    sInfoPostFix: "",
                    sInfoThousands: ".",
                    sSearchPlaceholder: "Pesquisar",
                    sZeroRecords:
                    "Nenhum registro encontrado <i class='far fa-frown'></i>",
                    paginate: {
                        sNext: "Próximo",
                        sPrevious: "Anterior",
                    },
                },
                drawCallback: function() {
                    $(".open-edicao").on("click", function(event) {
                        abreModalEdicao(event.target.id);
                    });
                    $(".open-compras").on("click", function(event) {
                        mostraCompras(event.target.id);
                    });
                }
            });

            this.loading = false;
        },
        // Modal de edição de cupom
        editaCupom(cupom_id){
            this.form_titulo = "Editar Cupom";
            this.limpa_campos();

            // Montando as informações do modal de edição
            this.cupons.forEach(cupom => {
                if(cupom.cupom_id == cupom_id){
                    this.edit_cupom_id = cupom.cupom_id;
                    this.edit_cupom_codigo = cupom.cupom_codigo;
                    this.edit_cupom_desconto = cupom.cupom_desconto;
                    this.edit_cupom_optional = cupom.cupom_optional;
                    this.edit_cupom_value = cupom.cupom_value;
                    this.edit_cupom_limite = cupom.cupom_limite;
                    this.edit_cupom_qtd_disponivel = cupom.cupom_qtd_disponivel;
                    this.edit_cupom_status = cupom.cupom_status;
                    this.edit_agenda_id = cupom.agenda_id;
                    this.edit_cupom_link = cupom.link;
                }
            });

            this.modal_cupom = true;
            this.$bvModal.show("modal_cupom");
        },
        // Modal de inserção de cupom
        adicionarCupom(){
            this.form_titulo ="Adicionar Cupom";
            this.limpa_campos();
            this.modal_cupom = true;
            this.$bvModal.show("modal_cupom");
        },
        // Função para limpar os campos
        limpa_campos(){
            this.edit_cupom_id = '';
            this.edit_cupom_codigo = '';
            this.edit_cupom_desconto = 0;
            this.edit_cupom_optional = '';
            this.edit_cupom_value = '';
            this.edit_cupom_limite = 0;
            this.edit_cupom_qtd_disponivel = '';
            this.edit_cupom_status = '';
            this.edit_agenda_id = '';
            this.edit_cupom_link = '';
        },
        // Garante que o usuário colocará somente números na campo
        isNumber: function(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        // Envia as alterações para a edição/inserção
        async salva_edicao_cupom(){
            this.loading_modal = true;
            // Tratamento das variáveis
            if (this.edit_cupom_optional == "treinamento"){
                this.edit_agenda_id = null;
                this.edit_cupom_link = '';
            }
            else{
                // Buscar o agenda_id_antigo
                let ag_id = this.edit_agenda_id;
                this.agenda_ids.forEach(agenda => {
                    if(agenda.value == this.edit_agenda_id){
                        ag_id = agenda.agenda_id_antigo;
                    }
                });
                this.edit_cupom_link = 'https://checkout.dnc.group/?id=' + ag_id + '&cupom=' + this.edit_cupom_codigo;
            }

            if (this.form_titulo == "Editar Cupom"){
                let url = "edita/cupom";
                await this.$http.post(url,{
                    page: this.page,
                    profissional_id: this.user,
                    acao: "Edição de Cupom",
                    cupom_id: this.edit_cupom_id,
                    cupom_codigo: this.edit_cupom_codigo,
                    cupom_desconto: this.edit_cupom_desconto,
                    cupom_optional: this.edit_cupom_optional,
                    agenda_id: this.edit_agenda_id,
                    cupom_value: this.edit_cupom_value,
                    cupom_limite: this.edit_cupom_limite,
                    cupom_status: this.edit_cupom_status
                });
            }
            else if(this.form_titulo == "Adicionar Cupom"){
                let url = "insere/cupom";
                await this.$http.post(url,{
                    page: this.page,
                    profissional_id: this.user,
                    acao: "Adição de Cupom",
                    cupom_codigo: this.edit_cupom_codigo,
                    cupom_desconto: this.edit_cupom_desconto,
                    cupom_optional: this.edit_cupom_optional,
                    agenda_id: this.edit_agenda_id,
                    cupom_value: this.edit_cupom_value,
                    cupom_limite: this.edit_cupom_limite,
                    cupom_status: this.edit_cupom_status
                });
            }

            this.loading_modal = false;
            this.modal_cupom = false;
            this.$bvModal.hide("modal_cupom");

            this.msg_modal = "Alterações salvas com sucesso!";
            this.$bvModal.show("modal_aviso");
        },
        // Recarrega a tabela
        reload_page(){
            this.lista_cupons();
            this.$bvModal.hide("modal_aviso");
        },
        // Busca compras de um cupom
        async mostraCompras(cupom_id){
            this.compras = [];

            let url = "cupom/compras";
            const response = await this.$http.post(url,{
                cupom_id: cupom_id
            });

            response.data.forEach(compra => {
                this.compras.push({ 
                    data_inscricao: compra.carrinho_dthr_atualizacao, 
                    nome: compra.aluno_nome, 
                    curso: compra.agenda_id_antigo + ' - ' + compra.treinamento_nome, 
                    e_mail: compra.aluno_email,
                    telefone: compra.aluno_telefone,
                    cpf: compra.aluno_cpf,
                    valor: compra.carrinho_valor,
                    metodo: compra.carrinho_forma,
                    pagarme: compra.carrinho_idpagarme,
                    carrinho_id: compra.carrinho_id
                });
                this.cupom_compra = compra.cupom_codigo;
            });

            // Aciona modal
            this.$bvModal.show("modal_compras");
        }
    },
    mounted() {
        window.elemento_vue = this;
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);

        this.user.permissoes.forEach(permissao => {
            if (permissao.privilegio_tipo_id == 6 || permissao.privilegio_tipo_id == 2){
                this.habilitar_pagina = true;
                if(permissao.privilegio_status == "LE" || permissao.privilegio_status == "A"){
                    this.habilitar_botao = true;
                }
            }
        });
    }
}
</script>
<style scoped>
.content-wrapper{
    margin-left: 0 !important;
}
</style>