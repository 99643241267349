<template>
    <div id="root">
        <Menu />
        <Navbar />
        <div class="content-wrapper" v-show="!habilitar_pagina">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Usuário sem permissão de acesso!</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Boletim das Turmas</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-wrapper" v-show="habilitar_pagina">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Boletim das Turmas</h1>
                        </div>
                        <div class="col-sm-12">
                            <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Boletim das Turmas</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="card">
                        <!-- upload box -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-2">
                                    <div><br></div>
                                    <button class="btn btn-lg bs-btn btn-success" type="button" @click="atualiza_boletim()">
                                        <span>Atualizar <i class="fas fa-play"></i></span>
                                    </button>
                                </div>
                            </div>
                            <div class="row p-3" v-show="!msg_atualiza_boletim"> 
                                <p>Como é calculado? Cada coeficiente é dividido em 3 parâmetros com pesos diferentes: assignments (média das notas e nº de entregas), deliverables (média das notas) e presença nos days! <br>A tabela abaixo mostra de forma acumulativa a cada Slot.</p>
                            </div>
                            <div class="row p-3" v-show="msg_atualiza_boletim"> 
                                <p>Procedimento demorado. Aproveite para tomar um cafézinho enquanto isso :D</p>
                            </div>
                            <!-- Exibição dos alunos de cada turma -->
                            <div class="mx-auto">
                                <div class="row" v-show="loading">
                                    <div class="mx-auto text-center">
                                        <b-img :src="require('../assets/loading.gif')" fluid width="100%"></b-img>
                                        <h2>Aguarde!</h2>
                                    </div>
                                </div> 
                                <div class="table-responsive" v-show="!loading && mostrar_tabela">
                                    <table id="notas_turma" class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Turma</th>
                                            <th>Slot #1</th>
                                            <th>Slot #2</th>
                                            <th>Slot #3</th>
                                            <th>Slot #4</th>
                                            <th>Slot #5</th>
                                            <th>Slot #6</th>
                                            <th>Slot #7</th>
                                            <th>Slot #8</th>
                                            <th>Slot #9</th>
                                            <th>Slot #10</th>
                                            <th>Slot #11</th>
                                            <th>Slot #12</th>
                                            <th>Slot #13</th>
                                            <th>Slot #14</th>
                                            <th>Slot #15</th>
                                        </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <b-modal id="modal_aviso" hide-footer>
            <h5 class="text-center">{{ msg_modal }}</h5>
            <hr>
            <div class="input-group justify-content-md-end">
                <b-button
                    class="mr-1 btn btn-secondary"
                    @click="recarregar_pagina()"
                >Ok</b-button>
            </div>
        </b-modal>
        <Footer />
    </div>
</template>

<style scoped>
    .bs-btn i {margin-left: 5px;}
</style>

<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {
            user: {},
            page: "BoletimTurmas",
            boletim: [],
            // Auxiliares de visualização
            loading: false,
            mostrar_tabela: false,
            msg_modal: '',
            loading_modal: false,
            habilitar_pagina: false,
            msg_atualiza_boletim: false
        };
    },
    created() {
        this.carrega_boletim();
    },
    methods: {
        // Busca das informações de boletim de uma turma
        async carrega_boletim(){
            this.loading = true;
            let url = "boletim/todas/turmas";
            const response = await this.$http.get(url);

            this.boletim = [];
            let data_table = new Array();
            response.data.forEach(turma => {
                let value_slot1 = '';
                let value_slot2 = '';
                let value_slot3 = '';
                let value_slot4 = '';
                let value_slot5 = '';
                let value_slot6 = '';
                let value_slot7 = '';
                let value_slot8 = '';
                let value_slot9 = '';
                let value_slot10 = '';
                let value_slot11 = '';
                let value_slot12 = '';
                let value_slot13 = '';
                let value_slot14 = '';
                let value_slot15 = '';

                // Tratativa das notas por slot
                if(turma.slot1 != null){
                    value_slot1 = Math.round(turma.slot1 * 100) / 100;
                }
                if(turma.slot2 != null){
                    value_slot2 = Math.round(turma.slot2 * 100) / 100;
                }
                if(turma.slot3 != null){
                    value_slot3 = Math.round(turma.slot3 * 100) / 100;
                }
                if(turma.slot4 != null){
                    value_slot4 = Math.round(turma.slot4 * 100) / 100;
                }
                if(turma.slot5 != null){
                    value_slot5 = Math.round(turma.slot5 * 100) / 100;
                }
                if(turma.slot6 != null){
                    value_slot6 = Math.round(turma.slot6 * 100) / 100;
                }
                if(turma.slot7 != null){
                    value_slot7 = Math.round(turma.slot7 * 100) / 100;
                }
                if(turma.slot8 != null){
                    value_slot8 = Math.round(turma.slot8 * 100) / 100;
                }
                if(turma.slot9 != null){
                    value_slot9 = Math.round(turma.slot9 * 100) / 100;
                }
                if(turma.slot10 != null){
                    value_slot10 = Math.round(turma.slot10 * 100) / 100;
                }
                if(turma.slot11 != null){
                    value_slot11 = Math.round(turma.slot11 * 100) / 100;
                }
                if(turma.slot12 != null){
                    value_slot12 = Math.round(turma.slot12 * 100) / 100;
                }
                if(turma.slot13 != null){
                    value_slot13 = Math.round(turma.slot13 * 100) / 100;
                }
                if(turma.slot14 != null){
                    value_slot14 = Math.round(turma.slot14 * 100) / 100;
                }
                if(turma.slot15 != null){
                    value_slot15 = Math.round(turma.slot15 * 100) / 100;
                }

                data_table.push([
                    "<span>" + turma.turma_codigo + "</span>",
                    "<span>" + value_slot1 + "</span>",
                    "<span>" + value_slot2 + "</span>",
                    "<span>" + value_slot3 + "</span>",
                    "<span>" + value_slot4 + "</span>",
                    "<span>" + value_slot5 + "</span>",
                    "<span>" + value_slot6 + "</span>",
                    "<span>" + value_slot7 + "</span>",
                    "<span>" + value_slot8 + "</span>",
                    "<span>" + value_slot9 + "</span>",
                    "<span>" + value_slot10 + "</span>",
                    "<span>" + value_slot11 + "</span>",
                    "<span>" + value_slot12 + "</span>",
                    "<span>" + value_slot13 + "</span>",
                    "<span>" + value_slot14 + "</span>",
                    "<span>" + value_slot15 + "</span>"
                ]);
            });

            $("#notas_turma").DataTable({
                data: data_table,
                destroy: true,
                responsive: {
                    details: {
                    display: $.fn.dataTable.Responsive.display.modal({
                        header: function (row) {
                        var data = row.data();
                        return "Details for " + data[0] + " " + data[1];
                        },
                    }),
                    renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
                    },
                },
                lengthChange: false,
                autoWidth: false,
                dom: 'Bfrtip',
                buttons: [            
                    {
                        extend: 'copy',
                        text: 'Copiar',
                        className:'btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio',
                    },
                    {
                        extend: 'csv',
                        text: 'CSV',
                        className:'btn btn-secondary buttons-csv buttons-html5',
                    },
                    {
                        extend: 'excel',
                        text: 'Excel',
                        className:'btn btn-secondary buttons-excel buttons-html5',
                    },
                    {
                        extend: 'pdf',
                        text: 'PDF',
                        className:'btn btn-secondary buttons-pdf buttons-html5',
                    },
                    {
                        extend: 'print',
                        text: 'Imprimir',
                        className:'btn btn-secondary buttons-print buttons-html5',
                    },
                    {
                        extend: 'colvis',
                        text: 'Exibir Colunas',
                        className:'btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim',              
                    }
                ],
                bLengthChange: true,
                bPaginate: true,
                pageLength: 10,
                bInfo: true,
                language: {
                    sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                    sInfoEmpty: " ",
                    sLengthMenu: "_MENU_ resultados por página",
                    sSearch: "",
                    sEmptyTable: "Nenhum registro encontrado",
                    sInfoFiltered: "Filtrados de _MAX_ registros",
                    sLoadingRecords: "Carregando...",
                    sProcessing: "Processando...",
                    sInfoPostFix: "",
                    sInfoThousands: ".",
                    sSearchPlaceholder: "Pesquisar",
                    sZeroRecords:
                    "Nenhum registro encontrado <i class='far fa-frown'></i>",
                    paginate: {
                        sNext: "Próximo",
                        sPrevious: "Anterior",
                    },
                }
            })
            .buttons()
            .container()
            .appendTo("#example_wrapper .col-md-6:eq(0)");

            this.mostrar_tabela = true;
            this.loading = false;
        },
        // Função PESADA que atualiza o boletim
        async atualiza_boletim(){
            this.msg_atualiza_boletim = true;
            this.loading = true;
            this.mostrar_tabela = false;

            let url = "atualizar/boletim/turmas";
            try {
                await this.$http.get(url);
                this.msg_modal = "Dados atualizados com sucesso!";
                this.$bvModal.show("modal_aviso");
            } catch (err) {
                console.log(err);
                this.msg_modal = "Algo deu errado. Entre em contato com Tech.";
                this.$bvModal.show("modal_aviso");
            }
        },
        // Função para recarregar a página
        recarregar_pagina(){
            location.reload();
        }
    },
    mounted() {
        window.elemento_vue = this; 
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);

        this.user.permissoes.forEach(permissao => {
            if (permissao.privilegio_tipo_id == 9 || permissao.privilegio_tipo_id == 12){
                this.habilitar_pagina = true;
            }
        });
    }
}
</script>