<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="content ">
        <div class="container-fluid">
          <div class="card GestaoSoftcheckCardTitleBtns col-sm-12 col-md-12">
            <div class="col-sm-7 col-md-7">
              <h1>Criação de itens para softcheck</h1>
            </div>
            <div class="col-sm-5 col-md-5 GestaoSoftcheckCardBtns">
                <button class="btn" v-b-modal.meu-modal-editar>Editar</button>
                <button class="btn" v-b-modal.meu-modal-Cadastro>Criar / Duplicar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div class="card col-sm-12">
            <div class="row GestaoSoftcheckCardTitleInputs">

              <div class="col-sm-4  GestaoSoftcheckCardTitle">
                <h1>Procurar itens de softcheck por turma</h1>
              </div>

              <select id="" class="form-control form-control-select col-sm-4">
                <option class=""></option>
              </select>

            </div>
          </div>
        </div>
      </div>

       <!--Modal Editar-->
      <b-modal size="xl" id="meu-modal-editar" hide-footer>
        <template #modal-title class="modal-editar-title-template"> Editar itens de softcheck</template>
        <div class="container container-modal-editar">
            
           <b-form class="p-1">
              <div>
                <b-row>
                <b-col md="12" sm="12" class="">
                  <label for="" class="col-md-5 ">Adicionar itens de softcheck para turma: </label>
                  <b-form-select
                    class="col-md-2"
                  >
                  </b-form-select>
                </b-col>
              </b-row>
              <br/>
              <b-row>
                <b-col md="12" sm="12" class="">
                  <label for="" class="col-md-2 ">Tipo: </label>
                  <b-form-select
                    class="col-md-2"
                  >
                  </b-form-select>
                </b-col>
              </b-row>
              </div>

              <div class=" mt-5">
                <b-row>
                  <b-col md="12" sm="12" class="">
                    <label for="" class="col-md-12">Editar itens: </label>  
                  </b-col>
                </b-row>

                 <b-row class="mt-3">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> - </span>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> - </span>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> + </span>
                    </b-col>
                  </b-row>
              </div>
           </b-form>
            
        </div>
      </b-modal>

      <!--Modal Cadastro-->
      <b-modal size="xl" id="meu-modal-Cadastro" hide-footer>
        <b-card no-body>
        <b-tabs card>

          <b-tab no-body title="Criar">
            <div class="">
              <b-form class="p-5">
              <div>
                <b-row>
                <b-col md="12" sm="12" class="">
                  <label for="" class="col-md-5 ">Adicionar itens de softcheck para turma: </label>
                  <b-form-select
                    class="col-md-2"
                  >
                  </b-form-select>
                </b-col>
              </b-row>
              <br/>
              <b-row>
                <b-col md="12" sm="12" class="">
                  <label for="" class="col-md-2 ">Tipo: </label>
                  <b-form-select
                    class="col-md-2"
                  >
                  </b-form-select>
                </b-col>
              </b-row>
              </div>

              <div class=" mt-5">
                <b-row>
                  <b-col md="12" sm="12" class="">
                    <label for="" class="col-md-12">Editar itens: </label>  
                  </b-col>
                </b-row>

                 <b-row class="mt-3">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> - </span>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> - </span>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> + </span>
                    </b-col>
                  </b-row>
              </div>
           </b-form>
            </div>
          </b-tab>

            <b-tab no-body title="Criar Tipos">
                <div class="">
              <b-form class="p-5">
              <div>
                <b-row>
                <b-col md="12" sm="12" class="">
                  <label for="" class="col-md-5 ">Adicionar itens de softcheck para turma: </label>
                  <b-form-select
                    class="col-md-2"
                  >
                  </b-form-select>
                </b-col>
              </b-row>
              <br/>
              </div>

              <div class=" mt-3">
                <b-row>
                  <b-col md="12" sm="12" class="">
                    <label for="" class="col-md-12">Editar itens: </label>  
                  </b-col>
                </b-row>

                 <b-row class="mt-3">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> - </span>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> - </span>
                    </b-col>
                  </b-row>

                  <b-row class="mt-2">
                    <b-col md="12" sm="12" class="">
                      <label for="" class="col-md-2 ">Item: </label>
                      <b-form-select
                        class="col-md-2"
                      >
                      </b-form-select> <span class="Itens-softcheck-Sinal"> + </span>
                    </b-col>
                  </b-row>
              </div>
           </b-form>
            </div>
            </b-tab>

            <b-tab no-body title="Importar">
              <div class="container p-5">
                <b-form>
                  <div>
                    <b-row>
                      <b-col md="12" sm="12" class="">
                            <label for="" class="col-md-12 softcheck-inportar-modal-txt softcheck-inportar-modal-txt2">Atenção! Este processo irá sobrescrever dados no banco.</label>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12" sm="12" class="mt-5">
                          <label for="" class="col-md-12 softcheck-inportar-modal-txt softcheck-inportar-modal-txt1">Arquivo exemplo para uploud</label>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12" sm="12" class="mt-5">
                        <div class="softcheck-inportar-modal-input-Btn">
                          <b-form-input class="col-md-9" placeholder="Arraste a planilha para cá"></b-form-input>
                          <button class="col-md-2">Procurar</button>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12" sm="12" class="mt-5 softcheck-inportar-modal-Btn-Ok-Cancelar">
                        <button class="btn-cancelar">Cancelar</button>
                        <button class="btn-ok">OK</button>
                      </b-col>
                    </b-row>

                  </div>
                </b-form>
              </div>
            </b-tab>

            <b-tab no-body title="Duplicar itens">
              <div class="container p-5">
                <b-form>
                  <b-row md="12" sm="12" >
                    <label for="" class="col-md-3 ">Copiar Itens : </label>

                    <b-form-select class="col-md-2" ></b-form-select>

                    <svg class="bi bi-arrow-right  col-md-1" xmlns="http://www.w3.org/2000/svg" width="16" height="40" fill="currentColor"  viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>

                    <label for="" class="col-md-2 ">Tipo : </label>

                    <b-form-select class="col-md-2" ></b-form-select>
                  </b-row>

                  <b-row md="12" sm="12" class="mt-3">
                    <label for="" class="col-md-3 ">Duplicar em : </label>

                    <b-form-select class="col-md-2" ></b-form-select>

                    <svg class="bi bi-arrow-right col-md-1" xmlns="http://www.w3.org/2000/svg" width="16" height="40" fill="currentColor"  viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>

                    <label for="" class="col-md-2 ">Tipo : </label>

                    <b-form-select class="col-md-2" ></b-form-select>
                  </b-row>

                  <b-row md="12" sm="12" class="mt-5">
                    <label for="" class="col-md-12 ">Visualizar itens do softcheck do {day 7}</label>
                  </b-row>

                  <b-row md="12" sm="12" class="mt-3">
                    <label for="" class="col-md-2 ">Item : </label>
                    <b-form-input class="col-md-2" placeholder="ponto 1"></b-form-input>
                  </b-row>
                  <b-row md="12" sm="12" class="mt-3">
                    <label for="" class="col-md-2 ">Item : </label>
                    <b-form-input class="col-md-2" placeholder="ponto 2"></b-form-input>
                  </b-row>
                  <b-row md="12" sm="12" class="mt-3">
                    <label for="" class="col-md-2 ">Item : </label>
                    <b-form-input class="col-md-2" placeholder="ponto 3"></b-form-input>
                  </b-row>

                  <b-row>
                      <b-col md="12" sm="12" class="mt-2 softcheck-inportar-modal-Btn-Ok-Cancelar">
                        <button class="btn-cancelar">Cancelar</button>
                        <button class="btn-ok">Copiar</button>
                      </b-col>
                    </b-row>
                </b-form>
              </div>
            </b-tab>

          </b-tabs>
        </b-card>
      </b-modal>
      
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

// function abreModalEdicao(usuario_id) {
//   window.elemento_vue.editaUsuario(usuario_id);
// }

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "GestaoSoftcheck",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user:{},
      preloader_status: true,
      file: "",
      page: "Usuarios",
    };
  },

  methods: {                                                                              


    
    // async listaUsuarios() {      
    //   this.habilitaBotoes();
    //   this.loadingDatatable = true;

    //    let url = "lista/usuarios";
    //    const response = await this.$http.get(url);

    //    this.dados_usuarios = response.data;
    //    console.log(this.dados_usuarios)

    //   let data_table = new Array();
    //    this.dados_usuarios.forEach((dados_usuario) => {
    //     let status_cor = "text-secundary";

    //     let botao_editar_usuario =
    //       "<button class='btn btn-warning btn-sm mr-1 open-usuario' id = " +
    //       dados_usuario.profissional_id +
    //       " type='button'><i id = " +
    //       dados_usuario.profissional_id +
    //       " class = 'fas fa-edit'></i>Editar</button>";


    //     data_table.push([
    //       "<span class=" +
    //         status_cor +
    //         ">" +
    //         (this.btn_editar ? botao_editar_usuario : "" )+
    //         "</span>",

    //       "<span class=" + status_cor + ">" + dados_usuario.profissional_nome + "</span>",
    //       "<span class=" + status_cor + ">" + dados_usuario.profissional_user + "</span>",
    //       "<span class=" + status_cor + ">" + dados_usuario.squad_nome + "</span>",
    //       "<span class=" + status_cor + ">" + 
    //       (dados_usuario.profissional_status == "A" ? "Ativo" : "Inativo")
    //        + "</span>",
    //       "<span class=" + status_cor + ">" + dados_usuario.profissional_log_criacao + "</span>",
    //       "<span class=" + status_cor + ">" + dados_usuario.profissional_cep + "</span>",
    //       "<span class=" + status_cor + ">" + dados_usuario.profissional_endereco + "</span>",
    //       "<span class=" + status_cor + ">" + dados_usuario.profissional_end_numero + "</span>",
    //       "<span class=" + status_cor + ">" + dados_usuario.profissional_ultacesso + "</span>",
    //     ]);
    //   });
      
    //   $("#tableUsuarios")
    //     .DataTable({
    //       data: data_table,
    //       destroy: true,
    //       responsive: true,
    //       lengthChange: false,
    //       autoWidth: false,
    //       language: {
    //         sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
    //         sInfoEmpty: " ",
    //         sLengthMenu: "_MENU_ resultados por página",
    //         sSearch: "",
    //         sEmptyTable: "Nenhum registro encontrado",
    //         sInfoFiltered: "Filtrados de _MAX_ registros",
    //         sLoadingRecords: "Carregando...",
    //         sProcessing: "Processando...",
    //         sInfoPostFix: "",
    //         sInfoThousands: ".",
    //         sSearchPlaceholder: "Pesquisar",
    //         sZeroRecords:
    //           "Nenhum registro encontrado <i class='far fa-frown'></i>",
    //         paginate: {
    //           sNext: "Próximo",
    //           sPrevious: "Anterior",
    //         },
    //       },
    //       buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
    //       drawCallback: function() {
    //         $(".open-usuario").on("click", function(event) {
    //           abreModalEdicao(event.target.id);
    //         });
    //       },
    //     })
    //     .buttons()
    //     .container()
    //     .appendTo("#tableUsuarios_wrapper .col-md-6:eq(0)"); //#example1_wrapper .col-md-6:eq(0)
    //   this.loadingDatatable = false;
    // },


  
  },

  created() {
    this.preloader_status = false
  },
  mounted() {
    window.elemento_vue = this
    this.user.dados = JSON.parse(sessionStorage.dados_user)
    this.user.privilegios = JSON.parse(sessionStorage.permissoes)
  },
};
</script>

<style >

  .content-wrapper{
    background-color: rgb(219, 219, 219);
  }


  .GestaoSoftcheckCardTitleBtns{
    display: flex;
    flex-direction: row;
    padding: 15px;
  }

  .GestaoSoftcheckCardTitleBtns h1{
    display: flex;
    font-size: 30px;
  }

  .GestaoSoftcheckCardBtns{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
  }

  .GestaoSoftcheckCardBtns button{
    width: 50%;
    background-color:rgb(219, 219, 219);
    font-weight: 600;
    font-size: 20px;
    margin-left: 5px;
    border: none;
  }

  .GestaoSoftcheckCardTitleInputs{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: 10px;
  }

  .GestaoSoftcheckCardTitle{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
  }

  .GestaoSoftcheckCardTitle h1{
    font-size: 15px;
    font-weight: 600;
  }

  .form-control-select{
    background-color: rgb(203, 249, 248);
  }

  /*modal*/
  .Itens-softcheck-Sinal{
    background-color: rgb(90, 90, 90);
    padding: 3px 10px;
    border-radius: 5px;
    color: white;
    font-weight: 600s;
  }

   /*modal editar*/

  .container-modal-editar{
  }

  .modal-editar-title-template{
    background-color: aqua;
  }

  .modal-editar-input{
    border: 1px solid rgb(233, 232, 232);
    background-color:rgb(181, 248, 226);
    padding: 10px;
  }

  .modal-editar-form{
    display: flex;
    width: 100%;
  }

  /*modal criar/duplicar*/

  .softcheck-inportar-modal-input-Btn{
    display: flex;
    justify-content: center;
    padding: 10px;
    border:3px dashed rgb(150, 150, 150);
    border-radius: 10px;
  }

  .softcheck-inportar-modal-input-Btn button{
    border: none;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 0px 5px 5px 0px ;
    background-color: rgb(175, 176, 176);
  }

  .softcheck-inportar-modal-input-Btn input{
    border-radius: 5px 0px 0px 5px ;
  }

  .softcheck-inportar-modal-txt{
    text-align: center;
  }

  .softcheck-inportar-modal-txt2{
    color:red;
  }

  .softcheck-inportar-modal-txt1{
      color:rgb(36, 36, 220);
  }

  .softcheck-inportar-modal-Btn-Ok-Cancelar{
    display: flex;
    justify-content: flex-end;
  }

  .softcheck-inportar-modal-Btn-Ok-Cancelar button{
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    font-weight: 600;
  }

  .btn-cancelar{
    background-color: rgb(247, 247, 67);
  }

  .btn-ok{
    background-color: rgb(236, 76, 76);
  }

  /*Responsividade*/

  @media (max-width:600px){
    .GestaoSoftcheckCardTitleBtns{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }

    .GestaoSoftcheckCardTitleBtns h1{
      text-align: center;
      font-size: 30px;
    }

    .softcheck-inportar-modal-input-Btn{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .softcheck-inportar-modal-input-Btn input{
      margin: 10px 0;
    }

    .Itens-softcheck-Sinal, .bi-arrow-right{
      display: none;
    }
  }
</style>
