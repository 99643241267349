import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/resetSenha',
    name: 'resetSenha',
    component: () => import("../views/resetSenha")
  },
  {
    path: '/editar-perfil',
    name: 'Perfil',
    component: () => import("../views/Perfil")
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import("../views/Agenda")
  },
  {
    path: '/help',
    name: 'HelpVideos',
    component: () => import("../views/HelpVideos")
  },
  {
    path: '/menu-usuarios',
    name: 'Usuarios',
    component: () => import("../views/Usuarios")
  },
  {
    path: '/compras',
    name: 'Compras',
    component: () => import("../views/Compras")
  },
  {
    path: '/vendas',
    name: 'Vendas',
    component: () => import("../views/Vendas")
  },
  {
    path: '/menu-alunos',
    name: 'Menu de alunos',
    component: () => import("../views/Aluno")
  },
  {
    path: '/menu-turmas',
    name: 'Menu de turmas',
    component: () => import("../views/Turmas")
  },
  {
    path: '/grupos',
    name: 'Gerenciamento de Grupos',
    component: () => import("../views/Grupos")
  },
  {
    path: '/gera-grupos',
    name: 'Grupos Aleatórios para Days',
    component: () => import("../views/GruposAleatorios")
  },
  {
    path: '/menu-zoomsalas',
    name: 'Menu de salas zoom',
    component: () => import("../views/ZoomSalas")
  },
  {
    path: '/menu-salaconferencia',
    name: 'Menu de salas de Conferencia',
    component: () => import("../views/SalasConferencia")
  },
  {
    path: '/zoom-videos',
    name: 'Videos do zoom',
    component: () => import("../views/ZoomVideos")
  },
  {
    path: '/menu-criarfacilitadores',
    name: 'Menu de cadastro de facilitadores',
    component: () => import("../views/CriarFacilitadores")
  },
  {
    path: '/menu-alocarfacilitadores',
    name: 'Menu de alocacao de facilitadores',
    component: () => import("../views/AlocarFacilitadores")
  },
  {
    path: '/controle-tarefa',
    name: 'Telas de Tarefas',
    component: () => import("../views/ControleTarefas")
  },
  {
    path: '/menu-treinamentos',
    name: 'Menu de treinamentos',
    component: () => import("../views/Treinamentos")
  },
  {
    path: '/gestao-spaceids-turmas',
    name: 'Gestão Space Ids',
    component: () => import("../views/GestaoSpaceIdsTurmas")
  },
  {
    path: '/area-usuario',
    name: 'Desktop',
    component: () => import("../views/Desktop")
  },
  {
    path: '/controle-geral',
    name: 'Controle Geral',
    component: () => import("../views/ControleGeral")
  },
  {
    path: '/abono',
    name: 'Abono de Falta',
    component: () => import("../views/Abono")
  },
   {
    path: '/embed/abono',
    name: 'Abono de Falta Embed',
    component: () => import("../views/EmbedV4/AbonoEmbed")
  },
  {
    path: '/mentoria-v3',
    name: 'Mentoria embed',
    component: () => import("../views/EmbedV4/MentoriaEmbed")
  },
  {
    path: '/embed/cupom',
    name: 'Cupom Embed',
    component: () => import("../views/EmbedV4/CupomEmbed")
  },
  {
    path: '/embed/agenda',
    name: 'Gerenciamento de Agenda Embed',
    component: () => import("../views/EmbedV4/AgendaEmbed")
  },
  {
    path: '/embed/reset-senha',
    name: 'Gerenciamento de Agenda Embed',
    component: () => import("../views/EmbedV4/ResetSenhaEmbed")
  },
  {
    path: '/embed/compras',
    name: 'Compras Antigo Embed',
    component: () => import("../views/EmbedV4/ComprasAntigo")
  },
  {
    path: '/embed/vendas',
    name: 'Compras novo Embed',
    component: () => import("../views/EmbedV4/VendasEmbed")
  },
  {
    path: '/embed/controle-geral',
    name: 'Controle Geral Embed',
    component: () => import("../views/EmbedV4/ControleGeralEmbed")
  },

  {
    path: '/embed/menu-alocarfacilitadores',
    name: 'Alocar facilitadores Embed',
    component: () => import("../views/EmbedV4/AlocarFacilitadoresEmbed")
  },
  {
    path: '/embed/menu-criarfacilitadores',
    name: 'Criar facilitadores Embed',
    component: () => import("../views/EmbedV4/CriarFacilitadoresEmbed")
  },
  {
    path: '/embed/videos-zoom',
    name: 'Videos Zoom Embed',
    component: () => import("../views/EmbedV4/VideosZoomEmbed")
  },
  {
    path: '/embed/menu-treinamentos',
    name: 'Treinamentos Embed',
    component: () => import("../views/EmbedV4/MenuTreinamentosEmbed")
  },
  {
    path: '/embed/gestao-class',
    name: 'Gestão Class Embed',
    component: () => import("../views/EmbedV4/GestaoClassEmbed")
  },
  {
    path: '/mentoria',
    name: 'mentoria de cx',
    component: () => import("../views/Mentoria")
  },
  {
    path: '/cupom',
    name: 'Gerenciamento de Cupons',
    component: () => import("../views/Cupom")
  },
  {
    path: '/cupom-novo',
    name: 'Gerenciamento de Cupons',
    component: () => import("../views/CupomNovo")
  },
  {
    path: '/compras-alunos',
    name: 'Compras por alunos',
    component: () => import("../views/ComprasAlunosVendas")
  },
  {
    path: '/provas',
    name: 'Configuração de Provas',
    component: () => import("../views/Provas")
  },
  {
    path: '/regras',
    name: 'Configuração de Regras',
    component: () => import("../views/Regras")
  },
  {
    path: '/regras-lms',
    name: 'Configuração de Regras LMS',
    component: () => import("../views/RegrasLms")
  },
  {
    path: '/emissao-nota-fiscal',
    name: 'Emissão de Nota Fiscal',
    component: () => import("../views/EmissaoNotaFiscal")
  },
  {
    path: '/liberacao-slot',
    name: 'Liberação de Slot',
    component: () => import("../views/LiberacaoSlot")
  },
  {
    path: '/plano-estudos',
    name: 'Plano de estudos',
    component: () => import("../views/PlanoEstudos")
  },
  {
    path: '/comunicados',
    name: 'Comunicados',
    component: () => import("../views/Comunicados")
  },
  {
    path: '/gestao-notificacoes',
    name: 'Gestão de Notificações',
    component: () => import("../views/GestaoNotificacoes")
  },
  {
    path: '/gestao-class',
    name: 'Gestão do dnc class',
    component: () => import("../views/GestaoClass")
  },
  {
    path: '/grupos-softcheck',
    name: 'Grupos do softcheck',
    component: () => import("../views/GruposSoftcheck.vue")
  },
  {
    path: '/bulk-arquivos',
    name: 'Bulk de Arquivos',
    component: () => import("../views/BulkArquivos")
  },
  {
    path: '/bulk-conteudo',
    name: 'Bulk de Conteúdo',
    component: () => import("../views/BulkConteudo")
  },
  {
    path: '/preview-bulk-conteudo',
    name: 'Preview Bulk de conteúdo',
    component: () => import("../views/PreviewBulkConteudo")
  },
  {
    path: '/bulk-acessos-cursos',
    name: 'Bulk de acesso aos cursos',
    component: () => import("../views/BulkAcessoCursos")
  },
  {
    path: '/gestao-de-cursos',
    name: 'Gestão de cursos',
    component: () => import("../views/GestaoCursos")
  },
  {
    path: '/gestao-de-versionamento',
    name: 'Gestão de versionamento',
    component: () => import("../views/GestaoVersionamento")
  },
  {
    path: '/presencamasp',
    name: 'Presença no Masp',
    component: () => import("../views/PresencaMasp")
  },
  {
    path: '/presencagravacao',
    name: 'Presença de Gravacao',
    component: () => import("../views/PresencaGravacao")
  },  
  {
    path: '/assignment',
    name: 'Assignment',
    component: () => import("../views/Assignment")
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/status',
    name: 'Status dos Sistemas',
    component: () => import("../views/DNCStatus")
  },
  {
    path: '/teste',
    name: 'Teste',
    component: () => import("../views/teste")
  },
  {
    path: '*',
    name: 'Página não encontrada',
    component: () => import("../views/NotFound")
  },
  {
    path: '/boletim-alunos',
    name: 'Boletim',
    component: () => import("../views/Boletim")
  },
  {
    path: '/boletim-turmas',
    name: 'BoletimTurmas',
    component: () => import("../views/BoletimTurmas")
  },
  {
    path: '/reset-credencial',
    name: 'ResetCredencial',
    component: () => import("../views/ResetCredencial")
  },
  {
    path: '/gestao-de-acessos',
    name: 'GestaoAcessos',
    component: () => import("../views/GestaoAcessos")
  },
  {
    path: '/link-vendas',
    name: 'LinkVendas',
    component: () => import("../views/LinkVendas")
  },
  {
    path: '/carrinho-temporario',
    name: 'Montar Carrinho',
    component: () => import("../views/CarrinhoTemporario")
  },
  {
    path: '/gestao-softcheck',
    name: 'Gestao softcheck',
    component: () => import("../views/GestaoSoftcheck")
  },

]

if((sessionStorage.acesso_auth!="Permitido") && (sessionStorage.dados_user!="") 
&& (sessionStorage.carrega_menu!="") && (sessionStorage.permissoes!="")  
&& (window.location.href.split("/")[3] != "") 
&& (window.location.href.split("/")[3] != "presencamasp") 
&& (window.location.href.split("/")[3] != "presencagravacao") 
&& (window.location.href.split("/")[3] != "embed") 
&& (window.location.href.split("/")[3] != "mentoria-v3") 
&& (window.location.href.split("/")[3] != "teste")){
  document.location = process.env.BASE_URL;
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
