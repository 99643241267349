<template>
  <div id="root">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
		<div class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<h1 class="m-0">Gestão de Acessos <i class="spinner-border text-primary"  v-show="loading"></i></h1>
					</div>
				
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item"><a href="home.php">Home</a></li>
							<li class="breadcrumb-item active">Gestão de Acessos</li>
						</ol>
					</div>
				</div>			
			</div>
		</div>

		<div class="content">
			<div class="container-fluid">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title">
							Adicione ou Remova Acessos de Alunos da LMS
						</h3>
					</div>
					<div class="card-body col-sm-12">
						<div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                    <b-input-group prepend="RID" class="mt-3">
                      <b-form-input type="number" v-model="aluno_id" @keydown.enter.native="verifica_aluno_id()" @change="verifica_aluno_id()"></b-form-input>                      
                    </b-input-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8">
                    <b-input-group prepend="Nome" class="mt-3">
                      <template #append>
                        <b-input-group-text :class="credencial_classe"><strong>{{status_aluno}}</strong></b-input-group-text>
                      </template>
                      <b-form-input readonly :class="credencial_classe" v-model="aluno_nome"></b-form-input>  
                    </b-input-group>
                </div>                  
              </div>
						
							<div class="row table-responsive mt-3" v-show="!loading">
								<table id="example" class="table table-striped table-bordered">
								<thead>
									<tr>
                    <th>Ação</th>  
                    <th>Acesso ID</th>
                    <th>Curso ID</th>
                    <th>Curso Nome</th>
                    <th>Data Criacao</th>
                    <th>Status</th>									
									</tr>
								</thead>
								<tbody></tbody>
								</table>
							</div>
              
						</div>
					</div>
				</div>
			</div>
		</div>		
	</div>
	<!-- Control Sidebar -->
	<aside class="control-sidebar control-sidebar-dark">
		<!-- Control sidebar content goes here -->
	</aside>
	<!-- /.control-sidebar -->

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>
.bs-btn i {
  transform: translateX(-5px);
}

.bs-btn-primary {
  color: var(--wht-3);
}
.bs-btn-primary:hover {
  color: #203d92;
}
</style>


<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

var dados_checks = new Array();
window.dados_checks = dados_checks;

function excluir_acesso(acesso_id) {
  window.elemento_vue.excluir_acesso(acesso_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

// import SelectUtmContent from "@/components/SelectUtmContent.vue";
// import SelectUtmSource from "@/components/SelectUtmSource.vue";
// import SelectUtmCampaign from "@/components/SelectUtmCampaign.vue";
// import SelectUtmMedium from "@/components/SelectUtmMedium.vue";


export default {
  name: "GestaoAcessos",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
    // SelectUtmContent,
    // SelectUtmMedium,
    // SelectUtmCampaign,
    // SelectUtmSource,
  },
  data() {
    return {
      preloader_status: true,
      loading: false,
      page: "",
      
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      cursos: [],
      aluno_nome:"",
      aluno_id:'',
      status_aluno:"",
      credencial_classe:"text-danger",
      
    };
  },
  created() {
    this.preloader_status = false;
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {

    async verifica_aluno_id() {
      try{
        this.loading = true;
        let url = "lista/acessos/lms/"+this.aluno_id;
        const response = await this.$http.get(url);      
        if(response.data!=null){
          this.aluno_nome = response.data.aluno.aluno_nome;
          this.cursos = response.data.cursos;
          var data_table = new Array();
          data_table = this.monta_dados(this.cursos); 
          $("#example")
            .DataTable({
              data: data_table,
              destroy: true,
              responsive: {
                details: {
                  display: $.fn.dataTable.Responsive.display.modal({
                    header: function (row) {
                      var data = row.data();
                      return "Details for " + data[0] + " " + data[1];
                    },
                  }),
                  renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
                },
              },
              lengthChange: false,
              autoWidth: false,
              buttons: [
                {
                  extend: "copy",
                  text: "Copiar",
                  className:
                    "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
                },
                {
                  extend: "csv",
                  text: "CSV",
                  className: "btn btn-secondary buttons-csv buttons-html5",
                },
                {
                  extend: "excel",
                  text: "Excel",
                  className: "btn btn-secondary buttons-excel buttons-html5",
                },
                {
                  extend: "pdf",
                  text: "PDF",
                  className: "btn btn-secondary buttons-pdf buttons-html5",
                },
                {
                  extend: "print",
                  text: "Imprimir",
                  className: "btn btn-secondary buttons-print buttons-html5",
                },
                {
                  extend: "colvis",
                  text: "Exibir Colunas",
                  className:
                    "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
                },
              ],
              drawCallback: function () {
                $(".botao_excluir").on("click", function (event) {
                  excluir_acesso(this.id,event.target.id);
                });
              },
              // columnDefs: [
              //   {
              //     targets: [10, 11, 13],
              //     visible: false,
              //   },
              // ],
            })
            .buttons()
            .container()            
            .appendTo("#example_wrapper .col-md-6:eq(0)");
          this.status_aluno = "ALUNO COM ACESSO";
          this.credencial_classe = "bg-warning text-black";
          this.loading = false;
        }        
      }catch(er){
        console.log("ERRO");
        this.aluno_nome = "";
        this.loading = false;
        this.status_aluno = "ALUNO SEM ACESSO";
        this.credencial_classe = "";
      }

    },

    excluir_acesso(acesso_id){
       this.$bvModal.msgBoxConfirm('Você deseja remover mesmo o acesso '+acesso_id+' do aluno '+this.aluno_nome+'?', {
          title: 'ATENÇÃO',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'EXCLUIR',
          cancelTitle: 'CANCELAR',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(value => {
            if(value==true){
              try{
                this.loading = false;
                let url = "exclui/acessos/lms";
                let dados = {
                  'acesso_id' : acesso_id,
                };
                const response = this.$http.post(url,dados);      
                if(response!=null){
                    this.$bvModal.msgBoxOk('Acesso Removido com Sucesso!', {
                      title: 'Confirmação',
                      size: 'sm',
                      buttonSize: 'sm',
                      okVariant: 'success',
                      headerClass: 'p-2 border-bottom-0',
                      footerClass: 'p-2 border-top-0',
                      centered: true
                    })
                    this.verifica_aluno_id();
                }                    
              }catch(er){
                  this.$bvModal.msgBoxOk('ERRO NA OPERAÇÃO');
              }                    
            }
            else{
              this.$bvModal.msgBoxOk('Operação Cancelada!');
            }
        }).catch(err=>{
          console.log("ERRO"+err);
        });
      
    },


    monta_dados(dados) {
      let data_table = new Array();
      dados.forEach((cursos) => {

        if(cursos.status==1){
          cursos.status = '<i class="fas fa-lightbulb fa-lg" style="color:orange" data-toggle="tooltip" data-html="true" title="Status Ativo"></i>';
        }else{
          cursos.status = '<i class="far fa-lightbulb fa-lg" data-toggle="tooltip" data-html="true" title="Status Desativadp"></i>';
        }
              
        data_table.push([
          "<span class='d-flex justify-content-center'> <button class='btn bg-danger btn-sm botao_excluir' id='"+cursos.acesso_id+"' title='Excluir'><i class='fas fa-trash-alt fa-lg'></i> </button></span>",
          cursos.acesso_id,
          cursos.curso_id,
          cursos.curso_titulo,
          cursos.acesso_data_criacao,
          "<span class='d-flex justify-content-center'>"+cursos.status+"</span>",          
        ]);
      });

      return data_table;
    },

    habilita_botoes() {
      let privilegio = this.dados_user.privilegio_financeiro;
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },
  },

  mounted() {
  this.dados_user = JSON.parse(sessionStorage.dados_user);
  window.elemento_vue = this;
  },
};
</script>

