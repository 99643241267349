<template>
  <div id="root">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <v-app>
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Criação de Carrinhos</h1>
            </div>
          
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Criação de Carrinhos</li>
              </ol>
            </div>
          </div>			
        </div>
      </div>
      <div class="content">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Veja os carrinhos temporários para o novo Checkout
              </h3>
            </div>
            <div class="card-body col-sm-12">
              <!-- Linha do Botao de Adicionar -->
              <div class="row mb-2 mt-2">
                <div class="col-sm-12 col-md-12"> 
                  <button class="btn btn-primary btn-block" v-show="!exibeCarrinho" @click="exibeCarrinho=!exibeCarrinho">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-plus-fill" viewBox="0 0 16 16">
                      <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z"/>
                    </svg> Novo Carrinho
                  </button>
                </div>
              </div>		
              <!-- Linha do Preenchimento do Form -->
              <div class="row">
                <!-- Coluna de Preenchimento -->
                <div class="col-md-8">
                  <div class="card bg-light" v-show="exibeCarrinho">
                    <h4 class="card-header bg-blue">
                      Criando Novo Carrinho
                      <button type="button" class="btn btn-primary btn-block" aria-label="Close" @click="exibeCarrinho=!exibeCarrinho">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </h4>
                    <div class="card-body" >
                      <div class="row">
                          <!-- 1 Linha Formação -->
                          <div class="col-md-12 col-sm-12">

                              <div class="form-group" v-show="!marketing">
                                <label for="exampleInputEmail1">Selecionar Vendedor:</label>
                                <select id="inputState" class="form-control" v-model="vendedor_selecionado" :disabled="bloqueia_vendedor">
                                  <option class="text-capitalize" v-for="(vendedor,index) in vendedores" :key="index"  :value="{'value':vendedor.value,'text':vendedor.text}"> {{vendedor.text.toLowerCase()}}</option>
                                </select>
                              </div>

                              <div class="row" v-show="!marketing">
                                <div class="col-md-12 col-12">
                                  <label for="inputState">Selecionar Aluno (Opcional):</label>
                                </div>
                              </div>

                              <div class="row m-1" v-show="!marketing">
                                <v-autocomplete class="bg-light" v-model="aluno_selecionado" :items="alunos" filled @change="mostraNome()"></v-autocomplete>                               
                              </div>

                              <div class="row">
                                <div class="col-md-12 col-12">
                                    <label for="inputState">Selecionar Formação:</label>
                                </div>                                
                              </div>
                              
                              <div class="row">
                                <div class="col-12">
                                  <div class="row " v-for="(curso_formacao,index) in cursos_formacao" :key="index_formacao=index">                                
                                    <div class="col-9 form-group">
                                      <select class="custom-select" required v-model="curso_formacao[index]" @change="verificaCupons()">
                                        <option v-for="(formacao,index2) in formacoes" :value="{'id_formacao':formacao.value,'formacao_nome':formacao.text}" :key="index2">
                                          {{formacao.text}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-2">                                  
                                      <button class="btn btn-warning" @click="removeFormacao(index)" v-show="cursos_formacao.length>1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                                          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-info" @click="adicionaFormacao()">Adicionar 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                    </button>
                                </div>
                              </div>
                            
                              <div class="row mt-2">
                                <div class="col-md-12 col-12">
                                  <label for="inputState">Selecionar Compelling: (Opcional)</label>
                                </div>                                
                              </div>
                              
                              <div class="row">
                                <div class="col-12">
                                  <div class="row" v-for="(curso,index) in cursos_compelling" :key="index">                                                                
                                    <div class="col-9 form-group">
                                      <select id="inputState" class="custom-select" v-model="curso[index]">
                                        <option selected v-for="(treinamento,index2) in compelling" :value="{'id_curso':treinamento.value,'curso_nome':treinamento.text}" :key="index2">
                                        {{treinamento.text}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-2">
                                      <button class="btn btn-warning" @click="removeCompelling(index)" v-show="cursos_compelling.length>1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                                          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                        </svg>
                                      </button>
                                    </div>                                
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12">
                                  <button class="btn btn-info" @click="adicionaCompelling()">Adicionar
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                  </svg>
                                  </button>
                                </div>
                              </div>
                          </div>
                      </div>

                      <!-- CONFIGURACOES AVANCADAS -->
                      <div class="row mt-3 mb-3">
                        <div class="col-md-12 col-12 text-center">
                          <hr>
                          <button type="button" class="btn btn-info" v-if="!habilita_avancadas" @click="habilita_avancadas=!habilita_avancadas"> Abrir Configurações Avançadas </button>                          
                          <button type="button" class="btn btn-info" v-else @click="habilita_avancadas=!habilita_avancadas"> Fechar Configurações Avançadas &times; </button>                          
                        </div>                        
                      </div>

                      <div class="row" v-show="habilita_avancadas">                     
                          <!-- 2 Linha da Formação -->                          
                          <div class="col-md-12 col-12">
                            
                            <div class="custom-control custom-switch mb-2">
                              <input type="checkbox" class="custom-control-input"  @change="habilita_pagamento = !habilita_pagamento" id="customSwitch1">
                              <label class="custom-control-label" for="customSwitch1">Restringir Formas de Pagamento</label>
                            </div>

                            <div id="formas_pagamento" v-show="habilita_pagamento">
                              <small>Selecione as formas de pagamento que devem estar liberadas para o usuário</small> <br>
                              <small style="color:red;">Obs: Desabilitar a opção "boleto parcelado" também desabilita a opção de boleto parcelado no pagamento personalizado!</small>
                              <hr>                             
                                <div class="form-group col-sm-12 col-12">
                                  <div class="form-group form-check  m-0" v-for="(pagamento,index) in tipos_pagamento" :key="index">
                                    <input type="checkbox"  class="form-check-input" id="exampleCheck1" v-model="pagamento.status" >
                                    <label class="form-check-label text-dark font-italic" for="exampleCheck1">{{pagamento.text}}</label>
                                  </div>
                                </div>
                              <hr>
                            </div>

                            <div class="custom-control custom-switch mb-2">
                              <input type="checkbox" class="custom-control-input" v-model="habilita_cartao" id="customSwitch2">
                              <label class="custom-control-label" for="customSwitch2">Permitir Parcelamento Sem Juros</label>
                            </div>

                            <label for="exampleInputEmail1" v-show="!loading_cupom ">Aplicar Cupom para:</label>                                                                                               
                            <p v-show="(typeof cursos_formacao[0][0]=='undefined')">Selecione uma Formação</p>
                            <p v-show="(typeof cursos_formacao[0][0]!='undefined')">{{((typeof cursos_formacao[0][0]!='undefined') && (cupons.length>0))?cursos_formacao[0][0].formacao_nome:'Sem cupons Disponíveis'}}</p>
                            <v-autocomplete @change="atualizaCupom" v-model="cupom_selecionado" hide-selected :loading="loading_cupom"  :items="cupons" filled ></v-autocomplete>                             
                            <!-- <div class="custom-control custom-switch mb-2">
                              <input type="checkbox" class="custom-control-input" v-model="habilita_cupom" id="customSwitch3">
                              <label class="custom-control-label" for="customSwitch3">Ocultar Campo de Cupom</label>
                            </div> -->
                          
                          </div>
                      </div>
                    </div>
                  </div>	
                </div>
              
                <!-- Coluna de Visualização -->
                <div class="col-md-4">
                  <div class="card bg-light" v-show="exibeCarrinho ">
                    
                    <h4 class="card-header bg-blue">
                      Opções Escolhidas
                      <button type="button" class="btn btn-primary btn-block" aria-label="Close" @click="exibeCarrinho=!exibeCarrinho">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </h4>
                    <div class="card-body">
                      <h5 class="card-title font-weight-bold" v-show="!marketing">Aluno Selecionado:</h5>
                        <p class="card-text mb-2 text-muted"> {{aluno_nome}}</p>
                      <hr>
                      <h5 class="card-title font-weight-bold">Formação escolhida:</h5>
                        <p class="card-text mb-2 text-muted">
                          <ul class="list-group">
                            <li class="list-group-item border-0 p-1 bg-light" v-for="(formacao,index) in cursos_formacao" :key="index" >{{formacao[index]?formacao[index].formacao_nome:'Sem Formação Selecionada'}}</li>
                          </ul>
                        </p>
                      <hr>
                      <h5 class="card-title font-weight-bold">Compelling escolhido:</h5>
                        <p class="card-text mb-2 text-muted">
                          <ul class="list-group">
                            <li class="list-group-item border-0 p-1 bg-light mr-1" v-for="(curso,index) in cursos_compelling" :key="index">
                              {{curso[index]?curso[index].curso_nome:'Sem Compelling Selecionado'}}
                            </li>
                          </ul>
                        </p>
                      <div v-show="habilita_pagamento">
                        <h5 class="card-title font-weight-bold">Tipos de Pagamentos Habilitados:</h5>                        
                          <p class="card-text mb-2 text-muted">                                                                   
                            <ul class="list-group">
                              <li class="card-text mb-2" v-for="pagamento in tipos_pagamento" :key="pagamento.id_pagamento" v-show="pagamento.status">{{pagamento.text}}</li>
                            </ul>
                          </p>
                        <hr>
                      </div>                 
                      <hr>
                      <!-- <div v-show="cupom_selecionado">
                        <h5 class="card-title font-weight-bold">Cupom Selecionado</h5>                                            
                          <p class="card-text mb-2 text-muted">
                            {{cupom_selecionado?cupons[cupom_selecionado-1].text:'Nenhum Cupom Selecionado'}}
                          </p>
                        <hr>
                      </div> -->
                      <!-- <div class="fs-4 mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="habilita_cupom? 'black': 'silver'" class="bi bi-ticket-detailed m-1" viewBox="0 0 16 16">
                            <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5ZM5 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z"/>
                            <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z"/>
                          </svg> 
                        <span v-show="!habilita_cupom" class="text-black font-weight-bold">Área de Cupom Habilitado</span>
                        <span v-show="habilita_cupom" ><s>Área de Cupom Ocultada</s></span>
                      </div>                
                      <hr> -->
                      <h5 class="card-title font-weight-bold" v-show="!marketing">Vendedor:</h5>
                        <p class="card-text mb-2">{{vendedor_selecionado.value!=''? vendedor_selecionado.text : 'Sem Vendedor Selecionado'}}</p>
                      <hr>
                    </div>
                    <h5 class="card-title font-weight-bold mb-2 pl-4">Valores:</h5> 
                    <v-row>
                      <v-col>
                        <li class="list-group-item border-0 pr-4 bg-light" v-for="(formacao,index) in cursos_formacao" :key="index" >{{formacao[index]?formacao[index].formacao_nome:'Sem Formação Selecionada'}}
                      </li>
                      </v-col>
                      <v-col>
                        <li class="oldProductStyle mt-3 mb-9 mr-4" v-for="(valor,index) in treinamento_valores" :key="index" > R$ {{(valor.valor_prazo).toFixed(2)}}</li>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <li class="list-group-item border-0 pr-4 bg-light">Desconto no Pix/Boleto:</li>
                        </v-col>
                        <v-col>
                          <li class="descontoStyle mt-3 mb-1 mr-4" v-for="(valor,index) in treinamento_valores" :key="index"  >  R$ {{(valor.valor_prazo - valor.valor_vista).toFixed(2)}}</li>
                      </v-col>
                    </v-row>
                     <div class="list-group-item border-0 pr-4 bg-light" >
                          Cupom de desconto:
                          <div class="descontoStyle" v-if="cupom_valor_vista !== null" > R$ {{(cupom_valor_vista).toFixed(2)}}
                           </div>
                     </div>
                     <h5 class="card-title font-weight-bold mb-2 pl-4" >Total:</h5> 
                    <div>  
                      <div class="list-group-item border-0 pr-4 bg-light" >
                          Pix/Boleto:
                        <div class="totalStyle" v-if="total_vista !== null">R$ {{(total_vista).toFixed(2)}}
                        </div>                      <h class="list-group-item border-0 pr-4 bg-light" ></h>
                          Cartão/Personalizado:
                        <div class="totalStyle" v-if="total_prazo !== null" >R$ {{(total_prazo).toFixed(2)}}</div>
                        </div>
                      </div> 
                          <div class="fs-4 mb-3 pl-4 mt-5">    
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="habilita_cartao? 'black': 'silver'" class="bi bi-credit-card m-1" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                             <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                        </svg> 
                        <span v-show="habilita_cartao" class="text-black font-weight-bold">Parcelamento Sem Juros Habilitado</span>
                        <span v-show="!habilita_cartao" ><s>Parcelamento Sem Juros Desabilitado</s></span>
                      </div>   
                      <hr>
                    <div class="row float-right m-2">
                      <div class="btn-group w-100" role="group" aria-label="">
                        <button type="button" class="btn btn-primary text-white" @click="salvaCarrinho()">Salvar Carrinho</button>                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-show="loading">
                <i class="spinner-border text-primary"></i>
              </div>
              <div class="row">
                <div class="table-responsive" v-show="!loading">
                  <table id="example" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                    <th>Carrinho ID</th>
                    <th>Vendedor</th>
                    <th>Cupom</th>
                    <th>Aluno</th>
                    <th>Métodos de Pagamento</th>
                    <th>Formação</th>
                    <th>Compelling</th>
                    <th>Sem Juros?</th>
                    <th>Data Criação</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>		
    </div>
    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
    <Footer />
    </v-app>

    <b-modal id="modal_response">
      <div class="overflow-auto text-center">
        Carrinho criado com sucesso!
        <div class="d-flex justify-content-center">
          <a @click.prevent="gerar_copiar_link()" class="flex-sup bglessBtn" target="_blank" rel="noopener noreferrer" ref="mylink">
            <i class="fas fa-copy"></i> <small> Copiar link </small>
          </a>
        </div>
      </div>
    </b-modal>

  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>
.bs-btn i {
  transform: translateX(-5px);
}

.bs-btn-primary {
  color: var(--wht-3);
}
.bs-btn-primary:hover {
  color: #203d92;
}
</style>


<script>
var body = document.body;
body.classList.add("hold-transition");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

var dados_checks = new Array();
window.dados_checks = dados_checks;

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "MontarCarrinho",
  components: {
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      checkout_base_url: "",

      preloader_status: false,
      exibeCarrinho: false,
      loading: false,

      cursos_formacao: [{}],
      index_formacao: 0,
      formacoes: [],

      cursos_compelling: [{}],
      compelling: [],

      habilita_avancadas: false,
      tipos_pagamento: [],

      habilita_pagamento: false,
      habilita_pagamento_sjuros: false,
      habilita_cartao: false,
      habilita_cupom: false,

      cupons: [],
      cupom_selecionado: null,
      loading_cupom: false,

      vendedor_selecionado: [],
      vendedores: [],
      bloqueia_vendedor: false,

      alunos: [],
      aluno_selecionado: null,
      aluno_nome: "",

      carrega_dados: false,
      link_gerado: "",
      permissoes: [],
      marketing: false,

      treinamento_valores: [],
      cupom_valor: null,
      cupom_valor_vista: null,
      cupom_valor_prazo: null,
      total_vista: null,
      total_prazo: null,
    };
  },
  created() {
    if (process.env.VUE_APP_ENV === 'homolog') {
      this.checkout_base_url = process.env.VUE_APP_API_CHECKOUT_STAGGING;
    } else {
      this.checkout_base_url = process.env.VUE_APP_API_CHECKOUT_STAGGING;
    }

    this.listaFormacoes();
    this.listaPagamentos();
    this.listaVendedores();
    this.listaAlunos();
  },
  computed: {},
  methods: {
    verificaCupons() {
      if (typeof this.cursos_formacao[0][0].id_formacao != undefined) {
        if (this.cursos_formacao[0][0].id_formacao != "") {
          this.loading_cupom = true;
          this.listaCupons();
        }
      }
      this.atualizaValores();  
    },

    atualizaValores() {
      this.treinamento_valores = []
      this.cursos_formacao.forEach((formacao, index) => {
        this.formacoes.forEach(formacao2 => {
          if (formacao2.value == formacao[index].id_formacao) {
            this.treinamento_valores.push({
              valor_vista: formacao2.valor_vista,
              valor_prazo: formacao2.valor_prazo,
              index: index +1,
            })
          }
        });
      });
    },

    atualizaCupom() {
      this.cupons.forEach(cupomEscolhido => {
        if (cupomEscolhido.value == this.cupom_selecionado){
            this.cupom_valor = cupomEscolhido.desconto
          }
      })
      this.cupom_valor_vista = (this.cupom_valor * this.treinamento_valores[0].valor_vista) / 100
      this.cupom_valor_prazo = (this.cupom_valor * this.treinamento_valores[0].valor_prazo) / 100
      this.total_vista = (this.treinamento_valores[0].valor_vista - this.cupom_valor_vista)
      this.total_prazo = (this.treinamento_valores[0].valor_prazo - this.cupom_valor_prazo)
    },

    verificaFormacoesIguais() {
      const agendas = new Array();
      const cursos = new Array();
      this.cursos_formacao.forEach((curso, index) => {
        agendas.push(curso[index].id_formacao);
        cursos.push(curso[index].formacao_nome);
      });

      let valores_repetidos = new Array();
      agendas.filter(function (elem, index, self) {
        if (!(index === self.indexOf(elem))) {
          valores_repetidos.push(cursos[index]);
        }
        return valores_repetidos;
      });

      if (valores_repetidos.length > 0) {
        this.$bvModal.msgBoxOk(valores_repetidos, {
          title: "Repetições encontradas - Verifique as Formações",
          size: "lg",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        return false;
      } else {
        return true;
      }
    },

    verificaCompellingIguais() {
      const agendas = new Array();
      const cursos = new Array();
      this.cursos_compelling.forEach((curso, index) => {
        agendas.push(curso[index].id_curso);
        cursos.push(curso[index].curso_nome);
      });

      let valores_repetidos = new Array();
      agendas.filter(function (elem, index, self) {
        if (!(index === self.indexOf(elem))) {
          valores_repetidos.push(cursos[index]);
        }
        return valores_repetidos;
      });

      if (valores_repetidos.length > 0) {
        this.$bvModal.msgBoxOk(valores_repetidos, {
          title: "Repetições encontradas - Verifique os Compellings",
          size: "lg",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
        return false;
      } else {
        return true;
      }
    },

    async salvaCarrinho() {
      let formacao_compra = false;
      let compelling_compra = false;

      if (this.cursos_formacao[0][0] != undefined) {
        formacao_compra = this.verificaFormacoesIguais();
      }

      if (this.cursos_compelling[0][0] != undefined) {
        compelling_compra = this.verificaCompellingIguais();
      }

      if (formacao_compra) {
        let path_url = 'escola-dnc'
        this.loading = true;
        let cursos_compra = []
        this.cursos_formacao.forEach(formacao => {
          cursos_compra.push({
            agenda_id_antigo: formacao[0].id_formacao,
            gratuito: 0
          })
          this.formacoes.forEach(formacao2 => {
            if (formacao2.value == formacao[0].id_formacao) {
              path_url = formacao2.path
            }
          });
        });

        if (compelling_compra) {
          this.cursos_compelling.forEach(curso => {
            cursos_compra.push({
              agenda_id_antigo: curso[0].id_curso,
              gratuito: 1
            })
          });
        }

        let dados = {
          aluno_id: this.aluno_selecionado,
          cursos: cursos_compra,
          tipos_pagamento: this.tipos_pagamento,
          cupom_id: this.cupom_selecionado,
          vendedor_id: this.vendedor_selecionado.value,
          habilita_cupom: this.habilita_cupom,
          habilita_cartao: this.habilita_cartao,
        };

        if (this.marketing) {
          dados = {
            aluno_id: null,
            cursos: cursos_compra,
            tipos_pagamento: this.tipos_pagamento,
            cupom_id: this.cupom_selecionado,
            vendedor_id: this.vendedor_selecionado.value,
            habilita_cupom: this.habilita_cupom,
            habilita_cartao: this.habilita_cartao,
          };
        }

        let url = "salvar/carrinho/temporario";
        await this.$http
          .post(url, dados)
          .then((resultado) => {
            this.link_gerado = "https://new.checkout.dnc.group/" + path_url + "?id=" + resultado.data + "&vendedor=" + this.vendedor_selecionado.value
            
            if (this.marketing) {
              this.link_gerado = "https://new.checkout.dnc.group/" + path_url + "?id=" + resultado.data
            }
            
            this.$bvModal.show("modal_response");
            this.loading = false;
            this.carregar_carrinhos();
          })
          .catch((erro) => {
            console.log(erro.message);
            this.loading = false;
            this.$bvModal.msgBoxOk("Um erro não identificado ocorreu. Por favor, entre em contato com tech.", {
              title: "Falha ao salvar carrinho temporário!",
              size: "sm",
              buttonSize: "md",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          });
      }
      else {
        this.$bvModal.msgBoxOk("Selecione pelo menos uma formação!", {
          title: "",
          size: "sm",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      }
    },

    mostraNome() {
      this.alunos.forEach((aluno) => {
        if (aluno.value != "") {
          if (aluno.value == this.aluno_selecionado) {
            this.aluno_nome = aluno.text;
          }
        }
      });
    },

    async listaFormacoes() {
      let url = "listar/cursos";
      await this.$http
        .get(url)
        .then((resultado) => {
          resultado.data.formacoes.forEach((formacao) => {
            if (formacao.agenda_id_antigo != "") {
              this.formacoes.push({
                text:
                  formacao.treinamento_nome +
                  " (" +
                  formacao.agenda_id_antigo +
                  ")",
                value: formacao.agenda_id_antigo,
                path: formacao.curso_path,
                valor_vista: formacao.valor_vista,
                valor_prazo: formacao.valor_prazo,
              });
            }
          });
          resultado.data.compelling.forEach((treinamento) => {
            this.compelling.push({
              text:
                treinamento.treinamento_nome +
                "(" +
                treinamento.agenda_id_antigo +
                ")",
              value: treinamento.agenda_id_antigo,
            });
          });
        })
        .catch((erro) => {
          console.log(erro.message);
          this.$bvModal.msgBoxOk("Um erro não identificado ocorreu. Por favor, entre em contato com tech.", {
            title: "Falha ao listar formações!",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },

    async listaPagamentos() {
      let url = "listar/tipos/pagamento";
      await this.$http
        .get(url)
        .then((resultado) => {
          resultado.data.forEach((tipo_pagamento, index) => {
            this.tipos_pagamento.push({
              id_pagamento: index,
              text: tipo_pagamento.tipo_pagamento,
              value: tipo_pagamento.carrinho_forma_id,
              status: true,
            });
          });
        })
        .catch((erro) => {
          console.log(erro.message);
          this.$bvModal.msgBoxOk("Um erro não identificado ocorreu. Por favor, entre em contato com tech.", {
            title: "Falha ao listar pagamentos!",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },

    async listaCupons() {
      let url = "listar/cupons/disponiveis";
      this.cupons = [];
      await this.$http
        .get(url)
        .then((resultado) => {
          resultado.data.forEach((cupom) => {
            this.cupons.push({
              text:
                cupom.cupom_codigo +
                " - " +
                cupom.cupom_desconto_porcentagem +
                "%",
              value: cupom.cupom_id,
              desconto: cupom.cupom_desconto_porcentagem,
            });
          });
          this.loading_cupom = false;
        })
        .catch((erro) => {
          console.log(erro.message);
          this.$bvModal.msgBoxOk("Um erro não identificado ocorreu. Por favor, entre em contato com tech.", {
            title: "Falha ao listar cupons!",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },

    async listaVendedores() {
      let url = "listar/vendedores";
      await this.$http
        .get(url)
        .then((resultado) => {
          resultado.data.forEach((vendedor) => {
            if (vendedor.profissional_id != "") {
              this.vendedores.push({
                text:
                  vendedor.profissional_cargo != null
                    ? vendedor.profissional_nome +
                      " (" +
                      vendedor.profissional_cargo +
                      ")"
                    : vendedor.profissional_nome,
                value: vendedor.profissional_id,
              });
              if (
                this.dados_user.profissional_id === vendedor.profissional_id
              ) {
                this.vendedor_selecionado = {
                  value: vendedor.profissional_id,
                  text:
                    vendedor.profissional_cargo != null
                      ? vendedor.profissional_nome +
                        " (" +
                        vendedor.profissional_cargo +
                        ")"
                      : vendedor.profissional_nome,
                };
                this.carregar_carrinhos();
              }
            }
          });
        })
        .catch((erro) => {
          console.log(erro.message);
          this.$bvModal.msgBoxOk("Um erro não identificado ocorreu. Por favor, entre em contato com tech.", {
            title: "Falha ao listar vendedores!",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },

    async listaAlunos() {
      let url = "listar/alunos/carrinho";
      await this.$http
        .get(url)
        .then((resultado) => {
          resultado.data.forEach((aluno) => {
            if (aluno.aluno_cpf == null) {
              aluno.aluno_cpf = "";
            }
            this.alunos.push({
              value: aluno.aluno_id,
              text:
                aluno.aluno_id +
                " - " +
                aluno.aluno_nome +
                " - " +
                aluno.aluno_cpf,
            });
          });
        })
        .catch((erro) => {
          console.log(erro.message);
          this.$bvModal.msgBoxOk("Um erro não identificado ocorreu. Por favor, entre em contato com tech.", {
            title: "Falha ao listar alunos!",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
      this.carrega_dados = true;
    },

    adicionaCompelling() {
      this.cursos_compelling.push({});
    },

    adicionaFormacao() {
      this.cursos_formacao.push({});
    },

    removeCompelling(indice) {
      this.cursos_compelling.splice(indice, 1);
    },

    removeFormacao(indice) {
      this.cursos_formacao.splice(indice, 1);
      const indexTreinamentoValor = this.treinamento_valores.findIndex(element => element.index === indice)
      this.treinamento_valores.splice(indexTreinamentoValor + 1, 1)
    },

    removeCupom(indice) {
      this.cupom_valor_vista.splice(indice, 1);
      const indexCupomValor = this.cupom_valor_vista.findIndex(element => element.index === indice)
      this.cupom_valor_vista.splice(indexCupomValor + 1, 1)
    },
    gerar_copiar_link() {
      navigator.clipboard.writeText(this.link_gerado);
      alert('Link copiado: ' + this.link_gerado)
    },

    async carregar_carrinhos() {
      this.loading = true;
      let url = '';
      if (!this.bloqueia_vendedor) {
        url = "listar/carrinho/temporario";
      }
      else {
        url = "listar/carrinho/temporario/" + this.vendedor_selecionado.value;
      }

      await this.$http
        .get(url)
        .then((response) => {
          
          let data_table = new Array();
          response.data.forEach(carrinho => {

            // Cupom
            let cupom_code = carrinho.cupom_codigo;
            if (carrinho.cupom_codigo == null) {
              cupom_code = '';
            }
            // Aluno
            let aluno_info = carrinho.aluno_id + " - " + carrinho.aluno_nome;
            if (carrinho.aluno_id == null) {
              aluno_info = '';
            }
            // Formação
            let formacoes_nomes = carrinho.formacao;
            if (carrinho.formacao == null) {
              formacoes_nomes = '';
            }
            // Compelling
            let compelling_nomes = carrinho.compelling;
            if (carrinho.compelling == null) {
              compelling_nomes = '';
            }
            // Sem juros
            let info_juros;
            if (carrinho.habilitar_juros == 0) {
              info_juros = 'Não';
            }
            else {
              info_juros = 'Sim';
            }

            let path_url = carrinho.curso_path_checkout

            if (carrinho.curso_path_checkout == null) {
              path_url = 'escola-dnc'
            }

            let link_Carrinho = ''
            link_Carrinho = "https://new.checkout.dnc.group/" + path_url + "?id=" + carrinho.carrinho_temporario_id + "&vendedor=" + this.vendedor_selecionado.value
            
            if (this.marketing) {
              link_Carrinho = "https://new.checkout.dnc.group/" + path_url + "?id=" + carrinho.carrinho_temporario_id
            }

            data_table.push([
              "<span> <a target='_blank' href='" + link_Carrinho + "'>" + carrinho.carrinho_temporario_id + "</a></span>",
              "<span>" + carrinho.profissional_id + " - " + carrinho.profissional_nome + "</span>",
              "<span>" + cupom_code + "</span>",
              "<span>" + aluno_info + "</span>",
              "<span>" + carrinho.formas_pagamento + "</span>",
              "<span>" + formacoes_nomes + "</span>",
              "<span>" + compelling_nomes + "</span>",
              "<span>" + info_juros + "</span>",
              "<span>" + carrinho.data_criacao + "</span>",
            ]);
          });

          $("#example").DataTable({
              data: data_table,
              destroy: true,
              responsive: true,
              lengthChange: false,
              autoWidth: false,
              order: [[ 0, "desc" ]],
              language: {
                  sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                  sInfoEmpty: " ",
                  sLengthMenu: "_MENU_ resultados por página",
                  sSearch: "",
                  sEmptyTable: "Nenhum registro encontrado",
                  sInfoFiltered: "Filtrados de _MAX_ registros",
                  sLoadingRecords: "Carregando...",
                  sProcessing: "Processando...",
                  sInfoPostFix: "",
                  sInfoThousands: ".",
                  sSearchPlaceholder: "Pesquisar",
                  sZeroRecords:
                  "Nenhum registro encontrado <i class='far fa-frown'></i>",
                  paginate: {
                      sNext: "Próximo",
                      sPrevious: "Anterior",
                  },
              }
          });
          this.loading = false;
        })
        .catch((erro) => {
          console.log(erro.message);
          this.loading = false;
          this.$bvModal.msgBoxOk("Um erro não identificado ocorreu. Por favor, entre em contato com tech.", {
            title: "Falha ao listar carrinhos!",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    }
  },
  mounted() {
    window.elemento_vue = this;
    this.dados_user = JSON.parse(sessionStorage.dados_user);
    this.permissoes = JSON.parse(sessionStorage.permissoes);
    this.bloqueia_vendedor = false;

    if (parseInt(this.dados_user.profissional_cargo_id) < 3) {
      this.bloqueia_vendedor = true;
    }

    // Verificar para Marketing
    this.permissoes.forEach(permissao => {
      if (permissao.privilegio_tipo_menu == "marketing"){
        this.marketing = true;
      }
    });
    this.permissoes.forEach(permissao => {
      if (permissao.privilegio_tipo_menu == "vendas"){
        this.marketing = false;
      }
    });
  },
};
</script>