<template>
    <div class="flex-sup alert-screen">
        <small> Alerta do sistema: </small>
        <h3> Aguarde um momento... </h3>
        <!-- log box -->
        <div class="flex-sup log-box">
            <!-- upload -->
            <span class="custom-alert amarelo-alr" v-if="porcentagem < 100"> Fazendo o upload dos arquivos... </span>
            <span class="custom-alert amarelo-azl" v-else> Processando arquivos... </span>
            <!-- <b-progress :value="porcentagem" max="100" show-progress animated></b-progress> -->
            <!-- loading animation -->
            <figure v-show="false" class="flex-sup timer-loading">
                <img src="../assets/img/timerloading.png" alt="Carregando">
            </figure>

            <!-- upload table -->
            <div class="upload-table">
                <!-- table header -->
                <div class="upload-table-line upload-table-line-header">
                    <div class="upload-table-cel header-cell cell-name"> <strong> Nome do arquivo </strong> </div>
                    <div class="upload-table-cel header-cell cell-size"> <strong> Tamanho </strong> </div>
                    <div class="upload-table-cel header-cell cell-stats"> <strong> Status </strong> </div>
                    <div class="upload-table-cel header-cell cell-opts"> <strong> Opções </strong> </div>
                </div>
                <!-- TABLE content: -->

                <!-- line: -->
                <div class="upload-table-line" v-for="(fila, index) in filas" :key="index">
                    <!-- cell: name -->
                    <div class="upload-table-cel cell-name">
                        <div class="flex-sup cell-wrapper">
                            <p>
                                {{ fila.name }}
                            </p>
                        </div>
                    </div>

                    <!-- cell: size -->
                    <div class="upload-table-cel cell-size">
                        <div class="flex-sup cell-wrapper">
                            <small> {{ Math.round(fila.size / 1048576)  }} <b> MB</b> </small>
                        </div>
                    </div>

                    <!-- cell: status -->
                    <div class="upload-table-cel cell-stats">
                        <div class="flex-sup cell-wrapper status-cel-col">
                            <progress :class="{ 'progress-complete': fila.porcentagem == 100 && fila.failed == false }" v-if="fila.failed == false" :value="fila.porcentagem" max="100">{{ fila.porcentagem }}%</progress>
                            <div :class="{
                                'flex-sup': true,
                                'table-badge': true,
                                'status-processando': fila.failed == false && fila.porcentagem != 100,
                                'status-failed': fila.failed == true,
                                'status-concluido': fila.porcentagem == 100 && fila.failed == false
                            }"> 
                                <small v-if="fila.failed == false && fila.porcentagem != 100"> Processando ({{ fila.porcentagem }}%) </small> 
                                <small v-else-if="fila.failed == true"> Falhou! </small> 
                                <small v-else-if="fila.porcentagem == 100"> Concluído! </small>
                                <small v-else-if="!fila.porcentagem"> Na fila! </small>
                                <i class="fas fa-spinner" v-if="fila.failed == false && fila.porcentagem != 100"></i>
                                <i class="fas fa-times" v-else-if="fila.failed == true"></i>
                                <i class="fas fa-check-circle" v-else-if="fila.porcentagem == 100"></i>
                                <i class="fas fa-pause-circle" v-else-if="!fila.porcentagem"></i>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="flex-sup estimativa">
                <small> Tempo percorrido: <strong> {{tempo_percorrido}} </strong> </small>
            </div>
            <div class="flex-sup alert-advise">
                <small> <span class="material-icons"> info </span>  Quando o processamento de todos os arquivos terminar, <strong> <a href="" @click.prevent="close_popup()"> clique aqui para fechar.</a> </strong> </small>
                
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            time: 0,
            interval: undefined
        }
    },
    props: {
        filas: Array,
        porcentagem: Number,
    },
    created() {
        this.interval = setInterval(this.incrementTime, 1000);
    },
    computed: {
        ...mapState(["all_videos_done"]),

        tempo_percorrido() {
            return this.time + " segundo(s)"
        }
    },
    watch: {
        tempo_percorrido() {
            if(this.all_videos_done) {
                clearInterval(this.interval)
            }
        }
    },
    methods: {
        incrementTime() {
            this.time = parseInt(this.time) + 1
        },

        close_popup() {
            this.$store.commit("CHANGE_LOADING")
        }
    }
}
</script>

<style>
.alert-screen, .alert-screen * {
    box-sizing: border-box;
}
    .alert-screen {
        position: absolute; z-index: 2000; top: 0; left: 0; width: 100vw; height: 100vh;
        background: #30303064; justify-content: center; align-items: center; flex-direction: column;
    }

        .alert-screen small {font: .9em monts-re; color: var(--wht-2); }
        .alert-screen h3 {font: 3em monts-bo; color: var(--wht-1); }

    /* log box */
    .log-box {
        width: 80%; flex-direction: column; background: var(--wht-3); padding: 21px; margin-top: 34px;
        max-height: 80vh; overflow-y: scroll;
    }
        .timer-loading {
            width: 100%; justify-content: center; margin-top: 21px;
        }

        @keyframes twinloop {
            to {transform: rotate(-360deg);}
        }

            .timer-loading img {animation: twinloop 3s linear forwards infinite;}

        /* custom alerts: */
        .custom-alert {
            width: 100%; font: .9em monts-re; color: var(--preto); font-weight: 900; border: 2px solid var(--blc-6); background: var(--wht-1);
            border-radius: 5px; padding: 5px 8px; margin-bottom: 13px;
        }
            .amarelo-alr {border-color: #FFB95A; background: #ffba5aa9;}
            .amarelo-azl {border-color: #5f92ff; background: #2a6eff3b;}

        /* rodape */  
        
        @media screen and (max-width: 537px) {

            .gestao-head {flex-direction: column; width: 100%;}
        
            .radio-list .custom-radio {height: max-content; min-height: 55px;}
        
        }
                .estimativa small {font: .9em monts-re; color: var(--blc-4); font-weight: 900;}
                    .estimativa small strong {font: 1em monts-bo; color: var(--blc-2); font-weight: 900;}
        
                .alert-advise {
                    width: 100%; justify-content: center; padding-top: 13px; border-top: 1px solid var(--rosa);
                    margin-top: 13px;
                }
                    .alert-advise small {color: var(--rosa);}
                        .alert-advise small span {transform: translateY(5px);}
        
                        .alert-advise strong a {color: var(--rosa); text-decoration: underline;}
                        .alert-advise strong a:hover {color: var(--preto);}
        
            @media screen and (max-width: 537px) {
        
                .alert-screen {width: 102vw; height: 105vh;}
        
                .alert-screen h3 {font-size: 2em; text-align: center;}
        
                    .log-box {width: 90%;}
            }
        
            /* Blue shift table */
        
            .upload-table {
                display: table; width: 100%; background: var(--wht-1);
                margin: 13px 0;
            }
        
                .upload-table-line {
                    display: table-row;
                }
        
                    /* > lines */
                    .upload-table-line:nth-child(even) {background: var(--wht-2);}
        
                    /* > cell */
                    .upload-table-cel {
                        display: table-cell; border-bottom: 3px solid var(--wht-3); border-left: 3px solid var(--wht-3);
                        position: relative;
                    }
        
                    /* > header */
                    .upload-table-line-header {
                        display: table-header-group;
                    }
                        .header-cell {background: #303030; padding: 5px;}
                        .header-cell strong {font: .9em monts-bo; color: var(--wht-2); }
        
                /* column sizes */
                .cell-name {width: 60%;}
                .cell-size {width: 10%;}
                .cell-stats {width: 30%;}
                .cell-opts {width: 10%; display: none;}
        
                /* cell wrapper */
                .cell-wrapper {
                    width: 100%; height: 100%; align-items: center; min-height: 40px; padding: 0 5px;
                }
        
                /* Cell types: */
                .cell-size .cell-wrapper small {
                    font: 1em monts-re; font-weight: 900; color: var(--blc-3);
                }
        
                .status-cel-col {
                    flex-direction: column; align-items: flex-start; padding: 0 13px;
                }
        
                    .status-cel-col progress {
                        width: 60%;
                        -webkit-appearance: none;
                        appearance: none;
                    }
                        .status-cel-col progress::-webkit-progress-bar {background: #fff; height: 8px; border-radius: 8px; border: 1px solid var(--blc-6);}
                        .status-cel-col progress::-webkit-progress-value {background: var(--bsblue); height: 6px; border-radius: 8px; }
        
                    .table-badge {align-items: center; margin-top: -2px;}
                        .table-badge small {font: .9em monts-re; font-weight: 900; color: var(--preto);}
                        .table-badge i {margin-left: 5px;}
        
                    /* status */
        
                    .status-processando i, .status-processando small  {color: var(--bsblue);}
                        .status-processando i {animation: twinloop 2s linear infinite;}
                    .status-concluido i, .status-concluido small {color: green}
                        .progress-complete::-webkit-progress-value {background: green!important;}
                    .status-naFila i, .status-naFila small {color: var(--blc-3);}
                    .status-failed i, .status-failed small {color: var(--rosa);}
                        .progress-failed::-webkit-progress-value {background: var(--rosa)!important;}
        
                    /* options */
        
                    .table-option {
                        width: 100%; align-items: center; padding: 5px 8px; border-radius: 5px; background: #fff;
                        transform: translateY(-5px); justify-content: space-between; transition: .1s ease-in-out;
                        box-shadow: 0 5px 8px -2px rgba(0,0,0,.103);
                    }
        
                    .options-cel-col {padding: 5px; flex-direction: column; align-items: center; justify-content: center;}
        
                        .table-option i {color: var(--bsblue); font-size: .8em;}
                        .table-option small {font: .7em monts-re; color: var(--bsblue);}
        
                        /* .tb-opt-copyLink {background: var(--bsblue);} */
        
                        .table-option:hover {background: var(--bsblue);}
                            .table-option:hover * {color: #fff;}
        
            /* TELA: Gestão versionamento */
        
            .versions-head {width: 100%;}
                .card-col {width: 25%; padding: 13px;}
        
                .version-options {
                    flex-direction: column; width: 15%;
                }
        
                    .sheet-download {background: none; border: 3px solid var(--bsblue)!important;  padding: 8px 21px!important;}
        
        
                /* POPUP: Confirmação */
        
                .confirm-wrapper {
                    position: fixed; top: 0; left: 0; z-index: 1100; width: 100%; flex-direction: column; height: 100vh;
                    background: #141259d8; animation: adviseWpp .3s ease forwards;
                }
        
                .confirm-ribbon {
                    background: #203d92da; justify-content: space-between; width: 100%;
                    padding: 21px 0; padding-left: 13%; align-items: center; backdrop-filter: blur(13px); justify-content: space-between;
                    animation: adiviseRibb .3s ease forwards;
                }
        
                    .left-aviso {align-items: center; width: 50%;}
                        .left-aviso h3 {font: 2.8em monts-bl; color: #fff; text-decoration: underline;}
                        .left-aviso p {font: .9em monts-me; color: var(--wht-2); margin-left: 21px; padding-top: 13px;}
        
                    .right-aviso {align-items: center; justify-content: flex-end; width: 50%; margin-right: 21px;}
        
                        .action-btn {
                            align-items: center; text-decoration: none; min-height: 34px; padding: 3px 13px; border-radius: 8px;
                            margin-left: 13px; cursor: pointer;
                        }
                            .action-btn small {font: .9em monts-bo; color: var(--wht-1);}
                            .action-btn span {color: var(--wht-1); margin-left: 5px;}
        
                            .action-btn, .action-btn * {text-decoration: none!important;}
        
                            .action-btn:last-child {background: var(--wht-1);}
                            .action-btn:last-child * {color: var(--bsblue);}
        
                        .action-btn:last-child:hover {background: var(--verde);}
                        .action-btn:first-child:hover * {color: var(--rosa);}
        
                @keyframes adviseWpp {
                    from {background: #14125900;}
                    to {background: #141259d8;}
                }
        
                @keyframes adiviseRibb {
                    from {transform: translateY(-120px); opacity: 0;}
                    to {transform: translateY(0); opacity: 1;}
                }
        
            @media screen and (max-width: 537px) {
        
                .status-cel-col progress {width: 100%;}
        
                        .confirm-ribbon {flex-direction: column; padding: 13px;}
                            .left-aviso {flex-direction: column; width: 100%;}
                            .right-aviso {
                                width: 100%; justify-content: space-between; padding: 21px 0 13px 0; border-top: 2px solid rgba(255, 255, 255, 0.219);
                                margin: 0; margin-top: 13px; flex-direction: row-reverse;
                            }
        
                                .action-btn {margin: 0;}
                                .action-btn:first-child small {font-size: .8em;}
        
            }
        
            /* > Defaoult theme */
        
            /* .content-header {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center;
                padding-bottom: 5px; justify-content: space-between;
            }
                .content-header .container-fluid .row .col-sm-6 {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                } */
                
</style>