<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">

      <!-- familiar nav -->
      <div class="flex-sup familiar-nav">

        <h3 class="flex-sup"> <small> Operações/Alunos </small> <i class="fas fa-angle-down"></i> </h3>

        <ul class="flex-sup fami-nav-list">

          <!-- variants: isAbaLoading, isActualPlace -->

          <!-- > gerenciamento -->
          <li class="flex-sup"> 
            <a class="flex-sup" href="/menu-alunos"> <i class="far fa-clipboard"></i> <small> Gerenciar alunos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > compras -->
          <li class="flex-sup"> 
            <a class="flex-sup" href="/compras"> <i class="fas fa-shopping-cart"></i> <small> Gerenciar compras </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>
          
          <!-- > turmas -->
          <li class="flex-sup isActualPlace"> 
            <a class="flex-sup" href="/menu-turmas"> <i class="fas fa-users"></i> <small> Gerenciar turmas </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > grupos -->
          <li class="flex-sup"> 
            <a class="flex-sup" href="/aleatoriza-grupos"> <i class="fas fa-random"></i> <small> Aleatorizar grupos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > treinamentos -->
          <li class="flex-sup"> 
            <a class="flex-sup" href="/menu-treinamentos"> <i class="fas fa-graduation-cap"></i> <small> Gerenciar treinamentos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

        </ul>

      </div>

      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gerenciamento de turmas</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Turmas</li>
              </ol>
            </div>

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="card">
        <div class="row">
          <div class="card-body col-sm-3">
            <button
              class="btn btn-lg bs-btn btn-success"
              v-show="btn_executar"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              :variant="varianteSubmit"
              @click="cadastraTurma()"
            >
              <span>Cadastrar nova turma <i class="fas fa-play"></i></span>
            </button>
          </div>
        </div>

        <!-- /.card-header -->
        <div class="card-body">
          <table id="example1" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Ações </th>
                <th>RID Turma</th>
                <th>RID Agenda Antigo</th>
                <th>Turma Codigo</th>
                <th>Turma Nome</th>
                <th>Fator multiplicativo de prova intermediaria</th>
                <th>Fator multiplicativo de prova final</th>
                <th>Data do certificado final</th>
                <th>Imagem fundo do certificado</th>
                <th>Carga horaria do certificado</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!-- /.card-body -->
      </div>
    </div>

    <b-modal size="lg" id="modal_grupos" hide-footer>
      <template #modal-title> Edição de Grupos </template>

      <table id="example2" class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>RID Aluno </th>
            <th>Nome</th>
            <th>Grupo ID</th>
            <th>Status </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(aluno, index) in alunosTurma" :key="index">
            <td>
              {{aluno.aluno_id}}
            </td>
            <td>
              {{aluno.aluno_nome}}
            </td>
            <td>
              {{aluno.grupo_id}}
            </td>
            <td>
              <b-form-select v-model="aluno.aluno_grupo_status" @change="alteraStatusAluno(aluno)">
                <b-form-select-option value="0">Estornado</b-form-select-option>
                <b-form-select-option value="1">Ativo</b-form-select-option>
                <b-form-select-option value="2">Trancado</b-form-select-option>
              </b-form-select>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <b-modal size="lg" id="modal_turma" hide-footer>
      <template #modal-title> {{ form_titulo }} </template>

      <b-form>
        <div class="row">
          <div class="col-sm-4">
            <b-form-group id="input-1" label="Agenda">
              <b-form-select
                v-model="formTurma.id_agenda"
                :options="agendas"
              ></b-form-select>
              <label class="text-danger small">{{
                errosFormTurma.id_agenda
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group id="input-1" label="Turma codigo">
              <b-form-input
                id="turma_agenda"
                trim
                placeholder="ex.: TITI10"
                v-model="formTurma.turma_codigo"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTurma.turma_codigo
              }}</label>
            </b-form-group>
          </div>
          <div class="col-sm-4">
            <b-form-group id="input-1" label="Turma nome">
              <b-form-input
                id="turma_nome"
                trim
                placeholder="turma_nome"
                v-model="formTurma.turma_nome"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTurma.turma_nome
              }}</label>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <b-form-group id="input-1" label="Carga horaria do certificado">
              <b-form-input
                id="carga_certificado"
                v-model="formTurma.carga_horaria"
                trim
                placeholder="240"
              ></b-form-input>
              <!-- <label class="text-danger small">{{
                        errosFormTurma.carga_horaria
                      }}</label> -->
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group id="input-1" label="Fator de prova intermediaria">
              <b-form-input
                id="prova_intermediaria"
                trim
                placeholder="normalmente é 3.1"
                v-model="formTurma.fator_inter"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTurma.fator_inter
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group id="input-1" label="Fator de prova final">
              <b-form-input
                id="prova_final"
                trim
                placeholder="normalmente é 5.0"
                v-model="formTurma.fator_final"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormTurma.fator_final
              }}</label>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group id="input-1" label="Data de certificado final">
              <b-form-input
                id="data_certificado"
                v-model="formTurma.data_certificado"
                trim
                placeholder="Jan 30, 2021 - Jun 12, 2021"
              ></b-form-input>
              <!-- <label class="text-danger small">{{
                        errosFormAluno.nome
                      }}</label> -->
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <b-form-group id="input-" label="Imagem do fundo do certificado">
              <b-form-input
                id="background_certificado"
                trim
                placeholder="https://"
                v-model="formTurma.background"
              ></b-form-input>
              <!-- <label class="text-danger small">{{
                        errosFormAluno.nome
                      }}</label> -->
            </b-form-group>
          </div>
        </div>
        <hr>
        <div class="input-group justify-content-md-end">
          <b-button
            class="mr-1"
            id="btn-cadastro"
            variant="info"
            @click.prevent="$bvModal.show('modal_grupos'), listaAlunosTurma()"
            >Editar Grupos</b-button
          >
          <b-button
            class="mr-1"
            @click="limparFormTurma()"
            id="btn-limpa-form"
            variant="danger"
            >Limpar formulário</b-button
          >
          <b-button
            class="mr-1"
            @click="enviarFormTurma()"
            id="btn-cadastro"
            :variant="varianteSubmit"
            >{{ botaoSubmitTurma }}</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>

  .bs-btn {align-items: center;}
  .bs-btn i {margin-left: 8px;}

</style>


<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

function abreModalEdicao(id_turma) {
  window.elemento_vue.editaTurma(id_turma);
}


import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Turmas",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user:{},
      preloader_status: true,
      loading: false,
      page: "Turmas",
      form_titulo: "",
      botaoSubmitTurma: "",
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      agendas: [],
      turmas: [],
      varianteSubmit:"",
      formTurma: {
        id_turma: "",
        id_agenda: "",
        turma_codigo: "",
        turma_nome: "",
        fator_inter: 3.1,
        fator_final: 5,
        data_certificado: "",
        background: "",
        carga_horaria: "",
      },
      errosFormTurma: {
        id_agenda:"",
        turma_codigo:"",
        turma_nome:"",
        carga_horaria:"",
      },
      alunosTurma: []
    };
  },
  created() {
    this.preloader_status = false;
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {
    async listaAgendas() {
      let url = "lista/agendas";
      const response = await this.$http.get(url);
      let agendas = response.data;
      agendas.forEach((agenda) => {
        this.agendas.push({
          text: agenda.agenda_id_antigo + " " + agenda.treinamento_nome,
          value: agenda.agenda_id_antigo,
        });
      });
    },

    async listaAlunosTurma() {
      await this.$http.post('busca/alunos/turma', {
        turma_codigo: this.formTurma.turma_codigo
      })
        .then((response) => {
          this.alunosTurma = response.data
        })
    },

    async cadastraTurma() {
      this.limparFormTurma();
      this.form_titulo = "Cadastra nova turma";
      this.botaoSubmitTurma = "Cadastrar turma";
      this.varianteSubmit = "primary";
      this.acao = "novo";
      this.errosFormTurma = {
        id_agenda:"",
        turma_codigo:"",
        turma_nome:"",
        carga_horaria:"",
      }
      this.$bvModal.show("modal_turma");    
    },

    async editaTurma(id_turma){
      this.limparFormTurma();
      this.form_titulo="Editar turma";
      this.botaoSubmitTurma = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";
      this.modal_turma = true;

      let url = "lista/turma/" + id_turma;

      try {
        const response = await this.$http.get(url);
        let dados_turma = response.data;
        this.formTurma.id_agenda= dados_turma.agenda_id,  
        this.formTurma.id_turma = id_turma,
        this.formTurma.turma_codigo= dados_turma.softcheck_turma_codigo,
        this.formTurma.turma_nome= dados_turma.turma_nome,
        this.formTurma.fator_inter= dados_turma.fator_multiplicativo_prova_intermediaria,
        this.formTurma.fator_final= dados_turma.fator_multiplicativo_prova_final,
        this.formTurma.carga_horaria= dados_turma.softcheck_carga_horaria_certificado,
        this.formTurma.data_certificado= dados_turma.softcheck_data_certificado_final,
        this.formTurma.background= dados_turma.softcheck_background_certificado
        this.$bvModal.show("modal_turma");
      }catch(err){ 
        let msg = "Erro ao buscar dados da turma!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
    },

    async alteraStatusAluno(aluno) {
      await this.$http.post('/altera/status/aluno', {
        aluno_id: aluno.aluno_id,
        grupo_id: aluno.grupo_id,
        aluno_grupo_status: aluno.aluno_grupo_status
      })
        .then(() => {
          alert("Status do aluno alterado com sucesso!")
        })
    },

    async lista_turmas() {
      let url = "lista/turmas";
      const response = await this.$http.get(url);

      this.turmas = response.data;
      let data_table = new Array();
      let status_cor = "text-secundary";

      this.turmas.forEach((turma) => {
        let botao_editar_turma=
        "<button class='btn btn-warning btn-sm mr-1 open-turma' id = " +
          turma.softcheck_turma_id +
          " type='button'><i id = " +
          turma.softcheck_turma_id +
          " class = 'fas fa-edit'></i>Editar</button>";

        data_table.push([

           "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_turma : "" )+
            "</span>",

          "<span class=" +
            status_cor +
            ">" +
            turma.softcheck_turma_id +
            "</span>",
          "<span class=" + status_cor + ">" + turma.agenda_id_antigo + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            turma.softcheck_turma_codigo +
            "</span>",
          "<span class=" + status_cor + ">" + turma.turma_nome + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            turma.fator_multiplicativo_prova_intermediaria +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            turma.fator_multiplicativo_prova_final +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            turma.softcheck_data_certificado_final +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            turma.softcheck_background_certificado +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            turma.softcheck_carga_horaria_certificado +
            "</span>",
        ]);
      });

      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
           drawCallback: function() {
            $(".open-turma").on("click", function(event) {
              abreModalEdicao(event.target.id);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
    },

    habilita_botoes() {
       let privilegio
      let admin = false
      Object.keys(this.user.permissoes).map(item => {
        if(this.user.permissoes[item]['privilegio_tipo_menu'] == "operacoes"){
          privilegio = this.user.permissoes[item]['privilegio_status']
        }

        if(this.user.permissoes[item]['privilegio_tipo_menu'] == "admin"){
          admin = true
        }
      });
      
      if(admin){
        privilegio = 'A'
      }
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },
    async enviarFormTurma(){
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let botaoLimpaForm = document.querySelector("#btn-limpa-form");
      let erros = false;
      botaoCadastro.disabled = true;
      botaoLimpaForm.disabled = true;
      

      if (!this.formTurma.id_agenda) {
        this.errosFormTurma.id_agenda = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTurma.id_agenda = "";
      }
      if (!this.formTurma.turma_codigo) {
        this.errosFormTurma.turma_codigo = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTurma.turma_codigo = "";
      }
       if (!this.formTurma.turma_nome) {
        this.errosFormTurma.turma_nome = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTurma.turma_nome = "";
      }
       if (!this.formTurma.fator_inter) {
        this.errosFormTurma.fator_inter = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTurma.fator_inter = "";
      }
       if (!this.formTurma.fator_final) {
        this.errosFormTurma.fator_final = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormTurma.fator_final = "";
      }
      if (!erros) {
        let retorno
         if (this.acao == "novo") {
            let url = "cadastra/turma";
            const response = await this.$http.post(url, {
              turma: {
                id_agenda: this.formTurma.id_agenda,
                turma_codigo: this.formTurma.turma_codigo,
                turma_nome: this.formTurma.turma_nome,
                fator_inter:this.formTurma.fator_inter,
                fator_final: this.formTurma.fator_final,
                carga_horaria:this.formTurma.carga_horaria +"h",
                data_certificado: this.formTurma.data_certificado,
                background:this.formTurma.background
              },
              page: this.page,
              profissional_id: this.user.dados.profissional_id,
              acao: "cadastrar turma"
            });
            retorno = response.data;
          }

          else if (this.acao == "editar") {
            let url = "atualiza/turma";
            const response = await this.$http.patch(url, {
              turma : {
                id_agenda: this.formTurma.id_agenda,
                id_turma: this.formTurma.id_turma,
                turma_nome: this.formTurma.turma_nome,
                turma_codigo: this.formTurma.turma_codigo,
                fator_inter: this.formTurma.fator_inter,
                fator_final: this.formTurma.fator_final,
                carga_horaria: this.formTurma.carga_horaria,
                data_certificado: this.formTurma.data_certificado,
                background: this.formTurma.background
              },
              page: this.page,
              profissional_id: this.user.dados.profissional_id,
              acao: "editar turma"
            });
            retorno = response.data;
          }

           if (retorno.erro) {
            this.$bvModal.msgBoxOk(retorno.erro, {
              title: "Aviso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            });
          } else {
            this.$bvModal.msgBoxOk(retorno.mensagem, {
              title: "Sucesso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "success",
              bodyBgVariant: "success",
              footerBgVariant: "success",
            });
            this.$bvModal.hide("modal_turma");
            this.lista_turmas();
          }

        }
        botaoCadastro.disabled = false;
      botaoLimpaForm.disabled = false;

      },  

    limparFormTurma(){
      this.formTurma.id_agenda= ""
      this.formTurma.turma_codigo= ""
      this.formTurma.turma_nome= ""
      this.formTurma.fator_inter= 3.1
      this.formTurma.fator_final= 5
      this.formTurma.data_certificado= ""
      this.formTurma.background= ""
      this.formTurma.carga_horaria= ""

      Object.keys(this.errosFormTurma).forEach((item) => {
        this.errosFormTurma[item] = "";
      });

    }
  
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    //quando liga a tela
    window.elemento_vue = this;
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    this.habilita_botoes();
    this.lista_turmas();
    this.listaAgendas();

  },
};
</script>


