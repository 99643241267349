<template>
  <div>
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Gestão de cursos</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Gestão de cursos</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="card">
        <div class="row">
          <div class="card-body col-sm-3">
            <button
              class="btn bs-btn btn-lg btn-success"
              data-toggle="modal"
              data-target="#modal-success"
              type="button"
              @click="modal_curso(), isCadastro = true"
            >
              <span>Novo curso</span>
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
        <!-- /.card-header -->
        <div class="table-field">

          <div class="card-body">
            <table id="example1" class="table table-bordered table-hover contolled-table">
              <thead>
                <tr>
                  <th>Liberado para Compra</th>
                  <th>#</th>
                  <th>curso_id</th>
                  <th class="title-sticky">curso_titulo</th>
                  <th>curso_categoria</th>
                  <th>curso_tipo</th>
                  <th>curso_logo</th>
                  <th>versão</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(curso, index) in cursos" :key="index">
                  <td>
                    <b-form-checkbox 
                      v-model="curso.habilitado"
                      name="check-button"
                      @change="editar_liberacao_compra(curso)"
                      switch
                    >
                    </b-form-checkbox>
                  </td>
                  <td class="btnDesabilitarEditar">
                    <b-button variant="danger" @click.prevent="alterar_status_curso(curso)" v-if="curso.curso_status === 1">Desabilitar</b-button>
                    <b-button variant="default" @click.prevent="alterar_status_curso(curso)" v-else>Habilitar</b-button>
                    <button
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#modal-success"
                      type="button"
                      @click="editar_curso(curso), isCadastro = false"
                    >
                      <span class="btnEditar">Editar </span>
                    </button>
                  </td>
                  <td>{{curso.curso_id}}</td>
                  <td class="title-sticky">{{curso.curso_titulo}}</td>
                  <td><b v-if="curso.curso_categoria === 1">Formação</b><b v-if="curso.curso_categoria === 2">Online</b></td>
                  <td><b v-if="curso.curso_tipo === 0">Formação</b><b v-if="curso.curso_tipo === 1">Profissão</b><b v-if="curso.curso_tipo === 2">Habilidade</b></td>
                  <td><a :href="curso.curso_logo" target="_blank">Logo</a></td>
                  <td>
                    <b-form-group>
                      <b-form-select v-model="curso.versao_id_selecionado">
                        <b-form-select-option v-for="(versao, index) in curso.versoes" :key="index" :value="versao.versao_id">
                          {{versao.versao_id}} - {{versao.versao_data_criada}}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    <b-button @click.prevent="alterar_versionamento(curso)">
                      Editar Versão
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div>
          <b-modal size="xl" id="modal-usuario" hide-footer @hidden="fecharModal()">
            <template #modal-title> <b v-if="isCadastro">Cadastrar Curso</b> <b v-else>Editar Curso</b> </template>
            <div>
              <b-form>
                <div class="row">
                  <div class="col-sm-3">
                    <b-form-group
                      label="Treinamento"
                      label-for="treinamento"
                    >
                      <b-form-select v-model="formCurso.treinamento_id" id="treinamento">
                        <b-form-select-option v-for="(treinamento, index) in treinamentos" :key="index" :value="treinamento.treinamento_id">
                          {{treinamento.treinamento_nome}}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-sm-3">
                    <b-form-group
                      label="Categoria"
                      label-for="categoria"
                    >
                      <b-form-select v-model="formCurso.curso_categoria" id="categoria">
                        <b-form-select-option value="1">
                          Formação
                        </b-form-select-option>
                        <b-form-select-option value="2">
                          Online
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    
                  </div>
                  <div class="col-sm-3">
                    <b-form-group
                      label="Tipo"
                      label-for="tipo"
                    >
                      <b-form-select v-model="formCurso.curso_tipo" id="tipo">
                        <b-form-select-option value="0">
                          Formação
                        </b-form-select-option>
                        <b-form-select-option value="1">
                          Profissão
                        </b-form-select-option>
                        <b-form-select-option value="2">
                          Habilidade
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    
                  </div>
                  <div class="col-sm-3">
                    <b-form-group
                      label="Título"
                      label-for="titulo"
                    >
                      <b-form-input type="text" v-model="formCurso.curso_titulo" placeholder="Nome do curso/formação" id="titulo">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-group
                      label="Quantidade de Slots"
                      label-for="quant_slots"
                    >
                      <b-form-input type="number" v-model="formCurso.curso_slot_quantidade" id="quant_slots">
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6">
                    <b-form-group
                      label="Url"
                      label-for="path"
                    >
                      <b-form-input type="text" v-model="formCurso.curso_path" placeholder="Exemplo: python-zero" id="path">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <b-form-group
                      label="Descrição"
                      label-for="descricao"
                    >
                      <b-form-textarea type="text" v-model="formCurso.curso_descricao" placeholder="Descrição breve.." id="descricao">
                      </b-form-textarea>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12">
                    <b-form-group
                      label="Tópicos de descrição"
                      label-for="conteudo"
                    >
                      <b-row>
                        <b-col cols="6">
                          <b-form-input type="text" :state="conteudos[0] ? true : false" v-model="conteudos[0]" placeholder="Descrição breve.." id="conteudo">
                          </b-form-input>
                          <b-form-invalid-feedback id="conteudo">
                            Required
                          </b-form-invalid-feedback>
                        </b-col>
                        <b-col cols="6">
                          <b-form-input type="text" :state="conteudos[1] ? true : false" v-model="conteudos[1] " placeholder="Descrição breve.." id="conteudo2">
                          </b-form-input>
                          <b-form-invalid-feedback id="conteudo2">
                            Required
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="6">
                          <b-form-input class="" type="text" v-model="conteudos[2]" placeholder="Descrição breve.." id="conteudo">
                          </b-form-input>
                        </b-col>
                        <b-col cols="6">
                          <b-form-input class="" type="text" v-model="conteudos[3]" placeholder="Descrição breve.." id="conteudo">
                          </b-form-input>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </div>                    
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <b-form-group
                      label="Logo do curso"
                      label-for="logo"
                    >
                        <b-form-file id="logo"
                          @change="studentTicketImage($event)"
                          placeholder="Arraste a imagem para cá"
                          drop-placeholder="Jogue aqui!"
                          type="file"
                          :state="formCurso.curso_logo ? true : false"
                        ></b-form-file>
                        <b-form-invalid-feedback id="logo">
                          Required
                        </b-form-invalid-feedback>

                    </b-form-group>
                    <div class="pt-1">
          <div class="progress-bar" :style="{ width: porcentagemArquivo + '%' }"></div>
        </div>
                    <p v-if="inputSizeError" class="error_upload">{{ uploadStatusMessage }}</p>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      label="Cor primária do produto"
                      label-for="rgba_1"
                    >
                      <b-form-input type="color" v-model="formCurso.curso_cor_1" id="rgba_1">
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-4">
                    <b-form-group
                      label="Cor secundária do produto"
                      label-for="rgba_2"
                    >
                      <b-form-input type="color" v-model="formCurso.curso_cor_2" id="rgba_2">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="input-group justify-content-md-end">
                  <b-button
                    class="mr-1"
                    @click="cadastrar_curso()"
                    id="btn-cadastro"
                    v-if="isCadastro"
                  >
                    Cadastrar
                  </b-button>
                  <b-button
                    :disabled="inputSizeError || disableBtn"
                    class="mr-1"
                    style="background-color: #0c70f2; border-color: #0c70f2; border-radius: 10px;"
                    @click="fetch_editar_curso()"
                    id="btn-cadastro"
                    v-else
                  >
                    Salvar
                  </b-button>
                </div>
              </b-form>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped>
  .btnEditar{
    font-weight: 600;
    width: 120px;
  }
  .btnDesabilitarEditar{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    gap: 5px;
  }
  .bs-btn i {margin-left: 8px;}

</style>

<script>
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      conteudos:[],
      treinamentos: [],
      disableBtn: false,
      isCadastro: true,
      inputSizeError: false,
      timeStamp: 0,
      porcentagemArquivo: 0,
      typesArray: ['png', 'jpeg', 'jpg'],
      lambdaResult: 'https://dnc-lms-bucket.s3.amazonaws.com/curso-logo',
      urlDeliverableFile: '',
      formCurso: {
        treinamento_id: null,
        curso_categoria: null,
        curso_titulo: null,
        curso_descricao: null,
        curso_path: null,
        curso_logo: null,
        curso_cor_1: null,
        curso_cor_2: null,
        curso_slot_quantidade: 0,
        curso_conteudo_descricao: null,
      },
      cursos: []
    }
  },
  created() {
    this.fetch_treinamentos()
    this.listar_curso()
  },
  methods: {
    async fetch_treinamentos() {
      await this.$http.get('treinamentos/listar')
        .then((response) => {
          if(response.data.status === true) {
            this.treinamentos = response.data.treinamentos
          }
        })
    },

    modal_curso() {
      this.formCurso= {
        treinamento_id: null,
        curso_categoria: null,
        curso_titulo: null,
        curso_descricao: null,
        curso_path: null,
        curso_logo: null,
        curso_cor_1: null,
        curso_cor_2: null,
        curso_slot_quantidade: 0,
        curso_conteudo_descricao: null,
      },
      this.conteudos = []
      this.porcentagemArquivo = 0
      this.$bvModal.show("modal-usuario");
    },
    fecharModal() {
      this.porcentagemArquivo = 0
    },
    async salvar_arquivo() {
      this.formCurso.curso_logo =  this.urlDeliverableFile   
    },

    async studentTicketImage(event) {
      this.disableBtn = true
      this.inputSizeError = false;
      this.porcentagemArquivo = 0
      const file = event.target.files[0]
      if (file) {
        this.fileUpload = file
        const fileName = file.name.split('.')
        const fileExtension = fileName[fileName.length - 1]
        this.timeStamp = this.formCurso.curso_titulo
        const fileSize = Math.round(((file.size / 1024) / 1024))
        this.urlDeliverableFile = this.lambdaResult + `/${this.timeStamp}/file.${fileExtension}`

        if (fileSize > 5 && this.typesArray.includes(fileExtension) === false) {
          this.inputSizeError = true;
          this.disableBtn = false;
          this.fileUpload = null;
          this.uploadStatusMessage = "O arquivo deve ter no máximo 5MB e deve estar entre os formatos suportados.";
          this.resetFileUpload();
          return;
        }

        if (this.typesArray.includes(fileExtension) === false) {
          this.inputSizeError = true;
          this.fileUpload = null;
          this.uploadStatusMessage = "O formato do arquivo anexado não é suportado.";
          this.disableBtn = false;
          return;
        }

        if (fileSize > 5) {
          this.inputSizeError = true;
          this.disableBtn = false;
          this.fileUpload = null;
          this.uploadStatusMessage = "O arquivo deve ter no máximo 5 MB.";
          this.resetFileUpload();
          return;
        }

          this.uploadStatusMessage = ""

        const lambdaUrl = 'https://igteqb4uxf.execute-api.us-east-1.amazonaws.com/upload-curso-logo'
        const key = `${this.timeStamp}/file.${fileExtension}`
        delete this.$http.defaults.headers.common.Token
        const response = await this.$http.get(`${lambdaUrl}?key=${key}&content_type=${file.type}`)
        const uploadURL = JSON.parse(response.data.body).uploadURL
        const config = {
          onUploadProgress: (progressEvent) => {
            this.porcentagemArquivo = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          },
          headers: {
            'Content-Type': file.type
          }
        }
        this.$http.put(uploadURL, file, config).then(() => {
          this.uploadSucess = true;
          this.readyToSendTicket = true;
          this.disableBtn = false;
          this.uploadCV = false;
          this.messageSending = false;
        }).catch(error => console.error(error))
      }
      this.salvar_arquivo()
    },

    createFormData(forms) {
      var formData = new FormData();  
      formData.append("treinamento_id", forms.treinamento_id);
      formData.append("curso_categoria", forms.curso_categoria);
      formData.append("curso_titulo", forms.curso_titulo);
      formData.append("curso_descricao", forms.curso_descricao);
      formData.append("curso_tipo", forms.curso_tipo);
      formData.append("curso_path", forms.curso_path);
      formData.append("curso_cor_1", forms.curso_cor_1);
      formData.append("curso_cor_2", forms.curso_cor_2);
      formData.append("curso_logo", forms.curso_logo);
      formData.append("curso_slot_quantidade", forms.curso_slot_quantidade);
      formData.append("curso_conteudo_descricao", forms.curso_conteudo_descricao);
      return formData;
    },

    async cadastrar_curso() {
      if(this.conteudos[0] && this.conteudos[1]){
        this.formCurso.curso_conteudo_descricao = this.conteudos.filter((element) => element != " " && element != "" && element != null).join("; ")
        await this.$http.post('curso/criar', this.createFormData(this.formCurso),
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            alert('Curso cadastrado')
            this.$bvModal.hide("modal-usuario");
            this.listar_curso()
          })
      } else{
        alert('Campos obrigatórios não preenchidos')
      }
    },

    async editar_liberacao_compra(curso) {
      await this.$http.put('editar/liberacao/compra', {
        'treinamento_id': curso.treinamento_id,
        'habilitado': curso.habilitado
      })
        .then(() => {
          this.porcentagemArquivo = 0
          this.listar_curso()
        })
    },

    async listar_curso() {
      await this.$http.get('curso/listar')
        .then((response) => {
          this.cursos = response.data.cursos
          this.cursos.map((element) => {
            if (element.habilitado == 1) {
              return element.habilitado = true
            }

            return element.habilitado = false
          })
        })
    },

    async alterar_status_curso({curso_id, curso_status}) {
      if(confirm('Você deseja alterar?')) {
        await this.$http.post('curso/status', {
          'curso_id': curso_id,
          'curso_status': curso_status
        })
          .then(() => {
            alert('Curso alterado')
            this.listar_curso()
          })
      }
    },

    async alterar_versionamento({curso_id, versao_id_selecionado}) {
      if(confirm('Você deseja alterar?')) {
        await this.$http.post('versionamento/alterar', {
          'curso_id': curso_id,
          'versao_id_selecionado': versao_id_selecionado
        })
          .then(() => {
            alert('Versionamento alterado')
            this.listar_curso()
          })
      }
    },

    async fetch_editar_curso() {
      if(this.conteudos[0] && this.conteudos[1]){
        if(confirm('Você deseja alterar?')) {
          this.formCurso.curso_conteudo_descricao = this.conteudos.filter((element) => element != " " && element != "" && element != null).join("; ")
          await this.$http.post('curso/alterar', this.formCurso)
            .then(() => {
              alert('Alterado com sucesso')
              this.listar_curso()
              this.$bvModal.hide("modal-usuario");
              this.porcentagemArquivo = 0
            })
        }
      } else{
        alert('Campos obrigatórios não preenchidos')
      }
    },

    editar_curso(curso) {
      this.$bvModal.show("modal-usuario");
      this.formCurso = curso
      this.conteudos = curso.curso_conteudo_descricao.split(";")
    }
  }
}
</script>