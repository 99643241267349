<template>
    <div id="root">
        <div class="content-wrapper" >
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0"></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="card">
                        <!-- upload box -->
                        <div class="card-body">
                            <div class="row col-md-12">
                                <div class="col-md-11">
                                    <h3>Lista de Mentorias</h3>
                                </div>
                                <div class="col-md-1 float-right">
                                    <b-button class="btn btn-primary" v-b-toggle.sidebar-right>Adicionar</b-button>
                                </div>
                            </div>
                            <hr>
                            <div class="row containerTable"> 
                               <div class="table-responsive">
                                    <table class="table table-bordered table-hover tableall">
                                        <thead>
                                            <tr>
                                                <th>Mentoria</th>
                                                <th>Tipo</th>
                                                <th>Link</th>
                                                <th>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(registerInfos) in registerInfo" :key="registerInfos.material_id">
                                                <td style="width:400px"> 
                                                    <div class="bannerText">
                                                        <div>
                                                            <figure class="bannerTextImg" :style="{backgroundImage:'url(\''+ registerInfos.material_imagem +'\') , url(\''+imgDefault+'\')'}" alt="imagem"></figure>
                                                        </div>
                                                        <span>{{ registerInfos.material_titulo }}</span>
                                                    </div>
                                                </td>
                                                <td> 
                                                    {{ registerInfos.material_categoria }}
                                                </td>
                                                <td> 
                                                    <a :href="registerInfos.material_url">Link da mentoria</a> 
                                                </td>
                                                <td class=""> 
                                                    <div class="iconsTableTrashPencil">
                                                        <button type='button' class="btn btn-danger" @click="delete_mentoria_id = registerInfos.material_id" data-toggle="modal" data-target="#exampleModalCenter"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                                        <button type='button' class="btn btn-info" @click="editar_mentoria_id(registerInfos)" v-b-toggle.sidebar-right-edit-mentoria><i class="fa fa-paint-brush" aria-hidden="true"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                               </div>

                                <!-- sideBar registro de mentoria -->
                                <div>
                                    <b-sidebar id="sidebar-right" width="30%" title="Registrar Mentoria" right shadow>
                                    <hr>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <h5><b>Mentoria</b></h5>
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p >Nome da mentoria*</p>
                                            <input type="email" v-model="registerNome" class="form-control" id="exampleFormControlInput1" placeholder="Nome da mentoria">
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p>Link*</p>
                                            <input type="text" class="form-control" v-model="registerLink" id="staticEmail" placeholder="Zoom.com.br/tallinkdetalcurso pararadsata">
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p>Tipo de mentoria*</p>
                                            <select class="form-control" id="exampleFormControlSelect1" v-model="registerTipoMentoria">
                                                <option>Tipo de mentoria</option>
                                                <option>Carreira</option>
                                                <option>Projetos</option>
                                                <option>Negócios</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p>Banner</p>
                                            <div class="custom-file" v-modal="banner">
                                                <div class="flex-sup file-upload-box" style="border:0px ;">
                                                    <b-form-file
                                                        multiple
                                                        placeholder="Arraste a Foto aqui"
                                                        drop-placeholder="Jogue aqui!"
                                                        type="file"
                                                        v-model="banner"
                                                    ></b-form-file>
                                                    <div class="flex-sup upload-indiq">
                                                        <strong> Solte os arquivos em cima  </strong>
                                                        <i class="fas fa-arrow-up"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row px-3 py-3">
                                        <div class="col-md-12 resgitermentoriabutton">
                                            <button type="button" class="btn btn-light">Cancelar</button>
                                            <button type="button" class="btn btn-primary" @click="confirmarRegister()">Confirmar</button>
                                        </div>
                                    </div>
                                    </b-sidebar>
                                </div>

                                <!-- sideBar editar de mentoria -->
                                <div>
                                    <b-sidebar id="sidebar-right-edit-mentoria" width="30%" title="Editar Mentoria" right shadow>
                                    <hr>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <h5><b>Mentoria sobre tal coisa</b></h5>
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p >Nome da mentoria*</p>
                                            <input type="text" v-model="editNome"  class="form-control" id="staticEmail" value="Mentoria sobre tal coisa">
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p>Link</p>
                                            <input type="text" v-model="editLink" class="form-control" id="staticEmail" placeholder="Zoom.com.br/tallinkdetalcurso pararadsata">
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p>Tipo de mentoria*</p>
                                            <select class="form-control" id="exampleFormControlSelect1" v-model="editTipoMentoria">
                                                <option value="carreira">Carreira</option>
                                                <option value="projetos">Projetos</option>
                                                <option value="negócios">Negócios</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 ">
                                            <p>Banner</p>
                                            <figure class="bannerTextImg" :style="{backgroundImage:'url(\''+ editImagem +'\') , url(\''+imgDefault+'\')'}" alt="imagem"></figure>
                                            <b-form-file
                                                multiple
                                                placeholder="Arraste a Foto aqui"
                                                drop-placeholder="Jogue aqui!"
                                                type="file"
                                                v-model="banner"
                                            ></b-form-file>
                                            <!-- <input type="text" class="form-control" id="staticEmail" placeholder="LinkDeImagem.com.br"  v-modal="editImagem"> -->
                                            <!-- <div class="RegisterMentoriaimgDowload">
                                                <img src="../assets/img/imgMentoria.png" alt="" width="50%">
                                                <div class="col-md-5 RegisterMentoriaimgDowloadRotulo">
                                                    <b>Rótulo</b>
                                                    <label class="labelInput" for="inputArquivo">Selecionar...</label>
                                                    <input type="file" name="inputArquivo" id="inputArquivo">
                                                    <p>O tamanho máximo de arquivo permitido é de 5 MB.</p>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="row px-3 py-1">
                                        <div class="col-md-12 resgitermentoriabutton">
                                            <button type="button" class="btn btn-light">Cancelar</button>
                                            <button type="button" @click="editarMentoria()" class="btn btn-primary">Confirmar alterações</button>
                                        </div>
                                    </div>
                                    </b-sidebar>
                                </div>

                                <!-- modal btn excluir -->
                                <!-- Button trigger modal -->

                                <!-- Modal -->
                                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body modalBodyInfo">
                                            <div class="modalBodyInfoExclamation">
                                                !
                                            </div>
                                            <div>
                                                <h4><b>Tem certeza?</b></h4>
                                            </div>
                                            <div>
                                                <p>Ao confirmar, a mentoria será deletada permanentemente.</p>
                                            </div>
                                        </div>
                                        <div class="modal-footer center">
                                            <button type="button" data-dismiss="modal" class="btn btn-danger">Cancelar</button>
                                            <button type="button" @click="confirmarDelete()" data-dismiss="modal" class="btn btn-primary">Deletar Mentoria</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <b-modal id="modal_aviso">
            <h5 class="text-center">{{ msg_modal }}</h5>
        </b-modal>
    </div>
</template>

<style scoped>
.content-wrapper{
    margin-left: 0 !important;
}
    .bs-btn i {margin-left: 5px;}

    .linkP{
        font-size: 10px;
    }

    td{
        text-align: center;
        vertical-align: middle;
    }

    .mentoringThumb {width: 70px; height: 50px; border: 3px solid #fff; box-shadow: 0 6px 14px rgba(0,0,0,.105); border-radius: 16px; font-size: 0;}

    .bannerText{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap:15px;
        align-items: center;
        text-align: center;
    }

    .bannerTextImg{
        width: 100px;
        height: 60px;
        border-radius: 3px;
        background-size: 100px 60px;
        background-repeat: no-repeat;
    }
    
    element.style {
        color: blue;
        font-size: 18px;
        font-weight: 600;
    }

    .containerTable::-webkit-scrollbar{
        display: none;
        /* width: 8px;
        border:1px solid #a9a9a9; */
    }

    .containerTable::-webkit-scrollbar-thumb{
        display: none;
        /* background-color: #303030;
        border-radius: 3px;
        height: 85px; */
    }

    .containerTable{
        height: 500px;
        padding: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
        font-size: 12px;
        font-weight: 600;
    }

    .modalBodyInfo{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        gap: 10px;
    }

    .modalBodyInfoExclamation{
        font-size: 46px;
        color: #F8BB86;
        border: 3px solid #F8BB86;
        border-radius: 50%;
        width: 20%;
    }

    .iconsTableTrashPencil{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .RegisterMentoriaimgDowload{
        display: flex;
        gap: 5px;
    }

    .RegisterMentoriaimgDowloadRotulo{
        display: flex;
        flex-direction: column;
    }

    input[type="file"]{
        display: none;
    }
    
    .labelInput{
        border:1px solid rgb(172, 172, 172);
        color: rgb(172, 172, 172);
        padding: 5px;
        border-radius: 10px;
    }
    .resgitermentoriabutton{
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        justify-content: flex-end
    }

    .maxCaracteres{
        text-align: end;
        font-size: 12px;
        font-weight: 600;
        width: 95%;
    }
</style>

<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import imgDefault from "../../assets/img/noImageMentoria.png";

export default {
    data() {
        return {
            imgDefault: imgDefault,
            banner: [],
            user: '',
            registerInfo:[''],
            material_id:'',
            registerNome:'',
            registerLink:'',
            registerTipoMentoria:'',
            registerImagem:'',
            editNome:'',
            editLink:'',
            editTipoMentoria:'',
            editImagem:'',
            editId: null,
            mentoria_material_imagem:true,
            page: "Boletim",
            turmas: [{
                text: "selecione",
                value: null
            }],
            turma_codigo: null,
            boletim: [],
            coeficiente_geral: "",
            // Auxiliares de visualização
            loading: false,
            mostrar_tabela: false,
            msg_modal: '',
            loading_modal: false,
            hash: '',
            delete_mentoria_id:''
        };
    },
    created() {
        this.carrega_turmas();
        this.listarMentoria();
        this.fetchDadosEmbed();
    },
    methods: {
        confirmarRegister(){
            this.criarMentoria();
        },

        confirmarDelete(){
            this.deletaMentoria();
        },

        editar_mentoria_id(material){
            console.log(material)
            this.editNome = material.material_titulo;
            this.editLink = material.material_url;
            this.editTipoMentoria = material.material_categoria;
            this.editImagem = material.material_imagem;
            this.editId = material.material_id;
            // console.log(this.editLink)
        },

        async criarMentoria(){
            let url = "/mentoria";
            var formData = new FormData();
            formData.append('material_titulo', this.registerNome? this.registerNome: '')
            formData.append('material_url', this.registerLink? this.registerLink: '')
            formData.append('material_categoria', this.registerTipoMentoria? this.registerTipoMentoria: '')
            // formData.append('material_imagem', this.banner? this.banner[0] : null)
            if(this.banner!=null){
                formData.append("material_imagem", this.banner[0]);
            }
            else{
                formData.append("material_imagem", null);
            }
            await this.$http.post(url, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
                if(response.status === 200){
                    alert("Cadastra realizado com sucesso!")
                    this.listarMentoria();
                }else{
                    alert("Não foi possível cadastrar os dados!")
                }
            });
        },

        async editarMentoria(){
            console.log(this.editImagem, this.editNome, this.editLink, this.editTipoMentoria, this.editId)
            let url = "/update/mentoria";
            var formData = new FormData();
            formData.append('material_id', this.editId? this.editId: null)
            formData.append('material_titulo', this.editNome? this.editNome: '')
            formData.append('material_url', this.editLink? this.editLink: '')
            formData.append('material_categoria', this.editTipoMentoria? this.editTipoMentoria: '')
            console.log(this.banner)
            if(this.banner[0]!=undefined){
                formData.append("material_imagem", this.banner[0]);
            }
            else{
                formData.append("material_imagem", this.editImagem);
            }
            await this.$http.post(url, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
                if(response.status === 200){
                    alert("Edição realizada com sucesso!")
                    this.listarMentoria();
                    this.banner = ''
                }else{
                    alert("Não foi possível editar os dados!")
                }
            });
        },

        async deletaMentoria(){
            let url = `/mentoria?material_id=${this.delete_mentoria_id}`;
            console.log(this.delete_mentoria_id)
            await this.$http.delete(url).then((response) => {
                if(response.status === 200){
                    this.$bvModal.msgBoxOk('Exclusão realizada com sucesso!', {
                        title: "Aviso!",
                        okVariant: "light",
                        okTitle: "ok",
                        headerBgVariant: "success",
                        bodyBgVariant: "success",
                        footerBgVariant: "success",
                        });
                        this.listarMentoria();
                }else{
                    this.$bvModal.msgBoxOk('Erro ao deletar!', {
                        title: "Aviso!",
                        okVariant: "light",
                        okTitle: "ok",
                        headerBgVariant: "danger",
                        bodyBgVariant: "danger",
                        footerBgVariant: "danger",
                        });
                }
            });
        },

        async listarMentoria(){
            let url = "/mentorias";
            await this.$http.get(url).then((response) => {
                if(response.status === 200){
                    this.registerInfo = response.data;
                }else{
                    alert("Não foi possível cadastrar os dados!")
                }
            });
        },

        // Select de turmas
        async carrega_turmas(){
            this.loading = true;
            let url = "turmas/lms";
            const response = await this.$http.get(url);
            response.data.forEach(turma => {
                this.turmas.push({
                    text: turma.turma_codigo,
                    value: turma.turma_codigo
                });
            });
            this.loading = false;
        },
        // Busca das informações de boletim de uma turma
        async carrega_boletim(){
            if (this.turma_codigo != null){
                this.loading = true;
                let url = "boletim/" + this.turma_codigo;
                const response = await this.$http.get(url);

                this.boletim = [];
                let data_table = new Array();
                let colunas_escondidas = [1,2,3,4,5,6,7,8,9,10,11,12,13];
                response.data.forEach(aluno => {
                    let value_slot1 = '';
                    let value_slot2 = '';
                    let value_slot3 = '';
                    let value_slot4 = '';
                    let value_slot5 = '';
                    let value_slot6 = '';
                    let value_slot7 = '';
                    let value_slot8 = '';
                    let value_slot9 = '';
                    let value_slot10 = '';
                    let value_slot11 = '';
                    let value_slot12 = '';

                    // Tratativa das notas por slot
                    aluno.notas.forEach(nota => {
                        if(nota.Slot == 1){
                            value_slot1 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 2){
                            value_slot2 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 3){
                            value_slot3 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 4){
                            value_slot4 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 5){
                            value_slot5 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 6){
                            value_slot6 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 7){
                            value_slot7 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 8){
                            value_slot8 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 9){
                            value_slot9 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 10){
                            value_slot10 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 11){
                            value_slot11 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        if(nota.Slot == 12){
                            value_slot12 = Math.round(nota.coeficienteGeral * 100) / 100;
                        }
                        
                        for(let i=0; i<colunas_escondidas.length; i++){
                            if (colunas_escondidas[i] === nota.Slot) {
                                colunas_escondidas.splice(i, 1);
                            }
                        }
                    });

                    data_table.push([
                        "<span>" + aluno.aluno_id + "</span>",
                        "<span>" + aluno.aluno_nome + "</span>",
                        "<span>" + value_slot1 + "</span>",
                        "<span>" + value_slot2 + "</span>",
                        "<span>" + value_slot3 + "</span>",
                        "<span>" + value_slot4 + "</span>",
                        "<span>" + value_slot5 + "</span>",
                        "<span>" + value_slot6 + "</span>",
                        "<span>" + value_slot7 + "</span>",
                        "<span>" + value_slot8 + "</span>",
                        "<span>" + value_slot9 + "</span>",
                        "<span>" + value_slot10 + "</span>",
                        "<span>" + value_slot11 + "</span>",
                        "<span>" + value_slot12 + "</span>"
                    ]);
                });

                $("#alunos_da_turma").DataTable({
                    data: data_table,
                    destroy: true,
                    responsive: {
                        details: {
                        display: $.fn.dataTable.Responsive.display.modal({
                            header: function (row) {
                            var data = row.data();
                            return "Details for " + data[0] + " " + data[1];
                            },
                        }),
                        renderer: $.fn.dataTable.Responsive.renderer.tableAll(),
                        },
                    },
                    lengthChange: false,
                    autoWidth: false,
                    dom: 'Bfrtip',
                    buttons: [            
                        {
                            extend: 'copy',
                            text: 'Copiar',
                            className:'btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio',
                        },
                        {
                            extend: 'csv',
                            text: 'CSV',
                            className:'btn btn-secondary buttons-csv buttons-html5',
                        },
                        {
                            extend: 'excel',
                            text: 'Excel',
                            className:'btn btn-secondary buttons-excel buttons-html5',
                        },
                        {
                            extend: 'pdf',
                            text: 'PDF',
                            className:'btn btn-secondary buttons-pdf buttons-html5',
                        },
                        {
                            extend: 'print',
                            text: 'Imprimir',
                            className:'btn btn-secondary buttons-print buttons-html5',
                        },
                        {
                            extend: 'colvis',
                            text: 'Exibir Colunas',
                            className:'btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim',              
                        }
                    ],
                    columnDefs: [{
                        targets: colunas_escondidas,
                        visible: false,
                    }],
                    bLengthChange: true,
                    bPaginate: true,
                    pageLength: 10,
                    bInfo: true,
                    language: {
                        sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                        sInfoEmpty: " ",
                        sLengthMenu: "_MENU_ resultados por página",
                        sSearch: "",
                        sEmptyTable: "Nenhum registro encontrado",
                        sInfoFiltered: "Filtrados de _MAX_ registros",
                        sLoadingRecords: "Carregando...",
                        sProcessing: "Processando...",
                        sInfoPostFix: "",
                        sInfoThousands: ".",
                        sSearchPlaceholder: "Pesquisar",
                        sZeroRecords:
                        "Nenhum registro encontrado <i class='far fa-frown'></i>",
                        paginate: {
                            sNext: "Próximo",
                            sPrevious: "Anterior",
                        },
                    }
                })
                .buttons()
                .container()
                .appendTo("#example_wrapper .col-md-6:eq(0)");

                this.busca_coeficiente();
                this.mostrar_tabela = true;
                this.loading = false;
            }else{
                this.mostrar_tabela = false;
            }
        },
        // Busca pelo coeficiente geral da turma
        async busca_coeficiente(){
            if(this.turma_codigo != null){
                this.loading = true;
                let url = "coeficiente/geral/" + this.turma_codigo;
                const response = await this.$http.get(url);
                this.coeficiente_geral = Math.round(response.data * 100) / 100;
                this.loading = false;
            }
        }
    },
    mounted() {
        window.elemento_vue = this; 
    }
}
</script>