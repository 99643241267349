<template>
    <div id="root" v-show="hash">
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Reset de Senha do Aluno
                  <i class="spinner-border text-primary" v-show="loading"></i>
                  <small style="font-size:10pt;padding-left:5px;" v-show="loading">Carregando...</small>
                </h1>              
              </div>
            </div>
          </div>
        </div>
  
        <div class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Resetar acesso do aluno para que ele tenha que redefinir a senha novamente na entrada da LMS!
                  <br>Digite o <b>RID</b> e aperte <b>ENTER</b> para verificar se o aluno possui credencial! 
                </h3>
              </div>
              <div class="card-body col-sm-12">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                        <b-input-group prepend="RID" class="mt-3">
                          <b-form-input type="number" v-model="aluno_id" @keydown.enter.native="verifica_aluno_id()" @change="verifica_aluno_id()"></b-form-input>                      
                        </b-input-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                        <b-input-group prepend="Nome" class="mt-3">
                          <template #append>
                            <b-input-group-text :class="credencial_classe"><strong>{{status_aluno}}</strong></b-input-group-text>
                          </template>
                          <b-form-input readonly :class="credencial_classe" v-model="aluno_nome"></b-form-input>  
                        </b-input-group>
                    </div>                  
                  </div>
  
                  <div class="row mt-3">
                    <div class="col-md-12">
                        <b-button type="submit" variant="danger" v-show="libera_botao" @click="resetar_credencial()">Resetar Acesso</b-button>
                    </div>   
                                  
                  </div> 
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->
  
    </div>
    <!-- /.content-wrapper -->
  </template>
  
  <style scoped>
  .bs-btn i {
    transform: translateX(-5px);
  }
  
  .bs-btn-primary {
    color: var(--wht-3);
  }
  .bs-btn-primary:hover {
    color: #203d92;
  }
  .content-wrapper{
    margin-left: 0 !important;
}
  </style>
  
  
  <script>
  var body = document.body;
  body.classList.add("hold-transition");
  // body.classList.add("dark-mode");
  body.classList.add("sidebar-mini");
  body.classList.add("layout-fixed");
  body.classList.add("layout-navbar-fixed");
  body.classList.add("layout-footer-fixed");
  
  var dados_checks = new Array();
  window.dados_checks = dados_checks;
  
  import jQuery from "jquery";
  const $ = jQuery;
  window.$ = $;
  
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  // import store from '@/store'
  
  // import SelectUtmContent from "@/components/SelectUtmContent.vue";
  // import SelectUtmSource from "@/components/SelectUtmSource.vue";
  // import SelectUtmCampaign from "@/components/SelectUtmCampaign.vue";
  // import SelectUtmMedium from "@/components/SelectUtmMedium.vue";
  
  export default {
    name: "ResetCredencial",
    components: {
    },
    data() {
      return {
        preloader_status: true,
        loading: false,
        page: "",
        aluno_id:"",
        aluno_nome:"",
        status_aluno:"",
        libera_botao:false,
        credencial_classe:"text-danger",
  
        caixa_confirmacao:'',
  
        btn_executar: true,
        btn_excluir: true,
        btn_editar: true,
        hash: '',
      }
    },
    created() {
    this.fetchDadosEmbed();
      this.preloader_status = false;
    },
    computed: {
      isDisabledExecutar: function () {
        return !this.btn_executar;
      },
      isDisabledExcluir: function () {
        return !this.btn_excluir;
      },
      isDisabledEditar: function () {
        return !this.btn_editar;
      },
    },
    methods: {
        async fetchDadosEmbed() {
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.hash = urlParams.get('habilitado')
        },
  
      async verifica_aluno_id(){
        
        let url = "verifica/aluno/credencial/";      
        try{
          this.loading = true;
          const response = await this.$http.get(url+this.aluno_id);
          if(response.data.aluno_credencial == 1){
            this.status_aluno = "COM Credencial";
            this.credencial_classe = "bg-warning text-black";
            this.libera_botao = true;
          }else{
            this.status_aluno = "SEM Credencial";
            this.libera_botao = false;
            this.credencial_classe = ""
          }                
          this.aluno_nome = response.data.aluno_nome;
          this.loading = false;
        }catch(er){
          console.log("Erro!");
          this.aluno_nome = "";
          this.loading = false;
          this.libera_botao = true;
          this.status_aluno = "";
          this.credencial_classe = "";
        }
              
      },
  
      async resetar_credencial(){
  
        this.$bvModal.msgBoxConfirm('Você deseja realmente REMOVER a credencial do aluno RID '+this.aluno_id+' e Nome '+this.aluno_nome+'?', {
            title: 'Atenção - Remoção de Credencial ',
            size: 'md',
            buttonSize: 'md',
            cancelTitle: 'Não',
            cancelVariant: 'info',
            okVariant: 'danger',
            okTitle: 'SIM',          
            headerClass: 'p-2 border-bottom-0 bg-danger',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          .then(value => {
            this.caixa_confirmacao = value
            if(value==true){
              this.remover_credencial();
            }
            else{
              this.$bvModal.msgBoxOk('Credencial não Removida!',{ title: 'Remoção de Credencial Cancelada',size: 'md',buttonSize: 'md',centered: true});
            }
          })
          .catch(err => {
            this.libera_botao = false;
            this.status_aluno = "";
            this.credencial_classe = "";
            this.loading = false;
            console.log("Erro!"+err);          
          })
        
  
        
  
      },
  
      async remover_credencial(){        
          try{
            let url = "exclui/credencial/aluno"; 
            this.loading = true;
            let dados = {
              "aluno_id":this.aluno_id
            };
            await this.$http.post(url,dados);     
            this.loading = false;      
            this.$bvModal.msgBoxOk('Credencial Removida com Sucesso!',{ title: 'Remoção de Credencial',headerClass: 'p-2 border-bottom-0 bg-success',size: 'md',buttonSize: 'md',centered: true});  
            this.aluno_id = "";
            this.aluno_nome = "";
            this.libera_botao = false;
            this.status_aluno = "";
            this.credencial_classe = "";
          }catch(er){
            console.log("Erro!"+er);
            this.loading = false;
          }
      },
    },
    mounted() {
  
    },
  };
  </script>
  
  