<template>
  <div id="root">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />

    <div class="content-wrapper" v-show="!habilitar_pagina">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Usuário sem permissão de acesso!</h1>
            </div>
            <div class="col-sm-12">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Alunos</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
      
    <!-- MODAL DE COMPRAS POR ALUNO -->
    <b-modal size="xl" id="modal_compras">
      <template #modal-title>Compras de {{ nome_aluno }}</template>
      <div class="overflow-auto">
        <table
          id="tabela_compras_aluno"
          class="table table-bordered table-hover"
        >
          <thead>
            <tr>                
                <th>Data</th>
                <th>Treinamento</th>
                <th>Carrinho ID</th>
                <th>Carrinho Valor</th>
                <th>Compra ID</th>
                <th>Compra valor</th>                
                <th>Status</th>
                <th>Forma de pagamento</th> 
            </tr>
          </thead>
          <tbody>
            <tr v-for="compra in aluno_compras_arr" :key="compra.compra_id">
                <th>{{ compra.data.split(" ")[0] }} <br> {{ compra.data.split(" ")[1] }}</th>
                <th>{{ compra.treinamento }}</th>
                <th>{{ compra.carrinho_id }}</th>
                <th>R$ {{ compra.carrinho_valor }}</th>
                <th>{{ compra.compra_id }}</th>
                <th>R$ {{ compra.compra_valor }}</th>                
                <th class="text-center"><i v-bind:class="[compra.carrinho_status_icon]" v-bind:title="[compra.carrinho_status]"></i></th>
                <th>{{ compra.carrinho_forma }}</th>  
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>

    <b-modal size="sm" id="modal_sem_compras">
      <div class="overflow-auto text-center">Aluno sem compras!</div>
    </b-modal>
    <!-- FIM MODALS -->

    <div class="content-wrapper" v-show="habilitar_pagina">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Compras por Alunos
                <i class="spinner-border text-primary" v-show="loadingDatatable"></i>
              </h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Compras-Alunos</li>
              </ol>
            </div>
          </div>          
        </div>
      </div>

      <div class="content" v-show="habilitar_pagina">
        <div class="container-fluid">
          <div class="card">

            <div class="card-body col-sm-12" v-show="!loadingDatatable && btn_executar">
              <div class="form-group">

                <div class="row" v-show="loadingUpload">
                  <i class="spinner-border text-primary"></i>
                </div>
              </div>

              <div class="row" v-show="loadingDatatable">
                <i class="spinner-border text-primary"></i>
              </div>

              <div class="table-responsive" v-show="!loadingDatatable">
                <table id="tableAlunos" class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Ações</th>
                      <th>RID</th>
                      <th>Nome</th>
                      <th>CPF</th>
                      <th>Email</th>
                      <th>Telefone</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Footer />
  </div>
</template>

<script>
var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

function abreModalComprasAluno(aluno_id) {
  window.elemento_vue.aluno_compras(aluno_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Aluno",
  components: {
    Preloader,
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      user: {},
      habilitar_pagina: false,
      loadingDatatable: true,
      loadingUpload: false,
      preloader_status: true,
      dados_alunos: [],
      modal_compras: false,
      aluno_compras_arr: [],
      nome_aluno: "",
    };
  },
  created() {
    this.preloader_status = false;
  },
  methods: {
    async listaAlunos() {
      this.habilitaBotoes();
      this.loadingDatatable = true;

      let url = "lista/alunos";
      const response = await this.$http.get(url);

      this.dados_alunos = response.data;

      let data_table = new Array();
      this.dados_alunos.forEach((dados_aluno) => {
        let status_cor = "text-secundary";

        let botao_compras_aluno =
          "<button class='btn btn-warning btn-sm mr-1 open-compras-aluno' title='Ver compras' id = " +
          dados_aluno.aluno_id +
          " type='button'><i id = " +
          dados_aluno.aluno_id +
          " class = 'fas fa-shopping-cart'></i></button>";

        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_executar ? botao_compras_aluno : "") +
            "</span>",

          "<span class=" + status_cor + ">" + dados_aluno.aluno_id + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_aluno.aluno_nome +
            "</span>",
          "<span class=" + status_cor + ">" + dados_aluno.aluno_cpf + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_aluno.aluno_email +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            dados_aluno.aluno_telefone +
            "</span>",
        ]);
      });
      $("#tableAlunos")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          language: {
            sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            sInfoEmpty: " ",
            sLengthMenu: "_MENU_ resultados por página",
            sSearch: "",
            sEmptyTable: "Nenhum registro encontrado",
            sInfoFiltered: "Filtrados de _MAX_ registros",
            sLoadingRecords: "Carregando...",
            sProcessing: "Processando...",
            sInfoPostFix: "",
            sInfoThousands: ".",
            sSearchPlaceholder: "Pesquisar",
            sZeroRecords:
              "Nenhum registro encontrado <i class='far fa-frown'></i>",
            paginate: {
              sNext: "Próximo",
              sPrevious: "Anterior",
            },
          },
          buttons: [
            {
              extend: "copy",
              text: "Copiar",
              className:
                "btn btn-secondary buttons-copy buttons-html5 buttons-html5-inicio",
            },
            {
              extend: "csv",
              text: "CSV",
              className: "btn btn-secondary buttons-csv buttons-html5",
            },
            {
              extend: "excel",
              text: "Excel",
              className: "btn btn-secondary buttons-excel buttons-html5",
            },
            {
              extend: "pdf",
              text: "PDF",
              className: "btn btn-secondary buttons-pdf buttons-html5",
            },
            {
              extend: "print",
              text: "Imprimir",
              className: "btn btn-secondary buttons-print buttons-html5",
            },
            {
              extend: "colvis",
              text: "Exibir Colunas",
              className:
                "btn btn-secondary buttons-collection dropdown-toggle buttons-colvis buttons-html5-fim",
            },
          ],
          drawCallback: function () {
            $(".open-compras-aluno").on("click", function (event) {
              abreModalComprasAluno(event.target.id);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#tableAlunos_wrapper .col-md-6:eq(0)"); //#example1_wrapper .col-md-6:eq(0)
      this.loadingDatatable = false;
    },

    habilitaBotoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "operacoes") {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }

      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } 
      else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },

    // Função para trazer as compras de um aluno
    async aluno_compras(aluno_id) {
      let url = "aluno/compras/" + aluno_id;
      const response = await this.$http.get(url);
      // Limpo o array de compras
      this.aluno_compras_arr = [];
      this.nome_aluno = "";
      // Monto a tabela
      response.data.forEach((compra) => {
        this.nome_aluno = compra.aluno_nome;        
        let status
       
        if(compra.carrinho_status=="paid"){
          status = "fas fa-check fa-lg text-success"
        }
        if(compra.carrinho_status=="waiting_payment"){
          status = "fas fa-history fa-lg text-warning"

        }
        if(compra.carrinho_status=="refused"){
          status = "fas fa-times-circle fa-lg text-danger"
        }
        if(compra.carrinho_status=="refounded"){
          status = "fas fa-wallet fa-lg text-info"

        }
        if(compra.carrinho_status=="processing"){
          status = "fas fa-cog fa-lg text-secondary"

        }
        // Preenchimento das informações
        this.aluno_compras_arr.push({
          data: compra.carrinho_data,
          treinamento:
            compra.agenda_id_antigo + " - " + compra.treinamento_nome,
          compra_id: compra.compra_id,
          compra_valor: compra.compra_valor,
          carrinho_id: compra.carrinho_id,
          carrinho_status: compra.carrinho_status,
          carrinho_status_icon:status,
          carrinho_forma: compra.carrinho_forma_descricao,
          carrinho_forma_id: compra.carrinho_forma,
          carrinho_valor: compra.carrinho_valor
        });
      });

      // Se aluno sem compras
      if (this.nome_aluno == "") {
        this.$bvModal.show("modal_sem_compras");
      } else {
        this.$bvModal.show("modal_compras");
      }
    }
  },
  filters: {
    dinheiro: function (value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  mounted() {
    window.elemento_vue = this;
    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);

    // Somente operações conseguem ver esta página
    this.user.permissoes.forEach((permissao) => {
      if (permissao.privilegio_tipo_id == 2) {
        this.habilitar_pagina = true;
        this.listaAlunos();
      }
    });
  },
};
</script>
