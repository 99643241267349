<template>
    <!-- Preloader -->
    <div class="preloader flex-column justify-content-center align-items-center" id="preloader_root">
        <img class="animation__wobble" src="../assets/img/logotipo-normal_dark.png" alt="Administração DNC">
    </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Login from '@/components/Login.vue'
// import store from '@/store'


export default {
  name: 'Preloader',
  components: {
    // HelloWorld,
    // Login
  },
  data(){
      return{
        
      };
  },  
  
}

</script>
