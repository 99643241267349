var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-sup alert-screen"},[_c('small',[_vm._v(" Alerta do sistema: ")]),_c('h3',[_vm._v(" Aguarde um momento... ")]),_c('div',{staticClass:"flex-sup log-box"},[(_vm.porcentagem < 100)?_c('span',{staticClass:"custom-alert amarelo-alr"},[_vm._v(" Fazendo o upload dos arquivos... ")]):_c('span',{staticClass:"custom-alert amarelo-azl"},[_vm._v(" Processando arquivos... ")]),_c('figure',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"flex-sup timer-loading"},[_c('img',{attrs:{"src":require("../assets/img/timerloading.png"),"alt":"Carregando"}})]),_c('div',{staticClass:"upload-table"},[_vm._m(0),_vm._l((_vm.filas),function(fila,index){return _c('div',{key:index,staticClass:"upload-table-line"},[_c('div',{staticClass:"upload-table-cel cell-name"},[_c('div',{staticClass:"flex-sup cell-wrapper"},[_c('p',[_vm._v(" "+_vm._s(fila.name)+" ")])])]),_c('div',{staticClass:"upload-table-cel cell-size"},[_c('div',{staticClass:"flex-sup cell-wrapper"},[_c('small',[_vm._v(" "+_vm._s(Math.round(fila.size / 1048576))+" "),_c('b',[_vm._v(" MB")])])])]),_c('div',{staticClass:"upload-table-cel cell-stats"},[_c('div',{staticClass:"flex-sup cell-wrapper status-cel-col"},[(fila.failed == false)?_c('progress',{class:{ 'progress-complete': fila.porcentagem == 100 && fila.failed == false },attrs:{"max":"100"},domProps:{"value":fila.porcentagem}},[_vm._v(_vm._s(fila.porcentagem)+"%")]):_vm._e(),_c('div',{class:{
                            'flex-sup': true,
                            'table-badge': true,
                            'status-processando': fila.failed == false && fila.porcentagem != 100,
                            'status-failed': fila.failed == true,
                            'status-concluido': fila.porcentagem == 100 && fila.failed == false
                        }},[(fila.failed == false && fila.porcentagem != 100)?_c('small',[_vm._v(" Processando ("+_vm._s(fila.porcentagem)+"%) ")]):(fila.failed == true)?_c('small',[_vm._v(" Falhou! ")]):(fila.porcentagem == 100)?_c('small',[_vm._v(" Concluído! ")]):(!fila.porcentagem)?_c('small',[_vm._v(" Na fila! ")]):_vm._e(),(fila.failed == false && fila.porcentagem != 100)?_c('i',{staticClass:"fas fa-spinner"}):(fila.failed == true)?_c('i',{staticClass:"fas fa-times"}):(fila.porcentagem == 100)?_c('i',{staticClass:"fas fa-check-circle"}):(!fila.porcentagem)?_c('i',{staticClass:"fas fa-pause-circle"}):_vm._e()])])])])})],2),_c('div',{staticClass:"flex-sup estimativa"},[_c('small',[_vm._v(" Tempo percorrido: "),_c('strong',[_vm._v(" "+_vm._s(_vm.tempo_percorrido)+" ")])])]),_c('div',{staticClass:"flex-sup alert-advise"},[_c('small',[_c('span',{staticClass:"material-icons"},[_vm._v(" info ")]),_vm._v(" Quando o processamento de todos os arquivos terminar, "),_c('strong',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.close_popup()}}},[_vm._v(" clique aqui para fechar.")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-table-line upload-table-line-header"},[_c('div',{staticClass:"upload-table-cel header-cell cell-name"},[_c('strong',[_vm._v(" Nome do arquivo ")])]),_c('div',{staticClass:"upload-table-cel header-cell cell-size"},[_c('strong',[_vm._v(" Tamanho ")])]),_c('div',{staticClass:"upload-table-cel header-cell cell-stats"},[_c('strong',[_vm._v(" Status ")])]),_c('div',{staticClass:"upload-table-cel header-cell cell-opts"},[_c('strong',[_vm._v(" Opções ")])])])
}]

export { render, staticRenderFns }