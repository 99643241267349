<template>
    <div id="rootGrupos">
        <Menu />
        <Navbar />
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Configuração de Provas</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Configuração de Provas</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card">                
                    <div class="row">
                        <!-- upload box -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-5">
                                    <div>
                                        <b-form-group label="Treinamento" label-for="treinamento">
                                            <b-form-select 
                                                id="treinamento"
                                                @click.prevent
                                                :options="treinamentos"
                                                @change="lista_provas()"
                                                v-model="treinamento">
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div><br></div>
                                    <button class="btn btn-lg bs-btn btn-success float-right" type="button" @click="adicionarProva()">
                                        <span>Adicionar Prova <i class="fas fa-plus"></i></span>
                                    </button>
                                </div>
                            </div>
                            <!-- Exibição das provas -->
                            <div class="row">
                                <div class="mx-auto" v-show="loading">
                                    <div class="mx-auto text-center">
                                        <div class="spinner-border text-primary"></div>
                                    </div>
                                </div> 
                                <table id="provas_treinamentos" class="table table-bordered table-hover" v-show="!loading">
                                    <thead>
                                    <tr>
                                        <th>Ação</th>
                                        <th>#</th>
                                        <th>Nome</th>
                                        <th>Sigla</th>
                                        <th>Treinamento</th>
                                        <th>Carga Horária</th>
                                        <th>Categoria</th>
                                        <th>Descrição</th>
                                    </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal size="lg" id="modal_prova" hide-footer>
            <template #modal-title> {{ form_titulo }} </template>
            <b-form @submit.prevent="salva_edicao_prova">
                <div class="row">
                    <div class="col-7">
                        <b-form-group id="input-1" label="Nome">
                            <b-form-input
                                id="nome_prova"
                                trim
                                v-model="edit_prova_nome"
                                placeholder="ex.: Excel Avançado"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-2">
                        <b-form-group id="input-2" label="Carga Horária">
                            <b-form-input
                                id="prova_carga"
                                trim
                                v-model="edit_prova_carga"
                                placeholder="em horas.."
                                @keypress="isNumber($event)"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group id="input-3" label="Categoria">
                            <b-form-select
                                v-model="edit_prova_categoria"
                                :options="categorias"
                                required
                            ></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-10">
                        <b-form-group id="input-4" label="Treinamento">
                            <b-form-select
                                v-model="edit_treinamento_id"
                                :options="todos_treinamentos"
                                required
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-2">
                        <b-form-group id="input-6" label="Sigla">
                            <b-form-input
                                id="sigla"
                                trim
                                v-model="edit_certificado_sigla"
                                placeholder="ex.: EXA"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-form-group id="input-5" label="Descrição">
                            <b-form-textarea
                                style="height: 200px;"
                                id="descricao"
                                trim
                                placeholder="descrição da prova.."
                                v-model="edit_descricao"
                                required
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                </div>
                <hr>
                <div class="input-group justify-content-md-end">
                    <div class="mx-auto" v-show="loading_modal">
                        <div class="mx-auto text-center">
                            <div class="spinner-border text-primary"></div>
                        </div>
                    </div> 
                    <b-button
                        class="mr-1 btn btn-danger"
                        id="btn-cadastro"
                        type="submit"
                    >Salvar</b-button>
                </div>
            </b-form>
        </b-modal>
        <Footer />
    </div>
</template>

<style scoped>

    .bs-btn i {margin-left: 5px;}

</style>

<script>
function abreModalEdicao(prova_id) {
    window.elemento_vue.editaProva(prova_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {
            user: {},
            page: "Configuração de Provas",
            loading: false,
            loading_modal: false,
            provas: [],
            categorias: [{
                    text: "Intermediário",
                    value: "I"
                },{
                    text: "Final",
                    value: "F"
                }
            ],
            treinamentos: [{
                text: "Todos",
                value: "sem_filtro"
            }],
            todos_treinamentos: [],
            treinamento: "sem_filtro",
            modal_prova: true,
            form_titulo: '',
            edit_prova_tipo_id: '',
            edit_prova_nome: '',
            edit_treinamento_id: '',
            edit_prova_carga: '',
            edit_prova_categoria: '',
            edit_descricao: '',
            edit_certificado_sigla: ''
        };
    },
    created() {
        this.busca_treinamentos();
        this.lista_provas();
        this.busca_todos_treinamentos();
    },
    methods: {
        // Carregamento do select dos treinamentos
        async busca_treinamentos(){
            this.loading = true;
            let url = "busca/treinamentos";
            const response = await this.$http.get(url);

            response.data.forEach(treinamento => {
                this.treinamentos.push({
                    text: treinamento.treinamento_nome,
                    value: treinamento.treinamento_id
                });
            });

            this.loading = false;
        },
        // Carregamento do select de todos os treinamentos
        async busca_todos_treinamentos(){
            let url = "busca/todos/treinamentos";
            const response = await this.$http.get(url);

            response.data.forEach(treinamento => {
                this.todos_treinamentos.push({
                    text: treinamento.treinamento_nome,
                    value: treinamento.treinamento_id
                });
            });
        },
        // Carregamento das informações da tabela
        async lista_provas(){
            this.loading = true;
            let url = "busca/provas";
            const response = await this.$http.post(url,{
                treinamento: this.treinamento
            });

            let data_table = new Array();
            response.data.forEach(prova => {
                let categoria = '';
                if (prova.prova_categoria == "I"){
                    categoria = "Intermediário";
                }
                else if (prova.prova_categoria == "F"){
                    categoria = "Final";
                }

                // Botão para editar a prova
                let botao_editar=
                    "<button class='btn btn-warning btn-sm mr-1 open-edicao' id = " +
                    prova.prova_tipo_id +
                    " type='button'><i id = " +
                    prova.prova_tipo_id +
                    " class = 'fas fa-edit'></i>Editar</button>";

                data_table.push([
                    "<span>" + botao_editar + "</span>",
                    "<span>" + prova.prova_numero + "</span>",
                    "<span>" + prova.prova_nome + "</span>",
                    "<span>" + prova.certificado_sigla + "</span>",
                    "<span>" + prova.treinamento_id + " - " + prova.treinamento_nome + "</span>",
                    "<span>" + prova.prova_carga + "h</span>",
                    "<span>" + categoria + "</span>",
                    "<span>" + prova.descricao + "</span>"
                ]);

                this.provas.push(prova);
            });

            $("#provas_treinamentos").DataTable({
                data: data_table,
                destroy: true,
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                language: {
                    sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                    sInfoEmpty: " ",
                    sLengthMenu: "_MENU_ resultados por página",
                    sSearch: "",
                    sEmptyTable: "Nenhum registro encontrado",
                    sInfoFiltered: "Filtrados de _MAX_ registros",
                    sLoadingRecords: "Carregando...",
                    sProcessing: "Processando...",
                    sInfoPostFix: "",
                    sInfoThousands: ".",
                    sSearchPlaceholder: "Pesquisar",
                    sZeroRecords:
                    "Nenhum registro encontrado <i class='far fa-frown'></i>",
                    paginate: {
                        sNext: "Próximo",
                        sPrevious: "Anterior",
                    },
                },
                drawCallback: function() {
                    $(".open-edicao").on("click", function(event) {
                        abreModalEdicao(event.target.id);
                    });
                }
            });

            this.loading = false;
        },
        // Modal de edição de prova
        editaProva(id_prova){
            this.form_titulo = "Editar Prova";
            this.limpa_campos();

            // Montando as informações do modal de edição
            this.provas.forEach(prova => {
                if(prova.prova_tipo_id == id_prova){
                    this.edit_prova_tipo_id = prova.prova_tipo_id;
                    this.edit_prova_nome = prova.prova_nome;
                    this.edit_treinamento_id = prova.treinamento_id;
                    this.edit_prova_carga = prova.prova_carga;
                    this.edit_prova_categoria = prova.prova_categoria;
                    this.edit_descricao = prova.descricao;
                    this.edit_certificado_sigla = prova.certificado_sigla;
                }
            });

            this.modal_prova = true;
            this.$bvModal.show("modal_prova");
        },
        // Modal de inserção de prova
        adicionarProva(){
            this.form_titulo ="Adicionar Prova";
            this.limpa_campos();
            this.modal_prova = true;
            this.$bvModal.show("modal_prova");
        },
        // Função para limpar os campos
        limpa_campos(){
            this.edit_prova_nome = '';
            this.edit_treinamento_id = '';
            this.edit_prova_carga = '';
            this.edit_prova_categoria = '';
            this.edit_descricao = '';
            this.edit_certificado_sigla = '';
        },
        // Garante que o usuário colocará somente números na carga horária
        isNumber: function(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        // Envia as alterações para a edição/inserção
        async salva_edicao_prova(){
            this.loading_modal = true;
            if (this.form_titulo == "Editar Prova"){
                let url = "edita/prova";
                await this.$http.post(url,{
                    page: this.page,
                    profissional_id: this.user.dados.profissional_id,
                    acao: "Edição de Prova",
                    prova_tipo_id: this.edit_prova_tipo_id,
                    prova_nome: this.edit_prova_nome,
                    treinamento_id: this.edit_treinamento_id,
                    prova_carga: this.edit_prova_carga,
                    prova_categoria: this.edit_prova_categoria,
                    descricao: this.edit_descricao,
                    certificado_sigla: this.edit_certificado_sigla
                });
            }
            else if(this.form_titulo == "Adicionar Prova"){
                let url = "insere/prova";
                await this.$http.post(url,{
                    page: this.page,
                    profissional_id: this.user.dados.profissional_id,
                    acao: "Adição de Prova",
                    prova_nome: this.edit_prova_nome,
                    treinamento_id: this.edit_treinamento_id,
                    prova_carga: this.edit_prova_carga,
                    prova_categoria: this.edit_prova_categoria,
                    descricao: this.edit_descricao,
                    certificado_sigla: this.edit_certificado_sigla
                });
            }

            this.loading_modal = false;
            this.modal_prova = false;
            this.$bvModal.hide("modal_prova");

            alert("Alterações salvas com sucesso!");
            location.reload();
        }
    },
    mounted() {
        window.elemento_vue = this;
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    }
}
</script>