<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <div class="container">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Gerenciamento de Usuários</h1>
              </div>
              <!-- /.col -->
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                  <li class="breadcrumb-item active">Usuários</li>
                </ol>
              </div>
              <!-- /.col -->
            </div>
            <div
              class="spinner-border text-primary"
              role="status"
              v-show="loadingDatatable"
            ></div>
            <!-- /.row -->
          </div>
          <!-- /.container-fluid -->
        </div>

        <div class="card">
          <div class="row">
            <div class="card-body col-sm-3">
              <button
                class="btn btn-lg btn-success"
                v-show="!loadingDatatable && btn_executar"
                data-toggle="modal"
                data-target="#modal-success"
                type="button"
                @click="cadastraUsuario()"
              >
                <span>Novo Usuário <i class="fas fa-play"></i></span>
              </button>
            </div>
          </div>   

          <!-- /.card-header -->
          <div class="card-body">
            <table id="tableUsuarios" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Usuário</th>
                  <th>Squad</th>
                  <th>Status</th>
                  <th>Criação</th>
                  <th>CEP</th>
                  <th>Endereço</th>
                  <th>Num.</th>
                  <th>Último Acesso</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div>
            <b-modal size="xl" id="modal-usuario" hide-footer>
              <template #modal-title> {{formTitulo}} </template>
              <div>
                <b-card no-body>
                  <b-tabs card>
                    <b-tab no-body title="Informações"> 
                      <div class="container">
                        <b-form>
                          <div class="text-center">
                          <p></p>
                          <label for="">Alterar Informações</label>
                            <hr>
                          </div>                    
                          <div class="row">
                            <b-form-input
                              v-show="false"
                              id="rid"
                              v-model="formUsuario.id"
                              trim
                            ></b-form-input>
                            <div class="col-sm-3">
                              <b-form-group id="input-1" label="Nome" label-for="name">
                                <b-form-input
                                  id="name"
                                  v-model="formUsuario.nome"
                                  placeholder="Nome completo, ex.: André Luís Rodrigues"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.nome
                                }}</label>
                              </b-form-group>
                            </div>
                            <div class="col-sm-3">
                              <b-form-group
                                id="input-2"
                                label="User"
                                label-for="user"
                              >
                                <b-form-input
                                  id="user"
                                  placeholder="ex.: andre.rodrigues"
                                  v-model="formUsuario.user"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.user
                                }}</label>
                              </b-form-group>
                            </div>
                            <div class="col-sm-3">
                              <b-form-group label="Senha" label-for="senha">
                                <b-form-input
                                  id="senha"
                                  type="password"
                                  placeholder="Senha de acesso"
                                  v-model="formUsuario.senha"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.senha
                                }}</label>
                              </b-form-group>
                            </div>

                            <div class="col-sm-3">
                              <b-form-group label="Confirmar senha" label-for="confirmaSenha">
                                <b-form-input
                                  id="confirmaSenha"
                                  type="password"
                                  placeholder="digite a senha novamente"
                                  v-model="formUsuario.confirmaSenha"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.confirmaSenha
                                }}</label>
                              </b-form-group>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-4">
                              <b-form-group id="input-1" label="E-mail Corpotativo" label-for="email">
                                <b-form-input
                                  id="email"
                                  type="email"
                                  placeholder="ex.: andre.rodrigues@gmail.com"
                                  v-model="formUsuario.email_gmail"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.email_gmail
                                }}</label>
                              </b-form-group>
                            </div>
                            <div class="col-sm-2">
                              <b-form-group
                                id="input-2"
                                label="Status"
                                label-for="status"
                              >
                                <b-form-select 
                                  id="status"
                                  v-model="formUsuario.status"
                                  :options="status"
                                ></b-form-select>
                                <label class="text-danger small">{{
                                  errosFormUsuario.status
                                }}</label>
                              </b-form-group>
                            </div>
                            <div class="col-sm-3">
                              <b-form-group
                                id="input-3"
                                label="Squad"
                                label-for="squad"
                              >
                                <b-form-select 
                                  id="squad"
                                  v-model="formUsuario.squad"
                                  :options="squads"
                                ></b-form-select>
                                <label class="text-danger small">{{
                                    errosFormUsuario.squad
                                  }}</label>
                                </b-form-group>
                            </div>
                            <div class="col-sm-3">
                              <b-form-group id="input-1" label="Cargo Profissional" label-for="cargo">
                                <b-form-select
                                  id="cargo" 
                                  v-model="formUsuario.cargo"
                                  :options="cargos"
                                ></b-form-select>
                                <label class="text-danger small">{{
                                  errosFormUsuario.cargo
                                }}</label>
                              </b-form-group>
                            </div> 
                          </div>

                          <div class="row">                  
                            <div class="col-sm-2">
                              <b-form-group label="CEP" label-for="cep">
                                <b-form-input
                                  id="cep"
                                  v-model="formUsuario.cep"
                                  @change="buscaCep()"
                                  maxlength="9"
                                  v-mask="'#####-###'"
                                  placeholder="ex.: 12954-731"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.cep
                                }}</label>
                              </b-form-group>
                            </div>
                            <div class="col-sm-2">
                              <b-form-group label="Estado" label-for="estado">
                                <b-form-input
                                  id="estado"
                                  v-model="formUsuario.estado"
                                  trim
                                  placeholder="ex.: SP"
                                  :readonly="ufReadonly"
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.estado
                                }}</label>
                              </b-form-group>
                            </div>

                            <div class="col-sm-6">
                              <b-form-group id="input-1" label="Gestor Direto" label-for="gestor_direto">
                                <b-form-select
                                v-model="formUsuario.gestor_direto"
                                :options="gestores"
                              ></b-form-select>
                                <label class="text-danger small">{{
                                  errosFormUsuario.gestor_direto
                                }}</label>
                              </b-form-group>
                            </div>

                            <div class="col-sm-2">
                              <b-form-group id="input-1" label="Data Nascimento" label-for="data_nascimento">
                                <b-form-input
                                  id="data_nascimento"
                                  v-mask="'####-##-##'"
                                  placeholder="ex.: 0000-11-22"
                                  v-model="formUsuario.data_nascimento"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.data_nascimento
                                }}</label>
                              </b-form-group>
                            </div>
          
                          </div>

                          <div class="row">
                            
                            <div class="col-sm-4">
                              <b-form-group label="Cidade" label-for="cidade">
                                <b-form-input
                                  id="cidade"
                                  v-model="formUsuario.cidade"
                                  trim
                                  placeholder="ex.: São José dos Campos"
                                  :readonly="cidadeReadonly"                              
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.cidade
                                }}</label>
                              </b-form-group>
                            </div>                          

                            <div class="col-sm-7">
                              <b-form-group label="Endereço" label-for="endereco">
                                <b-form-input
                                  id="endereco"
                                  v-model="formUsuario.endereco"
                                  trim
                                  placeholder="ex.: Rua Justino Cobre"
                                  :readonly="enderecoReadonly" 
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.endereco
                                }}</label>
                              </b-form-group>
                            </div>

                            <div class="col-sm-1">
                              <b-form-group label="Num." label-for="num">
                                <b-form-input
                                  id="num"
                                  v-model="formUsuario.endereco_numero"
                                  placeholder="ex.: 61"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.endereco_numero
                                }}</label>
                              </b-form-group>
                            </div>
                          </div> 
                        </b-form> 
                      </div>                   
                    </b-tab>

                    <b-tab no-body title="Privilégios">
                      <div class="text-center">
                        <p></p>
                        <label for="">Alterar Privilégios</label>
                          <hr>
                      </div>
                      <div class="container">
                          <div class="row">                          
                            <div class="col-md-4" 
                            v-for="privilegio in formUsuario.privilegios"
                            :key='privilegio.id'>
                            <b-form-group :label= "privilegio.nome" :label-for="privilegio.nome" >
                              <b-form-select
                                v-model="privilegio.status"
                                :options="acessos"
                              ></b-form-select>
                              <label class="text-danger small">{{
                                privilegio.erro
                              }}</label>
                            </b-form-group>
                          </div>
                          </div>
                        </div>                    
                    </b-tab>

                    <b-tab no-body title="Documentação">
                      <div class="text-center">
                        <p></p>
                        <label for="">Alterar Documentação</label>
                          <hr>
                      </div>
                      <div class="container">
                          <div class="row">                          
                            <div class="col-sm-6">
                              <b-form-group label="RG" label-for="rg">
                                <b-form-input
                                  id="rg"
                                  v-model="formUsuario.rg"
                                  v-mask="'##.###.###-#'"
                                  placeholder="ex.: 31.744.846-8"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.rg
                                }}</label>
                              </b-form-group>
                            </div>

                            <div class="col-sm-6">
                              <b-form-group label="CPF" label-for="cpf">
                                <b-form-input
                                  id="cpf"
                                  v-model="formUsuario.cpf"
                                  v-mask="'###.###.###-##'"
                                  placeholder="ex.: 099.600.870-50"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.cpf
                                }}</label>
                              </b-form-group>
                            </div>
                          </div>

                          <div class="row">                          
                            <div class="col-sm-6">
                              <b-form-group label="CNPJ" label-for="cnpj">
                                <b-form-input
                                  id="cnpj"
                                  v-model="formUsuario.cnpj"
                                  v-mask="'##.###.###/####-##'"
                                  placeholder="ex.: 19.576.965/0001-55"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.cnpj
                                }}</label>
                              </b-form-group>
                            </div>

                            <div class="col-sm-6">
                              <b-form-group label="Razão Social" label-for="razao_social">
                                <b-form-input
                                  id="razao_social"
                                  v-model="formUsuario.razao_social"                                
                                  placeholder="ex.: DNC LTDA"                                
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.razao_social
                                }}</label>
                              </b-form-group>
                            </div>
                          </div>

                          <div class="row">                          
                            <div class="col-sm-6">
                              <b-form-group id="input-1" label="Data de Inicio" label-for="data_inicio">
                                <b-form-input
                                  id="data_inicio"
                                  v-mask="'####-##-##'"
                                  placeholder="ex.: 0000-12-22"
                                  v-model="formUsuario.data_inicio"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.data_inicio
                                }}</label>
                              </b-form-group>
                            </div>

                            <div class="col-sm-6">
                              <b-form-group id="input-1" label="Data de desligamento" label-for="data_desligamento">
                                <b-form-input
                                  id="data_desligamento"
                                  v-mask="'####-##-##'"
                                  placeholder="ex.: 0000-12-22"
                                  v-model="formUsuario.data_desligamento"
                                  trim
                                ></b-form-input>
                                <label class="text-danger small">{{
                                  errosFormUsuario.data_desligamento
                                }}</label>
                              </b-form-group>
                            </div>
                          </div>
                        </div>                    
                    </b-tab>

                  </b-tabs>
                </b-card>
                <div class="row justify-content-center">
                  <div
                    class="spinner-border text-primary"
                    role="status"
                    v-show="loadingUsuario"
                  ></div>
                </div>
                <div class="input-group justify-content-md-end">
                  <b-button
                    @click="limparFormUsuario()"
                    id="btn-limpa-form"
                    variant="danger"
                    >Limpar formulário</b-button
                  >
                  <b-button
                    class="mr-1"
                    @click="enviaFormUsuario()"
                    id="btn-cadastro"
                    :variant="varianteSubmit"
                    >{{ botaoSubmitUsuario }}</b-button
                  >
                </div>
              </div>
              <div>              
              </div>
            </b-modal>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style>

.card{
  padding:0px;

}

button{
  margin:10px;
}
.card-header{
  background-color: #efebe4;
  padding:11px;
  margin:0px;
} 

</style>

<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

function abreModalEdicao(usuario_id) {
  window.elemento_vue.editaUsuario(usuario_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import store from '@/store'
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Aluno",
  components: {
    // HelloWorld,
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user:{},
      btn_executar:false,
      btn_editar:false,
      btn_excluir:false,
      loadingDatatable: true,
      loadingUsuario: false,
      loadingArquivo: false,
      loadingCompra: false,
      preloader_status: true,
      file: "",
      page: "Usuarios",

    formUsuario: {
        id: "",
        nome: "",
        user: "",
        oldUser:"",
        email: "",
        status:"",
        senha:"",        
        confirmaSenha:"",
        squad: "",
        cargo:"",
        email_gmail:"",
        data_nascimento:"",
        rg:"",
        cpf:"",
        cnpj:"",
        razao_social:"",        
        data_inicio:"",
        data_desligamento:"",
        cep:"",
        endereco:"", 
        estado:"",  
        cidade:"",    
        endereco_numero:"",
        privilegios:[],
        gestor_direto:[],
    },

    errosFormUsuario: {
        nome: "",
        user: "",
        email:"",
        status:"",
        senha:"",
        confirmaSenha:"",
        squad: "",
        cargo:"",
        cep:"",
        email_gmail:"",
        data_nascimento:"",
        rg:"",
        cpf:"",
        cnpj:"",
        razao_social:"",
        data_inicio:"",
        data_desligamento:"",
        estado:"", 
        cidade:"", 
        endereco:"",
        endereco_numero:"",
        admin: "",
        financeiro: "",
        operacoes: "",
        produtos: "",
        tech:""
      },
      cidadeReadonly:true,
      ufReadonly:true,
      enderecoReadonly:true,

      

      acessos: [
        { text: "Sem privilégios", value: "N" },
        { text: "Privilégio total", value: "A" },
        { text: "Leitura", value: "L" },
        { text: "Leitura e Escrita", value: "LE" },
        { text: "Leitura e Exclusão", value: "LX" }
      ],
      status: [
          { text: "Ativo", value: "A" },
          { text: "Inativo", value: "I" },
      ],
      squads: [],
      cargos: [],
      gestores:[],
      
      formTitulo: "",
      botaoSubmitUsuario: "",
      varianteSubmit: "",
      acao: "",
      modalTamanho: "lg"
    };
  },

  methods: {

    async buscaCep() {
      let cep = this.formUsuario.cep.replace("-", "");
      let url = "https://viacep.com.br/ws/" + cep + "/json";

        const response = await this.$http.get(url);
        let viacep = response.data;
        this.formUsuario.endereco = viacep.logradouro
        this.formUsuario.cidade = viacep.localidade
        this.formUsuario.estado = viacep.uf
        if(viacep.erro=='true'){
          alert("CEP não encontrado")
          this.cidadeReadonly=true;
          this.formUsuario.cidade = 'sem cidade'
          this.ufReadonly=false;
          this.enderecoReadonly=false;
          
        }
    },

    async cadastraUsuario() {
        this.limparErrosForm()
        this.limparFormUsuario()
        this.formTitulo = "Novo usuário"
        this.varianteSubmit = "primary";
        this.botaoSubmitUsuario = "Cadastrar"
        this.$bvModal.show("modal-usuario");
        this.acao = "novo";
        this.modalTamanho = "lg";
    },

    async editaUsuario(usuario_id) {      
      this.limparErrosForm();
      this.limparFormUsuario();
      this.formTitulo = "Editar usuário"
      this.botaoSubmitUsuario = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";

      let url = "lista/usuario/" + usuario_id;

      try {
        const response = await this.$http.get(url);
        let dados_usuario = response.data.usuario;
        let privilegios = response.data.privilegios;
        this.formUsuario.id = dados_usuario.profissional_id
        this.formUsuario.nome = dados_usuario.profissional_nome
        this.formUsuario.user = dados_usuario.profissional_user
        this.formUsuario.oldUser = dados_usuario.profissional_user
        this.formUsuario.email = dados_usuario.profissional_email
        this.formUsuario.email_gmail = dados_usuario.profissional_email_gmail
        this.formUsuario.data_nascimento = dados_usuario.profissional_dt_nasc
        this.formUsuario.rg = dados_usuario.profissional_rg
        this.formUsuario.cpf = dados_usuario.profissional_cpf
        this.formUsuario.cnpj = dados_usuario.profissional_cnpj
        this.formUsuario.razao_social = dados_usuario.profissional_razao_social        
        this.formUsuario.data_inicio = dados_usuario.profissional_dt_inicio 
        this.formUsuario.data_desligamento = dados_usuario.profissional_dt_desl
        this.formUsuario.status = dados_usuario.profissional_status
        this.formUsuario.squad = dados_usuario.squad_id
        this.formUsuario.cargo = dados_usuario.profissional_cargo_id
        this.formUsuario.cep = dados_usuario.profissional_cep
        this.formUsuario.cidade = dados_usuario.cidade_nome
        this.formUsuario.estado = dados_usuario.profissional_estado
        this.formUsuario.endereco = dados_usuario.profissional_endereco
        this.formUsuario.endereco_numero = dados_usuario.profissional_end_numero
        this.formUsuario.gestor_direto = dados_usuario.gestor_id

        this.formUsuario.privilegios.forEach(privilegio => {
          privilegios.forEach(resposta => {
            if(privilegio.nome == resposta.nome){
              privilegio.status = resposta.status
            }
          })
          
        })
        this.$bvModal.show("modal-usuario");
      } catch (err) {
        let msg = "Erro ao buscar dados do usuário!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
    },

    limparErrosForm(){
        this.errosFormUsuario = {
            nome: "",
            user: "",
            email:"",
            status:"",
            senha:"",
            confirmaSenha:"",
            squad: "",
            cargo: ""
        }
        this.formUsuario.privilegios.forEach(privilegio => {
          privilegio.erro = ""
        })
    },

    async enviaFormUsuario() {
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let botaoLimpaForm = document.querySelector("#btn-limpa-form");
      let erros = false;
      botaoCadastro.disabled = true;
      botaoLimpaForm.disabled = true;

      if (!this.formUsuario.nome) {
        this.errosFormUsuario.nome = "O nome é obrigatório!";
        erros = true;
      } else {
        this.errosFormUsuario.nome = "";
      }

      if (!this.formUsuario.user) {
        this.errosFormUsuario.user = "O usuario é obrigatório!";
        erros = true;
      } else {
        this.errosFormUsuario.user = "";
      }

      if(!this.formUsuario.email_gmail) {
        this.errosFormUsuario.email_gmail = "O e-mail é obrigatório!";
        erros = true;
      } else if (!this.validateEmail(this.formUsuario.email_gmail)){
        this.errosFormUsuario.email_gmail = "O e-mail é inválido!";
        erros = true;
      }else {
          this.errosFormUsuario.email_gmail = "";
      }

      if (!this.formUsuario.status) {
        this.errosFormUsuario.status = "O status é obrigatório!";
        erros = true;
      } else {
        this.errosFormUsuario.status = "";
      }

      if((this.formUsuario.endereco || this.formUsuario.cep) && this.formUsuario.cep.length < 8) {
        this.errosFormUsuario.cep = "O cep é inválido!";
        erros = true;
      } else {
        this.errosFormUsuario.cep = "";
      }

      if(this.formUsuario.cep && !this.formUsuario.endereco_numero) {
        this.errosFormUsuario.endereco_numero = "Preencha o número!";
        erros = true;
      } else {
        this.errosFormUsuario.endereco_numero = "";
      }
    
    if(this.acao == "novo"){
      if (!this.formUsuario.senha) {
        this.errosFormUsuario.senha = "Defina uma senha!";
        erros = true;
      } else if (!this.formUsuario.confirmaSenha) {
        this.errosFormUsuario.senha = "";
        this.errosFormUsuario.confirmaSenha = "Confirme a senha!";
        erros = true;
      } else if (this.formUsuario.senha != this.formUsuario.confirmaSenha) {
        this.errosFormUsuario.confirmaSenha = "";
        this.errosFormUsuario.senha = "As senhas não coincidem!";
        erros = true
      }else{
          this.errosFormUsuario.confirmaSenha = "";
          this.errosFormUsuario.senha = "";
      }
    }else{
        if (this.formUsuario.senha && (this.formUsuario.senha != this.formUsuario.confirmaSenha)) {
        this.errosFormUsuario.confirmaSenha = "";
        this.errosFormUsuario.senha = "As senhas não coincidem!";
        erros = true
      }else{
          this.errosFormUsuario.confirmaSenha = "";
          this.errosFormUsuario.senha = "";
      }
    }

      if (!this.formUsuario.squad) {
        this.errosFormUsuario.squad = "O squad é obrigatório!";
        erros = true;
      } else {
        this.errosFormUsuario.squad = "";
      }
      
      this.formUsuario.privilegios.forEach((privilegio => {
        if(!privilegio.status){
          privilegio.erro = "Escolha o tipo de privilégio!"
          erros = true
        }else{
          privilegio.erro = ""
        }
      }))
      
      if (!erros) {
        this.loadingUsuario = true;
        try {
            let retorno;
            let url;
          if (this.acao == "novo") {
            
              url = "cadastra/usuario";
              const response = await this.$http.post(url, {
                usuario: this.formUsuario,
                page: this.page,
                profissional_id: this.user.dados.profissional_id,
                acao: "cadastrar usuario"
            });

            retorno = response.data;
            
          } else if (this.acao == "editar") {
            url = "atualiza/usuario";
            const response = await this.$http.patch(url, {
                usuario: this.formUsuario,
                page: this.page,
                profissional_id: this.user.dados.profissional_id,
                acao: "editar usuario"
            });
            retorno = response.data;
          }

          if (retorno.erro) {
            this.$bvModal.msgBoxOk(retorno.erro, {
              title: "Aviso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "danger",
              bodyBgVariant: "danger",
              footerBgVariant: "danger",
            });
          } else {
            this.$bvModal.msgBoxOk(retorno.mensagem, {
              title: "Sucesso!",
              okVariant: "light",
              okTitle: "ok",
              headerBgVariant: "success",
              bodyBgVariant: "success",
              footerBgVariant: "success",
            });
            this.$bvModal.hide("modal-usuario");
            this.listaUsuarios();
          }
        } catch (err) {
          this.$bvModal.msgBoxOk("Ocorreu um erro!", {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        }
      }
      this.loadingUsuario = false;
      botaoCadastro.disabled = false;
      botaoLimpaForm.disabled = false;
    },

    limparFormUsuario() {
        this.formUsuario.id = ""
        this.formUsuario.nome = ""
        this.formUsuario.user = ""
        this.formUsuario.email = ""
        this.formUsuario.status = ""
        this.formUsuario.senha = ""
        this.formUsuario.confirmaSenha = ""
        this.formUsuario.squad = ""
        this.formUsuario.cargo = ""
        this.formUsuario.email_gmail = ""
        this.formUsuario.data_nascimento = ""
        this.formUsuario.rg = ""
        this.formUsuario.cpf = ""
        this.formUsuario.cnpj = ""
        this.formUsuario.razao_social = ""        
        this.formUsuario.data_inicio = ""
        this.formUsuario.data_desligamento = ""
        this.formUsuario.cep = ""
        this.formUsuario.estado = ""
        this.formUsuario.endereco = ""
        this.formUsuario.endereco_numero = ""
        this.formUsuario.gestor_direto = ""
        this.formUsuario.privilegios.forEach(privilegio => {
          privilegio.status = "N"
        })
    },

    async listaPrivilegios(){
      let url = 'lista/privilegios'
      const response = await this.$http.get(url);
      let privilegios = response.data
      privilegios.forEach((privilegio => {
        this.formUsuario.privilegios.push({
          id: privilegio.privilegio_tipo_id,
          nome: privilegio.privilegio_tipo_nome,
          status:"",
          erro:""
        })
      }))
    },

    validateEmail(email) {
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(email)) {
        return 1;
      } else {
        return 0;
      }
    },

    async listaGestores(){
      let url = "lista/gestores";
       const response = await this.$http.get(url);
       response.data.forEach(gestor => {
          this.gestores.push({'text':gestor.profissional_nome, 'value':gestor.profissional_id})
       });
    },

     async listaCargos(){
      let url = "lista/cargos";
       const response = await this.$http.get(url);
       response.data.forEach(cargo => {
          this.cargos.push({'text':cargo.profissional_cargo_nome, 'value':cargo.profissional_cargo_id})
       });
    },

     async listaSquads(){
      let url = "lista/squads";
       const response = await this.$http.get(url);
       response.data.forEach(squad => {
          this.squads.push({'text':squad.squad_nome, 'value':squad.squad_id})
       });
    },

    
    async listaUsuarios() {      
      this.habilitaBotoes();
      this.loadingDatatable = true;

       let url = "lista/usuarios";
       const response = await this.$http.get(url);

       this.dados_usuarios = response.data;
       console.log(this.dados_usuarios)

      let data_table = new Array();
       this.dados_usuarios.forEach((dados_usuario) => {
        let status_cor = "text-secundary";

        let botao_editar_usuario =
          "<button class='btn btn-warning btn-sm mr-1 open-usuario' id = " +
          dados_usuario.profissional_id +
          " type='button'><i id = " +
          dados_usuario.profissional_id +
          " class = 'fas fa-edit'></i>Editar</button>";


        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_usuario : "" )+
            "</span>",

          "<span class=" + status_cor + ">" + dados_usuario.profissional_nome + "</span>",
          "<span class=" + status_cor + ">" + dados_usuario.profissional_user + "</span>",
          "<span class=" + status_cor + ">" + dados_usuario.squad_nome + "</span>",
          "<span class=" + status_cor + ">" + 
          (dados_usuario.profissional_status == "A" ? "Ativo" : "Inativo")
           + "</span>",
          "<span class=" + status_cor + ">" + dados_usuario.profissional_log_criacao + "</span>",
          "<span class=" + status_cor + ">" + dados_usuario.profissional_cep + "</span>",
          "<span class=" + status_cor + ">" + dados_usuario.profissional_endereco + "</span>",
          "<span class=" + status_cor + ">" + dados_usuario.profissional_end_numero + "</span>",
          "<span class=" + status_cor + ">" + dados_usuario.profissional_ultacesso + "</span>",
        ]);
      });
      
      $("#tableUsuarios")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          language: {
            sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            sInfoEmpty: " ",
            sLengthMenu: "_MENU_ resultados por página",
            sSearch: "",
            sEmptyTable: "Nenhum registro encontrado",
            sInfoFiltered: "Filtrados de _MAX_ registros",
            sLoadingRecords: "Carregando...",
            sProcessing: "Processando...",
            sInfoPostFix: "",
            sInfoThousands: ".",
            sSearchPlaceholder: "Pesquisar",
            sZeroRecords:
              "Nenhum registro encontrado <i class='far fa-frown'></i>",
            paginate: {
              sNext: "Próximo",
              sPrevious: "Anterior",
            },
          },
          buttons: ["copy", "csv", "excel", "pdf", "print", "colvis"],
          drawCallback: function() {
            $(".open-usuario").on("click", function(event) {
              abreModalEdicao(event.target.id);
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#tableUsuarios_wrapper .col-md-6:eq(0)"); //#example1_wrapper .col-md-6:eq(0)
      this.loadingDatatable = false;
    },


    habilitaBotoes() {
      let privilegio
      let admin = false
      
      Object.keys(this.user.privilegios).map(item => {
        
        if(this.user.privilegios[item]['privilegio_tipo_menu'] == "tecnologia"){
          privilegio = this.user.privilegios[item]['privilegio_status']
        }

        if(this.user.privilegios[item]['privilegio_tipo_menu'] == "admin"){
          admin = true
        }
      });

      if(admin){
        privilegio = 'A'
      }

      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },
  },
  filters: {
    dinheiro: function(value) {
      value = parseFloat(value);
      var dinheiro = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        useGrouping: true,
      });
      return dinheiro;
    },
  },
  created() {
    this.preloader_status = false
  },
  mounted() {
    window.elemento_vue = this
    this.user.dados = JSON.parse(sessionStorage.dados_user)
    this.user.privilegios = JSON.parse(sessionStorage.permissoes)
    this.listaPrivilegios()
    this.listaUsuarios()
    this.listaGestores()
    this.listaSquads()
    this.listaCargos()
  },
};
</script>
