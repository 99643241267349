<template>
    <div id="root">
        <Menu />
        <Navbar />

        <div class="content-wrapper" v-show="!habilitar_pagina">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Usuário sem permissão de acesso!</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Gerenciamento de Cupons</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="content-wrapper" v-show="habilitar_pagina">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Link de Vendas</h1>
                    </div>
                    <div class="col-sm-12">
                        <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#" onclick="showAlert()">Home</a></li>
                        <li class="breadcrumb-item active">Link de Vendas</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <div class="content" >
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">
                                Criação de Link de Vendas
                            </h3>
                        </div>                        
                        <div id="liveAlertPlaceholder" class="col-sm-4 m-2 mx-auto"></div>                        
                        <div class="card-body col-sm-12">
                            <div class="row">
                                <div class="col-2">
                                    <div>
                                        <b-form-group label="Status da Agenda" label-for="agenda_status">
                                            <b-form-select 
                                                id="agenda_status"
                                                @change="filtraStatus()"
                                                :options="status"                                                
                                                v-model="agenda_status">
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-show="loading" >
								<i class="spinner-border text-primary"></i>
							</div>

                            <div class="table-responsive" v-show="!loading">                                                               
                                <table id="tabela_agendas" class="table table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th style ="word-break:break-all;">Agenda ID Antigo</th>
                                        <th style ="word-break:break-all;">Treinamento</th>
                                        <!--<th style ="word-break:break-all;">Agenda ID</th>-->
                                        <th>Status</th>
                                        <th>Copiar Link</th>
                                    </tr>
                                    </thead>
                                    <tbody></tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>            
        </div>        
        
        <Footer />        
    </div>
</template>

<script>

var alertTrigger = document.getElementById('btn_copy')
if (alertTrigger) {
   window.elemento_vue.alert_bootstrap()
}

// function abreModalEdicao(cupom_id) {
//     window.elemento_vue.editaCupom(cupom_id);
// }

// function showAlert() {
//     window.elemento_vue.showAlert();
// }

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Footer,
        Navbar,
        Menu
    },
    data() {
        return {

            user: {},
            page: "Link de Vendas",
            habilitar_pagina: false,
            habilitar_botao: false,
            loading: false,
            loading_modal: false,
            agenda_status: 'Ativo',
            status: [{
                    text: "Todos",
                    value: ""
                },{
                    text: "Desativada",
                    value: "Desativada"
                },{
                    text: "Ativo",
                    value: "Ativo"
                },{
                    text: "Cancelado",
                    value: "Cancelado"
                }
            ],            
            agenda_ids: [],
            agendas:[],
                    
        };
    },
    created() {
        this.lista_agendas();
        // this.listar_agenda_ids();
        // this.listar_treinamentos();
    },
    methods: {

        alert_bootstrap(message,type){
            let wrapper = document.createElement('div')
            wrapper.innerHTML = '<div id="alerta" class="alert alert-' + type + ' alert-dismissible text-center" role="alert">' + message + '</div>'
            $('#liveAlertPlaceholder').append(wrapper)
            setTimeout(function() {
                $('.alert').fadeOut()
            }, 5000);
            
        },

        async filtraStatus() {            
            let valor = this.agenda_status
            $('#tabela_agendas').DataTable().column(2).search(valor,true,false).draw()
        },
 
        // Select de Agenda IDs
        async listar_agenda_ids(){
            let url = "listar/agenda_ids_antigo";
            const response = await this.$http.get(url);

            response.data.forEach(agenda_id => {
                this.agenda_ids.push({
                    text: agenda_id.agenda_id_antigo + " - " + agenda_id.treinamento_nome,
                    value: agenda_id.agenda_id,
                    agenda_id_antigo: agenda_id.agenda_id_antigo
                });
            });
        },
        // Select de Treinamentos
        async listar_treinamentos(){
            let url = "listar/treinamentos";
            const response = await this.$http.get(url);

            response.data.forEach(treinamento => {
                this.treinamentos.push({
                    text: treinamento.treinamento_nome,
                    value: treinamento.treinamento_nome
                });
            });
        },
        // Carregamento das informações da tabela
        async lista_agendas(){
            this.loading = true;
            let url = "lista/agendas";
            const response = await this.$http.get(url);

            let data_table = new Array();
            response.data.forEach(agenda => {
                // Botão para editar cupom
                let botao_copiar_link = " - ";
                
                //Muda texto do Status e define a cor do botão de copia
                let agenda_status=''
                let cor_copia='btn-light'
                let alert_cuidado = ''
                if(agenda.agenda_status === 0){
                    agenda_status='Desativada';
                    cor_copia='btn-warning'
                    alert_cuidado = "alert(\"Cuidado! Este treinamento está DESATIVADO!\");"
                }
                if(agenda.agenda_status === 1){
                    agenda_status='Ativo';
                    cor_copia='btn-light'
                    alert_cuidado = "window.elemento_vue.alert_bootstrap(\"Link copiado para a área de transferência com sucesso!\", \"success\");"
                }
                if(agenda.agenda_status === 2){
                    agenda_status='Cancelado';
                    cor_copia='btn-danger'
                    alert_cuidado = "alert(\"Cuidado! Este treinamento está CANCELADO!\");"
                }
                let nome_vendedor = this.user.dados.profissional_nome
                nome_vendedor = nome_vendedor.replace(/\s/g,"-");
                nome_vendedor = nome_vendedor.replace(/[ÀÁÂÃÄÅ]/g,"A");
                nome_vendedor = nome_vendedor.replace(/[àáâãäå]/g,"a");
                nome_vendedor = nome_vendedor.replace(/[ÈÉÊË]/g,"E");
                nome_vendedor = nome_vendedor.replace(/[èéêë]/g,"e");
                // nome_vendedor = nome_vendedor.replace(/[^a-z0-9]/gi,'');

                if (this.habilitar_botao){
                    botao_copiar_link =
                    " <button class='btn "+cor_copia+" btn-sm mr-1 open-edicao' id='btn_copy' class = 'fas fa-edit' onclick='navigator.clipboard.writeText(\"http://checkout.dnc.group/?id="+agenda.agenda_id+"&utm_content="+nome_vendedor.toLowerCase()+"\");"+alert_cuidado+"'> http://checkout.dnc.group/?id="+agenda.agenda_id+" <i class='far fa-copy'></i></button>";
                }

                data_table.push([
                    "<span>" + agenda.agenda_id_antigo + "</span>",
                    "<span>" + agenda.treinamento_nome + "</span>",
                    //"<span>" + agenda.agenda_id + "</span>",                    
                    agenda_status,
                    "<span>" + botao_copiar_link + "</span>"
                ]);

                this.agendas.push(agenda);
            });

            $("#tabela_agendas").DataTable({
                data: data_table,
                destroy: true,
                responsive: true,
                lengthChange: false,
                autoWidth: false,
                language: {
                    sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
                    sInfoEmpty: " ",
                    sLengthMenu: "_MENU_ resultados por página",
                    sSearch: "",
                    sEmptyTable: "Nenhum registro encontrado",
                    sInfoFiltered: "Filtrados de _MAX_ registros",
                    sLoadingRecords: "Carregando...",
                    sProcessing: "Processando...",
                    sInfoPostFix: "",
                    sInfoThousands: ".",
                    sSearchPlaceholder: "Pesquisar",
                    sZeroRecords:
                    "Nenhum registro encontrado <i class='far fa-frown'></i>",
                    paginate: {
                        sNext: "Próximo",
                        sPrevious: "Anterior",
                    },
                },
                drawCallback: function() {                   
                    // $(".open-edicao").on("click", function(event) {
                    //     abreModalEdicao(event.target.id);
                    // });
                    // $(".open-compras").on("click", function(event) {
                    //     mostraCompras(event.target.id);
                    // });
                }
            });
            $('#tabela_agendas').DataTable().column(0).order([ 0, 'asc' ]).draw() // Ordena em ordem ascendente pela coluna 0
            $('#tabela_agendas').DataTable().column(2).search('Ativo',true,false).draw() // Filtra pelo valor Ativo

            this.loading = false;
        },

        // Recarrega a tabela
        reload_page(){
            this.lista_cupons();
            this.$bvModal.hide("modal_aviso");
        },

    },
    mounted() {
        window.elemento_vue = this;
        this.user.dados = JSON.parse(sessionStorage.dados_user);
        this.user.permissoes = JSON.parse(sessionStorage.permissoes);

        this.user.permissoes.forEach(permissao => {
            if (permissao.privilegio_tipo_id == 6 || permissao.privilegio_tipo_id == 2){
                this.habilitar_pagina = true;
                if(permissao.privilegio_status == "LE" || permissao.privilegio_status == "A"){
                    this.habilitar_botao = true;
                }
            }
        });
    }
}
</script>