<template>
  <div id="rootEmissao">
    <Menu />
    <Navbar />
    <div class="content-wrapper">

      <!-- familiar nav -->
      <div class="flex-sup familiar-nav">

          <h3 class="flex-sup"> <small> LMS / Uploads em massa </small> <i class="fas fa-angle-down"></i> </h3>

          <ul class="flex-sup fami-nav-list">

          <!-- variants: isAbaLoading, isActualPlace -->

          <!-- > turmas -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/bulk-arquivos"> <i class="fas fa-copy"></i> <small> Bulk de arquivos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > grupos -->
          <li class="flex-sup"> 
              <a class="flex-sup" href="/bulk-conteudo"> <i class="fas fa-cubes"></i> <small> Bulk de conteúdo </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          <!-- > treinamentos -->
          <li class="flex-sup isActualPlace"> 
              <a class="flex-sup" href="/bulk-acessos-cursos"> <i class="fas fa-clipboard-check"></i> <small> Acesso aos cursos </small> <img src="../assets/img/mini-load.png" alt="um momento"> </a>  
          </li>

          </ul>

      </div>

      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">BULK - Acesso aos cursos</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="home.php">Home</a></li>
                <li class="breadcrumb-item active">Bulk de vídeos</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>

      <div class="container-fluid">
        <div class="card">
          <div class="row">
            <!-- upload box -->
            <div class="card-body col-sm-6">
              <div class="form-group">
                <label>Selecionar planilha:</label>
                <div class="form-group">

                  <!-- upload box -->
                  <div class="flex-sup file-upload-box">

                    <b-form-file
                      @change="envia_planilha_acessos($event)"
                      placeholder="Arraste a planilha para cá"
                      drop-placeholder="Jogue aqui!"
                      type="file"
                    ></b-form-file>

                    <div class="flex-sup upload-indiq">
                      <strong> Solte os arquivos em cima  </strong>
                      <i class="fas fa-arrow-up"></i>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="row example-line">
            <div class="card-body col-sm-4">
              <b-button class="bs-btn bs-btn-primary" href="https://spreadsheets.google.com/feeds/download/spreadsheets/Export?key=16Fb_8pAkqg3nYAhHYL4whK3QfmLJCCQZ7iQVbETVLhs">Baixar Planilha de Exemplo</b-button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="acessos"
                :items-per-page="25"
                :search="search"
                :custom-filter="filterOnlyCapsText"
                item-key="aluno_id"
                loading
                loading-text="Carregando Aguarde"
                class="elevation-1"
              ></v-data-table>
            </v-card>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <!-- /.content-wrapper -->
</template>

<style scoped>



</style>

<script>

var body = document.body;
body.classList.add("hold-transition");
// body.classList.add("dark-mode");
body.classList.add("sidebar-mini");
body.classList.add("layout-fixed");
body.classList.add("layout-navbar-fixed");
body.classList.add("layout-footer-fixed");

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;


import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";


export default {
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      acessos: [],
      search:'',
      loading:true,
      headers:[
          { text: 'Aluno ID', value: 'aluno_id' },
          { text: 'Aluno Nome', value: 'aluno_nome' },
          { text: 'Curso Titulo', value: 'curso_titulo' },]
    };
  },
  created() {
    this.acessos_listar()
  },
  methods: {
    createFormData(article) {
      var formData = new FormData();
      formData.append("data", article.file);
      return formData;
    },

    async acessos_listar() {
      this.loading = true;
      await this.$http.get('acessos/listar')
        .then((response) => {
          this.acessos = response.data.acessos                            
          this.loading = false;
        });        
    },
    
    async envia_planilha_acessos(event) {
      const file = this.createFormData({
        file: event.target.files ? event.target.files[0] : event.dataTransfer.files[0]
      })
      await this.$http.post('acessos/excel/importar', 
        file,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        this.acessos_listar()
        alert('Importado')
      })
    },
  }
}
</script>