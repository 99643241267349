<template>
  <div id="rootEmissao">
    <Preloader v-show="preloader_status" />
    <Menu />
    <Navbar />
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Salas Zoom</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Salas Zoom</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      
      <div class="content" >  
        <div class="container-fluid">
          <div class="card">
            <div class="row">
              <div class="card-body col-sm-6 text-right">
                <button
                  class="btn btn-lg bs-btn btn-success"
                  v-show="btn_executar"
                  data-toggle="modal"
                  data-target="#modal-success"
                  type="button"
                  :variant="varianteSubmit"
                  @click="cadastraSala()"
                >
                  <span>Cadastrar nova sala <i class="fas fa-play"></i></span>
                </button>
              </div>
              <div class="card-body col-sm-6">
                <button
                  class="btn btn-lg bs-btn btn-success"
                  v-show="btn_executar"
                  data-toggle="modal"
                  data-target="#modal-success"
                  type="button"
                  :variant="varianteSubmit"
                  @click="uploadModal()"
                >
                  <span>Upload em Massa <i class="fas fa-file-upload"></i></span>
                </button>
              </div>
            </div>

            <!-- Tabela com as Salas Zoom -->
            <div class="card-body">
              <table id="example1" class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Ações</th>
                    <th>RID sala</th>
                    <th>RID curso</th>
                    <th>Sala URL</th>
                    <th>Turma Codigo</th>
                    <th>Presença</th>
                    <th>Valor</th>
                    <th>Criação da sala</th>
                    <th>Ativação da sala</th>
                    <th>Desativação</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para cadastro de nova Sala -->
    <b-modal size="lg" id="modal_sala" hide-footer>
      <template #modal-title> {{ form_titulo }} </template>
      <b-form>
        <div class="row">
          <div class="col-sm-4">
            <b-form-group label="Turma codigo">
              <select class="form-control" v-model="formSala.turma_codigo">
                <option value="">Sem turma</option>
                <option
                  v-for="turma in turmas"
                  :value="turma.softcheck_turma_codigo"
                  :key="turma.softcheck_turma_id"
                >
                  {{ turma.softcheck_turma_codigo }}
                </option>
              </select>
              <label class="text-danger small">{{
                errosFormSala.turma_codigo
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group label="Tipo de presença">
              <b-form-select
                id="presenca_tipo"
                trim
                v-model="formSala.presenca_tipo"
                :options="presenca_tipo"
              ></b-form-select>
              <label class="text-danger small">{{
                errosFormSala.presenca_tipo
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group label="Valor da presença">
              <b-form-select
                id="presenca_valor"
                trim
                v-model="formSala.presenca_valor"
              >
                <b-form-select-option v-for="i in 200" :key="i" :value="i">{{
                  i
                }}</b-form-select-option>
              </b-form-select>
              <label class="text-danger small">{{
                errosFormSala.presenca_valor
              }}</label>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-8">
            <b-form-group label="URL da sala">
              <b-form-input
                id="sala_url"
                trim
                placeholder="https://"
                v-model="formSala.sala_url"
              ></b-form-input>
              <label class="text-danger small">{{
                errosFormSala.sala_url
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-4">
            <b-form-group label="Status da sala">
              <b-form-select
                id="sala_status"
                trim
                v-model="formSala.sala_status"
              >
                <b-form-select-option :value="0">Inativo</b-form-select-option>
                <b-form-select-option :value="1">Ativo</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group label="Curso LMS">
              <b-form-select id="curso_lms" trim v-model="formSala.curso_id">
                <option value="" selected>Sem Curso</option>
                <b-form-select-option
                  v-for="curso in cursos"
                  :key="curso.curso_id"
                  :value="curso.curso_id"
                >
                  {{ curso.curso_titulo }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <b-form-group label="Data de ativação da sala">
              <b-form-datepicker
                v-model="formSala.sala_dt_inicio"
                locale="br"
              ></b-form-datepicker>
              <b-form-timepicker
                v-model="formSala.sala_hr_inicio"
                locale="br"
              ></b-form-timepicker>

              <label class="text-danger small">{{
                errosFormSala.sala_data_ativa_inicio
              }}</label>
            </b-form-group>
          </div>

          <div class="col-sm-6">
            <b-form-group label="Data de fim da sala">
              <b-form-datepicker
                v-model="formSala.sala_dt_fim"
                locale="br"
              ></b-form-datepicker>
              <b-form-timepicker
                v-model="formSala.sala_hr_fim"
                locale="br"
              ></b-form-timepicker>
              <label class="text-danger small">{{
                errosFormSala.sala_data_ativa_fim
              }}</label>
            </b-form-group>
          </div>
        </div>

        <hr />
        <div class="input-group justify-content-md-end">
          <b-button
            @click="limparFormSala()"
            id="btn-limpa-form"
            variant="danger"
            >Limpar formulário</b-button
          >
          <b-button
            class="mr-1"
            @click="enviarFormSala()"
            id="btn-cadastro"
            :variant="varianteSubmit"
            >{{ botaoSubmitSala }}</b-button
          >

        </div>
      </b-form>
    </b-modal>

    <!-- Modal para upload de salas -->
    <b-modal size="xl" id="modal_upload" hide-footer>
      <template #modal-title>Upload em Massa</template>
      <!-- Tela 1 - Upload -->
      <div class="overflow-auto" v-show="tela1_upload">
        <div class="text-center">
          <p class="text-danger">
            <strong
              >Atenção! Siga o modelo do arquivo de exemplo.</strong
            >
          </p>
          <a :href="caminho" download>Arquivo exemplo para upload</a>
          <hr />
        </div>
        <div class="flex-sup file-upload-box">
          <b-form-file
            multiple
            placeholder="Arraste a planilha para cá"
            drop-placeholder="Jogue aqui!"
            type="file"
            @change="envia_arquivo($event)"
          ></b-form-file>
          <div class="flex-sup upload-indiq">
            <strong> Solte os arquivos em cima </strong>
            <i class="fas fa-arrow-up"></i>
          </div>
        </div>
        <hr />
        <div class="input-group justify-content-md-end">
          <div class="mx-auto" v-show="loading_modal">
            <div class="mx-auto text-center">
              <div class="spinner-border text-primary"></div>
            </div>
          </div>
          <b-button class="mr-1 btn btn-danger" @click="fecharUpload();">Cancelar</b-button>
        </div>
      </div>

      <!-- Tela 2 - Verificar upload -->
      <div class="overflow-auto" v-show="tela2_upload">
        <div class="text-center">
          <p class="text-danger">
            <strong
              >Confirme as informações para salvar no banco ou refaça o upload com a correção.</strong
            >
          </p>
        </div>
        <div class="flex-sup">
          <table id="infos_arquivo" class="table table-bordered table-hover">
            <thead>
            <tr>
              <th>Sala URL</th>
              <th>Turma Código</th>
              <th>Curso ID</th>
              <th>Presença Tipo</th>
              <th>Presença Valor</th>
              <th>Sala Status</th>
              <th>Data Início</th>
              <th>Data Fim</th>
            </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <hr />
        <div class="input-group justify-content-md-end">
          <div class="mx-auto" v-show="loading_modal">
            <div class="mx-auto text-center">
              <div class="spinner-border text-primary"></div>
            </div>
          </div>
          <b-button class="mr-1 btn btn-success" type="submit" @click="salvar_arquivo();">Ok</b-button>
          <b-button class="mr-1 btn btn-danger" @click="fecharUpload();">Cancelar</b-button>
        </div>
      </div>
    </b-modal>
    
    <!-- Modal para resposta -->
    <b-modal id="modal_response">
      <div class="overflow-auto text-center">
        {{mensagem_response}}
      </div>
    </b-modal>

    <Footer />
  </div>
</template>

<style>

.btn{
  margin-right:5px;
}

.bi-calendar{
  color:black;
}

.bi-calendar-fill{
  color:black;
}

.bi-clock{
  color:black;
}

.bi-clock-fill{
  color:black;
}

.dropdown-menu > .b-calendar .btn{
  color:#000 !important;
}

.dropdown-menu > .b-time .btn{
  color:#000 !important;
}

.bs-btn i {
  margin-left: 5px;
}

.table{
  font-size:11pt;
}
</style>

<script>
var dados_checks = new Array();
window.dados_checks = dados_checks;

function abreModalEdicao(sala_id) {
  window.elemento_vue.editaSala(sala_id);
}

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

// @ is an alias to /src
import Preloader from "@/components/Preloader.vue";
import Navbar from "@/components/Navbar.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Zomsalas",
  components: {
    Preloader,
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      user: {},
      cursos: {},
      preloader_status: true,
      loading: false,
      page: "ZoomSalas",
      form_titulo: "",
      botaoSubmitSala: "",
      btn_executar: null,
      btn_excluir: null,
      btn_editar: null,
      varianteSubmit: "",
      turmas: [],
      presenca_tipo: [
        { text: "Day", value: "day" },
        { text: "Semana da diversidade", value: "Semana da diversidade" },
        { text: "Mentoria", value: "Mentoria" },
        { text: "Plantão de Dúvidas", value: "plantao"},
        { text: "Banca", value: "Banca" },
        { text: "Hackathon", value: "Hackathon" },
        { text: "Network", value: "Network" },
        { text: "Staff", value: "Staff" },
      ],
      formSala: {
        sala_id: "",
        curso_id: "",
        sala_url: "",
        turma_codigo: "",
        presenca_tipo: "day",
        presenca_valor: "",
        sala_data_criacao: "",
        sala_status: "",
        sala_dt_inicio: "",
        sala_hr_inicio: "",
        sala_dt_fim: "",
        sala_hr_fim: "",
        sala_data_ativa_inicio: "",
        sala_data_ativa_fim: "",
      },
      errosFormSala: {
        sala_url: "",
        turma_codigo: "",
        presenca_valor: "",
        presenca_tipo: "",
        sala_data_ativa_inicio: "",
        sala_data_ativa_fim: "",
      },
      // Upload de salas zoom
      file: [],
      caminho: "/files/exemplo_salas_zoom.xlsx",
      loading_modal: false,
      mensagem_response: '',
      tela1_upload: true,
      tela2_upload: false
    };
  },
  created() {
    this.preloader_status = false;
    this.listaTurmas();
  },
  computed: {
    isDisabledExecutar: function () {
      return !this.btn_executar;
    },
    isDisabledExcluir: function () {
      return !this.btn_excluir;
    },
    isDisabledEditar: function () {
      return !this.btn_editar;
    },
  },
  methods: {
    async listaTurmas() {
      let url = "lista/turmas";
      const response = await this.$http.get(url);
      this.turmas = response.data;
    },

    async cadastraSala() {
      this.limparFormSala();
      this.form_titulo = "Cadastra nova sala";
      this.botaoSubmitSala = "Cadastrar sala";
      this.varianteSubmit = "primary";
      this.acao = "novo";
      this.$bvModal.show("modal_sala");
    },

    async editaSala(sala_id) {
      this.limparFormSala();
      this.form_titulo = "Editar sala";
      this.botaoSubmitSala = "Salvar";
      this.varianteSubmit = "warning";
      this.acao = "editar";
      this.modalTamanho = "lg";
      this.modal_sala = true;

      let url = "lista/sala/" + sala_id;

      try {
        const response = await this.$http.get(url);
        let dados_sala = response.data;

        this.formSala.sala_url = dados_sala.sala_url;
        this.formSala.sala_id = dados_sala.sala_id;
        this.formSala.sala_url = dados_sala.sala_url;
        this.formSala.turma_codigo = dados_sala.turma_codigo;
        this.formSala.curso_id = dados_sala.curso_id;
        this.formSala.presenca_tipo = dados_sala.presenca_tipo;
        this.formSala.presenca_valor = dados_sala.presenca_valor;
        this.formSala.sala_data_criacao = dados_sala.sala_data_criacao;
        this.formSala.sala_data_ativa_inicio =
          dados_sala.sala_data_ativa_inicio;
        this.formSala.sala_data_ativa_fim = dados_sala.sala_data_ativa_fim;

        let info_dt_inicio = dados_sala.sala_data_ativa_inicio.split(" ");
        let info_dt_fim = dados_sala.sala_data_ativa_fim.split(" ");

        this.formSala.sala_dt_inicio = info_dt_inicio[0];
        this.formSala.sala_hr_inicio = info_dt_inicio[1];
        this.formSala.sala_dt_fim = info_dt_fim[0];
        this.formSala.sala_hr_fim = info_dt_fim[1];

        this.$bvModal.show("modal_sala");
      } catch (err) {
        let msg = "Erro ao buscar dados da sala!";
        this.$bvModal.msgBoxOk(msg, {
          title: "Aviso!",
          okVariant: "light",
          okTitle: "ok",
          headerBgVariant: "danger",
          bodyBgVariant: "danger",
          footerBgVariant: "danger",
        });
      }
    },

    async lista_salas() {
      let url = "lista/salas";
      const response = await this.$http.get(url);

      this.salas = response.data;
      let data_table = new Array();
      let status_cor = "text-secundary";

      this.salas.forEach((salas) => {
        let botao_editar_sala =
          "<button class='btn btn-warning btn-sm mr-1 open-sala' id = " +
          salas.sala_id +
          " type='button'><i id = " +
          salas.sala_id +
          " class = 'fas fa-edit'></i>Editar</button>";

        let botao_copia_link =
          "<button class='btn btn-secondary text-white btn-sm mr-1 copy-sala' id = " +
          salas.turma_codigo+'&presenca_tipo='+salas.presenca_tipo+'&presenca_valor='+salas.presenca_valor
           +
          " type='button'><i id = " +
          salas.sala_id +
          " class = 'fas fa-copy'></i> Link Sala </button>";  

        data_table.push([
          "<span class=" +
            status_cor +
            ">" +
            (this.btn_editar ? botao_editar_sala : "") + botao_copia_link + 
            "</span>",

          "<span class=" + status_cor + ">" + salas.sala_id + "</span>",
          "<span class=" + status_cor + ">" + salas.curso_id + "</span>",
          "<span class=" + status_cor + ">" + salas.sala_url + "</span>",
          "<span class=" + status_cor + ">" + salas.turma_codigo + "</span>",

          "<span class=" + status_cor + ">" + salas.presenca_tipo + "</span>",
          "<span class=" + status_cor + ">" + salas.presenca_valor + "</span>",
          "<span class=" +
            status_cor +
            ">" +
            salas.sala_data_criacao +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            salas.sala_data_ativa_inicio +
            "</span>",
          "<span class=" +
            status_cor +
            ">" +
            salas.sala_data_ativa_fim +
            "</span>",
          "<span class=" + status_cor + ">" + salas.sala_status + "</span>",
        ]);
      });

      $("#example1")
        .DataTable({
          data: data_table,
          destroy: true,
          responsive: true,
          lengthChange: false,
          autoWidth: false,
          language: {
            sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            sInfoEmpty: " ",
            sLengthMenu: "_MENU_ resultados por página",
            sSearch: "",
            sEmptyTable: "Nenhum registro encontrado",
            sInfoFiltered: "Filtrados de _MAX_ registros",
            sLoadingRecords: "Carregando...",
            sProcessing: "Processando...",
            sInfoPostFix: "",
            sInfoThousands: ".",
            sSearchPlaceholder: "Pesquisar",
            sZeroRecords:
              "Nenhum registro encontrado <i class='far fa-frown'></i>",
            paginate: {
              sNext: "Próximo",
              sPrevious: "Anterior",
            },
          },
          buttons: [
            {
              extend: "copy",
              text: "Copiar",
              className:
                "btn btn-dark buttons-copy text-white buttons-html5 buttons-html5-inicio",
            },
            {
              extend: "csv",
              text: "CSV",
              className: "btn btn-dark text-white buttons-csv buttons-html5",
            },
            {
              extend: "excel",
              text: "Excel",
              className: "btn btn-dark text-white buttons-excel buttons-html5",
            },
            {
              extend: "pdf",
              text: "PDF",
              className: "btn btn-dark text-white buttons-pdf buttons-html5",
            },
            {
              extend: "print",
              text: "Imprimir",
              className: "btn btn-dark text-white buttons-print buttons-html5",
            },
            {
              extend: "colvis",
              text: "Exibir Colunas",
              className:
                "btn btn-dark buttons-collection text-white dropdown-toggle buttons-colvis buttons-html5-fim",
            },
          ],
          drawCallback: function () {
            $(".open-sala").on("click", function (event) {
              abreModalEdicao(event.target.id);
            });
            $(".copy-sala").on("click", function (event) {              
              navigator.clipboard.writeText("ferramentas.dnc.group/presenca/?turma_codigo="+event.target.id);
              alert('Link Copiado');
            });
          },
        })
        .buttons()
        .container()
        .appendTo("#example1_wrapper .col-md-6:eq(0)");
    },

    async lista_cursos() {
      let url = "lista/cursos";
      const response = await this.$http.get(url);
      this.cursos = response.data;
    },

    habilita_botoes() {
      let privilegio;
      let admin = false;
      Object.keys(this.user.permissoes).map((item) => {
        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "operacoes") {
          privilegio = this.user.permissoes[item]["privilegio_status"];
        }

        if (this.user.permissoes[item]["privilegio_tipo_menu"] == "admin") {
          admin = true;
        }
      });

      if (admin) {
        privilegio = "A";
      }
      if (privilegio == "A") {
        this.btn_executar = true;
        this.btn_editar = true;
        this.btn_excluir = true;
      } else {
        if (privilegio == "LE") {
          this.btn_executar = true;
          this.btn_editar = true;
          this.btn_excluir = false;
        } else {
          if (privilegio == "LX") {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = true;
          } else {
            this.btn_executar = false;
            this.btn_editar = false;
            this.btn_excluir = false;
          }
        }
      }
    },

    async enviarFormSala() {
      let botaoCadastro = document.querySelector("#btn-cadastro");
      let botaoLimpaForm = document.querySelector("#btn-limpa-form");
      let erros = false;

      botaoCadastro.disabled = true;
      botaoLimpaForm.disabled = true;

      this.formSala.sala_data_ativa_inicio =
        this.formSala.sala_dt_inicio + " " + this.formSala.sala_hr_inicio;
      this.formSala.sala_data_ativa_fim =
        this.formSala.sala_dt_fim + " " + this.formSala.sala_hr_fim;

      if (!this.formSala.sala_url) {
        this.errosFormSala.sala_url = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormSala.sala_url = "";
      }
      if (!this.formSala.turma_codigo) {
        this.errosFormSala.turma_codigo = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormSala.turma_codigo = "";
      }
      if (!this.formSala.presenca_valor) {
        this.errosFormSala.presenca_valor = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormSala.presenca_valor = "";
      }
      if (!this.formSala.presenca_tipo) {
        this.errosFormSala.presenca_tipo = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormSala.presenca_tipo = "";
      }
      if (!this.formSala.sala_dt_inicio || !this.formSala.sala_hr_inicio) {
        this.errosFormSala.sala_data_ativa_inicio =
          "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormSala.sala_data_ativa_inicio = "";
      }
      if (!this.formSala.sala_hr_fim || !this.formSala.sala_dt_fim) {
        this.errosFormSala.sala_data_ativa_fim = "O campo não foi preenchido!";
        erros = true;
      } else {
        this.errosFormSala.sala_data_ativa_fim = "";
      }

      if (!erros) {
        let retorno;
        if (this.acao == "novo") {
          let url = "cadastra/sala";
          const response = await this.$http.post(url, {
            sala: {
              sala_id: this.formSala.sala_id,
              curso_id: this.formSala.curso_id,
              sala_url: this.formSala.sala_url,
              turma_codigo: this.formSala.turma_codigo,
              presenca_tipo: this.formSala.presenca_tipo,
              presenca_valor: this.formSala.presenca_valor,
              sala_status: this.formSala.sala_status,
              sala_data_criacao: this.formSala.sala_data_criacao,
              sala_data_ativa_inicio: this.formSala.sala_data_ativa_inicio,
              sala_data_ativa_fim: this.formSala.sala_data_ativa_fim,
            },
            page: this.page,
            profissional_id: this.user.dados.profissional_id,
            acao: "cadastrar sala",
          });
          retorno = response.data;
          console.log(retorno);
        } else if (this.acao == "editar") {
          let url = "atualiza/sala";
          const response = await this.$http.patch(url, {
            sala: {
              sala_id: this.formSala.sala_id,
              curso_id: this.formSala.curso_id,
              sala_url: this.formSala.sala_url,
              turma_codigo: this.formSala.turma_codigo,
              presenca_tipo: this.formSala.presenca_tipo,
              presenca_valor: this.formSala.presenca_valor,
              sala_status: this.formSala.sala_status,
              sala_data_criacao: this.formSala.sala_data_criacao,
              sala_data_ativa_inicio: this.formSala.sala_data_ativa_inicio,
              sala_data_ativa_fim: this.formSala.sala_data_ativa_fim,
            },
            page: this.page,
            profissional_id: this.user.dados.profissional_id,
            acao: "editar sala",
          });
          retorno = response.data;
          console.log(retorno);
        }

        if (retorno.erro) {
          this.$bvModal.msgBoxOk(retorno.erro, {
            title: "Aviso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "danger",
            bodyBgVariant: "danger",
            footerBgVariant: "danger",
          });
        } else {
          this.$bvModal.msgBoxOk(retorno.mensagem, {
            title: "Sucesso!",
            okVariant: "light",
            okTitle: "ok",
            headerBgVariant: "success",
            bodyBgVariant: "success",
            footerBgVariant: "success",
          });
          this.$bvModal.hide("modal_sala");
          this.lista_salas();
        }
      }
      botaoCadastro.disabled = false;
      botaoLimpaForm.disabled = false;
    },

    limparFormSala() {
      this.formSala.curso_id = "";
      this.formSala.sala_url = "";
      this.formSala.turma_codigo = "";
      this.formSala.presenca_tipo = "day";
      this.formSala.presenca_valor = "";
      this.formSala.sala_data_criacao = "";
      this.formSala.sala_data_ativa_inicio = "";
      this.formSala.sala_data_ativa_fim = "";
      this.formSala.sala_dt_inicio = "";
      this.formSala.sala_dt_fim = "";
      this.formSala.sala_status = "";
      this.errosFormSala = {
        sala_url: "",
        turma_codigo: "",
        presenca_valor: "",
        presenca_tipo: "",
        sala_data_ativa_inicio: "",
        sala_data_ativa_fim: "",
      };
    },
    // Função de upload de arquivo com salas zoom
    uploadModal() {
      this.$bvModal.show("modal_upload");
    },
    // Envia o arquivo para back analisar
    async envia_arquivo(event) {
      this.loading_modal = true;

      let formData = new FormData();
      formData.append(
        "file",
        event.target.files ? event.target.files[0] : event.dataTransfer.files[0]
      );

      this.file = formData;

      await this.$http
        .post("verificar/upload/zoom", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data_table = new Array();
          response.data.forEach(aluno => {
            data_table.push([
              "<span>" + aluno.sala_url + "</span>",
              "<span>" + aluno.turma_codigo + "</span>",
              "<span>" + aluno.curso_id + "</span>",
              "<span>" + aluno.presenca_tipo + "</span>",
              "<span>" + aluno.presenca_valor + "</span>",
              "<span>" + aluno.sala_status + "</span>",
              "<span>" + aluno.sala_data_ativa_inicio + "</span>",
              "<span>" + aluno.sala_data_ativa_fim + "</span>",
            ]);
          });

          $("#infos_arquivo").DataTable({
            data: data_table,
            destroy: true,
            responsive: true,
            lengthChange: false,
            autoWidth: false,
            language: {
              sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
              sInfoEmpty: " ",
              sLengthMenu: "_MENU_ resultados por página",
              sSearch: "",
              sEmptyTable: "Nenhum registro encontrado",
              sInfoFiltered: "Filtrados de _MAX_ registros",
              sLoadingRecords: "Carregando...",
              sProcessing: "Processando...",
              sInfoPostFix: "",
              sInfoThousands: ".",
              sSearchPlaceholder: "Pesquisar",
              sZeroRecords:
              "Nenhum registro encontrado <i class='far fa-frown'></i>",
              paginate: {
                sNext: "Próximo",
                sPrevious: "Anterior",
              },
            }
          });

          this.tela1_upload = false;
          this.tela2_upload = true;
        })
        .catch((exception) => {
          console.log(exception.response.data.mensagem);
          this.fecharUpload();
          this.mensagem_response = "Um erro ocorreu ao analisar o arquivo. Revise-o e tente realizar o upload novamente. Se o erro persistir, entre em contato com Tech.";
          this.$bvModal.show("modal_response");
        });

      this.loading_modal = false;
    },
    // Limpa campos do Upload
    fecharUpload(){
      this.tela1_upload = true;
      this.tela2_upload = false;
      this.loading_modal = false;
      this.$bvModal.hide("modal_upload");
    },
    // Função para salvar arquivo no BD
    async salvar_arquivo() {
      this.loading_modal = true;

      await this.$http
        .post("realizar/upload/zoom", this.file, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.fecharUpload();
          this.mensagem_response = "Arquivo salvo com sucesso!";
          this.$bvModal.show("modal_response");
        })
        .catch((exception) => {
          console.log(exception.response.data.mensagem);
          this.fecharUpload();
          this.mensagem_response = "Um erro ocorreu ao analisar o arquivo. Revise o arquivo e tente fazer o upload novamente. Se o erro persistir, entre em contato com Tech.";
          this.$bvModal.show("modal_response");
        });

      this.loading_modal = false;
    },
  },
  mounted() {
    //quando liga a tela
    window.elemento_vue = this;
    Date.prototype.addDias = function (dias) {
      this.setDate(this.getDate() + dias);
    };

    let hoje = new Date();
    let amanha = new Date();
    amanha.addDias(1);
    this.date_start = hoje;
    this.date_end = amanha;

    this.user.dados = JSON.parse(sessionStorage.dados_user);
    this.user.permissoes = JSON.parse(sessionStorage.permissoes);
    this.habilita_botoes();
    this.lista_salas();
    this.lista_cursos();
  },
};
</script>


